import { UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, Modal, Radio, Select, Space, Upload, notification } from 'antd';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import Axios from 'axios';
import { UserContext } from '../../../App';
import { SERVER_URL } from '../../../config';
import moment from 'moment';

const { Option } = Select;

export default function CancelTerminationModal({terminationRequestId, open, employee, company, handleClose, deleteTerminationHandler}) {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [isError, setIsError] = useState(false);
  const [isModalClosable, setIsModalClosable] = useState(true);
  const [employmentType, setEmploymentType] = useState((employee && employee.employmentType) || 'unlimited');
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);

  const initialValues = {};


  const handleSubmitExtendEmployment = useCallback(async (formData, currentuser) => {
    formData.user = currentuser.data;
    let keys = [];
    if (formData.attachments.length !== 0) {
      formData.attachments.forEach((attachment) => {
        keys.push(attachment.location);
      });
      const zipData = {
        keys: keys,
        employee: formData.employeeObj,
      };
      try {
        await Axios.post(`${SERVER_URL}/zip-employee`, zipData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } catch (error) {
        console.log('error in creating zip-employee', error)
      }

      try {
        await Axios.get(`${SERVER_URL}/file/zip/${formData.employeeObj.firstName}_${formData.employeeObj.lastName}_${formData.employeeObj.JMBG}.zip`, {
          withCredentials: false,
        });
      } catch (error) {
        console.log('error in creating file/zip for employee extension', error)
      }
    }

    try {
      await Axios.post(
          `${SERVER_URL}/extend-employee`, formData,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          }
      );

    } catch (error) {
      console.log('error in extend employment submit', error)
    }

    try {
      await Axios.post(`${SERVER_URL}/salary-on-extend`, {
        employee: formData.employee,
        employmentType: formData.employmentType,
        endDate: formData.endDate,
        month: moment(moment(formData.endDate).format('M'), 'M').format('MMMM'),
        year: moment(moment(formData.endDate).format('YYYY'), 'YYYY').format('YYYY'),
      }, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Employee Termination is Canceled.',
        placement: 'bottomRight',
      });

      handleClose();

      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      console.log('error in updating salary upon employment extension', error)
    }
  }, []);

  

  const onFinish = (formData) => {
    let attachments = [];
    for (let i = 0; i < files.length; i++) {
      attachments.push({
        location: files[i].location,
        originalName: files[i].originalName,
      });
    }
    formData.fileList = fileList;
    formData.files = files;
    formData.attachments = attachments;
    onSubmit(formData);

  }

  const onSubmit = async (formData) => {
    formData.user = currentuser.data;
    formData.employeeObj = employee;

    if (!formData.employee 
        || !formData.company 
        || (!formData.endDate && formData.employmentType === 'limited')
        || !formData.employmentType) {
      notification.error({message: "Please properly enter data in the form.", placement: 'bottomRight'});
      return;
    }

    if (moment(formData.endDate).isSameOrBefore(employee.startDate)) {
      notification.error({message: "You can only select a day after the start of employment!", placement: 'bottomRight'});
      return;
    }

    await deleteTerminationHandler(terminationRequestId)
    await handleSubmitExtendEmployment(formData, currentuser)
  }

  useEffect(() => {

    return () => {
      form.resetFields();
      setFiles([]);
      setFileList([]);
    }
  }, [form]);


  useEffect(() => {
    form.setFieldsValue({
      employee: employee._id,
      company: company._id,
      endDate: undefined,
      employmentType: employee.employmentType
    })
    
  }, [employee, company]);

  return (
    <Modal
      centered
      width={800}
      open={open}
      closable={isModalClosable}
      onCancel={handleClose}
      title={`Cancel Termination for ${employee.firstName} ${employee.lastName}`}
      footer={[
        <Button
            key='cancel'
            type='danger'
            id="extendCancel"
            onClick={handleClose}
            styles={{marginLeft: '10px'}}
        >
            Cancel
        </Button>,
        <Button
            type='primary'
            id="extendSubmit"
            onClick={() => {
                onFinish(form.getFieldsValue());
              }
            }
        >
            Apply
        </Button>
      ]}
    >
      <Form
        name='extendEmployment'
        initialValues={initialValues}
        onFinish={(values) => {
          onFinish(values);
        }}
        layout='horizontal'
        form={form}
      >
        
        <Form.Item
          label='Choose employee'
          name='employee'
          hidden={true}
        >
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled
          >
            {employee ?
              <Option value={employee._id} key={employee._id}>
                {employee.firstName} {employee.lastName} - {employee.JMBG}
              </Option>
              : 
              <Option>
                Unknown Employee  
              </Option>}
          </Select>
        </Form.Item>
        <Form.Item
          label='Choose company'
          name='company'
          hidden={true}
        >
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled
          >
            {company ?
              <Option value={company._id} key={company._id}>
                {company.name}
              </Option>
              : 
              <Option>
                Unknown Company  
              </Option>}
          </Select>
        </Form.Item>

        <div>Type Of Employment</div><br />
        <Form.Item name='employmentType'>
          <Radio.Group onChange={(event) => setEmploymentType(event.target.value)}>
            <Space direction='vertical'>
              <Radio value='unlimited'>Unlimited employment</Radio>
              <Radio value='limited'>Limited employment</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {(employmentType === 'limited' || (employee && employee.endDate && employmentType === 'limited')) && (
          <>
            <Divider styles={{ backgroundColor: '#f0f0f0', margin: '40px 0' }} />
            <Form.Item
              label='End date of work'
              name='endDate'
              rules={[
                {
                  required: true,
                  message: 'Please select start date!',
                },
              ]}
            >
              <DatePicker styles={{ marginLeft: '7px' }} format={'DD-MM-YYYY'} />
            </Form.Item>
          </>
        )}
        <div style={{ display: 'flex' }}>
          <Form.Item name='fileOption' label='Attach file' className='attach'>
            <Upload
              fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
              listType='picture'
              action={`${SERVER_URL}/upload-s3?employee=${JSON.stringify({
                employeeID: employee && employee.JMBG,
                employeeName:
                    employee && employee.firstName + '_' + employee.lastName,
              })}`}
              name='file'
              headers={{
                Authorization: `Bearer ${currentuser.data.token}`,
                uri: 'static/pdf/',
                timestamp: Date.now().toString(),
              }}
              onChange={(info) => {
                if (!info.file.status) {
                  setIsError(true);
                }
                if (info.file.status === 'done') {
                  setIsError(false);
                  setFiles([...files, { location: info.file.response.key, originalName: info.file.name }]);
                  for (const file of info.fileList) {
                    let nameArray = file.name.split('.');
                    switch (nameArray[nameArray.length - 1]) {
                      case 'pdf':
                        file.thumbUrl = '/pdf.png';
                        break;
                      case 'docx':
                        file.thumbUrl = '/docx.png';
                        break;
                      case 'png':
                        file.thumbUrl = '/png.png';
                        break;
                      case 'jpg':
                        file.thumbUrl = '/jpg.png';
                        break;
                      case 'xlsx':
                        file.thumbUrl = '/xlsx.png';
                        break;

                      default:
                        file.thumbUrl = '/other.png';
                        break;
                    }
                  }
                  notification.success({
                    message: 'Upload successfull',
                    placement: 'bottomRight',
                  });
                } else if (info.file.status === 'error') {
                  notification.error({
                    message: 'Upload failed',
                    placement: 'bottomRight',
                  });
                }
                setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
              }}
              beforeUpload={(file) => {
                setIsError(false);
                if (!employee || !Object.keys(employee).length) {
                  notification.error({
                    message: 'You must choose employee before upload.',
                    placement: 'bottomRight',
                  });
                  return false;
                }
                if (file.size / 1000000 > 5) {
                  notification.error({
                    message: 'File is larger than 5 MB!',
                    placement: 'bottomRight',
                  });
                  return false;
                }
                return true;
              }}
              onRemove={async (file) => {
                notification.info({
                  message: 'Removing file in progress.',
                  placement: 'bottomRight',
                });
                let newFileList = [...fileList];
                let newFiles = [...files];
                let foundFile;
                for (let i = 0; i < fileList.length; i++) {
                  if (fileList[i].name === file.name) {
                    foundFile = fileList[i];
                    newFileList.splice(i, 1);
                    if (employee && employee.attachments && employee.attachments.length !== 0) {
                      employee.attachments.splice(i, 1);
                      await Axios.put(
                        `${SERVER_URL}/data/${employee._id}`,
                        { attachments: employee.attachments },
                        {
                          withCredentials: false,
                          headers: { Authorization: `Bearer ${currentuser.data.token}` },
                        },
                      );
                    }
                  }
                }
                for (let i = 0; i < files.length; i++) {
                  if (files[i].originalName === file.name) {
                    newFiles.splice(i, 1);
                  }
                }
                let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
                if (linkParts && linkParts.length !== 0) {
                  await Axios.post(`${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`, {
                    withCredentials: false,
                  });
                }
                setFileList(newFileList);
                setFiles(newFiles);
              }}
            >
              <Button id='uploadButton' icon={<UploadOutlined />} styles={{marginLeft: '65px', marginBottom: '10px'}}>
                Upload
              </Button>
            </Upload>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}
