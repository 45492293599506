import React from 'react';

export default function HRIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_13_150)'>
        <path
          d='M24.6949 23.2208L20.2605 18.7865C21.918 16.7987 22.9167 14.2435 22.9167 11.4591C22.9167 5.14099 17.7765 0.000760078 11.4583 0.000760078C5.14014 0.000760078 0 5.1409 0 11.4591C0 17.7773 5.14019 22.9175 11.4583 22.9175C14.2436 22.9175 16.7996 21.9181 18.7876 20.2597L23.2218 24.694C23.4252 24.8974 23.6918 24.9991 23.9583 24.9991C24.2249 24.9991 24.4916 24.8974 24.6949 24.694C25.1018 24.2871 25.1018 23.6275 24.6949 23.2208Z'
          fill='#9BC9FF'
        />
        <path
          d='M11.4584 2.08418C6.28899 2.08418 2.08337 6.28975 2.08337 11.4592C2.08337 16.6286 6.28899 20.8342 11.4584 20.8342C16.6278 20.8342 20.8334 16.6286 20.8334 11.4592C20.8334 6.28975 16.6277 2.08418 11.4584 2.08418ZM6.59724 16.3203C6.59724 13.6354 8.77371 11.4591 11.4584 11.4591C9.73254 11.4591 8.33333 10.06 8.33333 8.33413C8.33333 6.60825 9.73254 5.20913 11.4584 5.20913C13.1842 5.20913 14.5834 6.60815 14.5834 8.33408C14.5834 10.06 13.1842 11.4591 11.4584 11.4591C14.1429 11.4591 16.3194 13.6353 16.3194 16.3202H6.59724V16.3203Z'
          fill='#BDFDFF'
        />
        <path
          d='M11.4584 11.459C13.1843 11.459 14.5834 10.0599 14.5834 8.33398C14.5834 6.60809 13.1843 5.20898 11.4584 5.20898C9.73248 5.20898 8.33337 6.60809 8.33337 8.33398C8.33337 10.0599 9.73248 11.459 11.4584 11.459Z'
          fill='#EFC27B'
        />
        <path
          d='M11.4584 11.4592C8.77369 11.4592 6.59723 13.6354 6.59723 16.3203H11.4584H16.3194C16.3194 13.6354 14.1429 11.4592 11.4584 11.4592Z'
          fill='#5286FA'
        />
        <path
          d='M8.33325 8.33408C8.33325 10.0601 9.73247 11.4591 11.4583 11.4591V5.20908C9.73252 5.20918 8.33325 6.6082 8.33325 8.33408Z'
          fill='#ECB45C'
        />
        <path d='M6.59723 16.3203H11.4584V11.4591C8.77369 11.4592 6.59723 13.6354 6.59723 16.3203Z' fill='#3D6DEB' />
      </g>
      <defs>
        <clipPath id='clip0_13_150'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
