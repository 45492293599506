import React from 'react';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CarouselForm = ({ excelData }) => {
  const cellStyle = {
    border: '1px solid #ccc',
    padding: '8px',
    textAlign: 'left',
    width: '300px',
  };

  const headerStyle = {
    ...cellStyle,
    fontWeight: 'bold',
    backgroundColor: '#f2f2f2',
    width: '200px',
  };

  return (
    <div className='carousel-container'>
      <Carousel
        prevArrow={<LeftOutlined />}
        nextArrow={<RightOutlined />}
        dots={true}
        draggable={true}
        dotPosition='bottom'
        arrows={true}
        infinite={false}
        fade={true}
        dotOffset={20}
      >
        {excelData?.map((item, index) => (
          <div key={index}>
            <table style={{ borderCollapse: 'collapse', width: 'auto', margin: 'auto' }}>
              <tbody>
                <tr>
                  <td style={headerStyle}>Name:</td>
                  <td style={cellStyle}>{item.firstName || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Surname:</td>
                  <td style={cellStyle}>{item.lastName || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Personal no:</td>
                  <td style={cellStyle}>{item.JMBG || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Email:</td>
                  <td style={cellStyle}>{item.email || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Position (Ser):</td>
                  <td style={cellStyle}>{item.position || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Position (Eng):</td>
                  <td style={cellStyle}>{item.positionEng || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Address:</td>
                  <td style={cellStyle}>{item.address || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>City:</td>
                  <td style={cellStyle}>{item.city || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Bank account:</td>
                  <td style={cellStyle}>{item.bankAccount || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Start date:</td>
                  <td style={cellStyle}>{item.startDate || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>End date:</td>
                  <td style={cellStyle}>{item.endDate || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Work time type:</td>
                  <td style={cellStyle}>{item.workTimeType || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Holiday days defined in contract:</td>
                  <td style={cellStyle}>{item.numberOfHolidayDaysContract || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Holiday days left from past year:</td>
                  <td style={cellStyle}>{item.numberOfHolidayDaysPast || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Holiday days used from current year:</td>
                  <td style={cellStyle}>{item.numberOfHolidayDaysUsed || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Total holiday days left:</td>
                  <td style={cellStyle}>{item.numberOfHolidayDaysCurrent || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Meal allowance:</td>
                  <td style={cellStyle}>{item.hotMeal || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Holiday allowance:</td>
                  <td style={cellStyle}>{item.regres || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Travel expenses:</td>
                  <td style={cellStyle}>{item.travelExpenses || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Other expenses:</td>
                  <td style={cellStyle}>{item.otherExpensesValue || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Suspension:</td>
                  <td style={cellStyle}>{item.suspensionValue || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Other income:</td>
                  <td style={cellStyle}>{item.otherIncomeValue || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Bonus:</td>
                  <td style={cellStyle}>{item.bonusValue || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Work from home:</td>
                  <td style={cellStyle}>{item.workFromHome || 'undefined'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselForm;
