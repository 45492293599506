import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';

const Sorting = ({ handleSortingChange, selectedSortingType, currentuser }) => {
  return (
    <>
      <div
        style={{
          position: 'relative',
          width: '250px',
        }}
      >
        <Select
          onChange={(value) => handleSortingChange(value)}
          style={{
            width: '100%',
          }}
          defaultValue={selectedSortingType}
        >
          <Select.Option value='ascending'>from A to Z</Select.Option>
          <Select.Option value='descending'>from Z to A</Select.Option>
          <Select.Option value='firstToLastAdded'>first to last added employee</Select.Option>
          <Select.Option value='lastToFirstAdded'>last to first added employee</Select.Option>
        </Select>
      </div>
      {!currentuser.data.role.includes('hrOfficer') && (
        <Tooltip
          title='Setting this order will affect order of employees displayed in timesheet section. Applied only to
                    Name column.'
          color='#b1b1b1'
          placement='right'
          autoAdjustOverflow={true}
        >
          <QuestionCircleOutlined style={{ marginLeft: '3px', color: '#b1b1b1' }} />
        </Tooltip>
      )}
    </>
  );
};

export default Sorting;
