import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import { Input, Select, Form, Button, Row, Col, notification, Divider } from 'antd';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const initialValues = {
  customerType: 'enterprise',
  customerName: undefined,
  customerNumber: undefined,
  customerCode: undefined,
  email: undefined,
  phoneNumber: undefined,
  channel: undefined,
  contactPerson: undefined,
  postalAddress: undefined,
  city: undefined,
  state: undefined,
  zipCode: undefined,
  country: undefined,
  vat: undefined,
  companyNumber: undefined,
  iban: undefined,
  bic: undefined,
};

let latinWord = [
  'NJ',
  'Nj',
  'nj',
  'N',
  'n',
  'Ć',
  'ć',
  'E',
  'e',
  'P',
  'R',
  'Dž',
  'DŽ',
  'dž',
  'Đ',
  'đ',
  'D',
  'd',
  'I',
  'A',
  'a',
  'B',
  'b',
  'Lj',
  'LJ',
  'lj',
  'J',
  'j',
  'L',
  'l',
  'K',
  'k',
  'Ž',
  'ž',
  'Z',
  'z',
  'I',
  'i',
  'U',
  'u',
  'R',
  'r',
  'V',
  'v',
  'G',
  'g',
  'C',
  'c',
  'H',
  'h',
  'Č',
  'č',
  'Š',
  'š',
  'T',
  't',
  'O',
  'o',
  'S',
  's',
  'P',
  'p',
  'F',
  'f',
  'M',
  'm',
];
let cyrillicWord = [
  'Њ',
  'Њ',
  'њ',
  'Н',
  'н',
  'Ћ',
  'ћ',
  'Е',
  'е',
  'П',
  'Р',
  'Џ',
  'Џ',
  'џ',
  'Ђ',
  'ђ',
  'Д',
  'д',
  'И',
  'А',
  'а',
  'Б',
  'б',
  'Љ',
  'Љ',
  'љ',
  'Ј',
  'ј',
  'Л',
  'л',
  'К',
  'к',
  'Ж',
  'ж',
  'З',
  'з',
  'И',
  'и',
  'У',
  'у',
  'Р',
  'р',
  'В',
  'в',
  'Г',
  'г',
  'Ц',
  'ц',
  'Х',
  'Х',
  'Ч',
  'ч',
  'Ш',
  'ш',
  'Т',
  'т',
  'О',
  'о',
  'С',
  'с',
  'П',
  'п',
  'Ф',
  'ф',
  'М',
  'м',
];

const CustomerInvoiceForm = ({
  setIsModalOpen,
  SERVER_URL,
  data,
  customerForm,
  allCompanies,
}) => {
  const currentuser = useContext(UserContext);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const history = useHistory();

  useEffect(() => {
    if (data) {
      customerForm.setFieldsValue({ ...data });
    }
  }, [data, customerForm]);

  let customer = data ? data : initialValues;

  const convertLatinToCyrillic = (str) => {
    for (const index in latinWord) {
      let wordCyrillicIndex = cyrillicWord[index];
      str = str.replaceAll(new RegExp(latinWord[index], 'g'), wordCyrillicIndex);
    }
    return str;
  };

  const handleSearch = (value) => {
    const transformedInput = convertLatinToCyrillic(value.toLowerCase());

    let filteredCompanyData = [];
    if (value.length >= 3) {
      allCompanies?.data?.filter((arr) => {
        if (arr.Name.toLowerCase().includes(value.toLowerCase()) || arr.Name.toLowerCase().includes(transformedInput)) {
          filteredCompanyData.push(arr);
        }
      });
      setFilteredCompanies(filteredCompanyData);
    }
  };

  const handleChange = (value) => {
    if (value) {
      setSelectedCompany(JSON.parse(value));
    } else {
      setSelectedCompany('');
    }
  };

  useEffect(() => {
    if (selectedCompany && !data) {
      customerForm.setFieldsValue({
        customerName: selectedCompany.Name,
        vat: `RS${selectedCompany.VatRegistrationCode}`,
        companyNumber: selectedCompany.RegistrationCode,
        budgetCompanyNumber: selectedCompany.BugetCompanyNumber,
      });
    }
  }, [selectedCompany]);

  const options = filteredCompanies.map((d, i) => (
    <Option key={i} value={JSON.stringify(d)}>
      <p>
        <b>{d.Name}</b>
      </p>
      <div>
        {d.VatRegistrationCode && <p>TIN: {d.VatRegistrationCode}</p>}
        {d.RegistrationCode && <p>Company number: {d.RegistrationCode} </p>}
      </div>
      <Divider />
    </Option>
  ));

  const onFinish = async (values) => {
    let companyId = currentuser.data.company._id;
    values.userCompany = currentuser.data.company._id;
    try {
      await Axios.post(`${SERVER_URL}/customer?filter=${JSON.stringify(companyId)}`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setIsModalOpen(false);
      history.go(0);
      notification.success({
        message: 'You have successfully added a new customer.',
        placement: 'bottomRight',
      });
    } catch (error) {
      notification.error({
        message: 'Problem with adding new customer. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const onUpdate = async (values) => {
    let companyId = currentuser?.data?.company?._id;
    try {
      await Axios.put(
        `${SERVER_URL}/customer/${data?._id}?filter=${JSON.stringify(companyId)}`,
        values,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      history.push('/admin/customers');
      notification.success({
        message: 'You have successfully updated the customer.',
        placement: 'bottomRight',
      });
    } catch (error) {
      notification.error({
        message: 'Problem with updating the customer. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <Form
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      labelAlign='left'
      initialValues={customer}
      layout='horizontal'
      form={customerForm}
      onFinish={data ? onUpdate : onFinish}
    >
      {!data && (
        <Select
          showSearch
          value={selectedCompany?.Name}
          placeholder='Search for company'
          style={{ width: '100%', marginBottom: '20px' }}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={allCompanies?.data === null ? 'Loading...' : 'No matching result found'}
          loading={true}
          allowClear={true}
        >
          {options}
        </Select>
      )}

      <h2 style={{ marginBottom: '20px', textTransform: 'uppercase' }}>Client</h2>
      <Row span={24} justify='space-between'>
        <Col span={11}>
          <Form.Item
            name='customerType'
            label='Customer Type'
            rules={[
              {
                required: true,
                message: 'Please choose customer type!',
              },
            ]}
          >
            <Select>
              <Option value='Enterprise'>Enterprise</Option>
              <Option value='Administration'>Administration</Option>
              <Option value='Residential'>Residential</Option>
            </Select>
          </Form.Item>
          <Form.Item name='customerName' label='Customer Name' required>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item name='customerNumber' label='Customer Number'>
            <Input placeholder='Customer number will be generated' />
          </Form.Item>
          <Form.Item name='customerCode' label='Customer Code'>
            <Input placeholder='Customer reference in your database' />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <h2 style={{ marginBottom: '20px', textTransform: 'uppercase' }}>Client's Details</h2>
      <Row span={24} justify='space-between'>
        <Col span={11}>
          <Form.Item name='email' label='Email'>
            <Input placeholder='Email address of your contact person' />
          </Form.Item>
          <Form.Item name='phoneNumber' label='Phone Number'>
            <Input placeholder='E.g. 0641112222' />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item name='contactPerson' label='Contact Person'>
            <Input placeholder='First and last name of your contact person' />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <h2 style={{ marginBottom: '20px', textTransform: 'uppercase' }}>Customer's Address</h2>
      <Row span={24} justify='space-between'>
        <Col span={11}>
          <Form.Item
            name='postalAddress'
            label='Postal address'
            rules={[
              {
                required: true,
                message: 'Please enter the postal address!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='city'
            label='City'
            rules={[
              {
                required: true,
                message: 'Please enter the city!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name='zipCode'
            label='Zipcode'
            rules={[
              {
                required: true,
                message: 'Please enter the zip code!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item name='state' label='State'>
            <Input />
          </Form.Item>

          <Form.Item name='country' label='Country'>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <h2 style={{ marginBottom: '20px', textTransform: 'uppercase' }}>Company's Identification</h2>
      <Row span={24} justify='space-between'>
        <Col span={11}>
          <Form.Item name='vat' label='TIN'>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item name='companyNumber' label='Company number'>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item name='budgetCompanyNumber' label='Budget company no.'>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <h2 style={{ marginBottom: '20px', textTransform: 'uppercase' }}>Bank Details</h2>
      <Row span={24} justify='space-between'>
        <Col span={11}>
          <Form.Item name='iban' label='IBAN'>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item name='bic' label='BIC'>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {data && (
        <div className='text-right'>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </div>
      )}
    </Form>
  );
};

export default CustomerInvoiceForm;
