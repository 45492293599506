import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, notification, Select, Upload } from 'antd';
import React, { useState, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';

const initialValues = {
  category: undefined,
  type: undefined,
  link: undefined,
  file: undefined,
};

const GuidelineForm = ({ data, createHandler, updateHandler, SERVER_URL, token, user }) => {
  const [form] = Form.useForm();
  const [categories, fetchCategories] = useAxios('', null, token, 'get');
  const [guideType, setGuideType] = useState(data && data.type);
  const [isError, setIsError] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const isNew = !data;

  let initValues = data ? { ...data } : initialValues;

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/guideline-category`, []);
  }, [fetchCategories, SERVER_URL]);

  const onFinish = (values) => {
    let attachments = [];
    for (let i = 0; i < files.length; i++) {
      attachments.push({
        location: files[i].location,
        originalName: files[i].originalName,
      });
    }
    values.attachments = attachments;
    let validUrl = true;
    if (values.link) {
      validUrl = isValidHttpUrl(values.link);
    }
    if (!values.link && guideType === 'link') {
      validUrl = false;
    }
    if (validUrl) {
      if (isNew) {
        createHandler(values);
      } else {
        updateHandler(values);
      }
    } else {
      notification.error({
        message: 'Link is not valid',
        placement: 'bottomRight',
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  if (data?.location && fileList.length === 0) {
    let thumbUrl;
    let nameArray = data.originalName.split('.');
    switch (nameArray[nameArray.length - 1]) {
      case 'pdf':
        thumbUrl = '/pdf.png';
        break;
      case 'docx':
        thumbUrl = '/docx.png';
        break;
      case 'png':
        thumbUrl = '/png.png';
        break;
      case 'jpg':
        thumbUrl = '/jpg.png';
        break;
      case 'xlsx':
        thumbUrl = '/xlsx.png';
        break;

      default:
        thumbUrl = '/other.png';
        break;
    }
    setFileList((prevState) => [
      ...prevState,
      {
        uid: data._id.toString(),
        name: data.originalName,
        status: 'done',
        thumbUrl,
        response: {
          key: data?.location,
        },
        fileId: data._id,
      },
    ]);
  }

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title={`${isNew ? 'NEW' : 'EDIT'} GUIDELINE`} bordered={false}>
            <Form
              name='basic'
              initialValues={initValues}
              onFinish={(values) => onFinish(values)}
              onFinishFailed={onFinishFailed}
              layout='horizontal'
              form={form}
            >
              <Form.Item
                name='category'
                label='Category'
                rules={[{ required: true, message: 'Please enter category!' }]}
              >
                <Select>
                  {categories.data &&
                    categories.data.items &&
                    categories.data.items.length > 0 &&
                    categories.data.items.map((category) => (
                      <Select.Option value={category._id}>{category.name}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name='type' label='Type' rules={[{ required: true, message: 'Please select type!' }]}>
                <Select onChange={(value) => setGuideType(value)}>
                  <Select.Option value='link'>Link</Select.Option>
                  <Select.Option value='file'>File</Select.Option>
                </Select>
              </Form.Item>
              {guideType === 'link' && (
                <Form.Item name='link' label='Link'>
                  <Input />
                </Form.Item>
              )}
              {guideType === 'file' && (
                <Form.Item name='file' label='File'>
                  <Upload
                    fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
                    listType='picture'
                    action={`${SERVER_URL}/upload-guide`}
                    name='guideFile'
                    headers={{
                      Authorization: `Bearer ${user.token}`,
                      uri: 'public/',
                      timestamp: Date.now().toString(),
                    }}
                    onChange={(info) => {
                      if (!info.file.status) {
                        setIsError(true);
                      }
                      if (info.file.status === 'done') {
                        setIsError(false);
                        setFiles([...files, { location: info.file.response.key, originalName: info.file.name }]);
                        for (const file of info.fileList) {
                          let nameArray = file.name.split('.');
                          switch (nameArray[nameArray.length - 1]) {
                            case 'pdf':
                              file.thumbUrl = '/pdf.png';
                              break;
                            case 'docx':
                              file.thumbUrl = '/docx.png';
                              break;
                            case 'png':
                              file.thumbUrl = '/png.png';
                              break;
                            case 'jpg':
                              file.thumbUrl = '/jpg.png';
                              break;
                            case 'xlsx':
                              file.thumbUrl = '/xlsx.png';
                              break;

                            default:
                              file.thumbUrl = '/other.png';
                              break;
                          }
                        }
                        notification.success({
                          message: 'Upload successfull',
                          placement: 'bottomRight',
                        });
                      } else if (info.file.status === 'error') {
                        notification.error({
                          message: 'Upload failed',
                          placement: 'bottomRight',
                        });
                      }
                      setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
                    }}
                    beforeUpload={(file) => {
                      setIsError(false);
                      if (file.size / 1000000 > 5) {
                        notification.error({
                          message: 'File is larger than 5 MB!',
                          placement: 'bottomRight',
                        });
                        return false;
                      }
                      return true;
                    }}
                    onRemove={async (file) => {
                      notification.info({
                        message: 'Removing file in progress.',
                        placement: 'bottomRight',
                      });
                      let newFileList = [...fileList];
                      let newFiles = [...files];
                      let foundFile;
                      for (let i = 0; i < fileList.length; i++) {
                        if (fileList[i].name === file.name) {
                          foundFile = fileList[i];
                          newFileList.splice(i, 1);
                        }
                      }
                      for (let i = 0; i < files.length; i++) {
                        if (files[i].originalName === file.name) {
                          newFiles.splice(i, 1);
                        }
                      }
                      let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
                      if (linkParts && linkParts.length !== 0) {
                        await Axios.post(`${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`, {
                          withCredentials: false,
                        });
                      }
                      setFileList(newFileList);
                      setFiles(newFiles);
                    }}
                  >
                    <Button id='uploadButton' icon={<UploadOutlined />}>
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
              )}
              <div className='text-right'>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default GuidelineForm;
