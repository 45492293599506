import React from 'react';
import { Form, Input, Button, Card } from 'antd';

const tailLayout = {
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const initialValues = {
  password: undefined,
};

const EmployeeForm = ({ data, updateHandler, result }) => {
  const [form] = Form.useForm();
  let user = data ? { ...data } : initialValues;

  const onFinish = (values) => {
    updateHandler(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title='Change Password' bordered={false}>
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={user}
                onFinish={(values) => onFinish(values)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >
                <Form.Item label='New password' name='newPassword'>
                  <Input.Password placeholder='New password' />
                </Form.Item>
                <Form.Item label='Confirm password' name='confirmPassword'>
                  <Input.Password placeholder='Confirm password' />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                    SUBMIT
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EmployeeForm;
