import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

export default function CompanyIcon(props) {
  const { theme } = useThemeContext();
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_32_3)'>
        <path d='M24.3258 10.9072H0.868774L12.3173 0.57843L24.3258 10.9072Z' fill='#EDF2F8' />
        <path
          d='M24.3258 11.2934H0.868769C0.714319 11.2934 0.55987 11.1968 0.501951 11.0424C0.444033 10.8879 0.482645 10.7335 0.598482 10.6176L12.0471 0.288838C12.1822 0.153695 12.4139 0.153695 12.549 0.288838L24.5768 10.6176C24.6926 10.7142 24.7505 10.8879 24.6926 11.0424C24.6347 11.1968 24.4802 11.2934 24.3258 11.2934ZM1.87269 10.5211H23.2832L12.3173 1.0997L1.87269 10.5211Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M10.5991 5.52082C10.5991 5.76676 10.6475 6.0103 10.7416 6.23753C10.8357 6.46476 10.9736 6.67123 11.1475 6.84515C11.3214 7.01907 11.5279 7.15703 11.7551 7.25116C11.9823 7.34528 12.2259 7.39373 12.4718 7.39373C12.7178 7.39373 12.9613 7.34528 13.1885 7.25116C13.4157 7.15703 13.6222 7.01907 13.7961 6.84515C13.97 6.67123 14.1079 6.46476 14.202 6.23753C14.2961 6.0103 14.3445 5.76676 14.3445 5.52082C14.3445 5.27487 14.2961 5.03133 14.202 4.8041C14.1079 4.57687 13.97 4.3704 13.7961 4.19648C13.6222 4.02256 13.4157 3.8846 13.1885 3.79047C12.9613 3.69635 12.7178 3.6479 12.4718 3.6479C12.2259 3.6479 11.9823 3.69635 11.7551 3.79047C11.5279 3.8846 11.3214 4.02256 11.1475 4.19648C10.9736 4.3704 10.8357 4.57687 10.7416 4.8041C10.6475 5.03133 10.5991 5.27487 10.5991 5.52082Z'
          fill='#C2CDDC'
        />
        <path
          d='M12.4718 7.76033C11.2362 7.76033 10.213 6.75641 10.213 5.50151C10.213 4.24661 11.2169 3.24268 12.4718 3.24268C13.7074 3.24268 14.7307 4.24661 14.7307 5.50151C14.7307 6.75641 13.7074 7.76033 12.4718 7.76033ZM12.4718 4.03424C11.661 4.03424 10.9853 4.69065 10.9853 5.52081C10.9853 6.35098 11.6417 7.00739 12.4718 7.00739C13.2827 7.00739 13.9584 6.35098 13.9584 5.52081C13.9584 4.69065 13.2827 4.03424 12.4718 4.03424Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M0.926682 10.9072H24.0555C24.3644 10.9072 24.5961 11.1582 24.5961 11.4478V11.9498C24.5961 12.2587 24.3451 12.4903 24.0555 12.4903H0.926682C0.617783 12.4903 0.386108 12.2394 0.386108 11.9498V11.4478C0.386108 11.1582 0.637089 10.9072 0.926682 10.9072Z'
          fill='white'
        />
        <path
          d='M24.0748 12.8958H0.926697C0.40543 12.8958 0 12.471 0 11.9691V11.4671C0 10.9459 0.424736 10.5404 0.926697 10.5404H24.0555C24.5768 10.5404 24.9822 10.9652 24.9822 11.4671V11.9691C25.0015 12.471 24.5768 12.8958 24.0748 12.8958ZM0.926697 11.2934C0.830166 11.2934 0.772247 11.3706 0.772247 11.4478V11.9498C0.772247 12.0463 0.849472 12.1042 0.926697 12.1042H24.0555C24.152 12.1042 24.21 12.027 24.21 11.9498V11.4478C24.21 11.3513 24.1327 11.2934 24.0555 11.2934H0.926697Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M3.80334 12.4903H6.96956V13.4557C6.96956 13.7646 6.71858 13.9962 6.42899 13.9962H4.36322C4.05433 13.9962 3.82265 13.7453 3.82265 13.4557V12.4903H3.80334Z'
          fill='#C2CDDC'
        />
        <path
          d='M6.42898 14.4017H4.36322C3.84195 14.4017 3.43652 13.9769 3.43652 13.475V12.5097C3.43652 12.2973 3.61028 12.1235 3.82265 12.1235H6.98886C7.20123 12.1235 7.37498 12.2973 7.37498 12.5097V13.475C7.35568 13.9769 6.95025 14.4017 6.42898 14.4017ZM4.18946 12.8765V13.4557C4.18946 13.5522 4.26669 13.6101 4.34391 13.6101H6.42898C6.52551 13.6101 6.58343 13.5329 6.58343 13.4557V12.8765H4.18946Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M4.36322 19.8267H6.44829C6.75719 19.8267 6.98887 20.0777 6.98887 20.3673V21.3326H3.80334V20.3673C3.80334 20.0584 4.05433 19.8267 4.36322 19.8267Z'
          fill='#C2CDDC'
        />
        <path
          d='M6.96957 21.7187H3.80336C3.59099 21.7187 3.41724 21.5449 3.41724 21.3326V20.3673C3.41724 19.846 3.84197 19.4406 4.34393 19.4406H6.429C6.95027 19.4406 7.3557 19.8653 7.3557 20.3673V21.3326C7.3557 21.5449 7.20125 21.7187 6.96957 21.7187ZM4.18948 20.9465H6.58345V20.3673C6.58345 20.2707 6.50623 20.2128 6.429 20.2128H4.36324C4.26671 20.2128 4.20879 20.29 4.20879 20.3673V20.9465H4.18948Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path d='M4.61414 14.0155H6.15863V19.8267H4.61414V14.0155Z' fill='#F3F3F3' />
        <path
          d='M6.15865 20.2128H4.61415C4.40178 20.2128 4.22803 20.0391 4.22803 19.8267V14.0155C4.22803 13.8032 4.40178 13.6294 4.61415 13.6294H6.15865C6.37101 13.6294 6.54477 13.8032 6.54477 14.0155V19.8267C6.54477 20.0391 6.37101 20.2128 6.15865 20.2128ZM5.00027 19.4406H5.77252V14.4017H5.00027V19.4406Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M8.37891 12.4903H11.5451V13.4557C11.5451 13.7646 11.2941 13.9962 11.0045 13.9962H8.91948C8.61058 13.9962 8.37891 13.7453 8.37891 13.4557V12.4903Z'
          fill='#C2CDDC'
        />
        <path
          d='M11.0046 14.4017H8.91949C8.39823 14.4017 7.9928 13.9769 7.9928 13.475V12.5483V12.5097C7.9928 12.2973 8.16655 12.1235 8.37892 12.1235H11.5451C11.7575 12.1235 11.9313 12.2973 11.9313 12.5097V13.475C11.9313 13.9769 11.5065 14.4017 11.0046 14.4017ZM8.76505 12.8765V13.4557C8.76505 13.5522 8.84227 13.6101 8.91949 13.6101H11.0046C11.1011 13.6101 11.159 13.5329 11.159 13.4557V12.8765H8.76505Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M8.91948 19.8267H10.9852C11.2941 19.8267 11.5258 20.0777 11.5258 20.3673V21.3326H8.37891V20.3673C8.37891 20.0584 8.61058 19.8267 8.91948 19.8267Z'
          fill='#C2CDDC'
        />
        <path
          d='M11.5451 21.7187H8.37892C8.16655 21.7187 7.9928 21.5449 7.9928 21.3326V20.3673C7.9928 19.846 8.41753 19.4406 8.91949 19.4406H11.0046C11.5258 19.4406 11.9313 19.8653 11.9313 20.3673V21.3326C11.9313 21.5449 11.7575 21.7187 11.5451 21.7187ZM8.76505 20.9465H11.159V20.3673C11.159 20.2707 11.0818 20.2128 11.0046 20.2128H8.91949C8.82296 20.2128 8.76505 20.29 8.76505 20.3673V20.9465Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path d='M9.1897 14.0155H10.7342V19.8267H9.1897V14.0155Z' fill='#F3F3F3' />
        <path
          d='M10.7342 20.2128H9.18971C8.97734 20.2128 8.80359 20.0391 8.80359 19.8267V14.0155C8.80359 13.8032 8.97734 13.6294 9.18971 13.6294H10.7342C10.9466 13.6294 11.1203 13.8032 11.1203 14.0155V19.8267C11.1203 20.0391 10.9466 20.2128 10.7342 20.2128ZM9.57584 19.4406H10.3481V14.4017H9.57584V19.4406Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M13.1475 12.4903H16.3137V13.4557C16.3137 13.7646 16.0627 13.9962 15.7731 13.9962H13.688C13.3791 13.9962 13.1475 13.7453 13.1475 13.4557V12.4903Z'
          fill='#C2CDDC'
        />
        <path
          d='M15.7731 14.4017H13.7073C13.1861 14.4017 12.7806 13.9769 12.7806 13.475V12.5483V12.5097C12.7806 12.2973 12.9544 12.1235 13.1668 12.1235H16.333C16.5453 12.1235 16.7191 12.2973 16.7191 12.5097V13.475C16.6998 13.9769 16.2944 14.4017 15.7731 14.4017ZM13.5336 12.8765V13.4557C13.5336 13.5522 13.6108 13.6101 13.688 13.6101H15.7731C15.8696 13.6101 15.9275 13.5329 15.9275 13.4557V12.8765H13.5336Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M13.688 19.8267H15.7538C16.0627 19.8267 16.2944 20.0777 16.2944 20.3673V21.3326H13.1475V20.3673C13.1475 20.0584 13.3984 19.8267 13.688 19.8267Z'
          fill='#C2CDDC'
        />
        <path
          d='M16.3137 21.7187H13.1475C12.9351 21.7187 12.7614 21.5449 12.7614 21.3326V20.3673C12.7614 19.846 13.1861 19.4406 13.688 19.4406H15.7731C16.2944 19.4406 16.6998 19.8653 16.6998 20.3673V21.3326C16.6998 21.5449 16.5261 21.7187 16.3137 21.7187ZM13.5336 20.9465H15.9276V20.3673C15.9276 20.2707 15.8503 20.2128 15.7731 20.2128H13.688C13.5915 20.2128 13.5336 20.29 13.5336 20.3673V20.9465Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path d='M13.9584 14.0155H15.5029V19.8267H13.9584V14.0155Z' fill='#F3F3F3' />
        <path
          d='M15.5029 20.2128H13.9584C13.746 20.2128 13.5723 20.0391 13.5723 19.8267V14.0155C13.5723 13.8032 13.746 13.6294 13.9584 13.6294H15.5029C15.7153 13.6294 15.889 13.8032 15.889 14.0155V19.8267C15.889 20.0391 15.7153 20.2128 15.5029 20.2128ZM14.3445 19.4406H15.1168V14.4017H14.3445V19.4406Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M17.7424 12.4903H20.9086V13.4557C20.9086 13.7646 20.6577 13.9962 20.3681 13.9962H18.283C17.9741 13.9962 17.7424 13.7453 17.7424 13.4557V12.4903Z'
          fill='#C2CDDC'
        />
        <path
          d='M20.368 14.4017H18.2829C17.7616 14.4017 17.3562 13.9769 17.3562 13.475V12.5483V12.5097C17.3562 12.2973 17.53 12.1235 17.7423 12.1235H20.9085C21.1209 12.1235 21.2947 12.2973 21.2947 12.5097V13.475C21.2947 13.9769 20.8699 14.4017 20.368 14.4017ZM18.1284 12.8765V13.4557C18.1284 13.5522 18.2057 13.6101 18.2829 13.6101H20.368C20.4645 13.6101 20.5224 13.5329 20.5224 13.4557V12.8765H18.1284Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M18.283 19.8267H20.3681C20.677 19.8267 20.9086 20.0777 20.9086 20.3673V21.3326H17.7424V20.3673C17.7424 20.0584 17.9741 19.8267 18.283 19.8267Z'
          fill='#C2CDDC'
        />
        <path
          d='M20.9085 21.7187H17.7423C17.53 21.7187 17.3562 21.5449 17.3562 21.3326V20.3673C17.3562 19.846 17.7809 19.4406 18.2829 19.4406H20.368C20.8892 19.4406 21.2947 19.8653 21.2947 20.3673V21.3326C21.2947 21.5449 21.1209 21.7187 20.9085 21.7187ZM18.1284 20.9465H20.5224V20.3673C20.5224 20.2707 20.4452 20.2128 20.368 20.2128H18.2829C18.1864 20.2128 18.1284 20.29 18.1284 20.3673V20.9465Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path d='M18.5532 14.0155H20.0977V19.8267H18.5532V14.0155Z' fill='#F3F3F3' />
        <path
          d='M20.0977 20.2128H18.5532C18.3409 20.2128 18.1671 20.0391 18.1671 19.8267V14.0155C18.1671 13.8032 18.3409 13.6294 18.5532 13.6294H20.0977C20.3101 13.6294 20.4839 13.8032 20.4839 14.0155V19.8267C20.4839 20.0391 20.3101 20.2128 20.0977 20.2128ZM18.9394 19.4406H19.7116V14.4017H18.9394V19.4406Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M2.58708 21.3326H22.3373C22.6462 21.3326 22.8779 21.5836 22.8779 21.8732V23.379C22.8779 23.6879 22.6269 23.9196 22.3373 23.9196H2.58708C2.27818 23.9196 2.04651 23.6686 2.04651 23.379V21.8732C2.04651 21.5836 2.27818 21.3326 2.58708 21.3326Z'
          fill='#EDF2F8'
        />
        <path
          d='M22.3565 24.3057H2.58698C2.06571 24.3057 1.66028 23.881 1.66028 23.379V21.8732C1.66028 21.3519 2.08501 20.9465 2.58698 20.9465H22.3372C22.8585 20.9465 23.2639 21.3712 23.2639 21.8732V23.379C23.2832 23.9003 22.8585 24.3057 22.3565 24.3057ZM2.58698 21.7187C2.49044 21.7187 2.43253 21.7959 2.43253 21.8732V23.379C2.43253 23.4756 2.50975 23.5335 2.58698 23.5335H22.3372C22.4337 23.5335 22.4917 23.4563 22.4917 23.379V21.8732C22.4917 21.7766 22.4144 21.7187 22.3372 21.7187H2.58698Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
        <path
          d='M0.926682 22.8385H24.0555C24.3644 22.8385 24.5961 23.0894 24.5961 23.379V23.881C24.5961 24.1899 24.3451 24.4216 24.0555 24.4216H0.926682C0.617783 24.4216 0.386108 24.1706 0.386108 23.881V23.379C0.386108 23.0894 0.637089 22.8385 0.926682 22.8385Z'
          fill='#EDF2F8'
        />
        <path
          d='M24.0748 24.8077H0.926697C0.40543 24.8077 0 24.383 0 23.881V23.379C0 22.8578 0.424736 22.4523 0.926697 22.4523H24.0555C24.5768 22.4523 24.9822 22.8771 24.9822 23.379V23.881C25.0015 24.4023 24.5768 24.8077 24.0748 24.8077ZM0.926697 23.2246C0.830166 23.2246 0.772247 23.3018 0.772247 23.379V23.881C0.772247 23.9775 0.849472 24.0354 0.926697 24.0354H24.0555C24.152 24.0354 24.21 23.9582 24.21 23.881V23.379C24.21 23.2825 24.1327 23.2246 24.0555 23.2246H0.926697Z'
          // fill={theme === 'dark' ? '#969696' : '#131313'}
          fill={theme === 'dark' ? '#969696' : '#969696'}
        />
      </g>
      <defs>
        <clipPath id='clip0_32_3'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
