import React from 'react';

export default function ViewAllRequestsIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_79_185)'>
        <path
          d='M19.4608 0C19.9113 0 20.3463 0.138529 20.6844 0.38965L24.3717 3.12824C24.7711 3.42487 25 3.85213 25 4.30109V22.6562C25 23.9507 23.7563 25 22.2222 25H2.77778C1.24365 25 0 23.9507 0 22.6562V2.34375C0 1.04933 1.24365 0 2.77778 0H19.4608Z'
          fill='url(#paint0_linear_79_185)'
        />
        <path
          d='M19.0206 -1C19.5882 -1 20.1363 -0.822683 20.5623 -0.501247L25.2084 3.00414C25.7116 3.38384 26 3.93073 26 4.50539V6H22.5C20.567 6 19 4.65685 19 3V-1H19.0206Z'
          fill='url(#paint1_linear_79_185)'
        />
        <path
          d='M17.5791 6.625C18.0914 6.625 18.5067 7.01675 18.5067 7.5C18.5067 7.98325 18.0914 8.375 17.5791 8.375H7.37559C6.8633 8.375 6.448 7.98325 6.448 7.5C6.448 7.01675 6.8633 6.625 7.37559 6.625H17.5791Z'
          fill='url(#paint2_linear_79_185)'
        />
        <path
          d='M17.5791 10.125C18.0914 10.125 18.5067 10.5168 18.5067 11C18.5067 11.4832 18.0914 11.875 17.5791 11.875H7.37559C6.8633 11.875 6.448 11.4832 6.448 11C6.448 10.5168 6.8633 10.125 7.37559 10.125H17.5791Z'
          fill='url(#paint3_linear_79_185)'
        />
        <path
          d='M17.5791 13.625C18.0914 13.625 18.5067 14.0168 18.5067 14.5C18.5067 14.9832 18.0914 15.375 17.5791 15.375H7.37559C6.8633 15.375 6.448 14.9832 6.448 14.5C6.448 14.0168 6.8633 13.625 7.37559 13.625H17.5791Z'
          fill='url(#paint4_linear_79_185)'
        />
        <path
          d='M14.0118 4C14.3361 4 14.6493 4.11636 14.8928 4.32731L17.5476 6.62772C17.8352 6.87689 18 7.23579 18 7.61291V23.0312C18 24.1186 17.1046 25 16 25H2C0.895431 25 0 24.1186 0 23.0312V5.96875C0 4.88144 0.895431 4 2 4H14.0118Z'
          fill='url(#paint5_linear_79_185)'
        />
        <path
          d='M14.0118 4C14.3361 4 14.6493 4.11636 14.8928 4.32731L17.5476 6.62772C17.8352 6.87689 18 7.23579 18 7.61291V8.59375H16C14.8954 8.59375 14 7.71231 14 6.625V4H14.0118Z'
          fill='url(#paint6_linear_79_185)'
        />
        <path
          d='M12.6666 11.2188C13.0348 11.2188 13.3333 11.5126 13.3333 11.875C13.3333 12.2374 13.0348 12.5312 12.6666 12.5312H5.33329C4.9651 12.5312 4.66663 12.2374 4.66663 11.875C4.66663 11.5126 4.9651 11.2188 5.33329 11.2188H12.6666Z'
          fill='url(#paint7_linear_79_185)'
        />
        <path
          d='M12.6666 13.8438C13.0348 13.8438 13.3333 14.1376 13.3333 14.5C13.3333 14.8624 13.0348 15.1562 12.6666 15.1562H5.33329C4.9651 15.1562 4.66663 14.8624 4.66663 14.5C4.66663 14.1376 4.9651 13.8438 5.33329 13.8438H12.6666Z'
          fill='url(#paint8_linear_79_185)'
        />
        <path
          d='M12.6666 16.4688C13.0348 16.4688 13.3333 16.7626 13.3333 17.125C13.3333 17.4874 13.0348 17.7812 12.6666 17.7812H5.33329C4.9651 17.7812 4.66663 17.4874 4.66663 17.125C4.66663 16.7626 4.9651 16.4688 5.33329 16.4688H12.6666Z'
          fill='url(#paint9_linear_79_185)'
        />
        <path
          d='M10.8877 8C11.1408 8 11.3852 8.0942 11.5752 8.26496L13.647 10.1272C13.8714 10.3289 14 10.6195 14 10.9247V23.4062C14 24.2865 13.3012 25 12.4392 25H1.51389C0.651903 25 -0.046875 24.2865 -0.046875 23.4062V9.59375C-0.046875 8.71355 0.651903 8 1.51389 8H10.8877Z'
          fill='url(#paint10_linear_79_185)'
        />
        <path
          d='M10.8876 8C11.1407 8 11.3851 8.0942 11.5751 8.26496L13.6469 10.1272C13.8713 10.3289 13.9999 10.6195 13.9999 10.9247V11.7188H12.4392C11.5772 11.7188 10.8784 11.0052 10.8784 10.125V8H10.8876Z'
          fill='url(#paint11_linear_79_185)'
        />
        <path
          d='M9.8379 13.8438C10.1252 13.8438 10.3582 14.0816 10.3582 14.375C10.3582 14.6684 10.1252 14.9062 9.8379 14.9062H4.1151C3.82777 14.9062 3.59485 14.6684 3.59485 14.375C3.59485 14.0816 3.82777 13.8438 4.1151 13.8438H9.8379Z'
          fill='url(#paint12_linear_79_185)'
        />
        <path
          d='M9.8379 15.9688C10.1252 15.9688 10.3582 16.2066 10.3582 16.5C10.3582 16.7934 10.1252 17.0312 9.8379 17.0312H4.1151C3.82777 17.0312 3.59485 16.7934 3.59485 16.5C3.59485 16.2066 3.82777 15.9688 4.1151 15.9688H9.8379Z'
          fill='url(#paint13_linear_79_185)'
        />
        <path
          d='M9.8379 18.0938C10.1252 18.0938 10.3582 18.3316 10.3582 18.625C10.3582 18.9184 10.1252 19.1562 9.8379 19.1562H4.1151C3.82777 19.1562 3.59485 18.9184 3.59485 18.625C3.59485 18.3316 3.82777 18.0938 4.1151 18.0938H9.8379Z'
          fill='url(#paint14_linear_79_185)'
        />
      </g>
      <defs>
        <linearGradient id='paint0_linear_79_185' x1='1250' y1='0' x2='1250' y2='2500' gradientUnits='userSpaceOnUse'>
          <stop stop-color='#FAEF8B' />
          <stop offset='0.0001' stop-color='#F4EEB9' />
          <stop offset='0.54' stop-color='#F7EFA1' />
          <stop offset='1' stop-color='#FAEF8B' />
        </linearGradient>
        <linearGradient id='paint1_linear_79_185' x1='369' y1='-1' x2='369' y2='689.362' gradientUnits='userSpaceOnUse'>
          <stop stop-color='#2DB1AB' />
          <stop offset='1' stop-color='#CFD0E8' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_79_185'
          x1='1212.32'
          y1='94.125'
          x2='6.448'
          y2='94.125'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C3C4D4' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_79_185'
          x1='1212.32'
          y1='97.625'
          x2='6.448'
          y2='97.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C3C4D4' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_79_185'
          x1='1212.32'
          y1='101.125'
          x2='6.448'
          y2='101.125'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C3C4D4' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <linearGradient id='paint5_linear_79_185' x1='900' y1='4' x2='900' y2='2104' gradientUnits='userSpaceOnUse'>
          <stop stop-color='#FFEE52' />
          <stop offset='1' stop-color='#D7D8E6' />
        </linearGradient>
        <linearGradient id='paint6_linear_79_185' x1='214' y1='4' x2='214' y2='457.05' gradientUnits='userSpaceOnUse'>
          <stop stop-color='#FFD791' />
          <stop offset='0.32' stop-color='#F7DCA5' />
          <stop offset='0.755' stop-color='#F3E0B0' />
          <stop offset='1' stop-color='#E6E8CF' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_79_185'
          x1='871.333'
          y1='76.8438'
          x2='4.66663'
          y2='76.8438'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#F5B536' />
          <stop offset='0.425' stop-color='#D7B374' />
          <stop offset='0.555' stop-color='#DEB366' />
          <stop offset='1' stop-color='#F5B536' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_79_185'
          x1='871.333'
          y1='79.4688'
          x2='4.66663'
          y2='79.4688'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#F5B536' />
          <stop offset='0.425' stop-color='#D7B374' />
          <stop offset='0.555' stop-color='#DEB366' />
          <stop offset='1' stop-color='#F5B536' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_79_185'
          x1='871.333'
          y1='82.0938'
          x2='4.66663'
          y2='82.0938'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#F5B536' />
          <stop offset='0.425' stop-color='#D7B374' />
          <stop offset='0.555' stop-color='#DEB366' />
          <stop offset='1' stop-color='#F5B536' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_79_185'
          x1='702.297'
          y1='8'
          x2='702.297'
          y2='1708'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#EDEEF4' />
          <stop offset='1' stop-color='#D7D8E6' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_79_185'
          x1='166.955'
          y1='8'
          x2='166.955'
          y2='374.755'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#DEDEE7' />
          <stop offset='1' stop-color='#CFD0E8' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_79_185'
          x1='679.926'
          y1='66.9688'
          x2='3.59485'
          y2='66.9688'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C3C4D4' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_79_185'
          x1='679.926'
          y1='69.0938'
          x2='3.59485'
          y2='69.0938'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C3C4D4' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <linearGradient
          id='paint14_linear_79_185'
          x1='679.926'
          y1='71.2188'
          x2='3.59485'
          y2='71.2188'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C3C4D4' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <clipPath id='clip0_79_185'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
