import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

export default function TimeSheetIcon(props) {
    const { theme } = useThemeContext();
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_1_515)'>
        <path
          d='M12.5 0.78125C18.9617 0.78125 24.2188 6.03828 24.2188 12.5C24.2188 18.9617 18.9617 24.2188 12.5 24.2188C6.03828 24.2188 0.78125 18.9617 0.78125 12.5C0.78125 6.03828 6.03828 0.78125 12.5 0.78125ZM12.5 0C5.59648 0 0 5.59648 0 12.5C0 19.4035 5.59648 25 12.5 25C19.4035 25 25 19.4035 25 12.5C25 5.59648 19.4035 0 12.5 0Z'
          fill={theme === 'dark' ? '#13B7E6' : 'white'}
        />
        <path
          d='M11.5399 19.8363V18.1293C10.3262 18.075 9.14924 17.7516 8.46057 17.3563L9.00471 15.2524C9.76565 15.6652 10.8336 16.043 12.0117 16.043C13.0442 16.043 13.7504 15.6477 13.7504 14.9289C13.7504 14.2453 13.1707 13.8133 11.8301 13.3637C9.89221 12.7164 8.56916 11.8176 8.56916 10.0738C8.56916 8.49102 9.69299 7.25079 11.6309 6.87305V5.16446H13.4051V6.74727C14.6188 6.80157 15.4344 7.05196 16.0317 7.34024L15.5067 9.37266C15.0364 9.17501 14.2028 8.76055 12.8977 8.76055C11.7207 8.76055 11.3403 9.26407 11.3403 9.76798C11.3403 10.3609 11.9742 10.7387 13.5141 11.3141C15.6688 12.0695 16.5387 13.0578 16.5387 14.6774C16.5387 16.2777 15.3973 17.6438 13.3149 18.0039V19.8371L11.5399 19.8363Z'
          fill={theme === 'dark' ? '#32BEA6' : 'white'}
        />
      </g>
      <defs>
        <clipPath id='clip0_1_515'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
