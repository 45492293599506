import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';

const DocsSettings = ({ chosenCompany, customDocsDrawer, setCustomDocsDrawer }) => {
  return (
    <>
      {chosenCompany[0].isHr && (
        <Form.Item name='docsSettings' className='customDaySalaryForm timesheetStyles docs-settings__form-item-wrapper'>
          <Checkbox
            checked={customDocsDrawer}
            className='checkbox-wrapper'
            onChange={() => {
              setCustomDocsDrawer(!customDocsDrawer);
            }}
          >
            <div className='wrapper test'>
              <span style={{ width: '100px' }}>Docs settings</span>
              <Tooltip
                title='Here you can define settings for HR docs creation.'
                color='#b1b1b1'
                placement='bottom'
                autoAdjustOverflow={true}
              >
                <QuestionCircleOutlined style={{ marginLeft: '3px', color: '#b1b1b1' }} />
              </Tooltip>
            </div>
          </Checkbox>
        </Form.Item>
      )}
    </>
  );
};

export default DocsSettings;
