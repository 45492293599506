import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, notification, Popconfirm, Row, Upload } from 'antd';
import { UserContext } from '../../App';
import { Link, useHistory } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Axios from 'axios';
import * as FileSaver from 'file-saver';

const Guidelines = () => {
  const currentuser = useContext(UserContext);
  const [guidelines, fetchGuidelines] = useAxios('', null, currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', null, currentuser.data.token, 'get');
  const [selectedCategory, setSelectedCategory] = useState();
  const history = useHistory();
  const [fileList, setFileList] = useState([]);
  const [files, setFiles] = useState([]);
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    let filter = {};
    if (selectedCategory) {
      filter = { category: selectedCategory };
    }
    fetchGuidelines(`${SERVER_URL}/guidelines?filter=${JSON.stringify(filter)}`, []);
    fetchCategories(`${SERVER_URL}/guideline-category`, []);
  }, [fetchGuidelines, fetchCategories, selectedCategory]);

  let videos = [];

  if (guidelines && guidelines.data && guidelines.data.items) {
    if (guidelines.data.items.length === 0 && fileList.length > 0) {
      setFileList([]);
    } else if (guidelines.data.items.length > 0) {
      const files = guidelines.data.items.filter((guide) => guide.type === 'file');
      if (files.length !== fileList.length && fileList.length > 0) {
        setFileList([]);
      } else if (fileList.length > 0) {
        const commonFiles = files.filter((a) => fileList.some((b) => a.location === b.response.key));
        if (commonFiles.length === 0) {
          setFileList([]);
        }
      }
    }
    guidelines.data.items.forEach((guideline) => {
      if (guideline.type === 'link') {
        const videoId = youtubeVideoId(guideline.link);
        const urlParts = guideline.link.split('/');
        const youtubeDomain = urlParts.join('/');
        videos.push({ ...guideline, link: `${youtubeDomain}/embed/${videoId}` });
      } else {
        if (guideline.location && fileList.length === 0) {
          let thumbUrl;
          let nameArray = guideline.originalName.split('.');
          switch (nameArray[nameArray.length - 1]) {
            case 'pdf':
              thumbUrl = '/pdf.png';
              break;
            case 'docx':
              thumbUrl = '/docx.png';
              break;
            case 'png':
              thumbUrl = '/png.png';
              break;
            case 'jpg':
              thumbUrl = '/jpg.png';
              break;
            case 'xlsx':
              thumbUrl = '/xlsx.png';
              break;

            default:
              thumbUrl = '/other.png';
              break;
          }
          setFileList((prevState) => [
            ...prevState,
            {
              uid: guideline._id.toString(),
              name: guideline.originalName,
              status: 'done',
              thumbUrl,
              response: {
                key: guideline.location,
              },
            },
          ]);
        }
      }
    });
  }
  function youtubeVideoId(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  }

  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/guidelines/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/guidelines');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const showUploadList = {
    showDownloadIcon: true,
    downloadIcon: 'Download',
    showRemoveIcon: !!(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')),
  };

  if (categories.data && categories.data.items && categories.data.items.length !== 0 && !selectedCategory) {
    setSelectedCategory(categories.data.items[0]._id);
  }

  return (
    <div style={{ overflowX: 'hidden' }}>
      {currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin') && (
        <div className='actions-block'>
          <Link to='/admin/new-guideline'>
            <Button type='primary'>Add guideline</Button>
          </Link>
          <Link to='/admin/guideline-categories'>
            <Button type='default' style={{ marginLeft: '10px' }}>
              Categories
            </Button>
          </Link>
        </div>
      )}
      <div className='category'>
        {categories.data &&
          categories.data.items &&
          categories.data.items.length !== 0 &&
          categories.data.items.map((category, index) => (
            <Button
              className={index === 0 && isInitial ? 'initCategory' : ''}
              onClick={() => {
                setSelectedCategory(category._id);
                setIsInitial(false);
              }}
            >
              {category.name}
            </Button>
          ))}
      </div>
      <Row gutter={16} style={{ maxWidth: '90%', margin: '0 auto' }} className='guidelines'>
        <Col md={12}>
          <div className='sectionTitle'>Videos</div>
          <div>
            {videos.length !== 0 &&
              videos.map((video) => (
                <div
                  className='guideline'
                  style={{ justifyContent: 'center' }}
                >
                  <iframe src={video.link}></iframe>
                  {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) && (
                    <div
                      style={{ marginLeft: '10px', minWidth: '15px', display: 'flex', flexDirection: 'column' }}
                      className='videos-icons'
                    >
                      {
                        <Link to={'/admin/edit-guideline/' + video._id}>
                          <EditOutlined title='Edit guideline' style={{ textDecoration: 'none' }} />
                        </Link>
                      }
                      <Popconfirm
                        placement='left'
                        title='This will delete guideline'
                        onConfirm={() => {
                          deleteHandler(video._id);
                        }}
                        okText='Ok'
                        cancelText='Cancel'
                      >
                        <DeleteOutlined title='Delete guideline' />
                      </Popconfirm>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </Col>
        <Col md={12}>
          <div className='sectionTitle'>Guides</div>
          <Row style={{ flexFlow: 'nowrap', justifyContent: 'space-between' }}>
            <Col md={20} style={{ width: '90%' }} className='guide'>
              <Upload
                fileList={fileList.length === 0 ? undefined : [...fileList]}
                listType='picture'
                showUploadList={showUploadList}
                onDownload={async (file) => {
                  notification.info({
                    message: 'Downloading in progress.',
                    placement: 'bottomRight',
                  });
                  const urlParts = file.response.key.split('/');
                  const response = await Axios.get(`${SERVER_URL}/file/${urlParts[0]}/${urlParts[1]}`, {
                    withCredentials: false,
                  });
                  FileSaver.saveAs(response.config.url, file.name);
                }}
                onRemove={async (file) => {
                  notification.info({
                    message: 'Removing file in progress.',
                    placement: 'bottomRight',
                  });
                  let newFileList = [...fileList];
                  let newFiles = [...files];
                  let foundFile;
                  for (let i = 0; i < fileList.length; i++) {
                    if (fileList[i].name === file.name) {
                      foundFile = fileList[i];
                      newFileList.splice(i, 1);
                      await Axios.delete(`${SERVER_URL}/guidelines/${file.uid}`, {
                        withCredentials: false,
                        headers: { Authorization: `Bearer ${currentuser.data.token}` },
                      });
                      break;
                    }
                  }
                  for (let i = 0; i < files.length; i++) {
                    if (files[i].originalName === file.name) {
                      newFiles.splice(i, 1);
                    }
                  }
                  let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
                  if (linkParts && linkParts.length !== 0) {
                    await Axios.post(`${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`, {
                      withCredentials: false,
                    });
                  }
                  setFileList(newFileList);
                  setFiles(newFiles);
                  window.location.reload();
                }}
              ></Upload>
            </Col>
            <Col md={4} style={{ flex: 'unset', maxWidth: 'unset', width: '10%', marginLeft: '10px' }}>
              {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) && (
                <div className='fileEdits'>
                  {fileList.map((file) => (
                    <Link style={{ marginRight: 'unset' }} to={'/admin/edit-guideline/' + file.uid}>
                      <EditOutlined title='Edit guideline' style={{ textDecoration: 'none' }} />
                    </Link>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {guidelines && guidelines.data && guidelines.data.items && guidelines.data.items.length === 0 && (
        <div className='noGuidelineText'>NO DATA</div>
      )}
    </div>
  );
};
export default Guidelines;
