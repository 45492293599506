import React, { useContext } from 'react';
import { Table, Input, Button, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import moment from 'moment';

const SalaryTable = ({ data, columnKeys, rowSelection, tableKey }) => {
    const user = useContext(UserContext);
    let searchInput;

    const getColumnSearchProps = (dataIndex) =>
        dataIndex !== 'recorded' && {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={(node) => {
                                searchInput = node;
                            }}
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Button
                            type='primary'
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            size='small'
                            style={{ width: 90, marginRight: 8 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                            Reset
                        </Button>
                    </div>
                );
            },
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
            onFilter: (value, record) => {
                const filterRecord = Array.isArray(dataIndex)
                    ? !dataIndex.includes('category')
                        ? record[dataIndex[0]][dataIndex[1]]
                        : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
                    : record[dataIndex];
                if (!filterRecord || !value) return false;
                return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
            },
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => {
                        searchInput.select();
                    });
                }
            },
        };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const renderTextWithTooltip = (text) => {
        const maxLength = 30;
        if (text && text.length > maxLength) {
            return (
                <Tooltip title={text}>
          <span style={{ display: 'inline-block', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {text}
          </span>
                </Tooltip>
            );
        } else {
            return <span>{text}</span>;
        }
    };

    const getWidth = (itemOriginalName) => {
      switch(itemOriginalName) {
        case 'recorded':
          return '12%';
        case 'numRow':
          return '5%';
        case 'JMBG':
        case 'firstName':
        case 'lastName':
          return '14%';
        case 'updatedAtForShow':
          return '18%';
        default: 
          return '0%';
      }
    }

    const columns = columnKeys?.map((item) => ({
        key: item.originalName,
        title: item.alternativeName.toUpperCase(),
        dataIndex: ['title', 'category'].includes(item.originalName)
            ? [item.originalName, user.language ? user.language.selected.code : '']
            : item.originalName,
        ...getColumnSearchProps(
            ['title', 'category'].includes(item.originalName)
                ? [item.originalName, user.language ? user.language.selected.code : '']
                : item.originalName,
        ),
        width: item.submittedIndicator === 'recorded' 
          ? '12%' 
          : getWidth(item.originalName),
        render: (text) => renderTextWithTooltip(text),
      }));

    function onChange(page) {
        page && localStorage.setItem('pageNumber', page);
        localStorage.getItem('pageNumber') &&
        parseInt(localStorage.getItem('pageNumber')) === 1 &&
        localStorage.removeItem('pageNumber');
    }

    return (
        <div>
            <Table
                className='salary-table_element'
                size='middle'
                bordered
                dataSource={data}
                key={tableKey}
                columns={columns}
                rowKey='_id'
                rowSelection={{
                    type: 'radio',
                    ...rowSelection,
                    columnTitle: 'Enter evidence',
                }}
                pagination={{
                    defaultPageSize: 15,
                    position: 'bottom',
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    hideOnSinglePage: true,
                    onChange: onChange,
                    defaultCurrent: localStorage.getItem('pageNumber') ? parseInt(localStorage.getItem('pageNumber')) : 1,
                }}
                rowClassName={(record) => {
                    const endDateFormatted = moment(record.endDate, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD');
                    const currentDateFormatted = moment().format('YYYY-MM-DD');
                    const isBefore = moment(endDateFormatted).isBefore(currentDateFormatted);
                    return record.endDate && isBefore ? 'suspended' : ''
                }}
            />
        </div>
    );
};

export default SalaryTable;
