import React, { useContext, useEffect } from 'react';
import { Input, Button, Form, Card } from 'antd';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import useAxios from '../../hooks/useAxios';
import { Link } from 'react-router-dom';

const ViewSector = (props) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const { companyId } = props.match.params;
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    if (companyId) {
      fetchData(`${SERVER_URL}/sector/${companyId}`, []);
    }
  }, [companyId, fetchData]);

  if (data.data) {
    form.setFieldsValue({
      companyId: data?.data?.company?.name,
      sectorName: data?.data?.sectorName,
      hrSpecialist: data?.data?.hrSpecialist ? `${data?.data?.hrSpecialist?.firstName} ${data?.data?.hrSpecialist?.lastName}` : 'None',
    });
  }

  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link to='/admin/sectors'>
          <Button type='primary'>All sectors</Button>
        </Link>
      </div>
      <div className='dashboard'>
        <div style={{ width: '60%' }}>
          <Card title={'SECTOR DETAILS'} bordered={false}>
            <Form className='form-horizontal dataForm' layout='horizontal' form={form} labelAlign='left'>
              <Form.Item label='Company' name='companyId' className='employeeField' labelCol={{ span: 6 }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Sector' name='sectorName' className='employeeField' labelCol={{ span: 6 }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='HR Officer' name='hrSpecialist' className='employeeField' labelCol={{ span: 6 }}>
                <Input disabled />
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ViewSector;
