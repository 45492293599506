import React, { useEffect, useState, useRef } from 'react';
import { Form, Button, Select, Input, Divider } from 'antd';

const { Option } = Select;

const typesPaymentSlips = [
  { advancedPaymentOfProfitTax: 'Advanced payment of profit tax' },
  { communalTax: 'Communal fee' },
  { ecoTax: 'Eco fee' },
  { directorTax: 'Tax for director' },
  { socialInsuranceForFoundersTax: 'Social insurance tax for founders' },
];

const PaymentSlipsForm = ({
  createHandler,
  createdPaymentSlips,
  paymentSlipsEmailReceivers,
  setPaymentSlipsEmailReceivers,
  paymentVisible,
}) => {
  const [form] = Form.useForm();
  const [openPaymentSlipSection, setOpenPaymentSlipSection] = useState(false);
  const [selectedPaymentSlipDisplay, setSelectedPaymentSlipDisplay] = useState('');
  const [selectedPaymentSlipValue, setSelectedPaymentSlipValue] = useState('');
  const [editKey, setEditKey] = useState('');
  const [editState, setEditState] = useState('');
  const [inputError, setInputError] = useState('');
  const [options, setOptions] = useState([]);
  const [validationError, setValidationError] = useState('');
  const [validationError2, setValidationError2] = useState('');
  const [accountSections, setAccountSections] = useState({
    section1: '',
    section2: '',
    section3: '',
  });
  const [overallErrorMessage, setOverallErrorMessage] = useState('');
  const [accountSections2, setAccountSections2] = useState({
    section1: '',
    section2: '',
    section3: '',
  });
  const [validationErrors2, setValidationErrors2] = useState({
    section1: '',
    section2: '',
    section3: '',
  });
  const [overallErrorMessage2, setOverallErrorMessage2] = useState('');

  const myRef = useRef(null);

  const resetFormValues = (value) => {
    form.setFieldsValue({
      typeOfPaymentSlip: value ? value : '',
      purposeOfPayment: '',
      payeeOfPayment: '',
      codeOfPayment: '',
      currencyOfPayment: 'RSD',
      amountForPayment: '',
      payersAccount: '',
      modelNumberDebit: '',
      callNumberDebit: '',
      recipientAccount: '',
      modelNumberApproval: '',
      callNumberApproval: '',
      dateOfSendingOfPayment: '',
    });
    setAccountSections({
      section1: '',
      section2: '',
      section3: '',
    });
    setAccountSections2({
      section1: '',
      section2: '',
      section3: '',
    });
    setInputError('');
    setValidationError('');
    setOverallErrorMessage('');
    setValidationError2('');
    setOverallErrorMessage2('');
  };

  const executeScroll = () => {
    if (myRef && myRef.current && myRef.current.scrollIntoView) {
      const element = myRef.current;
      const elementRect = element.getBoundingClientRect();
      const offset = window.pageYOffset || document.documentElement.scrollTop;

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        top: elementRect.top + offset - 10,
      });
    }
  };

  useEffect(() => {
    if (editState) {
      const selectedSlip = createdPaymentSlips.find((item) => item.typeOfPaymentSlip === editKey);
      if (selectedSlip) {
        setSelectedPaymentSlipDisplay(selectedSlip.typeOfPaymentSlipForDisplay);
        setSelectedPaymentSlipValue(selectedSlip.typeOfPaymentSlip);
      }
    }
  }, [editState, editKey, createdPaymentSlips]);

  useEffect(() => {
    const filteredPaymentSlips = typesPaymentSlips.filter(
      (paymentSlip) => !createdPaymentSlips.some((item) => Object.keys(paymentSlip)[0] === item.typeOfPaymentSlip),
    );

    const updatedOptions = [];

    updatedOptions.push(
      <Option key='none' value='none'>
        None
      </Option>,
    );

    // Only include the disabled title if there are multiple options available
    if (selectedPaymentSlipValue !== 'none' && editState && filteredPaymentSlips.length > 1) {
      updatedOptions.push(
        <Option key={editKey} value={editKey} disabled>
          {selectedPaymentSlipDisplay}
        </Option>,
      );
    }

    filteredPaymentSlips.forEach((paymentSlip) => {
      const key = Object.keys(paymentSlip)[0];
      const value = paymentSlip[key];
      updatedOptions.push(
        <Option key={key} value={key}>
          {value}
        </Option>,
      );
    });

    setOptions(updatedOptions);
  }, [
    typesPaymentSlips,
    createdPaymentSlips,
    selectedPaymentSlipValue,
    editState,
    editKey,
    selectedPaymentSlipDisplay,
  ]);

  const handleEditPaymentSlip = (paymentSlip) => {
    if (paymentSlip.payersAccount) {
      setAccountSections({
        section1: paymentSlip.payersAccount.substring(0, 3),
        section2: paymentSlip.payersAccount.substring(3, 16),
        section3: paymentSlip.payersAccount.substring(16, 18),
      });
    }
    if (paymentSlip.recipientAccount) {
      setAccountSections2({
        section1: paymentSlip.recipientAccount.substring(0, 3),
        section2: paymentSlip.recipientAccount.substring(3, 16),
        section3: paymentSlip.recipientAccount.substring(16, 18),
      });
    }

    form.setFieldsValue({
      typeOfPaymentSlip: paymentSlip.typeOfPaymentSlip,
      purposeOfPayment: paymentSlip.purposeOfPayment,
      payeeOfPayment: paymentSlip.payeeOfPayment,
      codeOfPayment: paymentSlip.codeOfPayment,
      currencyOfPayment: 'RSD',
      amountForPayment: paymentSlip.amountForPayment,
      payersAccount: paymentSlip.payersAccount,
      modelNumberDebit: paymentSlip.modelNumberDebit,
      callNumberDebit: paymentSlip.callNumberDebit,
      recipientAccount: paymentSlip.recipientAccount,
      modelNumberApproval: paymentSlip.modelNumberApproval,
      callNumberApproval: paymentSlip.callNumberApproval,
      dateOfSendingOfPayment: paymentSlip.dateOfSendingOfPayment,
    });
    setEditState(paymentSlip.typeOfPaymentSlipForDisplay);
    setEditKey(paymentSlip.typeOfPaymentSlip);
    setSelectedPaymentSlipDisplay(paymentSlip.typeOfPaymentSlipForDisplay);
    setSelectedPaymentSlipValue(paymentSlip.typeOfPaymentSlip);
    form.setFieldValue('typeOfPaymentSlip', paymentSlip.typeOfPaymentSlipForDisplay);
    setOpenPaymentSlipSection(true);
    executeScroll();
  };

  const handleDeletePaymentSlip = (paymentSlip) => {
    resetFormValues();
    createHandler(paymentSlip, true, true);
    setEditState('');
    setEditKey('');
    setOpenPaymentSlipSection(false);
    executeScroll();
  };

  const closeEditPaymentSlipSection = () => {
    setEditState('');
    setEditKey('');
    setSelectedPaymentSlipDisplay('none');
    setOpenPaymentSlipSection(false);
    form.setFieldValue('typeOfPaymentSlip', '');
    executeScroll();
  };

  const onPaymentSlipTypeSelectChange = (value) => {
    value !== 'none' ? setOpenPaymentSlipSection(true) : setOpenPaymentSlipSection(false);
    createdPaymentSlips.map((item) => {
      if (item.typeOfPaymentSlip !== value) {
        resetFormValues(value);
      }
    });

    setSelectedPaymentSlipValue(value);
    const selectedSlip = createdPaymentSlips.find((item) => item.typeOfPaymentSlip === value);
    if (selectedSlip) {
      setSelectedPaymentSlipDisplay(selectedSlip.typeOfPaymentSlipForDisplay);
    } else {
      const slipToDisplay = typesPaymentSlips.find((paymentSlip) => Object.keys(paymentSlip)[0] === value);
      if (slipToDisplay) {
        setSelectedPaymentSlipDisplay(slipToDisplay[value]);
      }
    }

    // Reset editState and editKey if the selected type is not in createdPaymentSlips
    if (!createdPaymentSlips.some((item) => item.typeOfPaymentSlip === value)) {
      setEditState('');
      setEditKey('');
    }
  };

  const onFinish = async (values) => {
    {
      /** TODO uncomment after implementation **/
    }
    // const overallError = getOverallValidationError();
    const overallError2 = getOverallValidationError2();

    {
      /** TODO uncomment after implementation **/
    }
    // if (overallError !== '') {
    //     setOverallErrorMessage(overallError);
    //     return;
    // }

    if (overallError2 !== '') {
      setOverallErrorMessage2(overallError2);
      return;
    }

    if (
      overallError2 &&
      (!accountSections2.section1 ||
        !accountSections2.section2 ||
        !accountSections2.section3 ||
        (accountSections2.section1 && accountSections2.section1 !== 3) ||
        (accountSections2.section1 && accountSections2.section3 !== 2) ||
        (!/^\d+$/.test(accountSections2.section1) && accountSections2.section1) ||
        (!/^\d+$/.test(accountSections2.section3) && accountSections2.section3) ||
        (!/^\d+$/.test(accountSections2.section2) && accountSections2.section2))
    ) {
      setOverallErrorMessage2(overallError2);
      return;
    }

    setValidationError('');
    setOverallErrorMessage('');
    setValidationError2('');
    setOverallErrorMessage2('');
    setInputError('');

    values.currencyOfPayment = 'rsd';
    if (values.typeOfPaymentSlip === 'communalTax') values.typeOfPaymentSlipForDisplay = 'Communal fee';
    if (values.typeOfPaymentSlip === 'advancedPaymentOfProfitTax')
      values.typeOfPaymentSlipForDisplay = 'Advanced payment of profit tax';
    if (values.typeOfPaymentSlip === 'ecoTax') values.typeOfPaymentSlipForDisplay = 'Eco fee';
    if (values.typeOfPaymentSlip === 'directorTax') values.typeOfPaymentSlipForDisplay = 'Tax for director';
    if (values.typeOfPaymentSlip === 'socialInsuranceForFoundersTax')
      values.typeOfPaymentSlipForDisplay = 'Social insurance tax for founders';
    {
      /** TODO uncomment after implementation **/
    }
    // values.payersAccount = `${accountSections.section1}${accountSections.section2}${accountSections.section3}`
    values.recipientAccount =
      !editState && form.getFieldValue('recipientAccount') && form.getFieldValue('recipientAccount').length > 13
        ? form.getFieldValue('recipientAccount')
        : `${accountSections2.section1}${accountSections2.section2}${accountSections2.section3}`;
    if (!values.amountForPayment.toString().match(/,\d{2}$/)) {
      values.amountForPayment = parseFloat(values.amountForPayment).toFixed(2).replace('.', ',');
    }

    if (editState && editState !== '') {
      values.typeOfPaymentSlip = selectedPaymentSlipValue;
      values.typeOfPaymentSlipForDisplay = selectedPaymentSlipDisplay;
      createHandler(values, true);
    } else createHandler(values);

    resetFormValues();
    setOpenPaymentSlipSection(false);
    setSelectedPaymentSlipDisplay('');
    executeScroll();
  };

  const handleInputChange = (index, event) => {
    const newEmails = [...paymentSlipsEmailReceivers];
    newEmails[index] = event.target.value;
    setPaymentSlipsEmailReceivers(newEmails);
  };

  const handleAddEmail = () => {
    setPaymentSlipsEmailReceivers([...paymentSlipsEmailReceivers, '']);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = [...paymentSlipsEmailReceivers];
    newEmails.splice(index, 1);
    setPaymentSlipsEmailReceivers(newEmails);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    const { section1, section2, section3 } = accountSections2;
    if (section1 === '' || section2 === '' || section3 === '') {
      setValidationError2('');
      setOverallErrorMessage2('');
      setInputError('');
      form.setFieldValue('recipientAccount', '');
    }
  }, [accountSections2]);

  {
    /** TODO uncomment after implementation **/
  }
  // const validateSections = (accountSections) => {
  //     const { section1, section2, section3 } = accountSections;
  //     const errors = {};
  //
  //     // Validation logic for each section
  //     if (!section1 || section1.length !== 3 || !/^\d+$/.test(section1)) {
  //         errors.section1 = 'Section 1 should be a 3-digit number';
  //     } else {
  //         errors.section1 = '';
  //     }
  //
  //     if (!section2 || section2.length !== 13 || !/^\d+$/.test(section2)) {
  //             errors.section2 = 'Section 2 should be a 13-digit number';
  //     } else {
  //         errors.section2 = '';
  //     }
  //
  //     if (!section3 || section3.length !== 2 || !/^\d+$/.test(section3)) {
  //         errors.section3 = 'Section 3 should be a 2-digit number';
  //     } else {
  //         errors.section3 = '';
  //     }
  //
  //     console.log('ERRORS 1111', errors)
  //
  //     return errors;
  // };

  // const validateAllSections = () => {
  //     const errors = validateSections(accountSections);
  //     setValidationErrors({ ...errors });
  // };

  // const getOverallValidationError = () => {
  //     validateAllSections(); // Trigger validation for all sections
  //
  //     const errorsArray = Object.values(validationErrors);
  //     const hasErrors = errorsArray.some((error) => error !== '');
  //
  //     return hasErrors ? 'Please enter a valid account number in the format xxx-xxxxxxxxxxxxx-xx' : '';
  // };

  // const handleSectionChange = (sectionIndex, value) => {
  //     setAccountSections((prevAccountSections) => ({
  //         ...prevAccountSections,
  //         [`section${sectionIndex + 1}`]: value,
  //     }));
  // };

  const validateSections2 = (accountSections2) => {
    const { section1, section2, section3 } = accountSections2;
    const errors = {};

    // Validation logic for each section
    if (!section1 || section1.length !== 3 || !/^\d+$/.test(section1)) {
      errors.section1 = 'Section 1 should be a 3-digit number';
    } else {
      errors.section1 = '';
    }

    if (!section2 || section2.length !== 13 || !/^\d+$/.test(section2)) {
      if (section2.length !== 13) {
        let paddedValue = section2;
        const numberOfZeros = 13 - section2.length;
        const zeros = '0'.repeat(numberOfZeros);
        paddedValue = zeros + section2;
        setAccountSections2({
          section1: section1,
          section2: paddedValue,
          section3: section3,
        });
        form.setFieldValue(
          'recipientAccount',
          `${accountSections2.section1}${paddedValue}${accountSections2.section3}`,
        );
      } else errors.section2 = 'Section 2 should be a 13-digit number';
    } else {
      errors.section2 = '';
    }

    if (!section3 || section3.length !== 2 || !/^\d+$/.test(section3)) {
      errors.section3 = 'Section 3 should be a 2-digit number';
    } else {
      errors.section3 = '';
    }

    return errors;
  };

  const validateAllSections2 = () => {
    const errors = validateSections2(accountSections2);
    setValidationErrors2({ ...errors });
    return errors;
  };

  const getOverallValidationError2 = () => {
    const errors = validateAllSections2();
    const errorsArray = Object.values({ ...errors });
    const hasErrors = errorsArray.some((error) => error !== '');

    return hasErrors ? 'Please enter a valid account number in the format xxx-xxxxxxxxxxxxx-xx' : '';
  };

  const handleSectionChange2 = (sectionIndex, value) => {
    setAccountSections2((prevAccountSections2) => ({
      ...prevAccountSections2,
      [`section${sectionIndex + 1}`]: value,
    }));
  };

  return (
    <>
      <div ref={myRef} />
      <div style={{ width: '50%', visibility: paymentVisible ? 'visible' : 'hidden' }}>
        {createdPaymentSlips && createdPaymentSlips.length ? (
          <>
            <div style={{ textDecoration: 'underline', fontWeight: '550', marginTop: '10px', display: 'flex' }}>
              EXISTING PAYMENT ORDERS:
            </div>
            <ol style={{ marginLeft: '5px', marginRight: '5px', marginTop: '10px', padding: 0 }}>
              {createdPaymentSlips.map((paymentSlip, index) => (
                <li
                  key={paymentSlip.typeOfPaymentSlipForDisplay}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    listStyle: 'none',
                    margin: '0 10px 5px 10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      maxWidth: '250px',
                      marginRight: '10px',
                      textAlign: 'right',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {index + 1}. {paymentSlip.typeOfPaymentSlipForDisplay}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        marginBottom: '5px',
                        marginRight: '10px',
                        color: 'blue',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleEditPaymentSlip(paymentSlip)}
                    >
                      Edit
                    </div>
                    <div
                      style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => handleDeletePaymentSlip(paymentSlip)}
                    >
                      Delete
                    </div>
                  </div>
                </li>
              ))}
              <Divider style={{ backgroundColor: '#2db1ab50', margin: '15px 0' }} />
              <div
                style={{
                  textDecoration: 'underline',
                  fontWeight: '550',
                  marginTop: '20px',
                  marginBottom: '15px',
                  display: 'flex',
                }}
              >
                DEFINED EMAIL RECEIVERS:
              </div>
              <div style={{ fontFamily: 'Arial, sans-serif', marginTop: '10px' }}>
                {paymentSlipsEmailReceivers &&
                  paymentSlipsEmailReceivers?.map((email, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ marginTop: '4px' }} key={index + 1}>
                        {index + 1}.
                      </div>
                      <input
                        type='email'
                        placeholder='Enter email'
                        value={email}
                        onChange={(event) => handleInputChange(index, event)}
                        style={{
                          padding: '8px',
                          borderRadius: '4px',
                          border: '1px solid #ccc',
                          marginRight: '10px',
                          marginLeft: '10px',
                          width: 'calc(100% - 120px)',
                          height: '30px',
                        }}
                      />
                      <button
                        onClick={() => handleRemoveEmail(index)}
                        style={{
                          padding: '8px',
                          borderRadius: '4px',
                          border: '1px solid #ccc',
                          backgroundColor: '#f44336',
                          color: 'white',
                          cursor: 'pointer',
                          marginRight: '10px',
                          height: '30px',
                          paddingTop: '6px',
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                <button
                  onClick={handleAddEmail}
                  style={{
                    padding: '8px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    backgroundColor: '#4caf50',
                    color: 'white',
                    cursor: 'pointer',
                    marginBottom: '10px',
                  }}
                >
                  Add Email
                </button>
              </div>
              <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />
            </ol>
          </>
        ) : null}
        <Form
          name='basic'
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
          layout='vertical'
          form={form}
          style={{ marginLeft: '10px', marginRight: '10px' }}
        >
          {options && options.length > 1 ? (
            <>
              <div style={{ textDecoration: 'underline', fontWeight: '550', marginTop: '20px', display: 'flex' }}>
                CREATE NEW PAYMENT ORDER:
                {openPaymentSlipSection && (
                  <div
                    style={{
                      marginLeft: '20px',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: 'blue',
                    }}
                    onClick={closeEditPaymentSlipSection}
                  >
                    close
                  </div>
                )}
              </div>
              <Form.Item
                name='typeOfPaymentSlip'
                label='Type of payment slip:'
                labelAlign='left'
                labelCol={{ span: 12 }}
                style={{ marginTop: '10px' }}
              >
                <Select
                  onChange={(value) => onPaymentSlipTypeSelectChange(value)}
                  value={selectedPaymentSlipDisplay}
                  placeholder={selectedPaymentSlipDisplay}
                >
                  {options}
                </Select>
              </Form.Item>
            </>
          ) : options && options.length < 2 && selectedPaymentSlipDisplay ? (
            <>
              <div style={{ textDecoration: 'underline', fontWeight: '550', marginTop: '10px', display: 'flex' }}>
                CREATE NEW PAYMENT ORDER:
                {openPaymentSlipSection && (
                  <div
                    style={{
                      marginLeft: '20px',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: 'blue',
                    }}
                    onClick={closeEditPaymentSlipSection}
                  >
                    close
                  </div>
                )}
              </div>
              <Form.Item
                label='Type of payment slip:'
                labelAlign='left'
                labelCol={{ span: 12 }}
                style={{ marginTop: '10px' }}
              >
                <Input disabled value={selectedPaymentSlipDisplay} style={{ textAlign: 'center' }} />
              </Form.Item>
            </>
          ) : null}
          {openPaymentSlipSection && (
            <div style={{ backgroundColor: '#f5faf6' }}>
              <Divider
                style={{
                  backgroundColor: '#2db1ab50',
                  margin: '15px 0',
                  border: '1px solid #2db1ab50',
                }}
              />
              <Form.Item
                label='Date for sending the payment slip:'
                labelAlign='left'
                labelCol={{ span: 20 }}
                name='dateOfSendingOfPayment'
                style={{ marginBottom: '10px' }}
                validateStatus={inputError ? 'error' : ''}
                help={inputError || ''}
                rules={[
                  {
                    required: true,
                    message: 'Please add date for sending the payment slip!',
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    const { value } = e.target;
                    clearTimeout(window.inputTimer);
                    window.inputTimer = setTimeout(() => {
                      const isValidInput = /^\d+$/.test(value) && value >= 1 && value <= 31;
                      setInputError(isValidInput ? '' : 'Please enter a number between 1 and 31');
                    }, 300);
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Purpose of payment:'
                labelAlign='left'
                labelCol={{ span: 14 }}
                name='purposeOfPayment'
                style={{ marginBottom: '10px' }}
                rules={[
                  {
                    required: true,
                    message: 'Please add purpose of payment!',
                  },
                  {
                    max: 35,
                    message: 'Maximum 35 characters allowed!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Payee:'
                labelAlign='left'
                labelCol={{ span: 12 }}
                name='payeeOfPayment'
                style={{ marginBottom: '10px' }}
                rules={[
                  {
                    required: true,
                    message: 'Please add payee!',
                  },
                  {
                    max: 70,
                    message: 'Maximum 70 characters allowed!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Payment code:'
                labelAlign='left'
                labelCol={{ span: 12 }}
                name='codeOfPayment'
                style={{ marginBottom: '10px' }}
                rules={[
                  {
                    required: true,
                    message: 'Please add payment code!',
                  },
                  {
                    max: 3,
                    message: 'Maximum 3 numbers allowed!',
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: 'Only numbers allowed!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Currency:'
                labelAlign='left'
                labelCol={{ span: 12 }}
                name='currencyOfPayment'
                style={{ marginBottom: '10px' }}
              >
                <Input disabled defaultValue='RSD' />
              </Form.Item>
              <Form.Item
                label={`Amount for pay:`}
                labelAlign='left'
                labelCol={{ span: 12 }}
                name='amountForPayment'
                style={{ marginBottom: '10px' }}
                rules={[
                  {
                    required: true,
                    message: `Please add amount for pay!`,
                  },
                  {
                    pattern: /^\d+(\,\d{2})?$/, // Regex pattern to allow numbers with exactly two decimals after a comma
                    message: 'Please enter a valid amount with two decimals after a comma!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/** TODO uncomment after implementation **/}
              {/*<Form.Item*/}
              {/*    label={`Payer's account:`}*/}
              {/*    labelAlign='left'*/}
              {/*    labelCol={{span: 12}}*/}
              {/*    name='payersAccount'*/}
              {/*    style={{marginBottom: '10px'}}*/}
              {/*    validateStatus={validationError ? 'error' : ''}*/}
              {/*    help={validationError}*/}
              {/*>*/}
              {/*    <Input.Group compact style={{fontSize: '14px'}}>*/}
              {/*        <Input*/}
              {/*            style={{width: '30%'}}*/}
              {/*            placeholder="xxx"*/}
              {/*            maxLength={3}*/}
              {/*            value={accountSections.section1}*/}
              {/*            onChange={(e) => handleSectionChange(0, e.target.value)}*/}
              {/*            onBlur={() => {*/}
              {/*                const errors = validateSections(accountSections);*/}
              {/*                setValidationErrors((prevErrors) => ({*/}
              {/*                    ...prevErrors,*/}
              {/*                    section1: errors.section1,*/}
              {/*                }));*/}
              {/*            }}*/}
              {/*        />*/}
              {/*        <Input*/}
              {/*            style={{width: '50%'}}*/}
              {/*            placeholder="xxxxxxxxxxxxx"*/}
              {/*            maxLength={13}*/}
              {/*            value={accountSections.section2}*/}
              {/*            onChange={(e) => handleSectionChange(1, e.target.value)}*/}
              {/*            onBlur={() => {*/}
              {/*                const errors = validateSections(accountSections);*/}
              {/*                setValidationErrors((prevErrors) => ({*/}
              {/*                    ...prevErrors,*/}
              {/*                    section2: errors.section2,*/}
              {/*                }));*/}
              {/*            }}*/}
              {/*        />*/}
              {/*        <Input*/}
              {/*            style={{width: '20%'}}*/}
              {/*            placeholder="xx"*/}
              {/*            maxLength={2}*/}
              {/*            value={accountSections.section3}*/}
              {/*            onChange={(e) => handleSectionChange(2, e.target.value)}*/}
              {/*            onBlur={() => {*/}
              {/*                const errors = validateSections(accountSections);*/}
              {/*                setValidationErrors((prevErrors) => ({*/}
              {/*                    ...prevErrors,*/}
              {/*                    section3: errors.section3,*/}
              {/*                }));*/}
              {/*            }}*/}
              {/*        />*/}
              {/*    </Input.Group>*/}
              {/*    {overallErrorMessage && <div style={{color: 'red'}}>{overallErrorMessage}</div>}*/}
              {/*</Form.Item>*/}
              {/*<Divider style={{backgroundColor: '#2db1ab50', margin: '5px 0'}}/>*/}
              {/*<span>Debit</span>*/}
              {/*<Form.Item*/}
              {/*    label='Model number:'*/}
              {/*    labelAlign='left'*/}
              {/*    labelCol={{span: 12}}*/}
              {/*    name='modelNumberDebit'*/}
              {/*    style={{marginBottom: "10px"}}*/}

              {/*>*/}
              {/*    <Input/>*/}
              {/*</Form.Item>*/}
              {/*<Form.Item*/}
              {/*    label='Call number (debit):'*/}
              {/*    labelAlign='left'*/}
              {/*    labelCol={{span: 12}}*/}
              {/*    name='callNumberDebit'*/}
              {/*    style={{marginBottom: "10px"}}*/}
              {/*>*/}
              {/*    <Input/>*/}
              {/*</Form.Item>*/}
              {/*<Divider style={{backgroundColor: '#2db1ab50', margin: '5px 0'}}/>*/}
              <Form.Item
                label={
                  <span>
                    <span style={{ color: 'red', marginRight: '3px' }}>*</span>
                    Recipient's account:
                  </span>
                }
                labelAlign='left'
                labelCol={{ span: 12 }}
                name='recipientAccount'
                style={{ marginBottom: '10px' }}
                validateStatus={validationError2 ? 'error' : ''}
                help={validationError2}
              >
                <Input.Group compact style={{ fontSize: '14px' }}>
                  <Input
                    style={{ width: '30%' }}
                    placeholder='xxx'
                    maxLength={3}
                    value={accountSections2.section1}
                    onChange={(e) => handleSectionChange2(0, e.target.value)}
                    onBlur={() => {
                      const errors = validateSections2(accountSections2);
                      setValidationErrors2((prevErrors) => ({
                        ...prevErrors,
                        section1: errors.section1,
                      }));
                    }}
                  />
                  <Input
                    style={{ width: '50%' }}
                    placeholder='xxxxxxxxxxxxx'
                    maxLength={13}
                    value={accountSections2.section2}
                    onChange={(e) => handleSectionChange2(1, e.target.value)}
                    onBlur={() => {
                      const errors = validateSections2(accountSections2);
                      setValidationErrors2((prevErrors) => ({
                        ...prevErrors,
                        section2: errors.section2,
                      }));
                    }}
                  />
                  <Input
                    style={{ width: '20%' }}
                    placeholder='xx'
                    maxLength={2}
                    value={accountSections2.section3}
                    onChange={(e) => handleSectionChange2(2, e.target.value)}
                    onBlur={() => {
                      const errors = validateSections2(accountSections2);
                      setValidationErrors2((prevErrors) => ({
                        ...prevErrors,
                        section3: errors.section3,
                      }));
                    }}
                  />
                </Input.Group>
                {overallErrorMessage2 && <div style={{ color: 'red' }}>{overallErrorMessage2}</div>}
              </Form.Item>
              <Divider style={{ backgroundColor: '#2db1ab50', margin: '15px 0' }} />
              <span>Approval</span>
              <Form.Item
                label='Model number:'
                labelAlign='left'
                labelCol={{ span: 12 }}
                name='modelNumberApproval'
                style={{ marginBottom: '10px' }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Call number (approval):'
                labelAlign='left'
                labelCol={{ span: 14 }}
                name='callNumberApproval'
                style={{ marginBottom: '10px' }}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  style={{
                    float: 'right',
                    marginTop: '10px',
                    backgroundColor: '#defae6',
                    color: 'blue',
                  }}
                  disabled={!!inputError}
                >
                  {editState ? 'Save changes' : 'Create'}
                </Button>
              </Form.Item>
              <Divider
                style={{
                  backgroundColor: '#2db1ab50',
                  margin: '15px 0',
                  border: '1px solid #2db1ab50',
                }}
              />
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default PaymentSlipsForm;
