import { holidaysArray } from '../constants/holidays.js';
import moment from 'moment';

function isHoliday(date) {
  return holidaysArray.some((holiday) => moment(holiday, 'D.M.YYYY').isSame(date, 'day'));
}
export function calculateBusinessDays(startDate, endDate) {
  let day = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');
  let businessDays = 0;
  while (day.isSameOrBefore(end, 'day')) {
    if (day.isoWeekday() !== 6 && day.isoWeekday() !== 7 && !isHoliday(day)) {
      businessDays++;
    }
    day.add(1, 'day');
  }
  return businessDays;
}

// helper function for date formatting
export function formatDate(dateString) {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}/;
  const isoTimeRegex = /\d{2}:\d{2}:\d{2}/;
  const dateSegment = dateString.match(isoDateRegex);
  const timeSegment = dateString.match(isoTimeRegex);

  if (dateSegment && timeSegment) {
    const dateParts = dateSegment[0].split('-');
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${timeSegment[0]}`.toString();
  } else {
    return 'Invalid date';
  }
}

// date formatting for dates in tables
export function formatDateForTables(dateString) {
  const dateObj = new Date(dateString);
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

// date formatting for dates in eInvoices table
export function formatEInvoiceDate(dateString) {
  const dateObj = new Date(dateString);
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${day}-${month}-${year}`;
}

// Function to disable weekends and public holidays
export function disabledDate(current) {
  const isWeekend = moment(current).isoWeekday() >= 6;

  const isHoliday = holidaysArray.some((holiday) => moment(holiday).isSame(current, 'day'));

  return isWeekend || isHoliday;
}

// Function to convert numbers to have a thousand separators
export function formatNumber(numberString) {
  let parts = numberString.split('.');
  let leftPart = parts[0];
  let rightPart = parts[1] ? ',' + parts[1] : '';

  leftPart = leftPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return leftPart + rightPart;
}
