import React from 'react';

export default function MyAccountIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_71_2)'>
        <path
          d='M16.875 13.2812C18.5156 12.0312 19.5312 10 19.5312 7.8125C19.5312 3.90625 16.4062 0.78125 12.5 0.78125C8.59375 0.78125 5.46875 3.90625 5.46875 7.8125C5.46875 10 6.48437 12.0312 8.125 13.2812C4.0625 13.5156 0.78125 16.9531 0.78125 21.0938V23.4375C0.78125 23.8281 1.17188 24.2188 1.5625 24.2188H12.5H23.4375C23.8281 24.2188 24.2188 23.8281 24.2188 23.4375V21.0938C24.2188 16.9531 20.9375 13.5156 16.875 13.2812Z'
          fill='#FFC10A'
        />
        <path
          d='M16.875 13.2812C15.7031 14.2188 14.1406 14.8438 12.5 14.8438C10.8594 14.8438 9.29687 14.2969 8.125 13.2812C4.0625 13.5156 0.78125 16.9531 0.78125 21.0938V23.4375C0.78125 23.8281 1.17188 24.2188 1.5625 24.2188H12.5H23.4375C23.8281 24.2188 24.2188 23.8281 24.2188 23.4375V21.0938C24.2188 16.9531 20.9375 13.5156 16.875 13.2812Z'
          fill='#2197F3'
        />
      </g>
      <defs>
        <clipPath id='clip0_71_2'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
