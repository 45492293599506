import React from 'react';
import { Col, Form, Select } from 'antd';

const SalaryFormCompanyPicker = ({
  currentuser,
  setShowCalendar,
  setSelectedEmployee,
  setDisplay,
  companyList,
  setUnsetEmployee,
  selectedCompany,
  setSelectedCompany,
}) => {
  return (
    (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) && (
      <>
        <Col xs={20} md={10}>
          <div className='salary-company-picker panel-body'>
            {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) && (
              <Form.Item label='Choose company'>
                <Select
                  defaultValue={selectedCompany}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.children?.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className='select-dropwdown'
                  onChange={(value) => {
                    localStorage.removeItem('pageNumber');
                    setShowCalendar(false);
                    localStorage.setItem('companySalary', value);
                    setSelectedCompany(value);
                    setSelectedEmployee(undefined);
                    setUnsetEmployee(true);
                    setDisplay('none');
                  }}
                >
                  {companyList}
                </Select>
              </Form.Item>
            )}
          </div>
        </Col>
      </>
    )
  );
};

export default SalaryFormCompanyPicker;
