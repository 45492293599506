import React from 'react';

export default function UploadEmployeesIcon(props) {
    return (
        <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M18.6718 6.0021H18.3325C17.2588 6.0021 16.3884 5.13169 16.3884 4.05796V2.46607C16.3884 1.39239 17.2588 0.521927 18.3325 0.521927H18.6718C19.7455 0.521927 20.6159 1.39239 20.6159 2.46607V4.05791C20.6159 5.13169 19.7455 6.0021 18.6718 6.0021Z'
                fill='#FFBAA8'
            />
            <path
                d='M14.9474 15.3967C14.1723 15.3967 13.5439 14.7716 13.5439 14.0005V8.91182L14.5095 6.00211H20.5353C22.1508 6.00211 23.4604 7.30484 23.4604 8.91182V14.0005C23.4604 14.7716 22.832 15.3967 22.0568 15.3967H14.9474Z'
                fill='#ECF0F1'
            />
            <path
                d='M21.5032 10.6733V23.2603C21.5032 23.9329 20.9579 24.4781 20.2854 24.4781H19.72C19.0474 24.4781 18.5022 23.9328 18.5022 23.2603V17.5365V17.5235V23.2603C18.5022 23.9329 17.957 24.4781 17.2844 24.4781H16.719C16.0464 24.4781 15.5011 23.9328 15.5011 23.2603V10.6733H21.5032Z'
                fill='#FFCF70'
            />
            <path d='M21.5032 10.6734V13.8441L18.5016 15.3707L15.5011 13.8514V10.6734H21.5032Z' fill='#ECF0F1' />
            <path
                d='M6.3283 6.0021H6.66755C7.74128 6.0021 8.61169 5.13169 8.61169 4.05796V2.46607C8.61169 1.39239 7.74128 0.521927 6.66755 0.521927H6.3283C5.25457 0.521927 4.38416 1.39239 4.38416 2.46607V4.05791C4.38416 5.13169 5.25457 6.0021 6.3283 6.0021Z'
                fill='#FFBAA8'
            />
            <path
                d='M10.0526 15.3967C10.8278 15.3967 11.4562 14.7716 11.4562 14.0005V8.91182L10.4906 6.00211H4.46483C2.84935 6.00211 1.53973 7.30484 1.53973 8.91182V14.0005C1.53973 14.7716 2.1681 15.3967 2.9433 15.3967H10.0526Z'
                fill='#ECF0F1'
            />
            <path
                d='M3.49695 10.6733V23.2603C3.49695 23.9329 4.04221 24.4781 4.71472 24.4781H5.2801C5.95271 24.4781 6.49788 23.9328 6.49788 23.2603V17.5365V17.5235V23.2603C6.49788 23.9329 7.04314 24.4781 7.71565 24.4781H8.28103C8.95369 24.4781 9.49895 23.9328 9.49895 23.2603V10.6733H3.49695Z'
                fill='#FFCF70'
            />
            <path d='M3.49695 10.6734V13.8441L6.49851 15.3707L9.49905 13.8514V10.6734H3.49695Z' fill='#ECF0F1' />
            <path
                d='M8.94537 15.3967C8.17023 15.3967 7.54181 14.7716 7.54181 14.0005V8.91182C7.54181 7.30484 8.85143 6.00211 10.4669 6.00211H14.5332C16.1486 6.00211 17.4583 7.30484 17.4583 8.91182V14.0005C17.4583 14.7716 16.8299 15.3967 16.0547 15.3967H8.94537Z'
                fill='#0BBBDA'
            />
            <path
                d='M15.5011 10.6733V23.2603C15.5011 23.9329 14.9558 24.4781 14.2833 24.4781H13.7179C13.0453 24.4781 12.5001 23.9328 12.5001 23.2603V17.5365V17.5235V23.2603C12.5001 23.9329 11.9549 24.4781 11.2823 24.4781H10.7169C10.0443 24.4781 9.49902 23.9328 9.49902 23.2603V10.6733H15.5011Z'
                fill='#FFCF70'
            />
            <path
                d='M12.6697 6.0021H12.3304C11.2567 6.0021 10.3863 5.13169 10.3863 4.05796V2.46607C10.3863 1.39239 11.2567 0.521927 12.3304 0.521927H12.6697C13.7434 0.521927 14.6138 1.39239 14.6138 2.46607V4.05791C14.6138 5.13169 13.7434 6.0021 12.6697 6.0021Z'
                fill='#FFBAA8'
            />
            <path d='M14.6364 6.00211L12.5 9.50767L10.3636 6.00211H14.6364Z' fill='#ECF0F1' />
            <path d='M15.5011 10.6734V13.8441L12.4995 15.3707L9.49902 13.8514V10.6734H15.5011Z' fill='#0BBBDA' />
            <path
                d='M11.4823 13.4385C11.7705 13.4385 12.0042 13.2049 12.0042 12.9166C12.0042 12.6284 11.7705 12.3947 11.4823 12.3947C11.194 12.3947 10.9603 12.6284 10.9603 12.9166C10.9603 13.2049 11.194 13.4385 11.4823 13.4385Z'
                fill='#FFBAA8'
            />
        </svg>
    );
}
