import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/UsersTable';
import { formatDateForTables } from '../../helpers/dates';

import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'avatar',
  'address',
  'zip',
  'taxId',
  'phone',
  'legalType',
  'wishList',
  'companyName',
  'city',
  'country',
  'position',
  'positionEng',
  'jmbg',
  'sectorId',
  'employeeId',
  'adminEmailsSettings',
  'employeeId',
  'statuaryAuthorization'
];

const Users = () => {
  const currentuser = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [salaries, fetchSalaries] = useAxios('', [], currentuser.data.token, 'get');

  const fetchUsers = async (data) => {
    try {
      if (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) {
        let userFilter = { role: { $in: [`${data ? data : 'user'}`] } };
        if (data === 'admin' && currentuser.data.role.includes('SuperAdmin'))
          userFilter = { role: { $in: ['admin', 'SuperAdmin'] } }; // returns both admin and superAdmin
        const response = await Axios.get(`${SERVER_URL}/users?filter=${JSON.stringify(userFilter)}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        setUsers(response);
      } else {
        const userFilter = { role: { $ne: ['employee'] } };
        const response = await Axios.get(`${SERVER_URL}/users?filter=${JSON.stringify(userFilter)}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        setUsers(response);
      }
    } catch (error) {
      console.log('error with fetching users', error.message);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [setUsers]);

  useEffect(() => {
    const userFilter = { role: { $ne: ['employee'] } };
    fetchSalaries(`${SERVER_URL}/salary`, []);
  }, [fetchSalaries]);

  const deleteUserHandler = async (id) => {
    let salariesByUser =
      salaries?.data && salaries?.data?.items && salaries?.data?.items?.filter((item) => item.createdBy?._id === id);
    try {
      if (salariesByUser) {
        for (const salary of salariesByUser) {
          await Axios.delete(`${SERVER_URL}/salary/${salary._id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
        }
      }
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Client is deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (users?.data?.items && users?.data?.items?.length > 0) {
    const keys = Object.keys(users.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));

    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'firstName') {
        alternativeNames.push('Name');
      } else if (columnKey === 'lastName') {
        alternativeNames.push('Surname');
      } else if (columnKey === 'role') {
        alternativeNames.push('role');
      } else if (columnKey === 'status') {
        alternativeNames.push(columnKey);
      } else if (columnKey === 'email') {
        alternativeNames.push(columnKey);
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('Created on');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('updated on');
      } else if (columnKey === 'position') {
        alternativeNames.push(columnKey);
      } else if (columnKey === 'jmbg') {
        alternativeNames.push(columnKey);
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index],
    }));
  }

  let tableData = [];
  if (users?.data?.items && users?.data?.items?.length > 0) {
    tableData = users.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      const formattedDate = formatDateForTables(item.createdAt);
      const formattedDate2 = formatDateForTables(item.updatedAt);
      return { ...item, createdAt: formattedDate, updatedAt: formattedDate2 };
    });
  }

  return (
    <div style={{ padding: '8px' }}>
      {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) && (
        <Button.Group>
          <Button onClick={() => fetchUsers('admin')}>Admins</Button>
          <Button onClick={() => fetchUsers('user')}>Clients</Button>
          <Button onClick={() => fetchUsers('userOfficer')}>Client officers</Button>
          <Button onClick={() => fetchUsers('hrOfficer')}>HR Officers</Button>
          <Button onClick={() => fetchUsers('eInvoiceOfficer')}>E invoice officers</Button>
        </Button.Group>
      )}
      {(currentuser.data.role.includes('SuperAdmin') ||
        currentuser.data.role.includes('admin') ||
        currentuser.data.role.includes('user') ||
        currentuser.data.role.includes('employee')) && (
        <div className='actions-block'>
          <Link to='/admin/new-user'>
            <Button type='primary'>New client</Button>
          </Link>
        </div>
      )}

      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {users?.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!users?.isLoading && users?.data?.items && users?.data?.items?.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={newColumnKeys}
            editPath='/admin/edit-user/'
            viewPath='/admin/view-user/'
          />
        )}
        {!users?.isLoading && users?.data?.items && users?.data?.items?.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Users;
