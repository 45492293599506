import React from 'react';

export default function HrRequestIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_38_693)'>
        <path d='M0.565674 22.1234V24.2297H24.4344V22.1234L12.5 20.4266L0.565674 22.1234Z' fill='#FFE290' />
        <path d='M0.565674 19.4515V21.5578H24.4344V19.4515L12.5 17.7547L0.565674 19.4515Z' fill='#FFCE47' />
        <path d='M0.565674 16.7796V18.8859H24.4344V16.7796L12.5 15.0828L0.565674 16.7796Z' fill='#FFE290' />
        <path d='M24.4345 0.565628H0.565674V16.0096H24.4345V0.565628Z' fill='#FFCE47' />
        <path
          d='M24.4344 22.9782C24.122 22.9782 23.8688 23.2314 23.8688 23.5438V23.8688H1.1312V23.5437C1.1312 23.2313 0.87793 22.9781 0.565576 22.9781C0.253223 22.9781 0 23.2313 0 23.5437V24.4344C0 24.7468 0.253271 25 0.565625 25H24.4344C24.7468 25 25 24.7468 25 24.4344V23.5437C25 23.2314 24.7467 22.9782 24.4344 22.9782Z'
          fill='#6E6E6E'
        />
        <path
          d='M24.4344 20.3064C24.122 20.3064 23.8688 20.5596 23.8688 20.872V21.197H1.1312V20.8719C1.1312 20.5595 0.87793 20.3063 0.565576 20.3063C0.253223 20.3063 0 20.5595 0 20.8719V21.7626C0 22.075 0.253271 22.3282 0.565625 22.3282H24.4344C24.7468 22.3282 25 22.075 25 21.7626V20.8719C25 20.5595 24.7467 20.3064 24.4344 20.3064Z'
          fill='#6E6E6E'
        />
        <path
          d='M24.4344 17.6344C24.122 17.6344 23.8688 17.8876 23.8688 18.2V18.525H1.1312V18.1999C1.1312 17.8875 0.87793 17.6343 0.565576 17.6343C0.253223 17.6343 0 17.8875 0 18.1999V19.0906C0 19.403 0.253271 19.6562 0.565625 19.6562H24.4344C24.7468 19.6562 25 19.403 25 19.0906V18.2C25 17.8876 24.7467 17.6344 24.4344 17.6344Z'
          fill='#6E6E6E'
        />
        <path
          d='M24.4344 14.9625C24.122 14.9625 23.8688 15.2158 23.8688 15.5281V15.8532H1.1312V15.5281C1.1312 15.2158 0.87793 14.9625 0.565576 14.9625C0.253223 14.9625 0 15.2158 0 15.5281V16.4188C0 16.7312 0.253271 16.9844 0.565625 16.9844H24.4344C24.7468 16.9844 25 16.7312 25 16.4188V15.5281C25 15.2158 24.7467 14.9625 24.4344 14.9625Z'
          fill='#6E6E6E'
        />
        <path
          d='M24.4344 0H0.565625C0.253271 0 0 0.253223 0 0.565625V13.7469C0 14.0593 0.253271 14.3125 0.565625 14.3125H24.4344C24.7468 14.3125 25 14.0593 25 13.7469V0.565625C25 0.253223 24.7467 0 24.4344 0ZM23.8688 13.1813H1.1312V1.1312H23.8688V13.1813H23.8688Z'
          fill='#6E6E6E'
        />
        <path
          d='M4.52339 11.3437C4.83574 11.3437 5.08901 11.0905 5.08901 10.7781V3.53433C5.08901 3.22198 4.83574 2.9687 4.52339 2.9687C4.21104 2.9687 3.95776 3.22198 3.95776 3.53433V10.7781C3.95781 11.0905 4.21104 11.3437 4.52339 11.3437Z'
          fill='#6E6E6E'
        />
        <path
          d='M7.35139 11.3437C7.66375 11.3437 7.91702 11.0905 7.91702 10.7781V3.53433C7.91702 3.22198 7.66375 2.9687 7.35139 2.9687C7.03904 2.9687 6.78577 3.22198 6.78577 3.53433V10.7781C6.78577 11.0905 7.03899 11.3437 7.35139 11.3437Z'
          fill='#6E6E6E'
        />
        <path
          d='M10.1795 11.3437C10.4919 11.3437 10.7451 11.0905 10.7451 10.7781V3.53433C10.7451 3.22198 10.4919 2.9687 10.1795 2.9687C9.86716 2.9687 9.61389 3.22198 9.61389 3.53433V10.7781C9.61389 11.0905 9.86711 11.3437 10.1795 11.3437Z'
          fill='#6E6E6E'
        />
        <path
          d='M13.0075 11.3437C13.3199 11.3437 13.5731 11.0905 13.5731 10.7781V3.53433C13.5731 3.22198 13.3199 2.9687 13.0075 2.9687C12.6952 2.9687 12.4419 3.22198 12.4419 3.53433V10.7781C12.4419 11.0905 12.6951 11.3437 13.0075 11.3437Z'
          fill='#6E6E6E'
        />
        <path
          d='M15.8356 11.3437C16.148 11.3437 16.4013 11.0905 16.4013 10.7781V7.1562C16.4013 6.8438 16.148 6.59058 15.8356 6.59058C15.5233 6.59058 15.27 6.8438 15.27 7.1562V10.7781C15.27 11.0905 15.5232 11.3437 15.8356 11.3437Z'
          fill='#6E6E6E'
        />
      </g>
      <defs>
        <clipPath id='clip0_38_693'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
