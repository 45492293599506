import { CaretUpOutlined, ClockCircleOutlined, SwapOutlined } from '@ant-design/icons';
import { Card, Timeline } from 'antd';
import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';

export default function TimelineCard({history}) {
  const [typeOfTimeline, setTypeOfTimeline] = useState('');

  const getTypeOfTimeline = (historyEl) => {
    const {
      companyFrom,
      companyTo,
      newSector,
      oldSector,
      oldPosition,
      newPosition,
    } = historyEl;

    console.log({companyFrom,
      companyTo,
      newSector,
      oldSector,
      oldPosition,
      newPosition});

    if (
      companyFrom 
      && companyTo 
      && companyFrom._id !== companyTo._id 
    ) {
      setTypeOfTimeline('Transfer');
      return;
    }

    if (oldSector 
        && newSector 
        && oldSector._id !== newSector._id) 
      {

      setTypeOfTimeline('Promotion - Sector');
      return;
    }

    if (oldSector 
      && newSector 
      && oldSector._id === newSector._id
      && oldPosition !== newPosition
    ) {
      setTypeOfTimeline('Promotion - Position');
      return;
    }

    if (!oldSector && !newSector &&
      !oldPosition && 
      newPosition
    ) {
      setTypeOfTimeline("Promotion - Position");
      return;
    }

    if (!oldSector && newSector &&
      !oldPosition && 
      newPosition
    ) {
      setTypeOfTimeline("Promotion - Position");
      return;
    }

    setTypeOfTimeline('Default');
  }

  const getTimelineIcon = useCallback((timelineType) => {
    switch(timelineType) {
      case 'Transfer':
        return <SwapOutlined style={{ fontSize: '24px', marginLeft: '6px' }} fill='green' />
      case 'Promotion':
        return <CaretUpOutlined style={{ fontSize: '24px', marginLeft: '6px'  }} fill='#0f0' />
      default:
        return <ClockCircleOutlined style={{ fontSize: '24px', marginLeft: '6px'  }} fill="green" />
    }
  }, []);

  useEffect(() => {
    if (history) {
      getTypeOfTimeline(history);
    }
  }, [history])
  return (
    <>
      {history && history.dateOfRequest && typeOfTimeline ? (
        <Timeline.Item dot={getTimelineIcon(typeOfTimeline)} >
          <Card 
            title={
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <p style={{marginBottom: '0'}}>{typeOfTimeline === 'Default' ? '' : typeOfTimeline}</p>
                <p style={{marginBottom: '0'}}>{moment(history.dateOfRequest).format("DD MMM YYYY")}</p>
              </div>
            } 
            bordered={true} 
            style={{maxWidth: '40%', marginLeft: '20px'}}
          >
            {typeOfTimeline === 'Transfer' ? (
              <>
                <p>Company From: {history.companyFrom.name} </p>
                <p>Company To: {history.companyTo.name}</p>
                {history.newSector ? <p>Sector: {history.newSector.sectorName}</p> : null}
                <p>Position: {history.newPosition}</p>
              </>
            ) : null}
            {typeOfTimeline === 'Promotion - Sector' ? (
              <>
                <p>Company: {history.companyFrom.name}</p>
                <p>Sector from: {history.oldSector?.sectorName}</p>
                <p>Sector to: {history.newSector?.sectorName}</p>
                <p>Position to: {history.newPosition}</p>
              </>
            ) : null}
            {typeOfTimeline === 'Promotion - Position' ? (
              <>
                <p>Company: {history.companyFrom.name}</p>
                {history.newSector ? (
                  <p>Sector: {history.newSector?.sectorName}</p>
                ) : null}
                {history.oldPosition ? (
                  <p>Position from: {history.oldPosition}</p>
                ) : null}
                <p>Position to: {history.newPosition}</p>
              </>
            ) : null}
            {typeOfTimeline === 'Default' ? (
              <>
                <p>Company: {history.companyFrom.name}</p>
                <p>Sector: {history.newSector?.sectorName}</p>
                <p>Position: {history.newPosition}</p>
              </>
            ) : null}
          </Card>
        </Timeline.Item>
      ) : null}
    </>
  )
}
