import { createContext, useCallback, useContext, useMemo, useState } from 'react';

const ThemeContext = createContext({
  theme: 'dark',
});

const ThemeContextDispatch = createContext({
  toggleTheme: () => {},
});

export const useThemeContext = () => useContext(ThemeContext);
export const useThemeContextDispatch = () => useContext(ThemeContextDispatch);

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => localStorage.getItem('colorTheme') || 'dark');

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light';
      localStorage.setItem('colorTheme', newTheme);
      return newTheme;
    });
  }, []);

  const values = useMemo(
    () => ({
      theme,
    }),
    [theme],
  );

  const functions = useMemo(
    () => ({
      toggleTheme,
    }),
    [toggleTheme],
  );

  return (
    <ThemeContext.Provider value={values}>
      <ThemeContextDispatch.Provider value={functions}>
        {children}
      </ThemeContextDispatch.Provider>
    </ThemeContext.Provider>
  );
};
