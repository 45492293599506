import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import GuidelineForm from '../../components/forms/GuidelineForm';
import useAxios from '../../hooks/useAxios';


const EditGuideline = (props) => {

  const currentuser = useContext(UserContext);
  const history = useHistory();
  const { guidelineId } = props.match.params;
  const [guideline, fetchGuideline] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (guidelineId) {
      fetchGuideline(`${SERVER_URL}/guidelines/${guidelineId}`, []);
    }
  }, [guidelineId, fetchGuideline]);

  const updateGuideline = async (data) => {
    try {
      await Axios.put(`${SERVER_URL}/guidelines/${guidelineId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Guideline is edited.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/guidelines');
    } catch (err) {
      notification.error({
        message: 'Problem with edit. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const createGuideline = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/guidelines`, { ...data }, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Guideline is created.',
        placement: 'bottomRight',
      });
      history.push('/admin/guidelines');
    } catch (err) {
      notification.error({
        message: err.response.data && err.response.data.message ? err.response.data.message : 'Problem with creating guideline. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div style={{ textAlign: 'center' }}>
        {guidelineId && guideline.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {guidelineId && !guideline.isLoading && !guideline.isError && guideline.data && (
          <GuidelineForm data={guideline.data} updateHandler={updateGuideline} SERVER_URL={SERVER_URL} token={currentuser.data.token}
            user={currentuser.data} />
        )}
        {guidelineId && !guideline.isLoading && guideline.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!guidelineId && (
          <GuidelineForm data={null} createHandler={createGuideline} SERVER_URL={SERVER_URL} token={currentuser.data.token}
            user={currentuser.data} />
        )}
      </div>
    </div>
  );
};

export default EditGuideline;