import React, {useContext, useEffect, useState} from 'react';
import {notification} from 'antd';
import useAxios from '../../hooks/useAxios';
import {UserContext} from '../../App';
import Axios from 'axios';
import {SERVER_URL} from '../../config';
import ExtendEmploymentForm from '../../components/forms/ExtendEmploymentForm';
import moment from 'moment';

const ExtendEmployment = () => {
  const currentuser = useContext(UserContext);
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [company, setCompany] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [chosenEmployee, setChosenEmployee] = useState([]);

  useEffect(() => {
    let companyFields = {
      _id: 1,
      name: 1,
    };
    if ((currentuser.data.role.includes('admin') || currentuser.data.role.includes('SuperAdmin'))) {
      fetchCompanies(`${SERVER_URL}/companies?select=${JSON.stringify(companyFields)}`);
    }
    if ((currentuser.data.role.includes('user') || currentuser.data.role.includes('userOfficer')) && currentuser.data.company) {
      setCompany(currentuser.data.company._id)
    }
    if (currentuser.data.role.includes('hrOfficer') && currentuser.data.sectorId && !currentuser.data.role.includes('user')) {
      fetchSelectedSector(currentuser.data.sectorId).then(r => {
        const filter = { employeeSector: currentuser.data.sectorId};
        fetchSelectedCompanyEmployees(filter).then(res => {
          setData(res.data.items);
        });
      });
    }
  }, [currentuser, fetchCompanies]);

  useEffect(() => {
    if (company) {
      const filter = { company: company};
      fetchSelectedCompanyEmployees(filter).then(r => {
        setData(r.data.items)
      });
    }
  }, [company]);

  const fetchSelectedCompanyEmployees = async(filter) => {
    let employeeFields = {
      _id: 1,
      firstName: 1,
      lastName: 1,
      startDate: 1,
      endDate: 1,
      employmentType: 1,
      JMBG: 1,
      attachments: 1
    };
    try {
      return await Axios.get(
          `${SERVER_URL}/terminated-employees?filter=${JSON.stringify(
              filter,
          )}&select=${JSON.stringify(
              employeeFields)}
              &type=extendEmployment
          &user=${currentuser.data.id}`,
          {
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentuser.data.token}`},
          }
      );
    } catch (error) {
      console.log('error in fetching employees for selected company', error)
    }
  }

  const fetchSelectedSector = async(sectorId) => {
    try {
      return await Axios.get(
          `${SERVER_URL}/sector/${sectorId}`,
          {
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentuser.data.token}`},
          });
    } catch (error) {
      console.log('error in fetching employees for selected company', error)
    }
  }

  const onSubmit = async(formData) => {
    formData.user = currentuser.data;
    let keys = [];
    if (formData.attachments.length !== 0) {
      formData.attachments.forEach((attachment) => {
        keys.push(attachment.location);
      });
      const zipData = {
        keys: keys,
        employee: chosenEmployee[0],
      };
      try {
        await Axios.post(`${SERVER_URL}/zip-employee`, zipData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } catch (error) {
        console.log('error in creating zip-employee', error)
      }

      try {
        await Axios.get(`${SERVER_URL}/file/zip/${chosenEmployee[0].firstName}_${chosenEmployee[0].lastName}_${chosenEmployee[0].JMBG}.zip`, {
          withCredentials: false,
        });
      } catch (error) {
        console.log('error in creating file/zip for employee extension', error)
      }
    }

    try {
      await Axios.post(
          `${SERVER_URL}/extend-employee`, formData,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          }
      );

    } catch (error) {
      console.log('error in extend employment submit', error)
    }

    try {
      await Axios.post(`${SERVER_URL}/salary-on-extend`, {
        employee: formData.employee,
        employmentType: formData.employmentType,
        endDate: formData.endDate,
        month: moment(moment(formData.endDate).format('M'), 'M').format('MMMM'),
        year: moment(moment(formData.endDate).format('YYYY'), 'YYYY').format('YYYY'),
      }, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Employee is extended.',
        placement: 'bottomRight',
      });
      setCompany(undefined);
      document.getElementById('extend').disabled = false;
    } catch (error) {
      console.log('error in updating salary upon employment extension', error)
    }
  }

  return (
      <div style={{ padding: '8px' }}>
        {data && data.length && (!currentuser.data.role.includes('admin') && !currentuser.data.role.includes('SuperAdmin')) && (
            <ExtendEmploymentForm
                data={data}
                onSubmit={onSubmit}
                SERVER_URL={SERVER_URL}
                token={currentuser.data.token}
                chosenEmployee={chosenEmployee}
                setChosenEmployee={setChosenEmployee}
            />
        )}
        {(currentuser.data.role.includes('admin') || currentuser.data.role.includes('SuperAdmin')) && companies && companies.data && companies.data.items && (
            <ExtendEmploymentForm
                data={data}
                onSubmit={onSubmit}
                SERVER_URL={SERVER_URL}
                token={currentuser.data.token}
                companies={companies}
                company={company}
                setCompany={setCompany}
                chosenEmployee={chosenEmployee}
                setChosenEmployee={setChosenEmployee}
            />
        )}
      </div>
  );

};

export default ExtendEmployment;