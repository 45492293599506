import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

export default function AddEmployeeIcon(props) {
  const { theme } = useThemeContext();
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M8.96114 15.6516L7.39807 23.483L9.32715 24.963L11.2541 23.484L9.6677 15.6516H8.96114Z' fill='#6EC4A7' />
      <path
        d='M15.4875 13.1237C14.3431 14.2068 12.8967 14.9914 11.2831 15.3544L12.9664 23.6356C13.03 23.927 12.9172 24.2272 12.6764 24.4121L11.9511 24.9681C13.97 24.7367 16.1579 24.232 18.4492 23.3096V17.1635C18.4492 15.3415 17.4696 14.0202 15.4875 13.1237Z'
        fill={theme === 'dark' ? 'white' : 'white'}
      />
      <path
        d='M7.34012 15.3805C5.68864 15.0274 4.20867 14.2336 3.04273 13.1298C1.14194 14.0236 0.204102 15.3432 0.204102 17.1635V23.3391C1.1179 23.7466 3.46957 24.6648 6.74212 25L5.97756 24.4131C5.73638 24.2276 5.62321 23.9287 5.68545 23.6387L7.34012 15.3805Z'
        fill={theme === 'dark' ? 'white' : 'white'}
      />
      <path
        d='M9.25976 14.009C13.259 14.009 16.5114 10.8671 16.5114 7.0047C16.5114 3.14226 13.2587 0 9.25976 0C5.26403 0 2.01306 3.14192 2.01306 7.00435C2.01306 10.8668 5.26403 14.009 9.25976 14.009Z'
        fill={theme === 'dark' ? 'white' : 'white'}
      />
      <path d='M16.1047 2.38509H19.9664V12.2634H16.1047V2.38509Z' fill={'white'} />
      <path d='M12.243 4.85466H23.8281V8.55902H12.243V4.85466Z' fill={'white'} />
      <path
        d='M18.2615 0.636383C14.5483 0.636383 11.5265 3.53508 11.5265 7.09899C11.5265 10.6622 14.5483 13.5616 18.2615 13.5616C21.9775 13.5616 25 10.6622 25 7.09899C25 3.53474 21.9775 0.636383 18.2615 0.636383ZM21.0007 8.00507H19.0777V9.85012C19.0777 10.32 18.6805 10.7002 18.1915 10.7002C17.7024 10.7002 17.306 10.32 17.306 9.85012V8.00507H15.3825C14.8935 8.00507 14.4963 7.6248 14.4963 7.15497C14.4963 6.68582 14.8935 6.30554 15.3825 6.30554H17.306V4.4615C17.306 3.99235 17.7024 3.61208 18.1915 3.61208C18.6805 3.61208 19.0777 3.99235 19.0777 4.4615V6.30554H21.0007C21.4898 6.30554 21.8862 6.68582 21.8862 7.15497C21.8862 7.6248 21.4898 8.00507 21.0007 8.00507Z'
        fill='#6EC4A7'
      />
    </svg>
  );
}
