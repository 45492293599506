import React, {useContext, useEffect, useState} from 'react';
import {Modal, notification, Spin} from 'antd';
import useAxios from '../../hooks/useAxios';
import {UserContext} from '../../App';
import Axios from 'axios';
import {SERVER_URL} from '../../config';
import TerminateEmployeeForm from '../../components/forms/TerminateEmployeeForm';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';

const TerminateEmployee = () => {
  const currentuser = useContext(UserContext);
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
  const [company, setCompany] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [chosenEmployee, setChosenEmployee] = useState([]);

  useEffect(() => {
    let companyFields = {
      _id: 1,
      name: 1,
    };
      if ((currentuser.data.role.includes('admin') || currentuser.data.role.includes('SuperAdmin'))) {
        fetchCompanies(`${SERVER_URL}/companies?select=${JSON.stringify(companyFields)}`);
    }
    if ((currentuser.data.role.includes('user') || currentuser.data.role.includes('userOfficer')) && currentuser.data.company) {
        setCompany(currentuser.data.company._id)
    }
    if (currentuser.data.role.includes('hrOfficer') && currentuser.data.sectorId && !currentuser.data.role.includes('user')) {
        fetchSelectedSector(currentuser.data.sectorId).then(r => {
        const filter = { employeeSector: currentuser.data.sectorId};
        fetchSelectedCompanyEmployees(filter).then(res => {
            setData(res.data.items);
            });
        });
    }
  }, [currentuser, fetchCompanies]);

  useEffect(() => {
    if (company) {
      const filter = { company: company};
      fetchSelectedCompanyEmployees(filter).then(r => {
          setData(r.data.items)
      });
    }
  }, [company]);

  const fetchSelectedCompanyEmployees = async(filter) => {
    let employeeFields = {
      _id: 1,
      firstName: 1,
      lastName: 1,
      startDate: 1,
      endDate: 1,
      employmentType: 1,
      JMBG: 1,
      attachments: 1

    };
    try {
      return await Axios.get(
          `${SERVER_URL}/employees-for-termination-dropdown?filter=${JSON.stringify(
              filter,
          )}&select=${JSON.stringify(
              employeeFields)}
              &type=terminateEmployment
          &user=${currentuser.data.id}`,
          {
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentuser.data.token}`},
          }
      );
    } catch (error) {
      console.log('error in fetching employees for selected company', error)
    }
  }

  const fetchSelectedSector = async(sectorId) => {
      try {
           return await Axios.get(
             `${SERVER_URL}/sector/${sectorId}`,
              {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentuser.data.token}`},
              });
     } catch (error) {
         console.log('error in fetching employees for selected company', error)
    }
  }

  const onSubmit = async(formData) => {
    formData.user = currentuser.data;
    let keys = [];
    if (formData.attachments.length !== 0) {
      setIsLoadingModalVisible(true);
      formData.attachments.forEach((attachment) => {
        keys.push(attachment.location);
      });
      const zipData = {
        keys: keys,
        employee: chosenEmployee[0],
      };
      try {
        await Axios.post(`${SERVER_URL}/zip-employee`, zipData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        }).then(async (res) => {
          if (res.data.message === 'Zip file created') {
              await Axios.get(`${SERVER_URL}/file/zip/${chosenEmployee[0].firstName}_${chosenEmployee[0].lastName}_${chosenEmployee[0].JMBG}.zip`, {
                withCredentials: false,
              }).then(async (zipResponse) => {
                if (zipResponse.status === 200) {
                  await Axios.post(
                    `${SERVER_URL}/terminate-employee`, formData,
                    {
                      withCredentials: false,
                      headers: { Authorization: `Bearer ${currentuser.data.token}` },
                    }
                  ).then(async (terminateResponse) => {
                    if (terminateResponse.data.message === 'Employment terminated and emails sent!') {
                      await Axios.post(`${SERVER_URL}/salary-on-terminate`, {
                        employee: formData.employee,
                        endDate: formData.endDate,
                        month: moment(moment(formData.endDate).format('M'), 'M').format('MMMM'),
                        year: moment(moment(formData.endDate).format('YYYY'), 'YYYY').format('YYYY'),
                      }, {
                        withCredentials: false,
                        headers: { Authorization: `Bearer ${currentuser.data.token}` },
                      }).then((salaryTerminateResponse) => {
                        if (salaryTerminateResponse.data.message === 'Salary is changed' || salaryTerminateResponse.data.message === 'Nothing to change') {
                          notification.success({
                            message: 'Employee is terminated.',
                            placement: 'bottomRight',
                          });
                          setCompany(undefined);
                          document.getElementById('terminate').disabled = false;
                          setIsLoadingModalVisible(false);
                        }
                      }).catch((salaryError) => {
                        console.log("Salary change error", salaryError)
                      });
                    }
                  }).catch((terminateErr) => {
                    console.log("Terminate err: ", terminateErr)
                  });
                }
              }).catch(zipErr => console.log('ZipErr: ', zipErr))
          }
         }).catch((error) => {
            console.log('error in creating zip-employee', error)
         });
       } catch (error) {
          console.log('error in creating zip-employee', error)
       }

    }

    if (formData.attachments.length === 0) {
      try {
        await Axios.post(
            `${SERVER_URL}/terminate-employee`, formData,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            }
        ).then(async (response) => {
          if (response.data.message === 'Employment terminated and emails sent!') {
              await Axios.post(`${SERVER_URL}/salary-on-terminate`, {
                employee: formData.employee,
                endDate: formData.endDate,
                month: moment(moment(formData.endDate).format('M'), 'M').format('MMMM'),
                year: moment(moment(formData.endDate).format('YYYY'), 'YYYY').format('YYYY'),
              }, {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
              }).then((salaryTerminateResponse) => {
                if (salaryTerminateResponse.data.message === 'Salary is changed' || salaryTerminateResponse.data.message === 'Nothing to change') {
                  notification.success({
                    message: 'Employee is terminated.',
                    placement: 'bottomRight',
                  });
                  setCompany(undefined);
                  document.getElementById('terminate').disabled = false;
                }
              }).catch((salaryError) => {
                console.log("Salary change error", salaryError)
              });
            }
          });
      } catch (error) {
        console.log('error in termination employment submit', error)
      }
    }
  }

  return (
      <div style={{ padding: '8px' }}>
        {data && data.length && (!currentuser.data.role.includes('admin') && !currentuser.data.role.includes('SuperAdmin')) && (
            <TerminateEmployeeForm
                data={data}
                onSubmit={onSubmit}
                SERVER_URL={SERVER_URL}
                token={currentuser.data.token}
                chosenEmployee={chosenEmployee}
                setChosenEmployee={setChosenEmployee}
            />
        )}
        {(currentuser.data.role.includes('admin') || currentuser.data.role.includes('SuperAdmin')) && companies && companies.data && companies.data.items && (
            <TerminateEmployeeForm
                data={data}
                onSubmit={onSubmit}
                SERVER_URL={SERVER_URL}
                token={currentuser.data.token}
                companies={companies}
                company={company}
                setCompany={setCompany}
                chosenEmployee={chosenEmployee}
                setChosenEmployee={setChosenEmployee}
            />
        )}
        <Modal
          visible={isLoadingModalVisible} 
          maskClosable={false} 
          closable={false} 
          footer={null}
        >
          <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
            <p>Terminating Employee.</p>
            <p>Please wait. This may take up to a minute depending on number of attachments that need to be uploaded.</p>
            <p>
              <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </p>
          </div>
        </Modal>
      </div>
  );

};

export default TerminateEmployee;