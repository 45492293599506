import { Button, Drawer, TreeSelect, TimePicker, Divider } from 'antd';

const CustomDrawer = ({
  onClose,
  customDayDrawer,
  showChildrenDrawer,
  showChildrenCustomDayDrawer,
  onChildrenSingleDayDrawerClose,
  customSingleDayChildrenDrawer,
  onChildrenDrawerClose,
  customChildrenDrawer,
  setWorkType1FromDrawer,
  workTypeList,
  setCustom2HoursFromDrawer,
  handlePatternCreation,
  setCustom1HoursFromDrawer,
  setWorkType2FromDrawer,
  handleSingleCreation,
  workType1FromDrawer,
  workType2FromDrawer,
  custom1HoursFromDrawer,
  custom2HoursFromDrawer,
  randomKey,
}) => {
  return (
    <Drawer title='Custom day settings' width={520} closable={false} onClose={onClose} open={customDayDrawer} >
      <div style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
        <p style={{ marginBottom: '10px' }}>Here you can set patterns for custom days.</p>
        <p style={{ marginBottom: '10px' }}>
          If you want to create <strong>an individual custom day</strong>, please click here:
        </p>
        <Button type='primary' onClick={showChildrenCustomDayDrawer} style={{ marginBottom: '20px' }}>
          Custom Day
        </Button>
        <Divider style={{ margin: '30px 0', backgroundColor: '#2db1ab50' }} />
        <div style={{ marginBottom: '10px' }}>
          If you want to set <strong>multimarking mode for custom days</strong>, click here:
        </div>
        <Button type='primary' onClick={showChildrenDrawer} style={{ marginBottom: '20px' }}>
          Multimarking Pattern
        </Button>
        <Drawer
          title='Custom Day Multimarking Pattern'
          width={480}
          closable={false}
          onClose={onChildrenDrawerClose}
          open={customChildrenDrawer}
        >
          <div style={{ padding: '20px', width: '100%' }}>
            <p style={{ marginBottom: '10px' }}>Set the pattern for multimarking custom days:</p>

            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
              <p style={{ marginBottom: '10px' }}>Type of work 1:</p>
              <TreeSelect
                size={window.innerHeight < 800 && window.innerWidth < 1600 ? 'small' : 'middle'}
                treeDefaultExpandAll
                onChange={(value) => {
                  setWorkType1FromDrawer(value);
                }}
                style={{ width: '100%' }}
                value={workType1FromDrawer}
              >
                {workTypeList.length !== 0 && workTypeList}
              </TreeSelect>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <p style={{ marginBottom: '10px' }}>Work hours 1:</p>
              <TimePicker
                onChange={(value) => setCustom1HoursFromDrawer(value)}
                format='HH:mm'
                showNow={false}
                style={{ width: '100%' }}
                hideDisabledOptions={true}
                allowClear={false}
                value={custom1HoursFromDrawer}
              />
            </div>
            <Divider style={{ margin: '30px 0', backgroundColor: '#2db1ab50' }} />
            <div style={{ marginBottom: '20px' }}>
              <p style={{ marginBottom: '10px' }}>Type of work 2:</p>
              <TreeSelect
                size={window.innerHeight < 800 && window.innerWidth < 1600 ? 'small' : 'middle'}
                treeDefaultExpandAll
                onChange={(value) => {
                  setWorkType2FromDrawer(value);
                }}
                style={{ width: '100%' }}
                value={workType2FromDrawer}
              >
                {workTypeList.length !== 0 && workTypeList}
              </TreeSelect>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <p style={{ marginBottom: '10px' }}>Work hours 2:</p>
              <TimePicker
                onChange={(value) => setCustom2HoursFromDrawer(value)}
                format='HH:mm'
                showNow={false}
                style={{ width: '100%' }}
                hideDisabledOptions={true}
                allowClear={false}
                value={custom2HoursFromDrawer}
              />
            </div>
            <p style={{ marginBottom: '20px', marginTop: '20px' }}>
              * This pattern is valid only until you submit timesheet for the current month.
            </p>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type='primary' onClick={handlePatternCreation} style={{ marginBottom: '20px' }}>
                Create Pattern
              </Button>
            </div>
          </div>
        </Drawer>

        <Drawer
          title='Individual Custom Day'
          width={480}
          closable={false}
          onClose={onChildrenSingleDayDrawerClose}
          open={customSingleDayChildrenDrawer}
          key={randomKey}
        >
          <div style={{ padding: '20px', width: '100%' }}>
            <p style={{ marginBottom: '10px' }}>Set an individual custom day:</p>

            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
              <p style={{ marginBottom: '10px' }}>Type of work 1:</p>
              <TreeSelect
                size={window.innerHeight < 800 && window.innerWidth < 1600 ? 'small' : 'middle'}
                treeDefaultExpandAll
                onChange={(value) => {
                  setWorkType1FromDrawer(value);
                }}
                style={{ width: '100%' }}
              >
                {workTypeList.length !== 0 && workTypeList}
              </TreeSelect>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <p style={{ marginBottom: '10px' }}>Work hours 1:</p>
              <TimePicker
                onChange={(value) => setCustom1HoursFromDrawer(value)}
                format='HH:mm'
                showNow={false}
                style={{ width: '100%' }}
                hideDisabledOptions={true}
                allowClear={false}
              />
            </div>
            <Divider style={{ margin: '30px 0', backgroundColor: '#2db1ab50' }} />
            <div style={{ marginBottom: '20px' }}>
              <p style={{ marginBottom: '10px' }}>Type of work 2:</p>
              <TreeSelect
                size={window.innerHeight < 800 && window.innerWidth < 1600 ? 'small' : 'middle'}
                treeDefaultExpandAll
                onChange={(value) => {
                  setWorkType2FromDrawer(value);
                }}
                style={{ width: '100%' }}
              >
                {workTypeList.length !== 0 && workTypeList}
              </TreeSelect>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <p style={{ marginBottom: '10px' }}>Work hours 2:</p>
              <TimePicker
                onChange={(value) => setCustom2HoursFromDrawer(value)}
                format='HH:mm'
                showNow={false}
                style={{ width: '100%' }}
                hideDisabledOptions={true}
                allowClear={false}
              />
            </div>
            <p style={{ marginBottom: '20px', marginTop: '20px' }}>* This setup is valid only for selected day.</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type='primary' onClick={handleSingleCreation} style={{ marginBottom: '20px' }}>
                Select day for custom work
              </Button>
            </div>
          </div>
        </Drawer>
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
