import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { UserContext } from '../../App';
import {
  Input,
  Select,
  Button,
  Form,
  Card,
  DatePicker,
  Modal,
  Row,
  Col,
  notification,
  Upload,
  Divider,
  Checkbox,
} from 'antd';
import { UploadOutlined, DownloadOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import FileSaver from 'file-saver';
import { toNumber } from 'lodash';
import CustomerInvoiceForm from './CustomerInvoiceForm';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

const { Option } = Select;
const { TextArea } = Input;

const formInit = {
  documentType: 'invoice',
  documentNumber: undefined,
  documentLanguage: 'Serbian',
  documentCurrency: 'RSD',
  documentDate: moment(),
  supplyDate: undefined,
  dueDate: moment().add(1, 'M').subtract(1, 'd'),
  interestArrears: 0,
  poNumber: undefined,
  customerReference: undefined,
  supplierReference: undefined,
  paymentReference: undefined,
  comment: undefined,
  totalDiscounts: 0,
  totalVatExcl: 0,
  total: 0,
  prepaidAmount: 0,
  openBalance: 0,
  isForeignInvoice: false,
};

const InvoiceForm = ({ isNew, SERVER_URL, data, invoiceId }) => {
  const [form] = Form.useForm();
  const [customerForm] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [allCompanies, setAllCompanies] = useAxios('', null, currentuser.data.token, 'get');
  const [selectedVat, setSelectedVat] = useState({});
  const [documentType, setDocumentType] = useState('invoice');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [buttonType, setButtonType] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [currencyType, setCurrencyType] = useState('RSD');
  const [languageType, setLanguageType] = useState('Serbian');
  const [measures, setMeasures] = useState();
  const [isForeignInvoice, setIsForeignInvoice] = useState(false);
  const [customers, setCustomers] = useAxios('', null, currentuser.data.token, 'get');
  const formRef = useRef(null);
  const saveBtnRef = useRef(null);
  const sendBtnRef = useRef(null);
  const history = useHistory();

  // helper for setting dynamic VAT values in EDIT mode
  const updateVatFields = useCallback(debounce((vatsByTaxRateObject) => {
    setSelectedVat(vatsByTaxRateObject);
    const vatFields = Object.keys(vatsByTaxRateObject).reduce((acc, keyName) => {
      acc[`vatSelected__${keyName}`] = vatsByTaxRateObject[keyName].toFixed(2);
      return acc;
    }, {});

    form.setFieldsValue(vatFields);
  }, 300), [form]);

  useEffect(() => {
    if (data?.data?.isForeignInvoice) {
      setIsForeignInvoice(true);
    }
  }, [data]);

  // conditional setting of form fields values if the page is in edit mode
  useEffect(() => {
    if (data && data.data) {
      const incomingData = data.data;
      form.setFieldsValue({
        documentDate: incomingData.documentDate,
        supplyDate: incomingData.supplyDate,
        dueDate: incomingData.dueDate,
        originalInvoiceDate: incomingData.originalInvoiceDate,
        poNumber: incomingData.poNumber,
        customerReference: incomingData.customerReference,
        supplierReference: incomingData.supplierReference,
        products: incomingData.products,
        totalDiscounts: incomingData.totalDiscounts,
        totalVatExcl: incomingData.totalVatExcl,
        total: incomingData.total,
        prepaidAmount: incomingData.prepaidAmount,
        openBalance: incomingData.openBalance,
        documentNumber: incomingData.documentNumber,
      });

      if (incomingData.vats) {
        const vatsByTaxRateObject = incomingData.vats.reduce((acc, vatObj) => {
          const key = Object.keys(vatObj)[0];
          acc[key] = +vatObj[key];
          return acc;
        }, {});

        updateVatFields(vatsByTaxRateObject);
      }

      setSelectedCustomer({
        customerName: incomingData.customerName,
        postalAddress: incomingData.customerPostalAddress,
        city: incomingData.customerCity,
        zipCode: incomingData.customerZipCode,
        vat: incomingData.customerVat,
        companyNumber: incomingData.customerCompanyNumber,
      });
      setDocumentType(incomingData.documentType);
      setLanguageType(incomingData.documentLanguage);
      setCurrencyType(incomingData.documentCurrency);
      setInvoiceData(incomingData.products);
    } else {
      setInvoiceData(formInit);
      form.setFieldsValue(formInit);
    }

    return () => {};
  }, [data, form, updateVatFields]);

  useEffect(() => {
    if (!data) {
      if (currentuser?.data?.company?._id) {
        setAllCompanies(`${SERVER_URL}/eCompanies`, []);
      } else {
        notification.error({
          message: 'Please set all required data before use of E-Invoices platform',
          placement: 'bottomRight',
        });
        setTimeout(() => {
          window.location.href = '/admin';
        }, 1200);
      }
    }

    return () => {};
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (data?.data) {
    data.data.documentDate = data.data.documentDate && moment(data.data.documentDate);
    data.data.supplyDate = data.data.supplyDate && moment(data.data.supplyDate);
    data.data.dueDate = data.data.dueDate && moment(data.data.dueDate);
  }

  useEffect(() => {
    let filter = currentuser.data.company._id;
    setCustomers(`${SERVER_URL}/customer?filter=${JSON.stringify(filter)}`, []);
  }, [setCustomers]);

  useEffect(() => {
    fetchMeasuresData();
  }, [setMeasures]);

  const fetchMeasuresData = async () => {
    try {
      let filter = currentuser.data.company._id;
      await Axios.get(`${SERVER_URL}/measures?filter=${JSON.stringify(filter)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((res) => {
        setMeasures(res?.data);
      });
    } catch (error) {
      notification.error({
        message: 'Cannot access measures. Check your company apiKey and try again',
        placement: 'bottomRight',
      });
    }
  };

  // helper function for calculating values
  const helperFunction = (arr) => {
    let sum = 0;
    for (let index = 0; index < arr.length; index++) {
      sum += arr[index];
    }
    return sum;
  };

  // helper function for removing properties with 0 values
  const removeZero = (item) =>
    Object.keys(item)
      .filter((key) => item[key] !== 0)
      .reduce((newObj, key) => {
        newObj[key] = item[key];
        return newObj;
      }, {});

  const showUploadList = {
    showDownloadIcon: true,
    downloadIcon: <DownloadOutlined />,
    showRemoveIcon: true,
  };

  const onValuesChange = (value, allValues) => {
    let totalVatExclSum = 0;
    let totalSum = 0;
    let totalDiscountSum;
    const vats = [];
    let ratePriceKey = 'ratePrice';
    let discountKey = 'discount';
    let vatKey = 'vat';
    let quantityKey = 'quantity';

    /** start of setting invoiceRows form-list values **/
    const array = [];
    allValues?.products?.map((item) => {
      let object = {
        description: '',
        discount: 0,
        grossPrice: 0,
        quantity: 1,
        ratePrice: 0,
        serviceProduct: '',
        subtotal: 0,
        unit: '',
        vat: 0,
      };

      if (item) {
        object.description = item.description;
        object.discount = toNumber(item.discount);
        object.quantity = toNumber(item.quantity);
        object.ratePrice = toNumber(item.ratePrice);
        object.serviceProduct = item.serviceProduct;
        object.unit = item.unit;
        object.vat = toNumber(item.vat);

        const subtotalCalc =
          toNumber(item[quantityKey]) *
          (toNumber(item[ratePriceKey]) - (toNumber(item[ratePriceKey]) * toNumber(item[discountKey])) / 100);
        object.subtotal = subtotalCalc;

        const grossPriceCalc = toNumber(
          item[ratePriceKey] -
            (toNumber(item[ratePriceKey]) * toNumber(item[discountKey])) / 100 +
            ((toNumber(item[ratePriceKey]) - (toNumber(item[ratePriceKey]) * toNumber(item[discountKey])) / 100) *
              item[vatKey]) /
              100,
        );
        object.grossPrice = grossPriceCalc.toFixed(2);

        // add corresponding vats
        const obj = {};
        obj[item.vat] = item[quantityKey] * grossPriceCalc - subtotalCalc;
        vats.push(obj);
        array.push(object);
      } else {
        array.push(object);
      }

      const filteredArray = [];
      array.forEach((element) => {
        if (element.vat !== undefined && element.serviceProduct !== undefined) {
          filteredArray.push(element);
        }
      });

      form.setFieldsValue({ ['products']: filteredArray });
      /** end of setting invoiceRows form-list values **/
    });

    // sum all vat values per key
    let total10 = 0;
    let total15 = 0;
    let total20 = 0;
    vats.map((item) => {
      if (item) {
        if (item[10]) {
          total10 += item[10];
        }
        if (item[15]) {
          total15 += item[15];
        }
        if (item[20]) {
          total20 += item[20];
        }
      }
    });

    let vatsByTaxRateObject = {
      10: total10,
      15: total15,
      20: total20,
    };

    // remove object properties with 0 values in purpose of not showing those VAT fields
    vatsByTaxRateObject = removeZero(vatsByTaxRateObject);

    /** start of setting fields with total values in invoice **/
    const formArray = form.getFieldsValue();
    const discountValues = [];
    const totalVatExcluded = [];
    const totalSumArray = [];
    formArray?.products?.forEach((element) => {
      if (element.vat !== undefined && element.serviceProduct !== undefined) {
        discountValues.push(
          toNumber(element[quantityKey]) * ((toNumber(element[ratePriceKey]) * toNumber(element[discountKey])) / 100),
        );
        totalVatExcluded.push(
          toNumber(element[quantityKey]) *
            (toNumber(element[ratePriceKey]) -
              (toNumber(element[ratePriceKey]) * toNumber(element[discountKey])) / 100),
        );
        totalSumArray.push(
          element[quantityKey] *
            (element[ratePriceKey] -
              (toNumber(element[ratePriceKey]) * toNumber(element[discountKey])) / 100 +
              ((toNumber(element[ratePriceKey]) -
                (toNumber(element[ratePriceKey]) * toNumber(element[discountKey])) / 100) *
                element[vatKey]) /
                100),
        );
      }
    });

    totalDiscountSum = helperFunction(discountValues);
    totalVatExclSum = helperFunction(totalVatExcluded);
    totalSum = helperFunction(totalSumArray);
    /** end of setting fields with total values in invoice **/

    setSelectedVat(vatsByTaxRateObject);
    for (const key in vatsByTaxRateObject) {
      if (vatsByTaxRateObject.hasOwnProperty(key)) {
        const vatSelectedKey = `vatSelected__${key}`;
        form.setFieldsValue({ [vatSelectedKey]: vatsByTaxRateObject[key].toFixed(2) });
      }
    }

    if (!('openBalance' in value)) {
      let prepaidAmount = allValues.prepaidAmount;
      formRef.current.setFieldsValue({
        totalDiscounts: totalDiscountSum.toFixed(2),
        totalVatExcl: totalVatExclSum.toFixed(2),
        total: totalSum.toFixed(2),
        openBalance: (totalSum - prepaidAmount).toFixed(2),
      });
    } else {
      let openBalance = allValues.openBalance;
      formRef.current.setFieldsValue({
        totalDiscounts: totalDiscountSum.toFixed(2),
        totalVatExcl: totalVatExclSum.toFixed(2),
        total: totalSum.toFixed(2),
        prepaidAmount: (totalSum - openBalance).toFixed(2),
      });
    }
  };

  const onFinish = async (values) => {
    const vats = selectedVat;
    const receiver = selectedCustomer;
    let uploadedFiles = [];
    let upload = [];
    for (let i = 0; i <= files.length - 1; i++) {
      uploadedFiles = files[i].info.file.response;
      upload.push(uploadedFiles);
    }
    // create PUT API call parameters if the page is in edit mode
    let APIRoute;
    const axiosMethod = data && buttonType === 'save' ? Axios.put : Axios.post;
    const editMode = data && buttonType === 'save';
    if (editMode) {
      APIRoute = `edit-sales/${invoiceId}`;
    } else {
      // create POST API call parameters if the page is not in edit mode
      APIRoute =
        buttonType === 'save' && !editMode
          ? `save-sales-invoice`
          : buttonType === 'send' && isForeignInvoice
            ? `foreign-invoice`
                    : `postUbl`;
    }
    values.vats = vats;
    values.customer = receiver;
    values.uploadedFiles = upload;
    values.userCompany = currentuser.data.company?._id;
    values.customer.customerPostalAddress = selectedCustomer.postalAddress;
    values.customer.customerCompanyNumber = selectedCustomer.companyNumber;
    values.customer.customerCity = selectedCustomer.city;
    values.customer.customerZipCode = selectedCustomer.zipCode;
    values.customer.customerCountry = selectedCustomer.country;
    values.isForeignInvoice = isForeignInvoice;
    values.email = selectedCustomer.email;
    values.documentType = documentType;
    values.documentLanguage = languageType;
    values.documentCurrency = currencyType;

    if (invoiceId) {
      values.invoiceId = invoiceId;
    }

    try {
      let companyId = currentuser.data.company._id;
      await axiosMethod(`${SERVER_URL}/${APIRoute}?filter=${JSON.stringify(companyId)}`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((r) => {
        notification.success({
          message: `${
            buttonType === 'save'
              ? `The invoice has been saved.`
              : buttonType === 'send'
                ? `The invoice has been sent.`
                : ''
          }`,
          placement: 'bottomRight',
        });
        history.push('/admin/accounts-receivable');
      });
    } catch (error) {
      notification.error({
        message: `${
          buttonType === 'save'
            ? `Problem with saving the invoice. Please try again later or contact administrator.`
            : buttonType === 'send'
              ? `Problem with sending the invoice. Please try again later or contact administrator.`
              : ''
        }`,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <Card title={isNew ? 'CREATE NEW INVOICE' : 'EDIT INVOICE'} bordered={false} style={{ width: '100%', marginTop: '20px' }}>
      <Form
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        labelAlign='left'
        onValuesChange={(value, allValues) => onValuesChange(value, allValues)}
        initialValues={invoiceData}
        onFinish={(values) => onFinish(values)}
        layout='horizontal'
        form={form}
        ref={formRef}
      >
        <Row justify='space-between'>
          <Col span={12}>
            <Form.Item
              label='Document type'
              rules={[
                {
                  required: true,
                  message: 'Please choose document type!',
                },
              ]}
            >
              <Select style={{ width: '300px' }} onChange={(value) => setDocumentType(value)} value={documentType}>
                <Option value='invoice'>Invoice</Option>
                <Option value='prepayment invoice'>Advance payment invoice</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label='Document number'
              name='documentNumber'
              rules={[
                {
                  required: true,
                  message: 'Please enter document number!',
                },
              ]}
            >
              <Input style={{ width: '300px' }} />
            </Form.Item>
            <Form.Item
              label='Language'
              rules={[
                {
                  required: true,
                  message: 'Please choose language of a document!',
                },
              ]}
            >
              <Select style={{ width: '300px' }} onChange={(value) => setLanguageType(value)} value={languageType}>
                <Option value='Serbian'>Serbian</Option>
                <Option value='English'>English</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label='Currency'
              rules={[
                {
                  required: true,
                  message: 'Please choose document currency!',
                },
              ]}
            >
              <Select style={{ width: '300px' }} onChange={(value) => setCurrencyType(value)} value={currencyType}>
                <Option value='RSD'>RSD</Option>
                <Option value='EUR'>EUR</Option>
                <Option value='USD'>USD</Option>
                <Option value='GBP'>GBP</Option>
                <Option value='CHF'>CHF</Option>
                <Option value='RUB'>RUB</Option>
              </Select>
            </Form.Item>
            {(documentType === 'credit note' || documentType === 'debit note') && (
              <Form.Item
                label='Original invoice number'
                name='originalInvoiceNumber'
                rules={[
                  {
                    required: true,
                    message: 'Please enter original invoice number!',
                  },
                ]}
              >
                <Input style={{ width: '300px' }} />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <div className='wrap-label'>
              <Form.Item
                valuePropName='checked'
                label='Invoice not send to E-invoice platform'
                // name='isForeignInvoice'
                labelAlign='left'
                style={{ lineHeight: 'normal' }}
                colon={false}
              >
                <Checkbox onChange={(e) => setIsForeignInvoice(e.target.checked)} checked={isForeignInvoice} />
                <QuestionCircleOutlined
                  className='descriptionIcon'
                  onMouseEnter={() => (document.getElementById('notSefInvoice').style.display = 'initial')}
                  onMouseLeave={() => (document.getElementById('notSefInvoice').style.display = 'none')}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <span id='notSefInvoice' style={{ display: 'none' }}>
                This invoice shall not be sent to e invoice platform.
                <br />
                Recipient of invoice if foreign company or local company that is not registered on e-invoice platform.
              </span>
            </div>
            <Form.Item
              label='Document date'
              name='documentDate'
              rules={[
                {
                  required: true,
                  message: 'Please choose document date!',
                },
              ]}
            >
              <DatePicker format={'DD-MM-YYYY'} style={{ width: '300px' }} />
            </Form.Item>
            {documentType !== 'debit note' && (
              <Form.Item
                label='Supply date'
                name='supplyDate'
                rules={[
                  {
                    required: true,
                    message: 'Please choose supply date!',
                  },
                ]}
              >
                <DatePicker format={'DD-MM-YYYY'} style={{ width: '300px' }} />
              </Form.Item>
            )}

            <Form.Item
              label='Due date'
              name='dueDate'
              rules={[
                {
                  required: true,
                  message: 'Please choose due date!',
                },
              ]}
            >
              <DatePicker style={{ width: '300px' }} format={'DD-MM-YYYY'} />
            </Form.Item>

            {(documentType === 'credit note' || documentType === 'debit note') && (
              <Form.Item
                label='Original Invoice date'
                name='originalInvoiceDate'
                rules={[
                  {
                    required: true,
                    message: 'Please choose original invoice date!',
                  },
                ]}
              >
                <DatePicker style={{ width: '300px' }} format='DD-MM-YYYY' />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Divider style={{ backgroundColor: '#6c9cb450' }} />

        <Row style={{ marginBlock: '20px' }} justify='space-between'>
          {currentuser.data.role.includes('user') || currentuser.data.role.includes('eInvoiceOfficer') ? (
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '20px 0' }}
            >
              <h2>
                From: <b>{currentuser.data.company?.name}</b>
              </h2>
              <p>{currentuser.data.company?.address}</p>
              <p>
                {currentuser.data.company?.city} {currentuser.data.company?.zip}
              </p>
              <p>
                <b>TIN number: {currentuser.data.company?.pib}</b>
              </p>
              <p>
                <b>Company number: {currentuser.data.company?.code}</b>
              </p>
            </Col>
          ) : null}

          <Col span={12} style={{ display: 'flex', flexDirection: 'column', margin: '20px 0' }}>
            {isNew ? (
              <>
                {' '}
                <div style={{ display: 'flex', justifyContent: 'end', gap: '60px', marginRight: '30px' }}>
                  <h2>To:</h2>{' '}
                  <Select
                    style={{ width: '80%' }}
                    optionFilterProp='children'
                    onChange={(value) => setSelectedCustomer(JSON.parse(value))}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {customers?.data?.map((customer, index) => (
                      <Option key={index} value={JSON.stringify(customer)}>
                        {customer.customerName}
                      </Option>
                    ))}
                  </Select>
                </div>
                <Button
                  type='secondary'
                  onClick={showModal}
                  style={{ width: 'fit-content', alignSelf: 'end', margin: '20px 30px' }}
                >
                  Add new customer
                </Button>
                {selectedCustomer && (
                  <>
                    <p>{selectedCustomer?.customerName}</p>
                    <p>{selectedCustomer.postalAddress}</p>
                    <p>
                      {selectedCustomer.city} {selectedCustomer.zipCode}
                    </p>
                    <p>
                      <b>TIN number: {selectedCustomer.vat}</b>
                    </p>
                    <p>
                      <b>Company number: {selectedCustomer.companyNumber}</b>
                    </p>
                  </>
                )}{' '}
              </>
            ) : (
              <>
                <h2>
                  To: <b>{data?.data?.customerName}</b>
                </h2>
                <p>{data?.data?.customerPostalAddress}</p>
                <p>
                  {data?.data?.customerCity} {data?.data?.customerZipCode}
                </p>
                <p>
                  <b>TIN number: {data?.data?.customerVat}</b>
                </p>
                <p>
                  <b>Company number: {data?.data?.customerCompanyNumber}</b>
                </p>
              </>
            )}
          </Col>
        </Row>

        <Divider style={{ backgroundColor: '#6c9cb450' }} />

        <Row span={24} style={{ marginBlock: '2s0px' }}>
          <Col span={24}>
            <h2 style={{ marginBottom: '30px' }}>Additional Details</h2>
            <Form.Item label='PO Number' name='poNumber' labelAlign='left' labelCol={{ span: 4 }}>
              <Input style={{ width: '500px' }} />
            </Form.Item>
            <Form.Item label='Customer reference' name='customerReference' labelAlign='left' labelCol={{ span: 4 }}>
              <Input style={{ width: '500px' }} />
            </Form.Item>
            <Form.Item label='Supplier reference' name='supplierReference' labelAlign='left' labelCol={{ span: 4 }}>
              <Input style={{ width: '500px' }} />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ backgroundColor: '#6c9cb450' }} />

        <Form.List
          name='products'
          initialValue={
            data?.data
              ? invoiceData?.products
              : [
                  {
                    description: '',
                    discount: 0,
                    grossPrice: 0,
                    quantity: 1,
                    ratePrice: 0,
                    serviceProduct: '',
                    subtotal: 0,
                    unit: '',
                    vat: 20,
                  },
                ]
          }
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Row key={field.key} span={24}>
                  <Col span={3}>
                    <Form.Item
                      label='Service/product'
                      name={[field.name, 'serviceProduct']}
                      className='invoice-product'
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter service/product!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label='Description'
                      name={[field.name, 'description']}
                      className='invoice-product'
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter description of service/product!',
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      label='Quantity'
                      initialValue={1}
                      name={[field.name, 'quantity']}
                      className='invoice-product'
                      colon={false}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    {!isForeignInvoice ? (
                      <Form.Item label='Unit' name={[field.name, 'unit']} className='invoice-product' colon={false}>
                        <Select>
                          {measures?.map((measure, index) => (
                            <Option key={index} value={measure?.NameEng}>
                              {measure?.NameEng}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : (
                      <Form.Item label='Unit' name={[field.name, 'unit']} className='invoice-product' colon={false}>
                        <Select
                          defaultValue='pc'
                          options={[
                            { value: 'kwh', label: 'kwh' },
                            { value: 'pc', label: 'pc' },
                            { value: 'kg', label: 'kg' },
                            { value: 'km', label: 'km' },
                            { value: 'g', label: 'g' },
                            { value: 'm', label: 'm' },
                            { value: 'l', label: 'l' },
                            { value: 't', label: 't' },
                            { value: 'm2', label: 'm2' },
                            { value: 'm3', label: 'm3' },
                            { value: 'min', label: 'min' },
                            { value: 'h', label: 'h' },
                            { value: 'd', label: 'd' },
                            { value: 'M', label: 'M' },
                            { value: 'y', label: 'y' },
                          ]}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label='Rate/Price'
                      name={[field.name, 'ratePrice']}
                      initialValue={0}
                      className='invoice-product'
                      colon={false}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      label='Discount'
                      name={[field.name, 'discount']}
                      ratePrice
                      initialValue={0}
                      className='invoice-product'
                      colon={false}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      label='VAT'
                      name={[field.name, 'vat']}
                      initialValue={20}
                      className='invoice-product'
                      colon={false}
                    >
                      <Select>
                        <Option value={0}>0%</Option>
                        <Option value={10}>10%</Option>
                        <Option value={15}>15%</Option>
                        <Option value={20}>20%</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label='Gross Price'
                      name={[field.name, 'grossPrice']}
                      initialValue={0}
                      className='invoice-product'
                      colon={false}
                    >
                      <Input suffix={currencyType} type='number' />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label='Subtotal'
                      name={[field.name, 'subtotal']}
                      initialValue={0}
                      className='invoice-product'
                      colon={false}
                    >
                      <Input suffix={currencyType} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Form.Item>
                      <Button
                        type='text'
                        style={{ border: 'none', background: 'transparent', boxShadow: 'none' }}
                        onClick={() => remove(field.name)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row span={24}>
                <Col span={13}>
                  <Form.Item>
                    <Button onClick={() => add()}>Add new line</Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
        <Row span={24}>
          <Col span={10}>
            <Form.Item label='Total Discounts' name='totalDiscounts'>
              <Input disabled suffix={currencyType} />
            </Form.Item>
            <Form.Item label='Total VAT excl' name='totalVatExcl'>
              <Input disabled suffix={currencyType} />
            </Form.Item>
            {selectedVat &&
              Object.keys(selectedVat).map((keyName, i) => (
                <Form.Item key={i} label={`VAT ${keyName}%`} name={`vatSelected__${keyName}`}>
                  <Input disabled suffix={currencyType} />
                </Form.Item>
              ))}
            <Form.Item label='Total' name='total'>
              <Input disabled suffix={currencyType} />
            </Form.Item>
            <Form.Item label='Prepaid amount' name='prepaidAmount' style={{ marginTop: '40px' }}>
              <Input suffix={currencyType} type='number' />
            </Form.Item>
            <Form.Item label='Open Balance' name='openBalance'>
              <Input suffix={currencyType} type='number' />
            </Form.Item>
          </Col>
        </Row>
        <Row span={24} style={{ marginBlock: '20px' }} justify='space-between'>
          <Col span={11}>
            <h2>Bank Details</h2>
            <p>{currentuser.data.company?.name}</p>
            <Form.Item label='Payment reference' name='paymentReference'>
              <Input style={{ width: '300px' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h2>Comments</h2>
            <Form.Item name='comment' className='invoice-comment'>
              <TextArea style={{ minWidth: '100%', minHeight: '100px' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row span={24} style={{ marginBlock: '50px' }} justify='space-between'>
          <Col span={11}>
            <h2>Attachments</h2>
            <Upload
              fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
              listType='picture'
              showUploadList={showUploadList}
              action={`${SERVER_URL}/upload-file`}
              name='file'
              headers={{
                Authorization: `Bearer ${currentuser.data.token}`,
                uri: 'static/pdf/',
                timestamp: Date.now().toString(),
              }}
              onChange={(info) => {
                if (!info.file.status) {
                  setIsError(true);
                }
                if (info.file.status === 'done') {
                  setIsError(false);
                  setFiles([...files, { info }]);
                  for (const file of info.fileList) {
                    let nameArray = file.name.split('.');
                    switch (nameArray[nameArray.length - 1]) {
                      case 'pdf':
                        file.thumbUrl = '/pdf.png';
                        break;
                      case 'docx':
                        file.thumbUrl = '/docx.png';
                        break;
                      case 'png':
                        file.thumbUrl = '/png.png';
                        break;
                      case 'jpg':
                        file.thumbUrl = '/jpg.png';
                        break;
                      case 'xlsx':
                        file.thumbUrl = '/xlsx.png';
                        break;

                      default:
                        file.thumbUrl = '/other.png';
                        break;
                    }
                  }
                  notification.success({
                    message: 'Upload successfull',
                    placement: 'bottomRight',
                  });
                } else if (info.file.status === 'error') {
                  notification.error({
                    message: 'Upload failed',
                    placement: 'bottomRight',
                  });
                }
                setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
              }}
              onRemove={async (file) => {
                notification.info({
                  message: 'File removed!',
                  placement: 'bottomRight',
                });
                const urlParts = file?.name;
                await Axios.post(`${SERVER_URL}/remove-file/${urlParts}`, {
                  withCredentials: false,
                  invoiceId,
                });
              }}
              onDownload={async (file) => {
                notification.info({
                  message: 'Downloading in progress.',
                  placement: 'bottomRight',
                });
                const urlParts = file?.name;
                const response = await Axios.get(`${SERVER_URL}/download-file/${urlParts}`, {
                  withCredentials: false,
                });
                FileSaver.saveAs(response.config.url, file?.name);
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            {data?.data?.uploadedFiles?.length > 0 &&
              data?.data?.uploadedFiles?.map((file, index) => (
                <div
                  key={index}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}
                >
                  <p style={{ marginBottom: '0px' }}>{file?.originalname}</p>
                  <div>
                    <DownloadOutlined
                      style={{ paddingInline: '10px' }}
                      onClick={async () => {
                        notification.info({
                          message: 'Downloading in progress.',
                          placement: 'bottomRight',
                        });
                        const urlParts = file?.originalname;
                        const response = await Axios.get(`${SERVER_URL}/download-file/${urlParts}`, {
                          withCredentials: false,
                        });
                        FileSaver.saveAs(response.config.url, file?.originalname);
                      }}
                    />
                    <DeleteOutlined
                      onClick={async () => {
                        const id = invoiceId;
                        notification.info({
                          message: 'File removed!',
                          placement: 'bottomRight',
                        });
                        const urlParts = file?.originalname;
                        await Axios.post(`${SERVER_URL}/remove-file/${urlParts}`, {
                          withCredentials: false,
                          id,
                        });
                        window.location.reload();
                      }}
                    />
                  </div>
                </div>
              ))}
          </Col>
        </Row>
        <Button
          type='primary'
          htmlType='submit'
          style={{ marginRight: '10px' }}
          ref={saveBtnRef}
          onClick={() => {
            setButtonType('save');
          }}
        >
          Save
        </Button>
        <Button
          type='primary'
          htmlType='submit'
          style={{ marginRight: '10px' }}
          ref={sendBtnRef}
          onClick={() => {
            setButtonType('send');
          }}
        >
          Save and send
        </Button>
        <Button>Cancel</Button>
        <Modal
          title='Add new customer'
          width={1000}
          visible={isModalOpen}
          onOk={customerForm.submit}
          onCancel={handleCancel}
          style={{ top: 20 }}
        >
          <div></div>
          <CustomerInvoiceForm
            customerForm={customerForm}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            SERVER_URL={SERVER_URL}
            allCompanies={allCompanies}
          />
        </Modal>
      </Form>
    </Card>
  );
};
export default InvoiceForm;
