import React, { useContext, useEffect, useState } from 'react';
import {Input, Select, Button, Form, Card, Divider, Tooltip} from 'antd';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';
import {QuestionCircleOutlined} from "@ant-design/icons";

const { Option } = Select;

const formInit = {
  company: undefined,
  sectorName: undefined,
  hrSpecialist: undefined,
};

const SectorForm = ({ isNew, data, onSubmit, SERVER_URL, clients }) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [company, fetchCompany] = useAxios('', [], currentuser.data.token, 'get');
  const [emailReceivers, setEmailReceivers] = useState([]);

  /** Roles definition **/
  const allowedToAdmins = currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin');
  const allowedToOtherRoles = currentuser.data.role.includes('user') || currentuser.data.role.includes('userOfficer');
  /** end of roles definition **/

  useEffect(() => {
    const filterData = { isSector: true };
    fetchCompany(`${SERVER_URL}/companies?filter=${JSON.stringify(filterData)}`, []);
  }, [SERVER_URL, fetchCompany]);

  // reset of the fields before fetching data
  useEffect(() => form.resetFields(), [data, form]);

  let initialValues = {
    ...formInit,
    ...data,
    company: data?.company?.name,
    hrSpecialist: data?.hrSpecialist?._id ? data?.hrSpecialist?._id : null,
  };

  useEffect(() => {
    if (data?.emailReceivers?.length) {
      const array = data.emailReceivers.map(email => email);
      setEmailReceivers(array);
    }
  }, [data]);

  const onFinish = async (values, isNew) => {
    values.emailReceivers = emailReceivers;
    if (!isNew) {
      values.company = data.company._id;
    }

    if (isNew) {
      form.resetFields();
      setEmailReceivers([]);
    }

    if (allowedToOtherRoles) {
      values.company = currentuser.data.company._id;
    }
    onSubmit(values, isNew);
  };

  const isReadyForDisplayForClient = data?.hrSpecialist?.firstName && data?.hrSpecialist?.lastName;

  const handleInputChange = (index, event) => {
    const newEmails = [...emailReceivers];
    newEmails[index] = event.target.value;
    setEmailReceivers(newEmails);
  };

  const handleAddEmail = (event) => {
    event.preventDefault();
    setEmailReceivers([...emailReceivers, '']);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = [...emailReceivers];
    newEmails.splice(index, 1);
    setEmailReceivers(newEmails);
  };

  return (
      <div className='dashboard'>
        <div style={{ width: '65%' }}>
          <Card title={`${isNew ? 'NEW' : 'EDIT'} SECTOR`} bordered={false}>
            <Form
                className='form-horizontal employeeForm'
                initialValues={initialValues}
                onFinish={(values) => onFinish(values, !data)}
                layout='horizontal'
                form={form}
                labelAlign='left'
            >
              {' '}
              {allowedToAdmins && (
                  <Form.Item
                      className='employeeField'
                      label='Company'
                      labelCol={{ span: 6 }}
                      name='company'
                      rules={[
                        {
                          required: true,
                          message: 'Please choose company!',
                        },
                      ]}
                  >
                    {isNew ? (
                        <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {company &&
                              company.data &&
                              company.data.items &&
                              company.data.items.map((item, index) => (
                                  <Option key={index} value={item._id}>
                                    {item.name}
                                  </Option>
                              ))}
                        </Select>
                    ) : (
                        <Input disabled />
                    )}
                  </Form.Item>
              )}
              <Form.Item
                  label='Sector:'
                  name='sectorName'
                  labelCol={{ span: 6 }}
                  rules={[
                    {
                      required: true,
                      message: 'Please add sector',
                    },
                  ]}
              >
                <Input />
              </Form.Item>
              {allowedToAdmins && (
                  <Form.Item label='HR Officer:' name='hrSpecialist' labelCol={{ span: 6 }}>
                    <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                            option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                      <Option key='none' value={null}>
                        None
                      </Option>
                      {clients &&
                          clients.map((item, index) => (
                              <Option key={index} value={item._id}>
                                {item.firstName} {item.lastName}
                              </Option>
                          ))}
                    </Select>
                  </Form.Item>
              )}
              {allowedToOtherRoles && isReadyForDisplayForClient && (
                  <Form.Item label='HR Officer::' labelCol={{ span: 6 }}>
                    <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                            option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        defaultValue={data?.hrSpecialist?._id ? data?.hrSpecialist?._id : 'none'}
                    >
                      <Option key='none' value={null}>
                        None
                      </Option>
                      <Option key={data?.hrSpecialist?._id} value={data?.hrSpecialist?._id}>
                        {data?.hrSpecialist?.firstName} {data?.hrSpecialist?.lastName}
                      </Option>
                    </Select>
                  </Form.Item>
              )}
              <Divider style={{ backgroundColor: '#2db1ab50', margin: '15px 0' }} />
              <div
                  style={{
                    marginTop: '20px',
                    marginBottom: '15px',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
              >
                <span>EMAIL RECEIVERS:</span>
              <Tooltip
                  title='Here you can create a list of additional email recipients who are associated with decisions related to this sector.'
                  color='#b1b1b1'
                  placement='bottom'
                  autoAdjustOverflow={true}
              >
                <QuestionCircleOutlined
                    className='tooltip-body-styling__element'
                    style={{ marginLeft: '8px' }}
                />
              </Tooltip>
              </div>
              <div style={{ fontFamily: 'Arial, sans-serif', marginTop: '10px' }}>
                {emailReceivers &&
                    emailReceivers?.map((email, index) => (
                        <div
                            key={index}
                            style={{
                              marginBottom: '8px',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}
                        >
                          <div style={{ marginTop: '4px' }} key={index + 1}>
                            {index + 1}.
                          </div>
                          <input
                              type='email'
                              placeholder='Enter email'
                              value={email}
                              onChange={(event) => handleInputChange(index, event)}
                              style={{
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                marginRight: '10px',
                                marginLeft: '10px',
                                width: 'calc(100% - 120px)',
                                height: '30px',
                              }}
                          />
                          <button
                              onClick={() => handleRemoveEmail(index)}
                              style={{
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f44336',
                                color: 'white',
                                cursor: 'pointer',
                                marginRight: '10px',
                                height: '30px',
                                paddingTop: '6px',
                              }}
                          >
                            Remove
                          </button>
                        </div>
                    ))}
                <button
                    onClick={handleAddEmail}
                    style={{
                      padding: '8px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      backgroundColor: '#4caf50',
                      color: 'white',
                      cursor: 'pointer',
                      marginBottom: '10px',
                    }}
                >
                  Add Email
                </button>
              </div>
              <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />
              <Form.Item>
                <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
  );
};

export default SectorForm;

