import React from 'react';

export default function TimeSheetSubIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M21.6838 20.4082L17.6022 24.4898V20.4082H21.6838Z' fill='#AFB6BB' />
      <path d='M21.6838 0.510208V20.4082H17.6021V24.4898H3.31641V0.510208H21.6838Z' fill='white' />
      <path
        d='M22.1939 0.510204C22.1939 0.228418 21.9655 0 21.6837 0H3.31636C3.03457 0 2.80615 0.228418 2.80615 0.510204V24.4898C2.80615 24.7716 3.03457 25 3.31636 25H17.6014C17.6016 25 17.6018 25 17.6019 25C17.7347 25 17.8652 24.9481 17.9628 24.8506L22.0445 20.7689C22.142 20.6713 22.1926 20.5409 22.1927 20.4082H22.1939V0.510204ZM17.6021 19.898C17.3203 19.898 17.0919 20.1264 17.0919 20.4082V23.9796H3.82656V1.02041H21.1735V19.898H17.6021ZM18.1123 23.2581V20.9184H20.452L18.1123 23.2581Z'
        fill='#231F20'
      />
      <path d='M9.69394 2.55103H5.6123V3.57143H9.69394V2.55103Z' fill='#231F20' />
      <path d='M12.245 4.08163H5.6123V5.10204H12.245V4.08163Z' fill='#231F20' />
      <path
        d='M18.8776 6.37755H6.12251C5.84072 6.37755 5.6123 6.60597 5.6123 6.88775V21.4286C5.6123 21.7104 5.84072 21.9388 6.12251 21.9388H15.3062V20.9184H10.4592V16.3265H18.3674V18.3676H19.3878V6.88775C19.3878 6.60597 19.1594 6.37755 18.8776 6.37755ZM18.3674 9.18367H10.4592V7.39796H18.3674V9.18367ZM10.4592 10.2041H18.3674V12.2449H10.4592V10.2041ZM9.43884 7.39796V9.18367H6.63271V7.39796H9.43884ZM6.63271 20.9184V10.2041H9.43884V20.9184H6.63271ZM10.4592 15.3061V13.2653H18.3674V15.3061H10.4592Z'
        fill='#231F20'
      />
      <path d='M8.67346 10.9694H7.39795V11.9898H8.67346V10.9694Z' fill='#231F20' />
      <path d='M8.67346 14.0306H7.39795V15.051H8.67346V14.0306Z' fill='#231F20' />
      <path d='M8.67346 17.0918H7.39795V18.1123H8.67346V17.0918Z' fill='#231F20' />
      <path d='M8.67346 19.1327H7.39795V20.1531H8.67346V19.1327Z' fill='#231F20' />
    </svg>
  );
}
