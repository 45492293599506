import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

export default function ExportIcon(props) {
  const { theme } = useThemeContext();
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M21.875 2.08334H15.625C15.3487 2.08334 15.0838 2.19308 14.8884 2.38843C14.6931 2.58378 14.5834 2.84874 14.5834 3.125C14.5834 3.40127 14.6931 3.66622 14.8884 3.86157C15.0838 4.05692 15.3487 4.16667 15.625 4.16667H19.3646L10.7188 12.8021C10.6211 12.8989 10.5436 13.0141 10.4908 13.1411C10.4379 13.268 10.4106 13.4042 10.4106 13.5417C10.4106 13.6792 10.4379 13.8153 10.4908 13.9423C10.5436 14.0692 10.6211 14.1844 10.7188 14.2813C10.8156 14.3789 10.9308 14.4564 11.0578 14.5093C11.1847 14.5621 11.3208 14.5894 11.4584 14.5894C11.5959 14.5894 11.732 14.5621 11.859 14.5093C11.9859 14.4564 12.1011 14.3789 12.1979 14.2813L20.8333 5.63542V9.375C20.8333 9.65127 20.9431 9.91622 21.1384 10.1116C21.3338 10.3069 21.5987 10.4167 21.875 10.4167C22.1513 10.4167 22.4162 10.3069 22.6116 10.1116C22.8069 9.91622 22.9167 9.65127 22.9167 9.375V3.125C22.9167 2.84874 22.8069 2.58378 22.6116 2.38843C22.4162 2.19308 22.1513 2.08334 21.875 2.08334Z'
        fill={theme === 'dark' ? '#2CA9BC' : 'white'}
      />
      <path
        d='M20.8334 22.9167H4.16671C3.61417 22.9167 3.08427 22.6972 2.69357 22.3065C2.30287 21.9158 2.08337 21.3859 2.08337 20.8333V4.16667C2.08337 3.61413 2.30287 3.08423 2.69357 2.69353C3.08427 2.30283 3.61417 2.08334 4.16671 2.08334H11.4584C11.7346 2.08334 11.9996 2.19308 12.1949 2.38843C12.3903 2.58378 12.5 2.84874 12.5 3.125C12.5 3.40127 12.3903 3.66622 12.1949 3.86157C11.9996 4.05692 11.7346 4.16667 11.4584 4.16667H4.16671V20.8333H20.8334V13.5417C20.8334 13.2654 20.9431 13.0004 21.1385 12.8051C21.3338 12.6097 21.5988 12.5 21.875 12.5C22.1513 12.5 22.4163 12.6097 22.6116 12.8051C22.807 13.0004 22.9167 13.2654 22.9167 13.5417V20.8333C22.9167 21.3859 22.6972 21.9158 22.3065 22.3065C21.9158 22.6972 21.3859 22.9167 20.8334 22.9167Z'
        // fill={theme === 'dark' ? 'white' : '#000'}
        fill={theme === 'dark' ? 'white' : 'white'}
      />
    </svg>
  );
}
