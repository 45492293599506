import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import CompanyForm from '../../components/forms/CompanyForm';

const EditCompany = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [createdPaymentSlips, setCreatedPaymentSlips] = useState([]);
  const [company, fetchCompany] = useAxios('', null, currentuser.data.token, 'get');
  const [companies, fetchCompanies] = useAxios('', null, currentuser.data.token, 'get');
  const [clients, fetchClients] = useAxios('', [], currentuser.data.token, 'get');
  const [einvoiceOfficers, fetchEInvoiceOfficers] = useAxios('', [], currentuser.data.token, 'get');
  const [userOfficers, fetchUserOfficers] = useAxios('', [], currentuser.data.token, 'get');
  const [admins, fetchAdmins] = useAxios('', [], currentuser.data.token, 'get');
  const [paymentSlipsEmailReceivers, setPaymentSlipsEmailReceivers] = useState(['']);
  const history = useHistory();
  const { companyId } = props.match.params;

  useEffect(() => {
    if (companyId) {
      fetchCompany(`${SERVER_URL}/companies/${companyId}`, []);
    }
    const filter = { role: { $in: ['user'], $nin: ['admin'] } };
    const eInvoiceOfficerFilter = { role: { $in: ['eInvoiceOfficer'] } };
    const userOfficerFilter = { role: { $in: ['userOfficer'] } };
    const filterAdmins = { role: { $in: ['admin'], $nin: ['SuperAdmin'] } };
    fetchClients(`${SERVER_URL}/users?filter=${JSON.stringify(filter)}`, []);
    fetchEInvoiceOfficers(`${SERVER_URL}/users?filter=${JSON.stringify(eInvoiceOfficerFilter)}`, []);
    fetchCompanies(`${SERVER_URL}/companies`, []);
    fetchUserOfficers(`${SERVER_URL}/users?filter=${JSON.stringify(userOfficerFilter)}`, []);
    fetchAdmins(`${SERVER_URL}/users?filter=${JSON.stringify(filterAdmins)}`, []);
  }, [companyId, fetchCompany, fetchClients, fetchCompanies, fetchUserOfficers, fetchAdmins]);

  useEffect(() => {
    if (company && company.data) {
      setCreatedPaymentSlips(company.data.paymentSlips);
      if (company.data.paymentSlipsEmailReceivers.length) {
        setPaymentSlipsEmailReceivers(company.data.paymentSlipsEmailReceivers);
      } else {
        const array = [];
        if (company.data.client) {
          array.push(company.data.client.email);
        }
        if (company.data.userOfficer) {
          array.push(company.data.userOfficer.email);
        }
        if (company.data.emailReceiver && company.data.emailReceiver.length) {
          company.data.emailReceiver.map((emailReceiver) => {
            array.push(emailReceiver);
          });
        }
        if (company.data.companyAdmin && company.data.companyAdmin.length) {
          company.data.companyAdmin.map((item) => {
            if (item.adminEmailsSettings.paymentOrdersEmails) {
              array.push(item.email);
            }
          });
        }
        setPaymentSlipsEmailReceivers(array);
      }
    }
  }, [company]);

  const updateCompany = async (data, type) => {
    if (createdPaymentSlips) {
      data.paymentSlips = createdPaymentSlips;
      data.paymentSlipsEmailReceivers = paymentSlipsEmailReceivers;
    }

    await Axios.put(
      `${SERVER_URL}/companies/${companyId}?type=${type}`,
      { ...data },
      { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
    )
      .then((res) => {
        notification.success({
          message: 'Company is edited.',
          placement: 'bottomRight',
        });
        history.push('/admin');
        history.push('/admin/companies');
      })
      .catch((error) => {
        if (error.response.status === 500) {
          notification.error({
            message: (
              <div>
                Message from NBS QR code generator: <br /> {error.response.data.error}
              </div>
            ),
            placement: 'bottomRight',
            duration: 0,
          });
        } else {
          console.log({...error});
          if (error.response.status === 403 && error.response.data.message.startsWith("You are not allowed")) {
            notification.error({
              message: error.response.data.message,
              placement: 'bottomRight'
            })
          } else {
            notification.error({
              message: 'Error in editing company. Please try again.',
              placement: 'bottomRight',
            });
          }
        }
      });
  };

  const createCompany = async (data, type) => {
    if (createdPaymentSlips && createdPaymentSlips.length) {
      data.paymentSlips = createdPaymentSlips;
      data.paymentSlipsEmailReceivers = paymentSlipsEmailReceivers;
    }

    try {
      await Axios.post(
        `${SERVER_URL}/companies`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Company is created.',
        placement: 'bottomRight',
      });
      setResult(true);
      history.push('/admin/companies');
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem with creating company. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  // this handles creating/updating of payment slips
  const createPaymentSlip = async (slipData, isEdit, isDelete) => {
    if (!isEdit) {
      setCreatedPaymentSlips([...createdPaymentSlips, slipData]);
    }

    if (isEdit && slipData && !isDelete) {
      const updatedPaymentSlips = createdPaymentSlips.map((paymentSlip) => {
        if (paymentSlip.typeOfPaymentSlip === slipData.typeOfPaymentSlip) {
          return slipData; // Replace with the new slipData object
        }
        return paymentSlip; // Keep the existing payment slip object
      });
      setCreatedPaymentSlips(updatedPaymentSlips);
    }

    if (isEdit && slipData && isDelete) {
      const updatedPaymentSlips = createdPaymentSlips.filter(
        (paymentSlip) => paymentSlip.typeOfPaymentSlip !== slipData.typeOfPaymentSlip,
      );

      setCreatedPaymentSlips(updatedPaymentSlips);
    }
  };

  let clientIDs = [];
  let filteredClients;
  if (clients.data && clients.data.items && companies.data && companies.data.items) {
    for (const client of clients.data.items) {
      for (const companyItem of companies.data.items) {
        if (companyItem.client?._id === client._id) {
          clientIDs.push(client._id);
        }
      }
    }
    filteredClients = clients.data.items.filter((item) => !clientIDs.includes(item._id));
  }

  // this is for testing - do not delete
  const triggerThisShit = async () => {
    notification.success({
      message: 'Shit is triggered :) proveri email',
      placement: 'bottomRight',
    });
    try {
      await Axios.post(
        `${SERVER_URL}/companies/triggerThisShit`,
        {},
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      ).then(() => {
        notification.success({
          message: 'Shit is triggered.',
          placement: 'bottomRight',
        });
      });
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem with shit. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div style={{ textAlign: 'center' }}>
        {companyId && company.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {companyId && !company.isLoading && !company.isError && company.data && (
          <CompanyForm
            data={company.data}
            updateHandler={updateCompany}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            clients={clients.data.items}
            user={currentuser.data}
            companies={companies.data?.items}
            einvoiceOfficers={einvoiceOfficers.data?.items}
            userOfficers={userOfficers?.data?.items}
            admins={admins?.data?.items}
            createPaymentSlip={createPaymentSlip}
            createdPaymentSlips={createdPaymentSlips}
            paymentSlipsEmailReceivers={paymentSlipsEmailReceivers}
            setPaymentSlipsEmailReceivers={setPaymentSlipsEmailReceivers}
          />
        )}
        {companyId && !company.isLoading && company.isError && (
          <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
        )}
        {!companyId && company && !company.data && (
          <CompanyForm
            data={null}
            createHandler={createCompany}
            result={result}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            clients={filteredClients}
            user={currentuser.data}
            companies={companies?.data?.items}
            einvoiceOfficers={einvoiceOfficers?.data?.items}
            userOfficers={userOfficers?.data?.items}
            admins={admins?.data?.items}
            createPaymentSlip={createPaymentSlip}
            createdPaymentSlips={createdPaymentSlips}
            paymentSlipsEmailReceivers={paymentSlipsEmailReceivers}
            setPaymentSlipsEmailReceivers={setPaymentSlipsEmailReceivers}
          />
        )}
        {/* TODO uncomment this for testings */}
        {/*<button*/}
        {/*  style={{ width: '200px', height: '50px', backgroundColor: 'blue', color: 'white' }}*/}
        {/*  onClick={triggerThisShit}*/}
        {/*>*/}
        {/*  CLICK ME FOR SIMULATION*/}
        {/*</button>*/}
      </div>
    </div>
  );
};

export default EditCompany;
