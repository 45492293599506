import React, { useEffect, useState } from 'react';
import { Form, Input, Card, Button, notification, Radio, Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import DisplayDirector from '../../components/DisplayDirector';
import DisplayShareholder from '../../components/DisplayShareholder';
import * as FileSaver from 'file-saver';

const { Option } = Select;

export default function Questionnaire() {
  const [directorNum, setDirectorNum] = useState(1);
  const [shareholderNum, setShareholderNum] = useState(1);
  const [shareholder1Type, setShareholder1Type] = useState('natural person');
  const [shareholder2Type, setShareholder2Type] = useState('natural person');
  const [shareholder3Type, setShareholder3Type] = useState('natural person');
  const [shareholder4Type, setShareholder4Type] = useState('natural person');
  const [shareholder5Type, setShareholder5Type] = useState('natural person');
  const [citizenship1, setCitizenship1] = useState('serbian');
  const [citizenship2, setCitizenship2] = useState('serbian');
  const [citizenship3, setCitizenship3] = useState('serbian');
  const [citizenship4, setCitizenship4] = useState('serbian');
  const [citizenship5, setCitizenship5] = useState('serbian');
  const [citizenshipShareholder1, setCitizenshipShareholder1] = useState('serbian');
  const [citizenshipShareholder2, setCitizenshipShareholder2] = useState('serbian');
  const [citizenshipShareholder3, setCitizenshipShareholder3] = useState('serbian');
  const [citizenshipShareholder4, setCitizenshipShareholder4] = useState('serbian');
  const [citizenshipShareholder5, setCitizenshipShareholder5] = useState('serbian');
  const [countries, setCountries] = useState([]);
  const [director1Data, setDirector1Data] = useState({
    firstName: '',
    lastName: '',
    passportNum: '',
    idNum: '',
    jmbg: '',
    address: {
      street: '',
      streetNum: '',
      aptNum: '',
      city: '',
      country: '',
    },
  });

  const [director2Data, setDirector2Data] = useState({
    firstName: '',
    lastName: '',
    passportNum: '',
    idNum: '',
    jmbg: '',
    address: {
      street: '',
      streetNum: '',
      aptNum: '',
      city: '',
      country: '',
    },
  });

  const [director3Data, setDirector3Data] = useState({
    firstName: '',
    lastName: '',
    passportNum: '',
    idNum: '',
    jmbg: '',
    address: {
      street: '',
      streetNum: '',
      aptNum: '',
      city: '',
      country: '',
    },
  });

  const [director4Data, setDirector4Data] = useState({
    firstName: '',
    lastName: '',
    passportNum: '',
    idNum: '',
    jmbg: '',
    address: {
      street: '',
      streetNum: '',
      aptNum: '',
      city: '',
      country: '',
    },
  });

  const [director5Data, setDirector5Data] = useState({
    firstName: '',
    lastName: '',
    passportNum: '',
    idNum: '',
    jmbg: '',
    address: {
      street: '',
      streetNum: '',
      aptNum: '',
      city: '',
      country: '',
    },
  });

  const [shareholder1Data, setShareholder1Data] = useState({
    firstName: '',
    lastName: '',
    passportNum: '',
    idNum: '',
    jmbg: '',
    address: {
      street: '',
      streetNum: '',
      aptNum: '',
      city: '',
      country: '',
    },
    ownershipPercentage: '',
  });

  const [shareholder2Data, setShareholder2Data] = useState({
    firstName: '',
    lastName: '',
    passportNum: '',
    idNum: '',
    jmbg: '',
    address: {
      street: '',
      streetNum: '',
      aptNum: '',
      city: '',
      country: '',
    },
    ownershipPercentage: '',
  });

  const [shareholder3Data, setShareholder3Data] = useState({
    firstName: '',
    lastName: '',
    passportNum: '',
    idNum: '',
    jmbg: '',
    address: {
      street: '',
      streetNum: '',
      aptNum: '',
      city: '',
      country: '',
    },
    ownershipPercentage: '',
  });

  const [shareholder4Data, setShareholder4Data] = useState({
    firstName: '',
    lastName: '',
    passportNum: '',
    idNum: '',
    jmbg: '',
    address: {
      street: '',
      streetNum: '',
      aptNum: '',
      city: '',
      country: '',
    },
    ownershipPercentage: '',
  });

  const [shareholder5Data, setShareholder5Data] = useState({
    firstName: '',
    lastName: '',
    passportNum: '',
    idNum: '',
    jmbg: '',
    address: {
      street: '',
      streetNum: '',
      aptNum: '',
      city: '',
      country: '',
    },
    ownershipPercentage: '',
  });

  const [shareholderCompany1Data, setShareholderCompany1Data] = useState({
    companyInfo: {
      firmName: '',
      firmAddress: { street: '', streetNum: '', aptNum: '', city: '', country: '' },
      registrationNum: '',
      ownershipPercentage: '',
    },
    directorInfo: {
      directorNum: 1,
      director1: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director2: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director3: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director4: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director5: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
    },
    shareholderInfo: {
      shareholderNum: 1,
      shareholder1: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder2: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder3: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder4: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder5: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
    },
  });

  const [shareholderCompany4Data, setShareholderCompany4Data] = useState({
    companyInfo: {
      firmName: '',
      firmAddress: { street: '', streetNum: '', aptNum: '', city: '', country: '' },
      registrationNum: '',
      ownershipPercentage: '',
    },
    directorInfo: {
      directorNum: 1,
      director1: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director2: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director3: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director4: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director5: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
    },
    shareholderInfo: {
      shareholderNum: 1,
      shareholder1: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder2: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder3: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder4: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder5: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
    },
  });

  const [shareholderCompany2Data, setShareholderCompany2Data] = useState({
    companyInfo: {
      firmName: '',
      firmAddress: { street: '', streetNum: '', aptNum: '', city: '', country: '' },
      registrationNum: '',
      ownershipPercentage: '',
    },
    directorInfo: {
      directorNum: 1,
      director1: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director2: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director3: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director4: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director5: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
    },
    shareholderInfo: {
      shareholderNum: 1,
      shareholder1: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder2: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder3: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder4: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder5: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
    },
  });

  const [shareholderCompany3Data, setShareholderCompany3Data] = useState({
    companyInfo: {
      firmName: '',
      firmAddress: { street: '', streetNum: '', aptNum: '', city: '', country: '' },
      registrationNum: '',
      ownershipPercentage: '',
    },
    directorInfo: {
      directorNum: 1,
      director1: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director2: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director3: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director4: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director5: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
    },
    shareholderInfo: {
      shareholderNum: 1,
      shareholder1: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder2: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder3: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder4: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder5: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
    },
  });

  const [shareholderCompany5Data, setShareholderCompany5Data] = useState({
    companyInfo: {
      firmName: '',
      firmAddress: { street: '', streetNum: '', aptNum: '', city: '', country: '' },
      registrationNum: '',
      ownershipPercentage: '',
    },
    directorInfo: {
      directorNum: 1,
      director1: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director2: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director3: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director4: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      director5: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
    },
    shareholderInfo: {
      shareholderNum: 1,
      shareholder1: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder2: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder3: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder4: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
      shareholder5: {
        firstName: '',
        lastName: '',
        passportNum: '',
        idNum: '',
        jmbg: '',
        citizenship: 'serbian',
        ownershipPercentage: '',
        address: {
          street: '',
          streetNum: '',
          aptNum: '',
          city: '',
          country: '',
        },
      },
    },
  });

  const [firmData, setFirmData] = useState({
    firmName: '',
    alternativeFirmName: '',
    firmAddress: {
      street: '',
      streetNum: '',
      floor: '',
      aptNum: '',
      city: '',
      municipality: '',
      zipCode: '',
      country: '',
    },
    codeActivity: {
      codeNum: '',
      codeDesc: '',
    },
    shareholdingValue: '',
    email: '',
  });

  const handleChange = (state, e) => {
    const { name, value } = e.target;
    if (state === firmData) {
      setFirmData({
        ...firmData,
        [name]: value,
      });
    } else if (state === director1Data) {
      setDirector1Data({
        ...director1Data,
        [name]: value,
      });
    } else if (state === director2Data) {
      setDirector2Data({
        ...director2Data,
        [name]: value,
      });
    } else if (state === director3Data) {
      setDirector3Data({
        ...director3Data,
        [name]: value,
      });
    } else if (state === director4Data) {
      setDirector4Data({
        ...director4Data,
        [name]: value,
      });
    } else if (state === director5Data) {
      setDirector5Data({
        ...director5Data,
        [name]: value,
      });
    } else if (state === director1Data.address) {
      setDirector1Data({
        ...director1Data,
        address: {
          ...director1Data.address,
          [name]: value,
        },
      });
    } else if (state === director2Data.address) {
      setDirector2Data({
        ...director2Data,
        address: {
          ...director2Data.address,
          [name]: value,
        },
      });
    } else if (state === director3Data.address) {
      setDirector3Data({
        ...director3Data,
        address: {
          ...director3Data.address,
          [name]: value,
        },
      });
    } else if (state === director4Data.address) {
      setDirector4Data({
        ...director4Data,
        address: {
          ...director4Data.address,
          [name]: value,
        },
      });
    } else if (state === director5Data.address) {
      setDirector5Data({
        ...director5Data,
        address: {
          ...director5Data.address,
          [name]: value,
        },
      });
    } else if (state === firmData.firmAddress) {
      setFirmData({
        ...firmData,
        firmAddress: {
          ...firmData.firmAddress,
          [name]: value,
        },
      });
    } else if (state === firmData.codeActivity) {
      setFirmData({
        ...firmData,
        codeActivity: {
          ...firmData.codeActivity,
          [name]: value,
        },
      });
    } else if (state === shareholder1Data) {
      setShareholder1Data({
        ...shareholder1Data,
        [name]: value,
      });
    } else if (state === shareholder2Data) {
      setShareholder2Data({
        ...shareholder2Data,
        [name]: value,
      });
    } else if (state === shareholder3Data) {
      setShareholder3Data({
        ...shareholder3Data,
        [name]: value,
      });
    } else if (state === shareholder4Data) {
      setShareholder4Data({
        ...shareholder4Data,
        [name]: value,
      });
    } else if (state === shareholder5Data) {
      setShareholder5Data({
        ...shareholder5Data,
        [name]: value,
      });
    } else if (state === shareholder1Data.address) {
      setShareholder1Data({
        ...shareholder1Data,
        address: {
          ...shareholder1Data.address,
          [name]: value,
        },
      });
    } else if (state === shareholder2Data.address) {
      setShareholder2Data({
        ...shareholder2Data,
        address: {
          ...shareholder2Data.address,
          [name]: value,
        },
      });
    } else if (state === shareholder3Data.address) {
      setShareholder3Data({
        ...shareholder3Data,
        address: {
          ...shareholder3Data.address,
          [name]: value,
        },
      });
    } else if (state === shareholder4Data.address) {
      setShareholder4Data({
        ...shareholder4Data,
        address: {
          ...shareholder4Data.address,
          [name]: value,
        },
      });
    } else if (state === shareholder5Data.address) {
      setShareholder5Data({
        ...shareholder5Data,
        address: {
          ...shareholder5Data.address,
          [name]: value,
        },
      });
    } else if (state === shareholderCompany1Data.companyInfo) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        companyInfo: {
          ...shareholderCompany1Data.companyInfo,
          [name]: value,
        },
      });
    } else if (state === shareholderCompany1Data.companyInfo.firmAddress) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        companyInfo: {
          ...shareholderCompany1Data.companyInfo,
          firmAddress: {
            ...shareholderCompany1Data.companyInfo.firmAddress,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.directorInfo.director1) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director1: {
            ...shareholderCompany1Data.directorInfo.director1,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.directorInfo.director1.address) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director1: {
            ...shareholderCompany1Data.directorInfo.director1,
            address: {
              ...shareholderCompany1Data.directorInfo.director1.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany1Data.directorInfo.director2) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director2: {
            ...shareholderCompany1Data.directorInfo.director2,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.directorInfo.director2.address) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director2: {
            ...shareholderCompany1Data.directorInfo.director2,
            address: {
              ...shareholderCompany1Data.directorInfo.director2.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany1Data.directorInfo.director3) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director3: {
            ...shareholderCompany1Data.directorInfo.director3,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.directorInfo.director3.address) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director3: {
            ...shareholderCompany1Data.directorInfo.director3,
            address: {
              ...shareholderCompany1Data.directorInfo.director3.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany1Data.directorInfo.director4) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director4: {
            ...shareholderCompany1Data.directorInfo.director4,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.directorInfo.director4.address) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director4: {
            ...shareholderCompany1Data.directorInfo.director4,
            address: {
              ...shareholderCompany1Data.directorInfo.director4.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany1Data.directorInfo.director5) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director5: {
            ...shareholderCompany1Data.directorInfo.director5,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.directorInfo.director5.address) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director5: {
            ...shareholderCompany1Data.directorInfo.director5,
            address: {
              ...shareholderCompany1Data.directorInfo.director5.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany1Data.shareholderInfo.shareholder1) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany1Data.shareholderInfo.shareholder1,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.shareholderInfo.shareholder1.address) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany1Data.shareholderInfo.shareholder1,
            address: {
              ...shareholderCompany1Data.shareholderInfo.shareholder1.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany1Data.shareholderInfo.shareholder2) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany1Data.shareholderInfo.shareholder2,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.shareholderInfo.shareholder2.address) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany1Data.shareholderInfo.shareholder2,
            address: {
              ...shareholderCompany1Data.shareholderInfo.shareholder2.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany1Data.shareholderInfo.shareholder3) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany1Data.shareholderInfo.shareholder3,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.shareholderInfo.shareholder3.address) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany1Data.shareholderInfo.shareholder3,
            address: {
              ...shareholderCompany1Data.shareholderInfo.shareholder3.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany1Data.shareholderInfo.shareholder4) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany1Data.shareholderInfo.shareholder4,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.shareholderInfo.shareholder4.address) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany1Data.shareholderInfo.shareholder4,
            address: {
              ...shareholderCompany1Data.shareholderInfo.shareholder4.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany1Data.shareholderInfo.shareholder5) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany1Data.shareholderInfo.shareholder5,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany1Data.shareholderInfo.shareholder5.address) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany1Data.shareholderInfo.shareholder5,
            address: {
              ...shareholderCompany1Data.shareholderInfo.shareholder5.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany2Data.companyInfo) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        companyInfo: {
          ...shareholderCompany2Data.companyInfo,
          [name]: value,
        },
      });
    } else if (state === shareholderCompany2Data.companyInfo.firmAddress) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        companyInfo: {
          ...shareholderCompany2Data.companyInfo,
          firmAddress: {
            ...shareholderCompany2Data.companyInfo.firmAddress,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.directorInfo.director1) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director1: {
            ...shareholderCompany2Data.directorInfo.director1,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.directorInfo.director1.address) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director1: {
            ...shareholderCompany2Data.directorInfo.director1,
            address: {
              ...shareholderCompany2Data.directorInfo.director1.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany2Data.directorInfo.director2) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director2: {
            ...shareholderCompany2Data.directorInfo.director2,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.directorInfo.director2.address) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director2: {
            ...shareholderCompany2Data.directorInfo.director2,
            address: {
              ...shareholderCompany2Data.directorInfo.director2.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany2Data.directorInfo.director3) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director3: {
            ...shareholderCompany2Data.directorInfo.director3,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.directorInfo.director3.address) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director3: {
            ...shareholderCompany2Data.directorInfo.director3,
            address: {
              ...shareholderCompany2Data.directorInfo.director3.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany2Data.directorInfo.director4) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director4: {
            ...shareholderCompany2Data.directorInfo.director4,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.directorInfo.director4.address) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director4: {
            ...shareholderCompany2Data.directorInfo.director4,
            address: {
              ...shareholderCompany2Data.directorInfo.director4.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany2Data.directorInfo.director5) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director5: {
            ...shareholderCompany2Data.directorInfo.director5,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.directorInfo.director5.address) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director5: {
            ...shareholderCompany2Data.directorInfo.director5,
            address: {
              ...shareholderCompany2Data.directorInfo.director5.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany2Data.shareholderInfo.shareholder1) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany2Data.shareholderInfo.shareholder1,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.shareholderInfo.shareholder1.address) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany2Data.shareholderInfo.shareholder1,
            address: {
              ...shareholderCompany2Data.shareholderInfo.shareholder1.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany2Data.shareholderInfo.shareholder2) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany2Data.shareholderInfo.shareholder2,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.shareholderInfo.shareholder2.address) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany2Data.shareholderInfo.shareholder2,
            address: {
              ...shareholderCompany2Data.shareholderInfo.shareholder2.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany2Data.shareholderInfo.shareholder3) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany2Data.shareholderInfo.shareholder3,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.shareholderInfo.shareholder3.address) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany2Data.shareholderInfo.shareholder3,
            address: {
              ...shareholderCompany2Data.shareholderInfo.shareholder3.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany2Data.shareholderInfo.shareholder4) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany2Data.shareholderInfo.shareholder4,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.shareholderInfo.shareholder4.address) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany2Data.shareholderInfo.shareholder4,
            address: {
              ...shareholderCompany2Data.shareholderInfo.shareholder4.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany2Data.shareholderInfo.shareholder5) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany2Data.shareholderInfo.shareholder5,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany2Data.shareholderInfo.shareholder5.address) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany2Data.shareholderInfo.shareholder5,
            address: {
              ...shareholderCompany2Data.shareholderInfo.shareholder5.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany3Data.companyInfo) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        companyInfo: {
          ...shareholderCompany3Data.companyInfo,
          [name]: value,
        },
      });
    } else if (state === shareholderCompany3Data.companyInfo.firmAddress) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        companyInfo: {
          ...shareholderCompany3Data.companyInfo,
          firmAddress: {
            ...shareholderCompany3Data.companyInfo.firmAddress,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.directorInfo.director1) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director1: {
            ...shareholderCompany3Data.directorInfo.director1,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.directorInfo.director1.address) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director1: {
            ...shareholderCompany3Data.directorInfo.director1,
            address: {
              ...shareholderCompany3Data.directorInfo.director1.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany3Data.directorInfo.director2) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director2: {
            ...shareholderCompany3Data.directorInfo.director2,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.directorInfo.director2.address) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director2: {
            ...shareholderCompany3Data.directorInfo.director2,
            address: {
              ...shareholderCompany3Data.directorInfo.director2.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany3Data.directorInfo.director3) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director3: {
            ...shareholderCompany3Data.directorInfo.director3,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.directorInfo.director3.address) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director3: {
            ...shareholderCompany3Data.directorInfo.director3,
            address: {
              ...shareholderCompany3Data.directorInfo.director3.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany3Data.directorInfo.director4) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director4: {
            ...shareholderCompany3Data.directorInfo.director4,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.directorInfo.director4.address) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director4: {
            ...shareholderCompany3Data.directorInfo.director4,
            address: {
              ...shareholderCompany3Data.directorInfo.director4.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany3Data.directorInfo.director5) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director5: {
            ...shareholderCompany3Data.directorInfo.director5,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.directorInfo.director5.address) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director5: {
            ...shareholderCompany3Data.directorInfo.director5,
            address: {
              ...shareholderCompany3Data.directorInfo.director5.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany3Data.shareholderInfo.shareholder1) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany3Data.shareholderInfo.shareholder1,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.shareholderInfo.shareholder1.address) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany3Data.shareholderInfo.shareholder1,
            address: {
              ...shareholderCompany3Data.shareholderInfo.shareholder1.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany3Data.shareholderInfo.shareholder2) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany3Data.shareholderInfo.shareholder2,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.shareholderInfo.shareholder2.address) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany3Data.shareholderInfo.shareholder2,
            address: {
              ...shareholderCompany3Data.shareholderInfo.shareholder2.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany3Data.shareholderInfo.shareholder3) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany3Data.shareholderInfo.shareholder3,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.shareholderInfo.shareholder3.address) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany3Data.shareholderInfo.shareholder3,
            address: {
              ...shareholderCompany3Data.shareholderInfo.shareholder3.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany3Data.shareholderInfo.shareholder4) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany3Data.shareholderInfo.shareholder4,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.shareholderInfo.shareholder4.address) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany3Data.shareholderInfo.shareholder4,
            address: {
              ...shareholderCompany3Data.shareholderInfo.shareholder4.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany3Data.shareholderInfo.shareholder5) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany3Data.shareholderInfo.shareholder5,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany3Data.shareholderInfo.shareholder5.address) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany3Data.shareholderInfo.shareholder5,
            address: {
              ...shareholderCompany3Data.shareholderInfo.shareholder5.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany4Data.companyInfo) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        companyInfo: {
          ...shareholderCompany4Data.companyInfo,
          [name]: value,
        },
      });
    } else if (state === shareholderCompany4Data.companyInfo.firmAddress) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        companyInfo: {
          ...shareholderCompany4Data.companyInfo,
          firmAddress: {
            ...shareholderCompany4Data.companyInfo.firmAddress,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.directorInfo.director1) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director1: {
            ...shareholderCompany4Data.directorInfo.director1,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.directorInfo.director1.address) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director1: {
            ...shareholderCompany4Data.directorInfo.director1,
            address: {
              ...shareholderCompany4Data.directorInfo.director1.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany4Data.directorInfo.director2) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director2: {
            ...shareholderCompany4Data.directorInfo.director2,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.directorInfo.director2.address) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director2: {
            ...shareholderCompany4Data.directorInfo.director2,
            address: {
              ...shareholderCompany4Data.directorInfo.director2.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany4Data.directorInfo.director3) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director3: {
            ...shareholderCompany4Data.directorInfo.director3,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.directorInfo.director3.address) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director3: {
            ...shareholderCompany4Data.directorInfo.director3,
            address: {
              ...shareholderCompany4Data.directorInfo.director3.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany4Data.directorInfo.director4) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director4: {
            ...shareholderCompany4Data.directorInfo.director4,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.directorInfo.director4.address) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director4: {
            ...shareholderCompany4Data.directorInfo.director4,
            address: {
              ...shareholderCompany4Data.directorInfo.director4.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany4Data.directorInfo.director5) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director5: {
            ...shareholderCompany4Data.directorInfo.director5,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.directorInfo.director5.address) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director5: {
            ...shareholderCompany4Data.directorInfo.director5,
            address: {
              ...shareholderCompany4Data.directorInfo.director5.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany4Data.shareholderInfo.shareholder1) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany4Data.shareholderInfo.shareholder1,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.shareholderInfo.shareholder1.address) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany4Data.shareholderInfo.shareholder1,
            address: {
              ...shareholderCompany4Data.shareholderInfo.shareholder1.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany4Data.shareholderInfo.shareholder2) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany4Data.shareholderInfo.shareholder2,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.shareholderInfo.shareholder2.address) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany4Data.shareholderInfo.shareholder2,
            address: {
              ...shareholderCompany4Data.shareholderInfo.shareholder2.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany4Data.shareholderInfo.shareholder3) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany4Data.shareholderInfo.shareholder3,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.shareholderInfo.shareholder3.address) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany4Data.shareholderInfo.shareholder3,
            address: {
              ...shareholderCompany4Data.shareholderInfo.shareholder3.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany4Data.shareholderInfo.shareholder4) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany4Data.shareholderInfo.shareholder4,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.shareholderInfo.shareholder4.address) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany4Data.shareholderInfo.shareholder4,
            address: {
              ...shareholderCompany4Data.shareholderInfo.shareholder4.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany4Data.shareholderInfo.shareholder5) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany4Data.shareholderInfo.shareholder5,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany4Data.shareholderInfo.shareholder5.address) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany4Data.shareholderInfo.shareholder5,
            address: {
              ...shareholderCompany4Data.shareholderInfo.shareholder5.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany5Data.companyInfo) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        companyInfo: {
          ...shareholderCompany5Data.companyInfo,
          [name]: value,
        },
      });
    } else if (state === shareholderCompany5Data.companyInfo.firmAddress) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        companyInfo: {
          ...shareholderCompany5Data.companyInfo,
          firmAddress: {
            ...shareholderCompany5Data.companyInfo.firmAddress,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.directorInfo.director1) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director1: {
            ...shareholderCompany5Data.directorInfo.director1,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.directorInfo.director1.address) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director1: {
            ...shareholderCompany5Data.directorInfo.director1,
            address: {
              ...shareholderCompany5Data.directorInfo.director1.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany5Data.directorInfo.director2) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director2: {
            ...shareholderCompany5Data.directorInfo.director2,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.directorInfo.director2.address) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director2: {
            ...shareholderCompany5Data.directorInfo.director2,
            address: {
              ...shareholderCompany5Data.directorInfo.director2.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany5Data.directorInfo.director3) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director3: {
            ...shareholderCompany5Data.directorInfo.director3,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.directorInfo.director3.address) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director3: {
            ...shareholderCompany5Data.directorInfo.director3,
            address: {
              ...shareholderCompany5Data.directorInfo.director3.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany5Data.directorInfo.director4) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director4: {
            ...shareholderCompany5Data.directorInfo.director4,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.directorInfo.director4.address) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director4: {
            ...shareholderCompany5Data.directorInfo.director4,
            address: {
              ...shareholderCompany5Data.directorInfo.director4.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany5Data.directorInfo.director5) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director5: {
            ...shareholderCompany5Data.directorInfo.director5,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.directorInfo.director5.address) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director5: {
            ...shareholderCompany5Data.directorInfo.director5,
            address: {
              ...shareholderCompany5Data.directorInfo.director5.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany5Data.shareholderInfo.shareholder1) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany5Data.shareholderInfo.shareholder1,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.shareholderInfo.shareholder1.address) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany5Data.shareholderInfo.shareholder1,
            address: {
              ...shareholderCompany5Data.shareholderInfo.shareholder1.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany5Data.shareholderInfo.shareholder2) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany5Data.shareholderInfo.shareholder2,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.shareholderInfo.shareholder2.address) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany5Data.shareholderInfo.shareholder2,
            address: {
              ...shareholderCompany5Data.shareholderInfo.shareholder2.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany5Data.shareholderInfo.shareholder3) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany5Data.shareholderInfo.shareholder3,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.shareholderInfo.shareholder3.address) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany5Data.shareholderInfo.shareholder3,
            address: {
              ...shareholderCompany5Data.shareholderInfo.shareholder3.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany5Data.shareholderInfo.shareholder4) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany5Data.shareholderInfo.shareholder4,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.shareholderInfo.shareholder4.address) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany5Data.shareholderInfo.shareholder4,
            address: {
              ...shareholderCompany5Data.shareholderInfo.shareholder4.address,
              [name]: value,
            },
          },
        },
      });
    } else if (state === shareholderCompany5Data.shareholderInfo.shareholder5) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany5Data.shareholderInfo.shareholder5,
            [name]: value,
          },
        },
      });
    } else if (state === shareholderCompany5Data.shareholderInfo.shareholder5.address) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany5Data.shareholderInfo.shareholder5,
            address: {
              ...shareholderCompany5Data.shareholderInfo.shareholder5.address,
              [name]: value,
            },
          },
        },
      });
    }
  };

  const handleFirmCountry = (e) => {
    setFirmData({
      ...firmData,
      firmAddress: {
        ...firmData.firmAddress,
        country: e,
      },
    });
  };

  const handleDirectorCountry1 = (e) => {
    setDirector1Data({
      ...director1Data,
      address: {
        ...director1Data.address,
        country: e,
      },
    });
  };

  const handleDirectorCountry2 = (e) => {
    setDirector2Data({
      ...director2Data,
      address: {
        ...director2Data.address,
        country: e,
      },
    });
  };

  const handleDirectorCountry3 = (e) => {
    setDirector3Data({
      ...director3Data,
      address: {
        ...director3Data.address,
        country: e,
      },
    });
  };

  const handleDirectorCountry4 = (e) => {
    setDirector4Data({
      ...director4Data,
      address: {
        ...director4Data.address,
        country: e,
      },
    });
  };

  const handleDirectorCountry5 = (e) => {
    setDirector5Data({
      ...director5Data,
      address: {
        ...director5Data.address,
        country: e,
      },
    });
  };

  const handleShareholderCountry1 = (e) => {
    setShareholder1Data({
      ...shareholder1Data,
      address: {
        ...shareholder1Data.address,
        country: e,
      },
    });
  };

  const handleShareholderCountry2 = (e) => {
    setShareholder2Data({
      ...shareholder2Data,
      address: {
        ...shareholder2Data.address,
        country: e,
      },
    });
  };

  const handleShareholderCountry3 = (e) => {
    setShareholder3Data({
      ...shareholder3Data,
      address: {
        ...shareholder3Data.address,
        country: e,
      },
    });
  };

  const handleShareholderCountry4 = (e) => {
    setShareholder4Data({
      ...shareholder4Data,
      address: {
        ...shareholder4Data.address,
        country: e,
      },
    });
  };

  const handleShareholderCountry5 = (e) => {
    setShareholder5Data({
      ...shareholder5Data,
      address: {
        ...shareholder5Data.address,
        country: e,
      },
    });
  };

  const handleShareholder1CompanyCountry = {
    handleShareholderCompanyCountry1: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        companyInfo: {
          ...shareholderCompany1Data.companyInfo,
          firmAddress: {
            ...shareholderCompany1Data.companyInfo.firmAddress,
            country: e,
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry1: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director1: {
            ...shareholderCompany1Data.directorInfo.director1,
            address: {
              ...shareholderCompany1Data.directorInfo.director1.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry2: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director2: {
            ...shareholderCompany1Data.directorInfo.director2,
            address: {
              ...shareholderCompany1Data.directorInfo.director2.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry3: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director3: {
            ...shareholderCompany1Data.directorInfo.director3,
            address: {
              ...shareholderCompany1Data.directorInfo.director3.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry4: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director4: {
            ...shareholderCompany1Data.directorInfo.director4,
            address: {
              ...shareholderCompany1Data.directorInfo.director4.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry5: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director5: {
            ...shareholderCompany1Data.directorInfo.director5,
            address: {
              ...shareholderCompany1Data.directorInfo.director5.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry1: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany1Data.shareholderInfo.shareholder1,
            address: {
              ...shareholderCompany1Data.shareholderInfo.shareholder1.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry2: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany1Data.shareholderInfo.shareholder2,
            address: {
              ...shareholderCompany1Data.shareholderInfo.shareholder2.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry3: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany1Data.shareholderInfo.shareholder3,
            address: {
              ...shareholderCompany1Data.shareholderInfo.shareholder3.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry4: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany1Data.shareholderInfo.shareholder4,
            address: {
              ...shareholderCompany1Data.shareholderInfo.shareholder4.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry5: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany1Data.shareholderInfo.shareholder5,
            address: {
              ...shareholderCompany1Data.shareholderInfo.shareholder5.address,
              country: e,
            },
          },
        },
      });
    },
  };

  const handleShareholder2CompanyCountry = {
    handleShareholderCompanyCountry1: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        companyInfo: {
          ...shareholderCompany2Data.companyInfo,
          firmAddress: {
            ...shareholderCompany2Data.companyInfo.firmAddress,
            country: e,
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry1: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director1: {
            ...shareholderCompany2Data.directorInfo.director1,
            address: {
              ...shareholderCompany2Data.directorInfo.director1.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry2: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director2: {
            ...shareholderCompany2Data.directorInfo.director2,
            address: {
              ...shareholderCompany2Data.directorInfo.director2.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry3: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director3: {
            ...shareholderCompany2Data.directorInfo.director3,
            address: {
              ...shareholderCompany2Data.directorInfo.director3.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry4: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director4: {
            ...shareholderCompany2Data.directorInfo.director4,
            address: {
              ...shareholderCompany2Data.directorInfo.director4.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry5: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director5: {
            ...shareholderCompany2Data.directorInfo.director5,
            address: {
              ...shareholderCompany2Data.directorInfo.director5.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry1: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany2Data.shareholderInfo.shareholder1,
            address: {
              ...shareholderCompany2Data.shareholderInfo.shareholder1.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry2: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany2Data.shareholderInfo.shareholder2,
            address: {
              ...shareholderCompany2Data.shareholderInfo.shareholder2.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry3: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany2Data.shareholderInfo.shareholder3,
            address: {
              ...shareholderCompany2Data.shareholderInfo.shareholder3.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry4: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany2Data.shareholderInfo.shareholder4,
            address: {
              ...shareholderCompany2Data.shareholderInfo.shareholder4.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry5: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany2Data.shareholderInfo.shareholder5,
            address: {
              ...shareholderCompany2Data.shareholderInfo.shareholder5.address,
              country: e,
            },
          },
        },
      });
    },
  };

  const handleShareholder3CompanyCountry = {
    handleShareholderCompanyCountry1: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        companyInfo: {
          ...shareholderCompany3Data.companyInfo,
          firmAddress: {
            ...shareholderCompany3Data.companyInfo.firmAddress,
            country: e,
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry1: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director1: {
            ...shareholderCompany3Data.directorInfo.director1,
            address: {
              ...shareholderCompany3Data.directorInfo.director1.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry2: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director2: {
            ...shareholderCompany3Data.directorInfo.director2,
            address: {
              ...shareholderCompany3Data.directorInfo.director2.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry3: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director3: {
            ...shareholderCompany3Data.directorInfo.director3,
            address: {
              ...shareholderCompany3Data.directorInfo.director3.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry4: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director4: {
            ...shareholderCompany3Data.directorInfo.director4,
            address: {
              ...shareholderCompany3Data.directorInfo.director4.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry5: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director5: {
            ...shareholderCompany3Data.directorInfo.director5,
            address: {
              ...shareholderCompany3Data.directorInfo.director5.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry1: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany3Data.shareholderInfo.shareholder1,
            address: {
              ...shareholderCompany3Data.shareholderInfo.shareholder1.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry2: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany3Data.shareholderInfo.shareholder2,
            address: {
              ...shareholderCompany3Data.shareholderInfo.shareholder2.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry3: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany3Data.shareholderInfo.shareholder3,
            address: {
              ...shareholderCompany3Data.shareholderInfo.shareholder3.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry4: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany3Data.shareholderInfo.shareholder4,
            address: {
              ...shareholderCompany3Data.shareholderInfo.shareholder4.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry5: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany3Data.shareholderInfo.shareholder5,
            address: {
              ...shareholderCompany3Data.shareholderInfo.shareholder5.address,
              country: e,
            },
          },
        },
      });
    },
  };

  const handleShareholder4CompanyCountry = {
    handleShareholderCompanyCountry1: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        companyInfo: {
          ...shareholderCompany4Data.companyInfo,
          firmAddress: {
            ...shareholderCompany4Data.companyInfo.firmAddress,
            country: e,
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry1: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director1: {
            ...shareholderCompany4Data.directorInfo.director1,
            address: {
              ...shareholderCompany4Data.directorInfo.director1.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry2: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director2: {
            ...shareholderCompany4Data.directorInfo.director2,
            address: {
              ...shareholderCompany4Data.directorInfo.director2.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry3: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director3: {
            ...shareholderCompany4Data.directorInfo.director3,
            address: {
              ...shareholderCompany4Data.directorInfo.director3.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry4: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director4: {
            ...shareholderCompany4Data.directorInfo.director4,
            address: {
              ...shareholderCompany4Data.directorInfo.director4.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry5: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director5: {
            ...shareholderCompany4Data.directorInfo.director5,
            address: {
              ...shareholderCompany4Data.directorInfo.director5.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry1: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany4Data.shareholderInfo.shareholder1,
            address: {
              ...shareholderCompany4Data.shareholderInfo.shareholder1.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry2: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany4Data.shareholderInfo.shareholder2,
            address: {
              ...shareholderCompany4Data.shareholderInfo.shareholder2.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry3: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany4Data.shareholderInfo.shareholder3,
            address: {
              ...shareholderCompany4Data.shareholderInfo.shareholder3.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry4: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany4Data.shareholderInfo.shareholder4,
            address: {
              ...shareholderCompany4Data.shareholderInfo.shareholder4.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry5: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany4Data.shareholderInfo.shareholder5,
            address: {
              ...shareholderCompany4Data.shareholderInfo.shareholder5.address,
              country: e,
            },
          },
        },
      });
    },
  };

  const handleShareholder5CompanyCountry = {
    handleShareholderCompanyCountry1: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        companyInfo: {
          ...shareholderCompany5Data.companyInfo,
          firmAddress: {
            ...shareholderCompany5Data.companyInfo.firmAddress,
            country: e,
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry1: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director1: {
            ...shareholderCompany5Data.directorInfo.director1,
            address: {
              ...shareholderCompany5Data.directorInfo.director1.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry2: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director2: {
            ...shareholderCompany5Data.directorInfo.director2,
            address: {
              ...shareholderCompany5Data.directorInfo.director2.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry3: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director3: {
            ...shareholderCompany5Data.directorInfo.director3,
            address: {
              ...shareholderCompany5Data.directorInfo.director3.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry4: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director4: {
            ...shareholderCompany5Data.directorInfo.director4,
            address: {
              ...shareholderCompany5Data.directorInfo.director4.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyDirectorCountry5: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director5: {
            ...shareholderCompany5Data.directorInfo.director5,
            address: {
              ...shareholderCompany5Data.directorInfo.director5.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry1: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany5Data.shareholderInfo.shareholder1,
            address: {
              ...shareholderCompany5Data.shareholderInfo.shareholder1.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry2: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany5Data.shareholderInfo.shareholder2,
            address: {
              ...shareholderCompany5Data.shareholderInfo.shareholder2.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry3: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany5Data.shareholderInfo.shareholder3,
            address: {
              ...shareholderCompany5Data.shareholderInfo.shareholder3.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry4: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany5Data.shareholderInfo.shareholder4,
            address: {
              ...shareholderCompany5Data.shareholderInfo.shareholder4.address,
              country: e,
            },
          },
        },
      });
    },
    handleShareholderCompanyShareholderCountry5: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany5Data.shareholderInfo.shareholder5,
            address: {
              ...shareholderCompany5Data.shareholderInfo.shareholder5.address,
              country: e,
            },
          },
        },
      });
    },
  };

  const radioShareholder1Company = {
    handleRadioShareholderDirector1: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director1: {
            ...shareholderCompany1Data.directorInfo.director1,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector2: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director2: {
            ...shareholderCompany1Data.directorInfo.director2,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector3: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director3: {
            ...shareholderCompany1Data.directorInfo.director3,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector4: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director4: {
            ...shareholderCompany1Data.directorInfo.director4,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector5: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        directorInfo: {
          ...shareholderCompany1Data.directorInfo,
          director5: {
            ...shareholderCompany1Data.directorInfo.director5,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder1: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany1Data.shareholderInfo.shareholder1,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder2: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany1Data.shareholderInfo.shareholder2,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder3: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany1Data.shareholderInfo.shareholder3,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder4: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany1Data.shareholderInfo.shareholder4,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder5: function (e) {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        shareholderInfo: {
          ...shareholderCompany1Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany1Data.shareholderInfo.shareholder5,
            citizenship: e.target.value,
          },
        },
      });
    },
  };

  const radioShareholder2Company = {
    handleRadioShareholderDirector1: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director1: {
            ...shareholderCompany2Data.directorInfo.director1,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector2: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director2: {
            ...shareholderCompany2Data.directorInfo.director2,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector3: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director3: {
            ...shareholderCompany2Data.directorInfo.director3,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector4: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director4: {
            ...shareholderCompany2Data.directorInfo.director4,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector5: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        directorInfo: {
          ...shareholderCompany2Data.directorInfo,
          director5: {
            ...shareholderCompany2Data.directorInfo.director5,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder1: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany2Data.shareholderInfo.shareholder1,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder2: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany2Data.shareholderInfo.shareholder2,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder3: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany2Data.shareholderInfo.shareholder3,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder4: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany2Data.shareholderInfo.shareholder4,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder5: function (e) {
      setShareholderCompany2Data({
        ...shareholderCompany2Data,
        shareholderInfo: {
          ...shareholderCompany2Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany2Data.shareholderInfo.shareholder5,
            citizenship: e.target.value,
          },
        },
      });
    },
  };

  const radioShareholder3Company = {
    handleRadioShareholderDirector1: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director1: {
            ...shareholderCompany3Data.directorInfo.director1,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector2: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director2: {
            ...shareholderCompany3Data.directorInfo.director2,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector3: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director3: {
            ...shareholderCompany3Data.directorInfo.director3,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector4: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director4: {
            ...shareholderCompany3Data.directorInfo.director4,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector5: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        directorInfo: {
          ...shareholderCompany3Data.directorInfo,
          director5: {
            ...shareholderCompany3Data.directorInfo.director5,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder1: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany3Data.shareholderInfo.shareholder1,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder2: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany3Data.shareholderInfo.shareholder2,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder3: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany3Data.shareholderInfo.shareholder3,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder4: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany3Data.shareholderInfo.shareholder4,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder5: function (e) {
      setShareholderCompany3Data({
        ...shareholderCompany3Data,
        shareholderInfo: {
          ...shareholderCompany3Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany3Data.shareholderInfo.shareholder5,
            citizenship: e.target.value,
          },
        },
      });
    },
  };

  const radioShareholder4Company = {
    handleRadioShareholderDirector1: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director1: {
            ...shareholderCompany4Data.directorInfo.director1,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector2: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director2: {
            ...shareholderCompany4Data.directorInfo.director2,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector3: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director3: {
            ...shareholderCompany4Data.directorInfo.director3,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector4: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director4: {
            ...shareholderCompany4Data.directorInfo.director4,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector5: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        directorInfo: {
          ...shareholderCompany4Data.directorInfo,
          director5: {
            ...shareholderCompany4Data.directorInfo.director5,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder1: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany4Data.shareholderInfo.shareholder1,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder2: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany4Data.shareholderInfo.shareholder2,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder3: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany4Data.shareholderInfo.shareholder3,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder4: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany4Data.shareholderInfo.shareholder4,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder5: function (e) {
      setShareholderCompany4Data({
        ...shareholderCompany4Data,
        shareholderInfo: {
          ...shareholderCompany4Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany4Data.shareholderInfo.shareholder5,
            citizenship: e.target.value,
          },
        },
      });
    },
  };

  const radioShareholder5Company = {
    handleRadioShareholderDirector1: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director1: {
            ...shareholderCompany5Data.directorInfo.director1,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector2: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director2: {
            ...shareholderCompany5Data.directorInfo.director2,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector3: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director3: {
            ...shareholderCompany5Data.directorInfo.director3,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector4: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director4: {
            ...shareholderCompany5Data.directorInfo.director4,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderDirector5: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        directorInfo: {
          ...shareholderCompany5Data.directorInfo,
          director5: {
            ...shareholderCompany5Data.directorInfo.director5,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder1: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder1: {
            ...shareholderCompany5Data.shareholderInfo.shareholder1,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder2: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder2: {
            ...shareholderCompany5Data.shareholderInfo.shareholder2,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder3: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder3: {
            ...shareholderCompany5Data.shareholderInfo.shareholder3,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder4: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder4: {
            ...shareholderCompany5Data.shareholderInfo.shareholder4,
            citizenship: e.target.value,
          },
        },
      });
    },
    handleRadioShareholderShareholder5: function (e) {
      setShareholderCompany5Data({
        ...shareholderCompany5Data,
        shareholderInfo: {
          ...shareholderCompany5Data.shareholderInfo,
          shareholder5: {
            ...shareholderCompany5Data.shareholderInfo.shareholder5,
            citizenship: e.target.value,
          },
        },
      });
    },
  };

  // const parentFunction = (infoFromChild) => {
  //   setShareholder1Type(infoFromChild);
  //   // console.log('parametar', infoFromChild);
  // };

  // useEffect(() => {
  //   console.log('novi state', type);
  // }, [type]);

  const handleRadioChange1 = (e) => {
    setCitizenship1(e.target.value);
  };
  const handleRadioChange2 = (e) => {
    setCitizenship2(e.target.value);
  };
  const handleRadioChange3 = (e) => {
    setCitizenship3(e.target.value);
  };
  const handleRadioChange4 = (e) => {
    setCitizenship4(e.target.value);
  };

  const handleRadioChange5 = (e) => {
    setCitizenship5(e.target.value);
  };
  const handleShareholderRadioChange1 = (e) => {
    setCitizenshipShareholder1(e.target.value);
  };
  const handleShareholderRadioChange2 = (e) => {
    setCitizenshipShareholder2(e.target.value);
  };
  const handleShareholderRadioChange3 = (e) => {
    setCitizenshipShareholder3(e.target.value);
  };
  const handleShareholderRadioChange4 = (e) => {
    setCitizenshipShareholder4(e.target.value);
  };
  const handleShareholderRadioChange5 = (e) => {
    setCitizenshipShareholder5(e.target.value);
  };

  const handleFirmAddressChange = (value) => {
    if (value === 'hlb') {
      setFirmData({
        ...firmData,
        firmAddress: {
          street: 'Cara Nikolaja',
          streetNum: 'II',
          floor: '',
          aptNum: '11',
          city: 'Belgrade',
          country: 'Serbia',
        },
      });
    } else {
      setFirmData({
        ...firmData,
        firmAddress: {
          street: '',
          streetNum: '',
          floor: '',
          aptNum: '',
          city: '',
          country: '',
        },
      });
    }
  };

  const handleDirectorNum = (value) => {
    if (value === 1) {
      setDirectorNum(1);
    } else if (value === 2) {
      setDirectorNum(2);
    } else if (value === 3) {
      setDirectorNum(3);
    } else if (value === 4) {
      setDirectorNum(4);
    } else if (value === 5) {
      setDirectorNum(5);
    }
  };

  const handleShareholderNum = (value) => {
    if (value === 1) {
      setShareholderNum(1);
    } else if (value === 2) {
      setShareholderNum(2);
    } else if (value === 3) {
      setShareholderNum(3);
    } else if (value === 4) {
      setShareholderNum(4);
    } else if (value === 5) {
      setShareholderNum(5);
    }
  };

  const handleDirectorDisplay = () => {
    if (directorNum === 1) {
      return (
        <DisplayDirector
          directorData={director1Data}
          citizenship={citizenship1}
          handleChange={handleChange}
          handleRadioChange={handleRadioChange1}
          handleDirectorCountry={handleDirectorCountry1}
          countries={countries}
          directorNumeration={2}
          directorFormName='First'
          shareholderDirectorNum=''
        />
      );
    } else if (directorNum === 2) {
      return (
        <>
          <p>
            <b>Director 1</b>
          </p>
          <DisplayDirector
            directorData={director1Data}
            citizenship={citizenship1}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange1}
            handleDirectorCountry={handleDirectorCountry1}
            countries={countries}
            directorNumeration='2a'
            directorFormName='First'
            shareholderDirectorNum=''
          />
          <p>
            <b>Director 2</b>
          </p>
          <DisplayDirector
            directorData={director2Data}
            citizenship={citizenship2}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange2}
            handleDirectorCountry={handleDirectorCountry2}
            countries={countries}
            directorNumeration='2b'
            directorFormName='Second'
            shareholderDirectorNum=''
          />
        </>
      );
    } else if (directorNum === 3) {
      return (
        <>
          <p>
            <b>Director 1</b>
          </p>
          <DisplayDirector
            directorData={director1Data}
            citizenship={citizenship1}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange1}
            handleDirectorCountry={handleDirectorCountry1}
            countries={countries}
            directorNumeration='2a'
            directorFormName='First'
            shareholderDirectorNum=''
          />
          <p>
            <b>Director 2</b>
          </p>
          <DisplayDirector
            directorData={director2Data}
            citizenship={citizenship2}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange2}
            handleDirectorCountry={handleDirectorCountry2}
            countries={countries}
            directorNumeration='2b'
            directorFormName='Second'
            shareholderDirectorNum=''
          />
          <p>
            <b>Director 3</b>
          </p>
          <DisplayDirector
            directorData={director3Data}
            citizenship={citizenship3}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange3}
            handleDirectorCountry={handleDirectorCountry3}
            countries={countries}
            directorNumeration='2c'
            directorFormName='Third'
            shareholderDirectorNum=''
          />
        </>
      );
    } else if (directorNum === 4) {
      return (
        <>
          <p>
            <b>Director 1</b>
          </p>
          <DisplayDirector
            directorData={director1Data}
            citizenship={citizenship1}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange1}
            handleDirectorCountry={handleDirectorCountry1}
            countries={countries}
            directorNumeration='2a'
            directorFormName='First'
            shareholderDirectorNum=''
          />
          <p>
            <b>Director 2</b>
          </p>
          <DisplayDirector
            directorData={director2Data}
            citizenship={citizenship2}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange2}
            handleDirectorCountry={handleDirectorCountry2}
            countries={countries}
            directorNumeration='2b'
            directorFormName='Second'
            shareholderDirectorNum=''
          />
          <p>
            <b>Director 3</b>
          </p>
          <DisplayDirector
            directorData={director3Data}
            citizenship={citizenship3}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange3}
            handleDirectorCountry={handleDirectorCountry3}
            countries={countries}
            directorNumeration='2c'
            directorFormName='Third'
            shareholderDirectorNum=''
          />
          <p>
            <b>Director 4</b>
          </p>
          <DisplayDirector
            directorData={director4Data}
            citizenship={citizenship4}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange4}
            handleDirectorCountry={handleDirectorCountry4}
            countries={countries}
            directorNumeration='2d'
            directorFormName='Fourth'
            shareholderDirectorNum=''
          />
        </>
      );
    } else if (directorNum === 5) {
      return (
        <>
          <p>
            <b>Director 1</b>
          </p>
          <DisplayDirector
            directorData={director1Data}
            citizenship={citizenship1}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange1}
            handleDirectorCountry={handleDirectorCountry1}
            countries={countries}
            directorNumeration='2a'
            directorFormName='First'
            shareholderDirectorNum=''
          />
          <p>
            <b>Director 2</b>
          </p>
          <DisplayDirector
            directorData={director2Data}
            citizenship={citizenship2}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange2}
            handleDirectorCountry={handleDirectorCountry2}
            countries={countries}
            directorNumeration='2b'
            directorFormName='Second'
            shareholderDirectorNum=''
          />
          <p>
            <b>Director 3</b>
          </p>
          <DisplayDirector
            directorData={director3Data}
            citizenship={citizenship3}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange3}
            handleDirectorCountry={handleDirectorCountry3}
            countries={countries}
            directorNumeration='2c'
            directorFormName='Third'
            shareholderDirectorNum=''
          />
          <p>
            <b>Director 4</b>
          </p>
          <DisplayDirector
            directorData={director4Data}
            citizenship={citizenship4}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange4}
            handleDirectorCountry={handleDirectorCountry4}
            countries={countries}
            directorNumeration='2d'
            directorFormName='Fourth'
            shareholderDirectorNum=''
          />
          <p>
            <b>Director 5</b>
          </p>
          <DisplayDirector
            directorData={director5Data}
            citizenship={citizenship5}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange5}
            handleDirectorCountry={handleDirectorCountry5}
            countries={countries}
            directorNumeration='2e'
            directorFormName='Fifth'
            shareholderDirectorNum=''
          />
        </>
      );
    }
  };

  const handleShareholderDisplay = () => {
    if (shareholderNum === 1) {
      return (
        <DisplayShareholder
          shareholderData={shareholder1Data}
          citizenship={citizenshipShareholder1}
          handleChange={handleChange}
          handleRadioChange={handleShareholderRadioChange1}
          handleShareholderCountry={handleShareholderCountry1}
          countries={countries}
          shareholderNumeration={3}
          shareholderCompanyData={shareholderCompany1Data}
          handleRadioCompanyChange={radioShareholder1Company}
          handleShareholderCompanyCountry={handleShareholder1CompanyCountry}
          companyShareholderNum=''
          setShareholderCompanyData={setShareholderCompany1Data}
          type={shareholder1Type}
          setType={setShareholder1Type}
        />
      );
    } else if (shareholderNum === 2) {
      return (
        <>
          <p>
            <b>Shareholder 1</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder1Data}
            citizenship={citizenshipShareholder1}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange1}
            handleShareholderCountry={handleShareholderCountry1}
            countries={countries}
            shareholderNumeration='3a'
            shareholderCompanyData={shareholderCompany1Data}
            handleRadioCompanyChange={radioShareholder1Company}
            handleShareholderCompanyCountry={handleShareholder1CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany1Data}
            type={shareholder1Type}
            setType={setShareholder1Type}
          />

          <p>
            <b>Shareholder 2</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder2Data}
            citizenship={citizenshipShareholder2}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange2}
            handleShareholderCountry={handleShareholderCountry2}
            countries={countries}
            shareholderNumeration='3b'
            shareholderCompanyData={shareholderCompany2Data}
            handleRadioCompanyChange={radioShareholder2Company}
            handleShareholderCompanyCountry={handleShareholder2CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany2Data}
            type={shareholder2Type}
            setType={setShareholder2Type}
          />
        </>
      );
    } else if (shareholderNum === 3) {
      return (
        <>
          <p>
            <b>Shareholder 1</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder1Data}
            citizenship={citizenshipShareholder1}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange1}
            handleShareholderCountry={handleShareholderCountry1}
            countries={countries}
            shareholderNumeration='3a'
            shareholderCompanyData={shareholderCompany1Data}
            handleRadioCompanyChange={radioShareholder1Company}
            handleShareholderCompanyCountry={handleShareholder1CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany1Data}
            type={shareholder1Type}
            setType={setShareholder1Type}
          />
          <p>
            <b>Shareholder 2</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder2Data}
            citizenship={citizenshipShareholder2}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange2}
            handleShareholderCountry={handleShareholderCountry2}
            countries={countries}
            shareholderNumeration='3b'
            shareholderCompanyData={shareholderCompany2Data}
            handleRadioCompanyChange={radioShareholder2Company}
            handleShareholderCompanyCountry={handleShareholder2CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany2Data}
            type={shareholder2Type}
            setType={setShareholder2Type}
          />
          <p>
            <b>Shareholder 3</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder3Data}
            citizenship={citizenshipShareholder3}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange3}
            handleShareholderCountry={handleShareholderCountry3}
            countries={countries}
            shareholderNumeration='3c'
            shareholderCompanyData={shareholderCompany3Data}
            handleRadioCompanyChange={radioShareholder3Company}
            handleShareholderCompanyCountry={handleShareholder3CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany3Data}
            type={shareholder3Type}
            setType={setShareholder3Type}
          />
        </>
      );
    } else if (shareholderNum === 4) {
      return (
        <>
          <p>
            <b>Shareholder 1</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder1Data}
            citizenship={citizenshipShareholder1}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange1}
            handleShareholderCountry={handleShareholderCountry1}
            countries={countries}
            shareholderNumeration='3a'
            shareholderCompanyData={shareholderCompany1Data}
            handleRadioCompanyChange={radioShareholder1Company}
            handleShareholderCompanyCountry={handleShareholder1CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany1Data}
            type={shareholder1Type}
            setType={setShareholder1Type}
          />
          <p>
            <b>Shareholder 2</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder2Data}
            citizenship={citizenshipShareholder2}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange2}
            handleShareholderCountry={handleShareholderCountry2}
            countries={countries}
            shareholderNumeration='3b'
            shareholderCompanyData={shareholderCompany2Data}
            handleRadioCompanyChange={radioShareholder2Company}
            handleShareholderCompanyCountry={handleShareholder2CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany2Data}
            type={shareholder2Type}
            setType={setShareholder2Type}
          />
          <p>
            <b>Shareholder 3</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder3Data}
            citizenship={citizenshipShareholder3}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange3}
            handleShareholderCountry={handleShareholderCountry3}
            countries={countries}
            shareholderNumeration='3c'
            shareholderCompanyData={shareholderCompany3Data}
            handleRadioCompanyChange={radioShareholder3Company}
            handleShareholderCompanyCountry={handleShareholder3CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany3Data}
            type={shareholder3Type}
            setType={setShareholder3Type}
          />
          <p>
            <b>Shareholder 4</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder4Data}
            citizenship={citizenshipShareholder4}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange4}
            handleShareholderCountry={handleShareholderCountry4}
            countries={countries}
            shareholderNumeration='3d'
            shareholderCompanyData={shareholderCompany4Data}
            handleRadioCompanyChange={radioShareholder4Company}
            handleShareholderCompanyCountry={handleShareholder4CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany4Data}
            type={shareholder4Type}
            setType={setShareholder4Type}
          />
        </>
      );
    } else if (shareholderNum === 5) {
      return (
        <>
          <p>
            <b>Shareholder 1</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder1Data}
            citizenship={citizenshipShareholder1}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange1}
            handleShareholderCountry={handleShareholderCountry1}
            countries={countries}
            shareholderNumeration='3a'
            shareholderCompanyData={shareholderCompany1Data}
            handleRadioCompanyChange={radioShareholder1Company}
            handleShareholderCompanyCountry={handleShareholder1CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany1Data}
            type={shareholder1Type}
            setType={setShareholder1Type}
          />
          <p>
            <b>Shareholder 2</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder2Data}
            citizenship={citizenshipShareholder2}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange2}
            handleShareholderCountry={handleShareholderCountry2}
            countries={countries}
            shareholderNumeration='3b'
            shareholderCompanyData={shareholderCompany2Data}
            handleRadioCompanyChange={radioShareholder2Company}
            handleShareholderCompanyCountry={handleShareholder2CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany2Data}
            type={shareholder2Type}
            setType={setShareholder2Type}
          />
          <p>
            <b>Shareholder 3</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder3Data}
            citizenship={citizenshipShareholder3}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange3}
            handleShareholderCountry={handleShareholderCountry3}
            countries={countries}
            shareholderNumeration='3c'
            shareholderCompanyData={shareholderCompany3Data}
            handleRadioCompanyChange={radioShareholder3Company}
            handleShareholderCompanyCountry={handleShareholder3CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany3Data}
            type={shareholder3Type}
            setType={setShareholder3Type}
          />
          <p>
            <b>Shareholder 4</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder4Data}
            citizenship={citizenshipShareholder4}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange4}
            handleShareholderCountry={handleShareholderCountry4}
            countries={countries}
            shareholderNumeration='3d'
            shareholderCompanyData={shareholderCompany4Data}
            handleRadioCompanyChange={radioShareholder4Company}
            handleShareholderCompanyCountry={handleShareholder4CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany4Data}
            type={shareholder4Type}
            setType={setShareholder4Type}
          />
          <p>
            <b>Shareholder 5</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholder5Data}
            citizenship={citizenshipShareholder5}
            handleChange={handleChange}
            handleRadioChange={handleShareholderRadioChange5}
            handleShareholderCountry={handleShareholderCountry5}
            countries={countries}
            shareholderNumeration='3e'
            shareholderCompanyData={shareholderCompany5Data}
            handleRadioCompanyChange={radioShareholder5Company}
            handleShareholderCompanyCountry={handleShareholder5CompanyCountry}
            companyShareholderNum=''
            setShareholderCompanyData={setShareholderCompany5Data}
            type={shareholder5Type}
            setType={setShareholder5Type}
          />
        </>
      );
    }
  };

  const handleSubmit = async () => {
    const totalOwnershipShareholders =
      Number(shareholder1Data.ownershipPercentage) +
      Number(shareholder2Data.ownershipPercentage) +
      Number(shareholder3Data.ownershipPercentage) +
      Number(shareholder4Data.ownershipPercentage) +
      Number(shareholder5Data.ownershipPercentage) +
      Number(shareholderCompany1Data.companyInfo.ownershipPercentage) +
      Number(shareholderCompany2Data.companyInfo.ownershipPercentage) +
      Number(shareholderCompany3Data.companyInfo.ownershipPercentage) +
      Number(shareholderCompany4Data.companyInfo.ownershipPercentage) +
      Number(shareholderCompany5Data.companyInfo.ownershipPercentage);
    try {
      if (totalOwnershipShareholders === 100) {
        const responsePdf = await Axios.post(
          `${SERVER_URL}/pdf`,
          {
            firmData,
            directorNum,
            director1Data,
            director2Data,
            director3Data,
            director4Data,
            director5Data,
            shareholderNum,
            shareholder1Data,
            shareholder2Data,
            shareholder3Data,
            shareholder4Data,
            shareholder5Data,
            shareholderCompany1Data,
            shareholder1Type,
            shareholderCompany2Data,
            shareholder2Type,
            shareholderCompany3Data,
            shareholder3Type,
            shareholderCompany4Data,
            shareholder4Type,
            shareholderCompany5Data,
            shareholder5Type,
          },
          // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        const response = await Axios.post(
          `${SERVER_URL}/word`,
          {
            firmData,
            directorNum,
            director1Data,
            director2Data,
            director3Data,
            director4Data,
            director5Data,
            shareholderNum,
            shareholder1Data,
            shareholder2Data,
            shareholder3Data,
            shareholder4Data,
            shareholder5Data,
            shareholderCompany1Data,
            shareholder1Type,
            shareholderCompany2Data,
            shareholder2Type,
            shareholderCompany3Data,
            shareholder3Type,
            shareholderCompany4Data,
            shareholder4Type,
            shareholderCompany5Data,
            shareholder5Type,
          },
          // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        // const response2 = await Axios.post(
        //   `${SERVER_URL}/word2`,
        //   {
        //     firmData,
        //     directorNum,
        //     director1Data,
        //     director2Data,
        //     director3Data,
        //     director4Data,
        //     director5Data,
        //     shareholderNum,
        //     shareholder2Data,
        //     shareholderCompany2Data,
        //     shareholder2Type,
        //   },
        //   // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        // );
        //
        // const response3 = await Axios.post(
        //   `${SERVER_URL}/word3`,
        //   {
        //     firmData,
        //     directorNum,
        //     director1Data,
        //     director2Data,
        //     director3Data,
        //     director4Data,
        //     director5Data,
        //     shareholderNum,
        //     shareholder3Data,
        //     shareholderCompany3Data,
        //     shareholder3Type,
        //   },
        //   // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        // );
        //
        // const response4 = await Axios.post(
        //   `${SERVER_URL}/word4`,
        //   {
        //     firmData,
        //     directorNum,
        //     director1Data,
        //     director2Data,
        //     director3Data,
        //     director4Data,
        //     director5Data,
        //     shareholderNum,
        //     shareholder4Data,
        //     shareholderCompany4Data,
        //     shareholder4Type,
        //   },
        //   // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        // );
        //
        // const response5 = await Axios.post(
        //   `${SERVER_URL}/word5`,
        //   {
        //     firmData,
        //     directorNum,
        //     director1Data,
        //     director2Data,
        //     director3Data,
        //     director4Data,
        //     director5Data,
        //     shareholderNum,
        //     shareholder5Data,
        //     shareholderCompany5Data,
        //     shareholder5Type,
        //   },
        //   // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        // );

        const responseAct = await Axios.post(
          `${SERVER_URL}/act`,
          {
            firmData,
            directorNum,
            director1Data,
            director2Data,
            director3Data,
            director4Data,
            director5Data,
            shareholderNum,
            shareholder1Data,
            shareholder2Data,
            shareholder3Data,
            shareholder4Data,
            shareholder5Data,
            shareholderCompany1Data,
            shareholder1Type,
            shareholderCompany2Data,
            shareholder2Type,
            shareholderCompany3Data,
            shareholder3Type,
            shareholderCompany4Data,
            shareholder4Type,
            shareholderCompany5Data,
            shareholder5Type,
          },
          // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        const responseAttorney = await Axios.post(
          `${SERVER_URL}/attorney`,
          {
            firmData,
            directorNum,
            director1Data,
            director2Data,
            director3Data,
            director4Data,
            director5Data,
            shareholderNum,
            shareholder1Data,
            shareholder2Data,
            shareholder3Data,
            shareholder4Data,
            shareholder5Data,
            shareholderCompany1Data,
            shareholder1Type,
            shareholderCompany2Data,
            shareholder2Type,
            shareholderCompany3Data,
            shareholder3Type,
            shareholderCompany4Data,
            shareholder4Type,
            shareholderCompany5Data,
            shareholder5Type,
          },
          // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        // const responseAttorney2 = await Axios.post(
        //   `${SERVER_URL}/attorney2`,
        //   {
        //     firmData,
        //     directorNum,
        //     director1Data,
        //     director2Data,
        //     director3Data,
        //     director4Data,
        //     director5Data,
        //     shareholderNum,
        //     shareholder2Data,
        //     shareholderCompany2Data,
        //     shareholder2Type,
        //   },
        //   // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        // );
        // const responseAttorney3 = await Axios.post(
        //   `${SERVER_URL}/attorney3`,
        //   {
        //     firmData,
        //     directorNum,
        //     director1Data,
        //     director2Data,
        //     director3Data,
        //     director4Data,
        //     director5Data,
        //     shareholderNum,
        //     shareholder3Data,
        //     shareholderCompany3Data,
        //     shareholder3Type,
        //   },
        //   // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        // );
        // const responseAttorney4 = await Axios.post(
        //   `${SERVER_URL}/attorney4`,
        //   {
        //     firmData,
        //     directorNum,
        //     director1Data,
        //     director2Data,
        //     director3Data,
        //     director4Data,
        //     director5Data,
        //     shareholderNum,
        //     shareholder4Data,
        //     shareholderCompany4Data,
        //     shareholder4Type,
        //   },
        //   // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        // );
        // const responseAttorney5 = await Axios.post(
        //   `${SERVER_URL}/attorney5`,
        //   {
        //     firmData,
        //     directorNum,
        //     director1Data,
        //     director2Data,
        //     director3Data,
        //     director4Data,
        //     director5Data,
        //     shareholderNum,
        //     shareholder5Data,
        //     shareholderCompany5Data,
        //     shareholder5Type,
        //   },
        //   // { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        // );
        notification.success({
          message: 'Questionnaire is submitted.',
          placement: 'bottomRight',
        });
        // if (shareholder2Data.firstName || shareholderCompany2Data.companyInfo.firmName) {
        //   FileSaver.saveAs(SERVER_URL + response2.data.path, 'Power of Attorney (S2) - Bussiness register.doc');
        // }
        // if (shareholder3Data.firstName || shareholderCompany3Data.companyInfo.firmName) {
        //   FileSaver.saveAs(SERVER_URL + response3.data.path, 'Power of Attorney (S3) - Bussiness register.doc');
        // }
        // if (shareholder4Data.firstName || shareholderCompany4Data.companyInfo.firmName) {
        //   FileSaver.saveAs(SERVER_URL + response4.data.path, 'Power of Attorney (S4) - Bussiness register.doc');
        // }
        // if (shareholder5Data.firstName || shareholderCompany5Data.companyInfo.firmName) {
        //   FileSaver.saveAs(SERVER_URL + response5.data.path, 'Power of Attorney (S5) - Bussiness register.doc');
        // }
        FileSaver.saveAs(SERVER_URL + responseAct.data.path, 'Founding Act.doc');
        FileSaver.saveAs(SERVER_URL + response.data.path, 'Power of Attorney - Bussiness register.doc');
        FileSaver.saveAs(SERVER_URL + responseAttorney.data.path, 'Power of Attorney - Founding Act.doc');
        FileSaver.saveAs(SERVER_URL + responsePdf.data.path, 'Registraciona prijava.pdf');
        // if (shareholder2Data.firstName || shareholderCompany2Data.companyInfo.firmName) {
        //   FileSaver.saveAs(SERVER_URL + responseAttorney2.data.path, 'Power of Attorney (S2) - Founding Act.doc');
        // }
        // if (shareholder3Data.firstName || shareholderCompany3Data.companyInfo.firmName) {
        //   FileSaver.saveAs(SERVER_URL + responseAttorney3.data.path, 'Power of Attorney (S3) - Founding Act.doc');
        // }
        // if (shareholder4Data.firstName || shareholderCompany4Data.companyInfo.firmName) {
        //   FileSaver.saveAs(SERVER_URL + responseAttorney4.data.path, 'Power of Attorney (S4) - Founding Act.doc');
        // }
        // if (shareholder5Data.firstName || shareholderCompany5Data.companyInfo.firmName) {
        //   FileSaver.saveAs(SERVER_URL + responseAttorney5.data.path, 'Power of Attorney (S5) - Founding Act.doc');
        // }

        // history.push('/admin/guidelines');}
      } else if (totalOwnershipShareholders < 100 || totalOwnershipShareholders > 100) {
        notification.error({
          message: 'Sum of shareholding value is more or less than 100%.',
          placement: 'bottomRight',
        });
      }
    } catch (err) {
      notification.error({
        message: 'Problem with sending. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  useEffect(
    () => {
      console
        .log
        // 'firma',
        // firmData,
        // 'direktor 1',
        // director1Data,
        // 'direktor 2',
        // director2Data,
        // 'direktor 3',
        // director3Data,
        // 'direktor 4',
        // director4Data,
        // 'direktor 5',
        // director5Data,
        // 'shareholder 1',
        // shareholder1Data,
        // 'shareholder 2',
        // shareholder2Data,
        // 'shareholder 3',
        // shareholder3Data,
        // 'shareholder 4',
        // shareholder4Data,
        // 'shareholder 5',
        // shareholder5Data,
        // 'shareholder company 1',
        // shareholderCompany1Data,
        // 'shareholder company 2',
        // shareholderCompany2Data,
        // 'shareholder company 3',
        // shareholderCompany3Data,
        // 'shareholder company 4',
        // shareholderCompany4Data,
        // 'shareholder company 5',
        // shareholderCompany5Data,
        ();
    },
    [
      // firmData,
      // director1Data,
      // director2Data,
      // director3Data,
      // director4Data,
      // director5Data,
      // shareholder1Data,
      // shareholder2Data,
      // shareholder3Data,
      // shareholder4Data,
      // shareholder5Data,
      // shareholderCompany1Data,
      // shareholderCompany2Data,
      // shareholderCompany3Data,
      // shareholderCompany4Data,
      // shareholderCompany5Data,
    ],
  );
  useEffect(() => {
    fetch('https://restcountries.com/v2/all')
      .then((res) => res.json())
      .then((data) => {
        setCountries(
          data.map((country) => {
            return country.name;
          }),
        );
      });
  }, []);

  useEffect(() => {
    if (shareholder1Type === 'natural person') {
      setShareholderCompany1Data({
        ...shareholderCompany1Data,
        companyInfo: {
          ...shareholderCompany1Data.companyInfo,
          ownershipPercentage: '',
        },
      });
      if (shareholderNum === 1) {
        setShareholder1Data({ ...shareholder1Data, ownershipPercentage: 100 });
      } else if (shareholderNum > 1) {
        setShareholder1Data({ ...shareholder1Data, ownershipPercentage: '' });
      }
    } else if (shareholder1Type === 'company') {
      setShareholder1Data({ ...shareholder1Data, ownershipPercentage: '' });
      if (shareholderNum === 1) {
        setShareholderCompany1Data({
          ...shareholderCompany1Data,
          companyInfo: {
            ...shareholderCompany1Data.companyInfo,
            ownershipPercentage: 100,
          },
        });
      } else if (shareholderNum > 1) {
        setShareholderCompany1Data({
          ...shareholderCompany1Data,
          companyInfo: {
            ...shareholderCompany1Data.companyInfo,
            ownershipPercentage: '',
          },
        });
      }
    }
  }, [shareholderNum, shareholder1Type]);

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title='QUESTIONNAIRE' className='questionnaire-card'>
          <Form onFinish={handleSubmit} labelCol={{ span: 11 }} wrapperCol={{ span: 14 }}>
            <p>
              <b>1. Company Data</b>
            </p>
            <Form.Item
              // name='firmNameForm'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter name of firm!',
              //   },
              // ]}
              required
              label='1.1 Name of firm:'
            >
              <input
                type='text'
                name='firmName'
                value={firmData.firmName}
                onChange={(e) => handleChange(firmData, e)}
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: '100%',
                  outline: 'none',
                }}
              />
            </Form.Item>
            <Form.Item
              required
              // name='alternativeFirmNameForm'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter alternative name of firm!',
              //   },
              // ]}
              // validateStatus={firmData.alternativeFirmName === '' ? 'error' : ''}
              // hasFeedback
              // help={firmData.alternativeFirmName === '' ? 'Please enter alternative name of firm!' : ''}
              label='1.2 Alternative name of firm:'
            >
              <input
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: '100%',
                  outline: 'none',
                }}
                type='text'
                name='alternativeFirmName'
                value={firmData.alternativeFirmName}
                onChange={(e) => handleChange(firmData, e)}
              />
            </Form.Item>
            <Form.Item label='1.3 Address:'>
              <Select
                style={{
                  width: 140,
                }}
                onChange={handleFirmAddressChange}
              >
                <Option value='custom'>Custom</Option>
                <Option value='hlb'>HLB address</Option>
              </Select>
            </Form.Item>
            <Form.Item
              required
              // name='streetForm'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter street name!',
              //   },
              // ]}
              label='1.4 Street:'
            >
              <input
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: '100%',
                  outline: 'none',
                }}
                type='text'
                name='street'
                value={firmData.firmAddress.street}
                onChange={(e) => handleChange(firmData.firmAddress, e)}
              />
            </Form.Item>
            <Form.Item
              required
              // name='streetNumForm'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter street number!',
              //   },
              // ]}
              label='1.5 Street Number:'
            >
              <input
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: '100%',
                  outline: 'none',
                }}
                type='text'
                name='streetNum'
                value={firmData.firmAddress.streetNum}
                onChange={(e) => handleChange(firmData.firmAddress, e)}
              />
            </Form.Item>
            <Form.Item label='1.6 Floor:'>
              <Input
                type='text'
                name='floor'
                value={firmData.firmAddress.floor}
                onChange={(e) => handleChange(firmData.firmAddress, e)}
              />
            </Form.Item>
            <Form.Item label='1.7 Apartment Number:'>
              <Input
                type='text'
                name='aptNum'
                value={firmData.firmAddress.aptNum}
                onChange={(e) => handleChange(firmData.firmAddress, e)}
              />
            </Form.Item>
            <Form.Item
              required
              // name='cityForm'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter city!',
              //   },
              // ]}
              label='1.8 City:'
            >
              <input
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: '100%',
                  outline: 'none',
                }}
                type='text'
                name='city'
                value={firmData.firmAddress.city}
                onChange={(e) => handleChange(firmData.firmAddress, e)}
              />
            </Form.Item>
            <Form.Item required label='1.9 Municipality:'>
              <input
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: '100%',
                  outline: 'none',
                }}
                type='text'
                name='municipality'
                value={firmData.firmAddress.municipality}
                onChange={(e) => handleChange(firmData.firmAddress, e)}
              />
            </Form.Item>
            <Form.Item required label='1.10 Zip code:'>
              <input
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: '100%',
                  outline: 'none',
                }}
                type='text'
                name='zipCode'
                value={firmData.firmAddress.zipCode}
                onChange={(e) => handleChange(firmData.firmAddress, e)}
              />
            </Form.Item>
            <Form.Item required label='1.11 Country:'>
              <Select
                showSearch
                // defaultValue='Serbia'
                style={{
                  width: 140,
                }}
                value={firmData.firmAddress.country}
                name='country'
                onSelect={(values) => handleFirmCountry(values)}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {countries.map((country, id) => {
                  return (
                    <Option key={id} value={country}>
                      {country}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              required
              // name='codeNumForm'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter code of activity number!',
              //   },
              // ]}
              label='1.12 Select code of activity:'
            >
              <input
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: 70,
                  outline: 'none',
                }}
                type='number'
                name='codeNum'
                value={firmData.codeActivity.codeNum}
                onChange={(e) => handleChange(firmData.codeActivity, e)}
              />
              <input
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: 150,
                  marginLeft: 10,
                  outline: 'none',
                }}
                type='text'
                name='codeDesc'
                value={firmData.codeActivity.codeDesc}
                onChange={(e) => handleChange(firmData.codeActivity, e)}
              />
            </Form.Item>
            <Form.Item
              required
              // name='shareholdingValueForm'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter value of shareholding capital!',
              //   },
              // ]}
              label='1.13 Value of shareholding capital:'
            >
              <input
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: 150,
                  outline: 'none',
                }}
                type='number'
                name='shareholdingValue'
                value={firmData.shareholdingValue}
                onChange={(e) => handleChange(firmData, e)}
                min={100}
              />
              {'  '}
              RSD
            </Form.Item>
            <Form.Item
              required
              // name='email'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter email!',
              //   },
              // ]}
              label='1.14 Email:'
            >
              <input
                required
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  padding: '4px 11px',
                  width: '100%',
                  outline: 'none',
                }}
                type='email'
                name='email'
                onChange={(e) => handleChange(firmData, e)}
              />
            </Form.Item>
            <p>
              <b>2. Directors</b>
            </p>
            <Form.Item label='Number of directors:'>
              <Select
                defaultValue='1'
                style={{
                  width: 60,
                }}
                onChange={handleDirectorNum}
              >
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
              </Select>
            </Form.Item>
            {handleDirectorDisplay()}
            <p>
              <b>3. Shareholders</b>
            </p>
            <Form.Item label='Number of shareholders:'>
              <Select
                defaultValue='1'
                style={{
                  width: 60,
                }}
                onChange={handleShareholderNum}
              >
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
              </Select>
            </Form.Item>
            <Form.Item label='Type:'>
              <Select
                // value={type}
                style={{
                  width: 150,
                }}
                onChange={(value) => {
                  if (value === 'natural person') {
                    setShareholder1Type('natural person');
                    setShareholder2Type('natural person');
                    setShareholder3Type('natural person');
                    setShareholder4Type('natural person');
                    setShareholder5Type('natural person');
                  } else if (value === 'company') {
                    setShareholder1Type('company');
                    setShareholder2Type('company');
                    setShareholder3Type('company');
                    setShareholder4Type('company');
                    setShareholder5Type('company');
                  }
                }}
              >
                <Option value='natural person'>Natural Person</Option>
                <Option value='company'>Company</Option>
              </Select>
            </Form.Item>
            {handleShareholderDisplay()}

            <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }}>
              Submit
            </Button>
            {/* <Button type='primary' style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}>
              Save
            </Button> */}
          </Form>
        </Card>
      </div>
    </div>
  );
}
