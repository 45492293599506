import React, { useMemo } from 'react';
import { HrLogoIcon } from '../icons';

export default function HrLogoSeparator({ theme }) {
  const separatorStyle = useMemo(() => {
    return {
      borderTop: `2px solid ${theme === 'light' ? '#454f54' : '#2db1ab'}`,
      borderBottom: `2px solid ${theme === 'light' ? '#454f54' : '#2db1ab'}`,
    };
  }, [theme]);
  return (
    <div className='hr-logo-separator-temporary__wrapper'>
      <div className='hr-logo-separator__wrapper'>
        <div className='elements-group__wrapper' style={separatorStyle}>
          <span className='horizontal-line__element'></span>
          <HrLogoIcon theme={theme}/>
          <span className='vertical-line__element'></span>
          <span className='hr-text__element'>HR</span>
          <span className='horizontal-line__element'></span>
        </div>
      </div>
    </div>
  );
}
