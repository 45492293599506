import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import Axios from 'axios';
import { SERVER_URL } from '../config';
import moment from 'moment';
import { notification } from 'antd';

const SET_TERMINATE_EMPLOYMENT = 'SET_TERMINATE_EMPLOYMENT';
const UPDATE_TERMINATE_EMPLOYMENT = 'UPDATE_TERMINATE_EMPLOYMENT';
const REMOVE_TERMINATE_EMPLOYMENT = 'REMOVE_TERMINATE_EMPLOYMENT';
const RESET_TERMINATE_EMPLOYMENT = 'RESET_TERMINATE_EMPLOYMENT';

const SET_EXTEND_EMPLOYMENT = 'SET_EXTEND_EMPLOYMENT';
const UPDATE_EXTEND_EMPLOYMENT = 'UPDATE_EXTEND_EMPLOYMENT';
const REMOVE_EXTEND_EMPLOYMENT = 'REMOVE_EXTEND_EMPLOYMENT';
const RESET_EXTEND_EMPLOYMENT = 'RESET_EXTEND_EMPLOYMENT';

const terminateEmploymentReducer = (state, action) => {
  switch (action.type) {
    case SET_TERMINATE_EMPLOYMENT:
      return { ...state, ...action.payload };
    case UPDATE_TERMINATE_EMPLOYMENT:
      return { ...state, [action.payload.key]: action.payload.value };
    case REMOVE_TERMINATE_EMPLOYMENT:
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    case RESET_TERMINATE_EMPLOYMENT:
      return {};
    default:
      return state;
  }
};

const extendEmploymentReducer = (state, action) => {
  switch (action.type) {
    case SET_EXTEND_EMPLOYMENT:
      return { ...state, ...action.payload };
    case UPDATE_EXTEND_EMPLOYMENT:
      return { ...state, [action.payload.key]: action.payload.value };
    case REMOVE_EXTEND_EMPLOYMENT:
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    case RESET_EXTEND_EMPLOYMENT:
      return {};
    default:
      return state;
  }
};

const TimesheetContext = createContext({
  terminateEmploymentFormData: {},
});

const TimesheetContextDispatch = createContext({
  setTerminateEmploymentData: () => {},
  updateTerminateEmploymentData: () => {},
  removeTerminateEmploymentData: () => {},
  resetTerminateEmploymentData: () => {},
  handleSubmitTerminateEmployment: async () => {},
  setExtendEmploymentData: () => {},
  updateExtendEmploymentData: () => {},
  removeExtendEmploymentData: () => {},
  resetExtendEmploymentData: () => {},
  handleSubmitExtendEmployment: async () => {},
});

export const useTimesheetContext = () => useContext(TimesheetContext);
export const useTimesheetContextDispatch = () => useContext(TimesheetContextDispatch);

export const TimesheetProvider = ({ children }) => {
  const [terminateEmploymentFormData, dispatch] = useReducer(terminateEmploymentReducer, {});
  const [extendEmploymentFormData, dispatchExtension] = useReducer(extendEmploymentReducer, {});

  const setTerminateEmploymentData = useCallback((terminateEmploymentData) => {
    dispatch({ type: SET_TERMINATE_EMPLOYMENT, payload: terminateEmploymentData });
  }, []);

  const updateTerminateEmploymentData = useCallback((key, value) => {
    dispatch({ 
      type: UPDATE_TERMINATE_EMPLOYMENT, 
      payload: { key, value } 
    });
  }, []);

  const removeTerminateEmploymentData = useCallback((key) => {
    dispatch({ type: REMOVE_TERMINATE_EMPLOYMENT, payload: key });
  }, []);

  const resetTerminateEmploymentData = useCallback(() => {
    dispatch({ type: RESET_TERMINATE_EMPLOYMENT });
  }, []);

  const setExtendEmploymentData = useCallback((extendEmploymentData) => {
    dispatchExtension({ type: SET_EXTEND_EMPLOYMENT, payload: extendEmploymentData });
  }, []);

  const updateExtendEmploymentData = useCallback((key, value) => {
    dispatchExtension({ 
      type: UPDATE_EXTEND_EMPLOYMENT, 
      payload: { key, value } 
    });
  }, []);

  const removeExtendEmploymentData = useCallback((key) => {
    dispatchExtension({ type: REMOVE_EXTEND_EMPLOYMENT, payload: key });
  }, []);

  const resetExtendEmploymentData = useCallback(() => {
    dispatchExtension({ type: RESET_EXTEND_EMPLOYMENT });
  }, []);

  const values = useMemo(
    () => ({
      terminateEmploymentFormData,
      extendEmploymentFormData,
    }),
    [terminateEmploymentFormData, extendEmploymentFormData],
  );

  const handleSubmitTerminateEmployment = useCallback(async (formData, currentuser) => {
    if (formData.attachments.length !== 0) {
      let keys = [];
      formData.attachments.forEach((attachment) => {
        keys.push(attachment.location);
      });
       const zipData = {
         keys: keys,
         employee: formData.employeeObj,
       };
       try {
         await Axios.post(`${SERVER_URL}/zip-employee`, zipData, {
           withCredentials: false,
           headers: { Authorization: `Bearer ${currentuser.data.token}` },
         }).then(async (res) => {
          if (res.data.message === 'Zip file created') {
            await Axios.get(`${SERVER_URL}/file/zip/${formData.employeeObj.firstName}_${formData.employeeObj.lastName}_${formData.employeeObj.JMBG}.zip`, {
              withCredentials: false,
            }).then(async (zipResponse) => {
              if (zipResponse.status === 200) {
                await Axios.post(
                  `${SERVER_URL}/terminate-employee`, formData,
                  {
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${currentuser.data.token}` },
                  }
                ).then(async (terminateResponse) => {
                  if (terminateResponse.data.message === 'Employment terminated and emails sent!') {
                    await Axios.post(`${SERVER_URL}/salary-on-terminate`, {
                      employee: formData.employee,
                      endDate: formData.endDate,
                      month: moment(moment(formData.endDate).format('M'), 'M').format('MMMM'),
                      year: moment(moment(formData.endDate).format('YYYY'), 'YYYY').format('YYYY'),
                    }, {
                      withCredentials: false,
                      headers: { Authorization: `Bearer ${currentuser.data.token}` },
                    })
                  }
                }).then(async (salaryTerminateResponse) => {
                      if (salaryTerminateResponse.data.message === 'Salary is changed' 
                        || salaryTerminateResponse.data.message === 'Nothing to change') {
                        notification.success({
                          message: 'Employee is terminated.',
                          placement: 'bottomRight',
                        });
                      }
                    }).catch((salaryError) => {
                      console.log("Salary change error", salaryError)
                    }).catch((terminateErr) => {
                  console.log("Terminate err: ", terminateErr)
                });
              }
            }).catch(zipErr => console.log('ZipErr: ', zipErr))
          }
         }).catch((error) => {
          console.log('error in creating zip-employee', error)
         });
       } catch (error) {
          console.log('error in creating zip-employee', error)
       }
    }

    if (formData.attachments.length === 0) {
      try {
        await Axios.post(
            `${SERVER_URL}/terminate-employee`, formData,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            }
        ).then(async (response) => {
          if (response.data.message === 'Employment terminated and emails sent!') {
              await Axios.post(`${SERVER_URL}/salary-on-terminate`, {
                employee: formData.employee,
                endDate: formData.endDate,
                month: moment(moment(formData.endDate).format('M'), 'M').format('MMMM'),
                year: moment(moment(formData.endDate).format('YYYY'), 'YYYY').format('YYYY'),
              }, {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
              }).then((salaryTerminateResponse) => {
                if (salaryTerminateResponse.data.message === 'Salary is changed' || salaryTerminateResponse.data.message === 'Nothing to change') {
                  notification.success({
                    message: 'Employee is terminated.',
                    placement: 'bottomRight',
                  });
                }
              }).catch((salaryError) => {
                console.log("Salary change error", salaryError)
              });
            }
          });
      } catch (error) {
        console.log('error in termination employment submit', error)
      }
    }
  }, []);

  const handleSubmitExtendEmployment = useCallback(async (formData, currentuser) => {
    formData.user = currentuser.data;
    let keys = [];
    if (formData.attachments.length !== 0) {
      formData.attachments.forEach((attachment) => {
        keys.push(attachment.location);
      });
      const zipData = {
        keys: keys,
        employee: formData.employeeObj,
      };
      try {
        await Axios.post(`${SERVER_URL}/zip-employee`, zipData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } catch (error) {
        console.log('error in creating zip-employee', error)
      }

      try {
        await Axios.get(`${SERVER_URL}/file/zip/${formData.employeeObj.firstName}_${formData.employeeObj.lastName}_${formData.employeeObj.JMBG}.zip`, {
          withCredentials: false,
        });
      } catch (error) {
        console.log('error in creating file/zip for employee extension', error)
      }
    }

    try {
      await Axios.post(
          `${SERVER_URL}/extend-employee`, formData,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          }
      );

    } catch (error) {
      console.log('error in extend employment submit', error)
    }

    try {
      await Axios.post(`${SERVER_URL}/salary-on-extend`, {
        employee: formData.employee,
        employmentType: formData.employmentType,
        endDate: formData.endDate,
        month: moment(moment(formData.endDate).format('M'), 'M').format('MMMM'),
        year: moment(moment(formData.endDate).format('YYYY'), 'YYYY').format('YYYY'),
      }, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Employee is extended.',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log('error in updating salary upon employment extension', error)
    }
  }, []);

  const functions = useMemo(
    () => ({
      setTerminateEmploymentData,
      updateTerminateEmploymentData,
      removeTerminateEmploymentData,
      resetTerminateEmploymentData,
      handleSubmitTerminateEmployment,
      setExtendEmploymentData,
      updateExtendEmploymentData,
      removeExtendEmploymentData,
      resetExtendEmploymentData,
      handleSubmitExtendEmployment
    }),
    [
      setTerminateEmploymentData,
      updateTerminateEmploymentData,
      removeTerminateEmploymentData,
      resetTerminateEmploymentData,
      handleSubmitTerminateEmployment,
      setExtendEmploymentData,
      updateExtendEmploymentData,
      removeExtendEmploymentData,
      resetExtendEmploymentData,
      handleSubmitExtendEmployment
    ]
  );

  return (
    <TimesheetContext.Provider value={values}>
      <TimesheetContextDispatch.Provider value={functions}>
        {children}
      </TimesheetContextDispatch.Provider>
    </TimesheetContext.Provider>
  );
};
