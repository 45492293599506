import React from 'react';
import { Form, Input, Radio, Select } from 'antd';

const { Option } = Select;

const DisplayShareholderCompanyShareholder = ({
  shareholderData,
  citizenship,
  handleChange,
  handleRadioChange,
  handleShareholderCountry,
  countries,
  shareholderNumeration,
  companyShareholderNum,
}) => {
  return (
    <>
      <Form.Item label='Citizenship:'>
        <Radio.Group name='citizenship' defaultValue={citizenship} onChange={handleRadioChange}>
          <Radio value='serbian'>Serbian Citizen</Radio>
          <Radio value='foreign'>Foreign Citizen</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={`${companyShareholderNum}${shareholderNumeration}.1 Ownership Percentage:`}>
        <input
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: 70,
            outline: 'none',
          }}
          type='number'
          name='ownershipPercentage'
          value={shareholderData?.ownershipPercentage}
          onChange={(e) => handleChange(shareholderData, e)}
        />{' '}
        %
      </Form.Item>
      <Form.Item label={`${companyShareholderNum}${shareholderNumeration}.2 First Name:`}>
        <input
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='firstName'
          value={shareholderData?.firstName}
          onChange={(e) => handleChange(shareholderData, e)}
        />
      </Form.Item>
      <Form.Item label={`${companyShareholderNum}${shareholderNumeration}.3 Last Name:`}>
        <input
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='lastName'
          value={shareholderData?.lastName}
          onChange={(e) => handleChange(shareholderData, e)}
        />
      </Form.Item>
      {citizenship === 'serbian' ? (
        <>
          <Form.Item label={`${companyShareholderNum}${shareholderNumeration}.4 ID number:`}>
            <input
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='number'
              name='idNum'
              value={shareholderData?.idNum}
              onChange={(e) => handleChange(shareholderData, e)}
            />
          </Form.Item>
          <Form.Item label={`${companyShareholderNum}${shareholderNumeration}.5 JMBG:`}>
            <input
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='number'
              name='jmbg'
              value={shareholderData?.jmbg}
              onChange={(e) => handleChange(shareholderData, e)}
            />
          </Form.Item>
        </>
      ) : citizenship === 'foreign' ? (
        <>
          <Form.Item label={`${companyShareholderNum}${shareholderNumeration}.4 Passport number:`}>
            <input
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='number'
              name='passportNum'
              value={shareholderData?.passportNum}
              onChange={(e) => handleChange(shareholderData, e)}
            />
          </Form.Item>
        </>
      ) : (
        ''
      )}
      <p>Address:</p>
      <Form.Item
        label={`${companyShareholderNum}${shareholderNumeration}.${citizenship === 'serbian' ? '6' : '5'} Street:`}
      >
        <input
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='street'
          value={shareholderData?.address.street}
          onChange={(e) => handleChange(shareholderData.address, e)}
        />
      </Form.Item>
      <Form.Item
        label={`${companyShareholderNum}${shareholderNumeration}.${
          citizenship === 'serbian' ? '7' : '6'
        } Street Number:`}
      >
        <input
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='streetNum'
          value={shareholderData?.address.streetNum}
          onChange={(e) => handleChange(shareholderData.address, e)}
        />
      </Form.Item>
      <Form.Item
        label={`${companyShareholderNum}${shareholderNumeration}.${
          citizenship === 'serbian' ? '8' : '7'
        } Apartment Number:`}
      >
        <Input
          type='text'
          name='aptNum'
          value={shareholderData?.address.aptNum}
          onChange={(e) => handleChange(shareholderData.address, e)}
        />
      </Form.Item>
      <Form.Item
        label={`${companyShareholderNum}${shareholderNumeration}.${citizenship === 'serbian' ? '9' : '8'} City`}
      >
        <input
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='city'
          value={shareholderData?.address.city}
          onChange={(e) => handleChange(shareholderData.address, e)}
        />
      </Form.Item>
      <Form.Item
        label={`${companyShareholderNum}${shareholderNumeration}.${citizenship === 'serbian' ? '10' : '9'} Country:`}
      >
        <Select
          showSearch
          style={{
            width: 140,
          }}
          value={shareholderData?.address.country}
          name='country'
          onSelect={(values) => handleShareholderCountry(values)}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
          {countries.map((country, id) => {
            return (
              <Option key={id} value={country}>
                {country}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export default DisplayShareholderCompanyShareholder;
