import React, { useEffect, useContext, useState } from 'react';
import { Select, notification } from 'antd';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import Table from '../../components/tables/HRTable';
import { formatDateForTables } from '../../helpers/dates';

const { Option } = Select;
const TABLE_COLUMN_KEYS = ['firstName', 'lastName', 'email', 'typeOfLeave', 'status', 'createdAt'];

export default function AllRequests() {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [requests, fetchRequests] = useAxios('', [], currentuser.data.token, 'get');
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [companiesFiltered, fetchCompaniesFiltered] = useAxios('', [], currentuser.data.token, 'get');
  const [branches, fetchBranches] = useAxios('', [], currentuser.data.token, 'get');
  const [company, setCompany] = useState(
    localStorage.getItem('selectedCompany') ? localStorage.getItem('selectedCompany') : undefined,
  );
  const [companyTypeOfHr, setCompanyTypeOfHr] = useState('');
  const [userCompany, setUserCompany] = useState('');

  useEffect(() => {
    if (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) {
      if (company) {
        const filter = { $or: [{ _id: company }, { mainCompany: company }] };
        fetchCompaniesFiltered(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
        const companiesId =
          companiesFiltered &&
          companiesFiltered.data &&
          companiesFiltered.data.items &&
          companiesFiltered.data.items.map((item) => item._id);
        const filterData = { company: { $in: companiesId ? companiesId : [] } };
        fetchData(`${SERVER_URL}/data?filter=${JSON.stringify(filterData)}`, []);
      }
      fetchCompanies(`${SERVER_URL}/companies`, []);
    } else if (currentuser.data.role.includes('user') || currentuser.data.role.includes('userOfficer')) {
      let filter;
      if (currentuser.data.role.includes('user') && !currentuser.data.role.includes('userOfficer')) {
        filter = { client: currentuser.data.id };
      }
      if (!currentuser.data.role.includes('user') && currentuser.data.role.includes('userOfficer')) {
        filter = { userOfficer: currentuser.data.id };
      }
      fetchCompaniesFiltered(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      const companiesId =
        companiesFiltered &&
        companiesFiltered.data &&
        companiesFiltered.data.items &&
        companiesFiltered.data.items.map((item) => item._id);
      if (companiesId && companiesId.length !== 0) {
        const filterBranch = { mainCompany: companiesId[0] };
        fetchBranches(`${SERVER_URL}/companies?filter=${JSON.stringify(filterBranch)}`, []);
      }
      branches &&
        branches.data &&
        branches.data.items &&
        branches.data.items.forEach((item) => companiesId.push(item._id));
      const filterData = { company: { $in: companiesId ? companiesId : [] } };
      fetchData(`${SERVER_URL}/data?filter=${JSON.stringify(filterData)}`, []);
    } else if (currentuser.data.role.includes('employee')) {
      const filter = { _id: currentuser.data.id };
      fetchCompaniesFiltered(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      const companiesId =
        companiesFiltered &&
        companiesFiltered.data &&
        companiesFiltered.data.items &&
        companiesFiltered.data.items.map((item) => item._id);
      if (companiesId && companiesId.length !== 0) {
        const filterBranch = { mainCompany: companiesId[0] };
        fetchBranches(`${SERVER_URL}/companies?filter=${JSON.stringify(filterBranch)}`, []);
      }
      branches &&
        branches.data &&
        branches.data.items &&
        branches.data.items.forEach((item) => companiesId.push(item._id));
      const filterData = { company: { $in: companiesId ? companiesId : [] } };
      fetchData(`${SERVER_URL}/data?filter=${JSON.stringify(filterData)}`, []);
      setCompanyTypeOfHr(currentuser?.data?.employeeCompany?.typeOfHr);
    }
  }, [
    fetchData,
    companies,
    fetchCompanies,
    currentuser.data.role,
    currentuser.data.id,
    branches,
    fetchBranches,
    company,
    fetchCompaniesFiltered,
    companiesFiltered,
  ]);
  let companyFilter;
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    companyFilter = data.data.items[0].company._id;
  }
  useEffect(() => {
    if (data && data.data && data.data.items && data.data.items.length > 0) {
      setUserCompany(data.data.items[0].company);
    }
  }, [data]);

  useEffect(() => {
    setCompanyTypeOfHr(userCompany.typeOfHr);
  }, [userCompany]);

  const deleteRequestHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/request/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((r) => {
        notification.success({
          message: 'The request has been deleted.',
          placement: 'bottomRight',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      });
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    const filter = { companyID: company };
    const userFilter = { companyID: companyFilter };
    const employeeFilter = { employee: currentuser.data.employee };
    const hrOfficerFilter = { hrOfficer: currentuser.data.sectorId };
    if (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) {
      fetchRequests(`${SERVER_URL}/request?filter=${JSON.stringify(filter)}`, []);
    } else if (
      (currentuser.data.role.includes('user') || currentuser.data.role.includes('userOfficer')) &&
      companyFilter !== undefined &&
      !currentuser.data.role.includes('hrOfficer')
    ) {
      fetchRequests(`${SERVER_URL}/request?filter=${JSON.stringify(userFilter)}`, []);
    } else if (currentuser.data.role.includes('employee') && !currentuser.data.role.includes('hrOfficer')) {
      fetchRequests(`${SERVER_URL}/request?filter=${JSON.stringify(employeeFilter)}`, []);
    } else if (currentuser.data.role.includes('hrOfficer')) {
      fetchRequests(`${SERVER_URL}/get-requests-by-sector?filter=${JSON.stringify(hrOfficerFilter)}`, []);
    }
  }, [fetchRequests, requests, company, data]);

  let tableData = [];
  if (requests.data && requests.data && requests.data.length > 0) {
    tableData = requests.data.map((item) => {
      const formattedDate = formatDateForTables(item.createdAt);
      const formattedDate2 = formatDateForTables(item.updatedAt);
      return { ...item, createdAt: formattedDate, updatedAt: formattedDate2 };
    });
  }

  let companiesList = [];
  if (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) {
    if (companies?.data?.items) {
      companiesList = companies.data.items;
    }
  } else {
    if (companiesFiltered?.data?.items) {
      companiesList = companiesFiltered.data.items;
    }
  }

  return (
    <>
      {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) && (
        <div>
          <p style={{ marginTop: '20px' }}>Choose company:</p>
          <Select
            allowClear={true}
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(value) => {
              localStorage.setItem('selectedCompany', value);
              setCompany(value);
            }}
            style={{ width: '45%', marginBottom: '20px' }}
            defaultValue={localStorage.getItem('selectedCompany')}
          >
            {companiesList.map((item) => (
              <Option value={item._id} key={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      )}
      <Table
        title='request'
        data={tableData}
        deleteHandler={deleteRequestHandler}
        columnKeys={TABLE_COLUMN_KEYS}
        editPath='/admin/edit-data/'
        viewPath='/admin/view-data/'
        typeOfHr={companyTypeOfHr}
        focusedCompany={userCompany}
      />
    </>
  );
}
