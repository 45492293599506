import { 
  Timeline,
  Switch,
} from 'antd';
import { SERVER_URL } from '../../config';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import TimelineCard from '../custom/cards/TimelineCard';

export default function CareerForm({
  employee,
  userToken,
}) {

  const [careerHistory, setCareerHistory] = useState([]);

  const [historyOrder, setHistoryOrder] = useState(() => {
    const savedOrder = localStorage.getItem('historyOrder');
    return savedOrder ? savedOrder : 'desc';
  });

  const reorderCareerHistory = (careerHistory, sortOrder) => {
    return careerHistory.slice().sort((a, b) => {
      const dateA = new Date(a.dateOfRequest);
      const dateB = new Date(b.dateOfRequest);

      if (sortOrder === 'asc') {
        return dateA - dateB;
      } else if (sortOrder === 'desc') {
        return dateB - dateA; 
      } else {
        throw new Error('Invalid sortOrder. Use "asc" or "desc".');
      }
    });
  };

  const handleSwitchChange = (checked) => {
    const newOrder = checked ? 'asc' : 'desc';
    setHistoryOrder(newOrder);
    localStorage.setItem('historyOrder', newOrder);
  };

  const getEmployeeHistory = async (employeeId) => {
    const response = await Axios.get(
      `${SERVER_URL}/employee-career-history/${employeeId}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${userToken}` },
      },
    );
    return response.data;
  };

  useEffect(() => {
    if (Object.keys(employee).length) {
      getEmployeeHistory(employee._id).then(data => {
        const orderedHistory = reorderCareerHistory(data, historyOrder);
        setCareerHistory(orderedHistory);
      });
    }
  }, [employee, historyOrder]);


  return (
    <div>
      {careerHistory && careerHistory.length ? 
        <>
          <div>
            <span style={{ marginRight: '10px' }}>
              Career Order: 
            </span>
            
            <Switch 
              checkedChildren="Ascending"  
              unCheckedChildren="Descending"
              onChange={handleSwitchChange}
              checked={historyOrder === 'asc'}
              style={{ margin: '10px 0 20px' }}
            />
          </div>
          
            <Timeline mode="left">
              {careerHistory.map((historyItem) => (
                <TimelineCard key={historyItem._id} history={historyItem} />
              ))}
            </Timeline>
        </>
      : null}
      {careerHistory && !careerHistory.length ? 
        <p>No Career Data</p>
      : null}
      
    </div>
  );
}
