import React, { useContext, useEffect } from 'react';
import { Input, Button, Form, Card, DatePicker, Radio, Space, Row, Col, notification, Divider } from 'antd';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import useAxios from '../../hooks/useAxios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import Axios from 'axios';
import * as FileSaver from 'file-saver';

const { TextArea } = Input;

const ViewData = (props) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const { dataId } = props.match.params;
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');

  let isLimitedEmploymentWithinCurrentYear;
  let isUnlimitedEmployment;

  useEffect(() => {
    if (dataId) {
      fetchData(`${SERVER_URL}/data/${dataId}`, []);
    }
  }, [dataId, fetchData]);

  const calcMethods = {
    automatic: 'Per month (regular work)',
    manual: ['Per month (all days of work)', 'Per month (all days)'],
    fixed: 'Per month (fixed defined value)',
    perDay: ['Per day (regular work)', 'Per day (holiday leave)'],
    monthlyFee: 'Per month (excluding sick leave)',
  };
  let calcMethodNames = {};
  if (data && data.data && data.data.company) {
    calcMethodNames = {
      calculationMethodMeal: Array.isArray(calcMethods[data.data.company.calculationMethodMeal])
          ? calcMethods[data.data.company.calculationMethodMeal][0]
          : calcMethods[data.data.company.calculationMethodMeal],
      calculationMethodHoliday: Array.isArray(calcMethods[data.data.company.calculationMethodHoliday])
          ? data.data.company.calculationMethodHoliday === 'perDay'
              ? calcMethods[data.data.company.calculationMethodHoliday][1]
              : calcMethods[data.data.company.calculationMethodHoliday][0]
          : calcMethods[data.data.company.calculationMethodHoliday],
      travelExpenses: Array.isArray(calcMethods[data.data.company.travelExpenses])
          ? calcMethods[data.data.company.travelExpenses][1]
          : calcMethods[data.data.company.travelExpenses],
      workFromHome: Array.isArray(calcMethods[data.data.company.calculationMethodWorkFromHome])
          ? calcMethods[data.data.company.calculationMethodWorkFromHome][1]
          : calcMethods[data.data.company.calculationMethodWorkFromHome],
    };
  }
  if (data.data) {
    isLimitedEmploymentWithinCurrentYear = moment(data.data.endDate) && moment(data.data.endDate).isSame(moment(), 'year');
    isUnlimitedEmployment = !data.data.endDate;
    form.setFieldsValue({
      company: data.data.company && data.data.company.name,
      firstName: data.data.firstName,
      lastName: data.data.lastName,
      JMBG: data.data.JMBG,
      email: data.data.email,
      position: data.data.position,
      city: data.data.city,
      address: data.data.address,
      bankAccount: data.data.bankAccount,
      startDate: moment(data.data.startDate),
      endDate: moment(data.data.endDate),
      employmentType: data.data.employmentType,
      workTimeType: data.data.workTimeType,
      numberOfHolidayDays: data.data.numberOfHolidayDays,
      numberOfHolidayDaysPast: data.data.numberOfHolidayDaysPast,
      numberOfHolidayDaysUsed: data.data.numberOfHolidayDaysUsed,
      numberOfHolidayDaysContract: data.data.numberOfHolidayDaysContract,
      numberOfHolidayDaysByEndOfContract: data.data.numberOfHolidayDaysByEndOfContract,
      hotMeal: data.data.hotMeal,
      regres: data.data.regres,
      travelExpenses: data.data.travelExpenses,
      workFromHome: data.data.workFromHome,
      otherRemark: data.data.otherRemark,
    });
  }

  const attachments = data.data.attachments;

  const downloadFiles = async () => {
    let keys = [];
    for (let i = 0; i < attachments.length; i++) {
      keys.push(attachments[i].location);
    }
    if (keys.length !== 0) {
      await Axios.post(
          `${SERVER_URL}/zip-file`,
          { keys: keys, user: currentuser.data.id },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
      );
      setTimeout(async () => {
        const response = await Axios.get(`${SERVER_URL}/file/zip/${currentuser.data.id}.zip`, {
          withCredentials: false,
        });
        FileSaver.saveAs(response.config.url, 'Attach - ' + data.data.firstName + ' ' + data.data.lastName + '.zip');
        document.getElementById('clientDownload').disabled = false;
      }, 5000);
    }
  };

  return (
      <div className='content-wrapper'>
        <div className='actions-block'>
          <Link to='/admin/data'>
            <Button type='primary'>All employees</Button>
          </Link>
        </div>
        <div className='dashboard'>
          <div style={{ width: '80%' }}>
            <Card title={"OVERVIEW OF EMPLOYEE'S DATA"} bordered={false}>
              <Form className='form-horizontal dataForm' layout='horizontal' form={form} labelAlign='left'>
                <Form.Item label='Company' name='company' className='employeeField' labelCol={{ span: 6 }}>
                  <Input disabled />
                </Form.Item>

                <Form.Item label='Name' name='firstName' className='employeeField' labelCol={{ span: 6 }}>
                  <Input disabled />
                </Form.Item>

                <Form.Item label='Surname' name='lastName' className='employeeField' labelCol={{ span: 6 }}>
                  <Input disabled />
                </Form.Item>

                <Form.Item label='Personal number' name='JMBG' labelCol={{ span: 6 }}>
                  <Input disabled />
                </Form.Item>

                <Form.Item label='Email' name='email' className='employeeField' labelCol={{ span: 6 }}>
                  <Input disabled />
                </Form.Item>

                <Form.Item label='Position' name='position' className='employeeField' labelCol={{ span: 6 }}>
                  <Input disabled />
                </Form.Item>

                <Form.Item label='Address' name='address' className='employeeField' labelCol={{ span: 6 }}>
                  <Input disabled />
                </Form.Item>

                <Form.Item className='employeeField' label='City' name='city' labelCol={{ span: 6 }}>
                  <Input disabled />
                </Form.Item>

                <Form.Item label='Bank account number' name='bankAccount' labelCol={{ span: 6 }}>
                  <Input disabled />
                </Form.Item>

                <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />

                <Form.Item label='Start date of work' name='startDate'>
                  <DatePicker disabled format={'DD-MM-YYYY'} />
                </Form.Item>

                {data && data.data && data.data.endDate && (
                    <Form.Item label='End date of work' name='endDate'>
                      <DatePicker disabled format={'DD-MM-YYYY'} style={{marginLeft: '7px'}}/>
                    </Form.Item>
                )}

                <Form.Item name='employmentType'>
                  <Radio.Group disabled>
                    <Space direction='vertical'>
                      <Radio value='unlimited'>Unlimited employment</Radio>
                      <Radio value='limited'>Limited employment</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name='workTimeType'>
                  <Radio.Group disabled>
                    <Space direction='vertical'>
                      <Radio value='fullTime'>Full time</Radio>
                      <Radio value='halfTime'>50% of work time</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>

                <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />

                <Form.Item
                    label='Holiday days left from past year'
                    labelCol={{ span: 7 }}
                    name='numberOfHolidayDaysPast'
                    style={{display:
                          form.getFieldValue('numberOfHolidayDaysPast')
                              ? 'block' : 'none'}}
                >
                  <Input type='number' style={{ width: '10%' }} disabled />
                </Form.Item>
                <Form.Item
                    label={isLimitedEmploymentWithinCurrentYear && !isUnlimitedEmployment ? 'Holiday days used from current contract' : 'Holiday days used from current year'}
                    labelCol={{ span: 7 }}
                    name='numberOfHolidayDaysUsed'
                >
                  <Input type='number' style={{ width: '10%' }} disabled />
                </Form.Item>
                <Form.Item
                    label='Holiday days defined in contract'
                    labelCol={{ span: 7 }}
                    name='numberOfHolidayDaysContract'
                    style={{display:
                          form.getFieldValue('numberOfHolidayDaysContract')
                              ? 'block' : 'none'}}
                >
                  <Input type='number' style={{ width: '10%' }} disabled />
                </Form.Item>

                <Form.Item label='Total holiday days left up to year end' labelCol={{ span: 7 }} name='numberOfHolidayDays'>
                  <Input type='number' style={{ width: '10%' }} disabled />
                </Form.Item>

                <Form.Item
                    label='Total days left by limited contract'
                    labelCol={{ span: 7 }}
                    name='numberOfHolidayDaysByEndOfContract'
                    style={{display:
                          form.getFieldValue('numberOfHolidayDaysByEndOfContract')
                              ? 'block' : 'none'}}
                >
                  <Input type='number' style={{ width: '10%' }} disabled />
                </Form.Item>

                <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />

                <Row type='flex' gutter={10}>
                  <Col xs={12} md={7}>
                    <Form.Item label='Meal allowance' name='hotMeal' labelCol={{ span: 17 }}>
                      <Input type='number' disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={2}>
                    <Input
                        value={
                          data.data && data.data.company && data.data.company.mealCurrency && data.data.mealCurrency
                              ? data.data.company.mealCurrency.toUpperCase()
                              : 'RSD'
                        }
                        style={{ pointerEvents: 'none', textAlign: 'center' }}
                        disabled
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                      <TextArea
                          autoSize={{ maxRows: 2 }}
                          value={
                            data.data.company && data.data.company.calculationMethodMeal
                                ? !data.data.company.calculationMethodMeal.includes('perDay')
                                    ? 'per month'
                                    : 'per day'
                                : undefined
                          }
                          style={{ pointerEvents: 'none' }}
                          disabled
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={11}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: '5px', marginLeft: '30px', width: '35%' }}>calculation method</span>
                      <TextArea
                          autoSize={{ maxRows: 2 }}
                          value={data.data.company && calcMethodNames.calculationMethodMeal}
                          style={{ pointerEvents: 'none' }}
                          disabled
                      />
                    </div>
                  </Col>
                </Row>

                <Row type='flex' gutter={10}>
                  <Col xs={12} md={7}>
                    <Form.Item label='Holiday allowance' name='regres' labelCol={{ span: 17 }}>
                      <Input type='number' disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={2}>
                    <Input
                        value={
                          data.data && data.data.company && data.data.company.holidayCurrency && data.data.holidayCurrency
                              ? data.data.company.holidayCurrency.toUpperCase()
                              : 'RSD'
                        }
                        style={{ pointerEvents: 'none', textAlign: 'center' }}
                        disabled
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                      <TextArea
                          autoSize={{ maxRows: 2 }}
                          value={
                            data.data.company && data.data.company.calculationMethodHoliday
                                ? !data.data.company.calculationMethodHoliday.includes('perDay')
                                    ? 'per month'
                                    : 'per day'
                                : undefined
                          }
                          style={{ pointerEvents: 'none' }}
                          disabled
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={11}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: '5px', marginLeft: '30px', width: '35%' }}>calculation method</span>
                      <TextArea
                          autoSize={{ maxRows: 2 }}
                          value={data.data.company && calcMethodNames.calculationMethodHoliday}
                          style={{ pointerEvents: 'none' }}
                          disabled
                      />
                    </div>
                  </Col>
                </Row>

                <Row type='flex' gutter={10}>
                  <Col xs={12} md={7}>
                    <Form.Item label='Travel expenses' name='travelExpenses' labelCol={{ span: 17 }}>
                      <Input type='number' disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={2}>
                    <Input
                        value={
                          data.data &&
                          data.data.company &&
                          data.data.company.travelExpCurrency &&
                          data.data.travelExpCurrency
                              ? data.data.company.travelExpCurrency.toUpperCase()
                              : 'RSD'
                        }
                        style={{ pointerEvents: 'none', textAlign: 'center' }}
                        disabled
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                      <TextArea
                          autoSize={{ maxRows: 2 }}
                          value={
                            data.data.company && data.data.company.travelExpenses
                                ? !data.data.company.travelExpenses.includes('perDay')
                                    ? 'per month'
                                    : 'per day'
                                : undefined
                          }
                          style={{ pointerEvents: 'none' }}
                          disabled
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={11}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: '5px', marginLeft: '30px', width: '35%' }}>calculation method</span>
                      <TextArea
                          autoSize={{ maxRows: 2 }}
                          value={data.data.company && calcMethodNames.travelExpenses}
                          style={{ pointerEvents: 'none' }}
                          disabled
                      />
                    </div>
                  </Col>
                </Row>

                {data.data.company?.workFromHomeType === '1' && (
                    <Row type='flex' gutter={10}>
                      <Col xs={12} md={7}>
                        <Form.Item label='Work from home 1' labelCol={{ span: 17 }} name='workFromHome'>
                          <Input type='number' disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={2}>
                        <Input
                            value={
                              data.data.company && data.data.company.workFromHomeCurrency
                                  ? data.data.company.workFromHomeCurrency.toUpperCase()
                                  : 'RSD'
                            }
                            style={{ pointerEvents: 'none', textAlign: 'center' }}
                            disabled
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                          <TextArea
                              autoSize={{ maxRows: 2 }}
                              value={
                                data.data.company && data.data.company.travelExpenses
                                    ? !data.data.company.travelExpenses.includes('perDay')
                                        ? 'per month'
                                        : 'per day'
                                    : undefined
                              }
                              style={{ pointerEvents: 'none' }}
                              disabled
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={11}>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginRight: '5px', marginLeft: '30px', width: '35%' }}>calculation method</span>
                          <TextArea
                              autoSize={{ maxRows: 2 }}
                              value={data.data.company && calcMethodNames.workFromHome}
                              style={{ pointerEvents: 'none' }}
                              disabled
                          />
                        </div>
                      </Col>
                    </Row>
                )}
                {data.data.company?.workFromHomeType === '2' && (
                    <Row type='flex' gutter={10}>
                      <Col xs={12} md={7}>
                        <Form.Item label='Work from home 2' labelCol={{ span: 17 }} name='workFromHome'>
                          <Input type='number' disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={2}>
                        <Input
                            value={
                              data.data.company && data.data.company.workFromHomeCurrency
                                  ? data.data.company.workFromHomeCurrency.toUpperCase()
                                  : 'RSD'
                            }
                            style={{ pointerEvents: 'none', textAlign: 'center' }}
                            disabled
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                          <TextArea
                              autoSize={{ maxRows: 2 }}
                              value={
                                data.data.company
                                    ? !data.data.company.calculationMethodWorkFromHome.includes('perDay')
                                        ? 'per month'
                                        : 'per day'
                                    : undefined
                              }
                              style={{ pointerEvents: 'none' }}
                              disabled
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={11}>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginRight: '5px', marginLeft: '30px', width: '35%' }}>calculation method</span>
                          <TextArea
                              autoSize={{ maxRows: 2 }}
                              value={data.data.company && calcMethodNames.workFromHome}
                              style={{ pointerEvents: 'none' }}
                              disabled
                          />
                        </div>
                      </Col>
                    </Row>
                )}

                {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) &&
                    attachments &&
                    attachments.length !== 0 && (
                        <div>
                          <Button
                              id='clientDownload'
                              size='small'
                              icon={<DownloadOutlined />}
                              onClick={() => {
                                document.getElementById('clientDownload').disabled = true;
                                notification.info({
                                  message: 'Download in progress.',
                                  placement: 'bottomRight',
                                });
                                downloadFiles();
                              }}
                          >
                            Download files
                          </Button>
                        </div>
                    )}
                <Form.Item label='Other remark' name='otherRemark' style={{ marginTop: '15px' }}>
                  <TextArea rows={4} disabled />
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      </div>
  );
};

export default ViewData;
