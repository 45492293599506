import React from 'react';

export default function ArchiveIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_35_389)'>
        <path
          d='M0.625 24.3749V3.12498H8.12497V24.3749H0.625ZM4.37498 18.1249C3.68583 18.1249 3.12499 18.6858 3.12499 19.3749C3.12499 20.0641 3.68583 20.6249 4.37498 20.6249C5.06414 20.6249 5.62498 20.0641 5.62498 19.3749C5.62498 18.6858 5.06414 18.1249 4.37498 18.1249Z'
          fill='#FEDEA1'
        />
        <path d='M15.6249 0.625H8.12494V5.62498H15.6249V0.625Z' fill='#FFB7B2' />
        <path
          d='M8.12494 24.3749V5.62498H15.6249V24.3749H8.12494ZM11.8749 16.8749C11.1858 16.8749 10.6249 17.4358 10.6249 18.1249C10.6249 18.8141 11.1858 19.3749 11.8749 19.3749C12.5641 19.3749 13.1249 18.8141 13.1249 18.1249C13.1249 17.4358 12.5641 16.8749 11.8749 16.8749Z'
          fill='#FF877F'
        />
        <path d='M20.5649 1.66436L16.8719 2.31535L17.5229 6.00828L21.2159 5.35729L20.5649 1.66436Z' fill='#6FB0B6' />
        <path d='M23.8257 20.13L20.1328 20.781L20.7837 24.4735L24.4766 23.8225L23.8257 20.13Z' fill='#6FB0B6' />
        <path
          d='M4.37499 20.6249C5.06535 20.6249 5.62499 20.0653 5.62499 19.3749C5.62499 18.6846 5.06535 18.1249 4.37499 18.1249C3.68464 18.1249 3.125 18.6846 3.125 19.3749C3.125 20.0653 3.68464 20.6249 4.37499 20.6249Z'
          fill='#BDC4D0'
        />
        <path d='M21.2187 5.35796L17.5258 6.00895L20.1297 20.7806L23.8226 20.1297L21.2187 5.35796Z' fill='#538489' />
        <path
          d='M11.8749 19.3749C12.5653 19.3749 13.1249 18.8153 13.1249 18.1249C13.1249 17.4346 12.5653 16.8749 11.8749 16.8749C11.1846 16.8749 10.6249 17.4346 10.6249 18.1249C10.6249 18.8153 11.1846 19.3749 11.8749 19.3749Z'
          fill='#BDC4D0'
        />
        <path
          d='M4.37499 17.4999C3.3396 17.4999 2.5 18.3395 2.5 19.3749C2.5 20.4103 3.3396 21.2499 4.37499 21.2499C5.41039 21.2499 6.24999 20.4103 6.24999 19.3749C6.24999 18.3395 5.41039 17.4999 4.37499 17.4999ZM4.37499 19.9999C4.03041 19.9999 3.75 19.7195 3.75 19.3749C3.75 19.0303 4.03041 18.7499 4.37499 18.7499C4.71957 18.7499 4.99999 19.0303 4.99999 19.3749C4.99999 19.7195 4.71957 19.9999 4.37499 19.9999Z'
          fill='#573A32'
        />
        <path
          d='M11.8749 19.9999C12.9103 19.9999 13.7499 19.1603 13.7499 18.1249C13.7499 17.0895 12.9103 16.2499 11.8749 16.2499C10.8395 16.2499 9.99994 17.0895 9.99994 18.1249C9.99994 19.1603 10.8395 19.9999 11.8749 19.9999ZM11.8749 17.4999C12.2195 17.4999 12.4999 17.7804 12.4999 18.1249C12.4999 18.4695 12.2195 18.7499 11.8749 18.7499C11.5304 18.7499 11.2499 18.4695 11.2499 18.1249C11.2499 17.7804 11.5304 17.4999 11.8749 17.4999Z'
          fill='#573A32'
        />
        <path
          d='M14.9999 0H8.74997C8.05954 0 7.49997 0.559568 7.49997 1.25V2.49999H1.25C0.559568 2.49999 0 3.05956 0 3.74999V23.7499C0 24.4403 0.559568 24.9999 1.25 24.9999H7.49997C7.73288 24.9999 7.9383 24.9191 8.12497 24.8083C8.31164 24.9191 8.51745 24.9999 8.74997 24.9999H14.9999C15.6904 24.9999 16.2499 24.4403 16.2499 23.7499V1.25C16.2499 0.559568 15.6904 0 14.9999 0ZM1.25 23.7499V3.74999H7.49997V23.7499H1.25ZM14.9999 23.7499H8.74997V6.24998H14.9999V23.7499ZM14.9999 4.99998H8.74997V1.25H14.9999V4.99998Z'
          fill='#573A32'
        />
        <path
          d='M21.2908 2.17123C21.1707 1.49164 20.5225 1.03749 19.8424 1.15746L17.3808 1.59164C16.7012 1.71166 16.247 2.35995 16.367 3.03998L20.057 23.967C20.177 24.647 20.8253 25.1007 21.5049 24.9808L23.9669 24.5466C24.6469 24.4266 25.1007 23.7782 24.9807 23.0987L21.2908 2.17123ZM17.5979 2.8225L20.0599 2.38832L20.4941 4.85037L18.032 5.28455L17.5979 2.8225ZM18.2487 6.51579L20.7107 6.08162L23.0982 19.6228L20.6362 20.057L18.2487 6.51579ZM21.2879 23.7495L20.8537 21.2875L23.3158 20.8533L23.7499 23.3153L21.2879 23.7495Z'
          fill='#573A32'
        />
        <path d='M6.24999 7.49997H2.5V8.74996H6.24999V7.49997Z' fill='#573A32' />
      </g>
      <defs>
        <clipPath id='clip0_35_389'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
