import React, { useState, useEffect, useContext } from 'react';
import { Form, Card, notification } from 'antd';
import { UserContext } from '../App';
import { SERVER_URL } from '../config';
import Axios from 'axios';

const HrDisplay = (props) => {
  const currentuser = useContext(UserContext);
  const [requests, setRequests] = useState();
  const { requestId } = props.match.params;

  const fetchRequests = async () => {
    try {
      const data = await Axios.get(`${SERVER_URL}/request/${requestId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (data && data.data && data.data.request) {
        setRequests(data.data.request);
      }
    } catch (err) {
      console.log('err', err);
      notification.error({
        message: 'Problem with fetching the data. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  const displayForm = () => {
    if (requests?.typeOfLeave === 'Annual leave') {
      return (
        <>
          <Form.Item label='Name' name='firstName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.firstName}</p>
          </Form.Item>
          <Form.Item label='Surname' name='lastName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.lastName}</p>
          </Form.Item>
          <Form.Item label='Email' name='email' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.email}</p>
          </Form.Item>
          <Form.Item label='Status of leave' name='status' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.status}</p>
          </Form.Item>
          <Form.Item label='Type of leave' name='typeOfLeave' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.typeOfLeave}</p>
          </Form.Item>
          <Form.Item label='Date from' name='dateFrom' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateFrom}</p>
          </Form.Item>
          <Form.Item label='Date to' name='dateTo' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateTo}</p>
          </Form.Item>
          <Form.Item label='Number of days' name='numOfDays' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.numOfDays} days</p>
          </Form.Item>
          <Form.Item label='First day back' name='firstDayBack' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.firstDayBack}</p>
          </Form.Item>
        </>
      );
    } else if (requests?.typeOfLeave === 'Paid days off' || requests?.typeOfLeave === 'Unpaid days off') {
      return (
        <>
          <Form.Item label='Name' name='firstName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.firstName}</p>
          </Form.Item>
          <Form.Item label='Surname' name='lastName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.lastName}</p>
          </Form.Item>
          <Form.Item label='Email' name='email' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.email}</p>
          </Form.Item>
          <Form.Item label='Status of leave' name='status' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.status}</p>
          </Form.Item>
          <Form.Item label='Type of leave' name='typeOfLeave' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.typeOfLeave}</p>
          </Form.Item>
          <Form.Item label='Reason of leave' name='reasonOfLeave' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.reasonOfLeave}</p>
          </Form.Item>
          <Form.Item label='Date from' name='dateFrom' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateFrom}</p>
          </Form.Item>
          <Form.Item label='Date to' name='dateTo' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateTo}</p>
          </Form.Item>
          <Form.Item label='Number of days' name='numOfDays' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.numOfDays} days</p>
          </Form.Item>
          <Form.Item label='First day back' name='firstDayBack' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.firstDayBack}</p>
          </Form.Item>
        </>
      );
    } else if (requests?.typeOfLeave === 'Maternity leave' || requests?.typeOfLeave === 'Pregnancy leave') {
      return (
        <>
          <Form.Item label='Name' name='firstName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.firstName}</p>
          </Form.Item>

          <Form.Item label='Surname' name='lastName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.lastName}</p>
          </Form.Item>
          <Form.Item label='Email' name='email' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.email}</p>
          </Form.Item>
          <Form.Item label='Status of leave' name='status' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.status}</p>
          </Form.Item>
          <Form.Item label='Type of leave' name='typeOfLeave' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.typeOfLeave}</p>
          </Form.Item>
          <Form.Item label='Date from' name='dateFrom' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateFrom}</p>
          </Form.Item>
          <Form.Item label='Date to' name='dateTo' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateTo}</p>
          </Form.Item>

          <Form.Item label='First day back' name='firstDayBack' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.firstDayBack}</p>
          </Form.Item>
        </>
      );
    } else if (requests?.typeOfLeave === 'Bonus') {
      return (
        <>
          <Form.Item label='Name' name='firstName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.firstName}</p>
          </Form.Item>

          <Form.Item label='Surname' name='lastName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.lastName}</p>
          </Form.Item>
          <Form.Item label='Email' name='email' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.email}</p>
          </Form.Item>
          <Form.Item label='Status of leave' name='status' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.status}</p>
          </Form.Item>
          <Form.Item label='Type of leave' name='typeOfLeave' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.typeOfLeave}</p>
          </Form.Item>
          <Form.Item label='Date from' name='dateFrom' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateFrom}</p>
          </Form.Item>
          <Form.Item label='Date to' name='dateTo' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateTo}</p>
          </Form.Item>
          <Form.Item label='Type of bonus' name='typeBonus' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.typeBonus}</p>
          </Form.Item>
          <Form.Item label='Value' name='valueBonus' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.valueBonus}</p>
          </Form.Item>
          <Form.Item label='Currency' name='currencyBonus' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.currencyBonus}</p>
          </Form.Item>
        </>
      );
    } else if (requests?.typeOfLeave === 'Overtime') {
      return (
        <>
          <Form.Item label='Name' name='firstName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.firstName}</p>
          </Form.Item>

          <Form.Item label='Surname' name='lastName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.lastName}</p>
          </Form.Item>
          <Form.Item label='Email' name='email' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.email}</p>
          </Form.Item>
          <Form.Item label='Status of leave' name='status' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.status}</p>
          </Form.Item>
          <Form.Item label='Type of leave' name='typeOfLeave' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.typeOfLeave}</p>
          </Form.Item>
          <Form.Item label='Date of overtime' name='dateOvertime' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateOvertime}</p>
          </Form.Item>
          <Form.Item label='Basis for overtime' name='basisOvertime' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.basisOvertime}</p>
          </Form.Item>
          <Form.Item
            label='Number of hours worked overtime'
            name='numOfHoursOvertime'
            className='bold-label request-label-width'
          >
            <p style={{ marginTop: '5px' }}>{requests?.numOfHoursOvertime} h</p>
          </Form.Item>
          <Form.Item
            label='Percentage of bonus for overtime'
            name='percentageBonusOvertime'
            className='bold-label request-label-width'
          >
            <p style={{ marginTop: '5px' }}>{requests?.percentageBonusOvertime}</p>
          </Form.Item>
        </>
      );
    } else if (requests?.typeOfLeave === 'Slava - Saint day') {
      return (
        <>
          <Form.Item label='Name' name='firstName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.firstName}</p>
          </Form.Item>
          <Form.Item label='Surname' name='lastName' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.lastName}</p>
          </Form.Item>
          <Form.Item label='Email' name='email' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.email}</p>
          </Form.Item>
          <Form.Item label='Status of leave' name='status' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.status}</p>
          </Form.Item>
          <Form.Item label='Type of leave' name='typeOfLeave' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.typeOfLeave}</p>
          </Form.Item>
          <Form.Item label='Date from' name='dateFrom' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateFrom}</p>
          </Form.Item>
          <Form.Item label='Date to' name='dateTo' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.dateTo}</p>
          </Form.Item>
          <Form.Item label='Number of days' name='numOfDays' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.numOfDays} days</p>
          </Form.Item>
          <Form.Item label='First day back' name='firstDayBack' className='bold-label request-label-width'>
            <p style={{ marginTop: '5px' }}>{requests?.firstDayBack}</p>
          </Form.Item>
        </>
      );
    }
  };
  return (
    <div className='dashboard'>
      <div style={{ width: '62%' }}>
        <Card title="OVERVIEW OF EMPLOYEE'S REQUEST">
          <Form className='form-horizontal dataForm' layout='horizontal' labelCol={20}>
            {displayForm}
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default HrDisplay;
