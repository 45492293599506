import React, { useEffect, useContext, useState } from 'react';
import { Card, Select } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ExportExcel from '../../components/csv/ExportExcel';
import moment from 'moment';
import Axios from "axios";

const { Option } = Select;

const Export = () => {
  const currentuser = useContext(UserContext);
  const [month, setMonth] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [year, setYear] = useState(undefined);
  const [companies, fetchCompanies] = useAxios('', null, currentuser.data.token, 'get');
  const [companiesFiltered, fetchCompaniesFiltered] = useAxios('', null, currentuser.data.token, 'get');
  const [employees, fetchEmployees] = useAxios('', null, currentuser.data.token, 'get');
  const [branches, fetchBranches] = useAxios('', [], currentuser.data.token, 'get');
  const [newSalaries, setNewSalaries] = useState([]);
  const [salaries, setSalaries] = useState(undefined);
  const [allFetched, setAllFetched] = useState(false);

  const yearFilter = new Date().getFullYear();
  const uniqueYears = [2021, 2022, 2023, 2024];

  useEffect(() => {
    if (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) {
      fetchCompanies(`${SERVER_URL}/companies`, []);
    } else {
      const filter = { client: currentuser.data.id };
      fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
    }
    if (month && company) {
      if (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) {
        const filterCompanies = { $or: [{ _id: company }, { mainCompany: company }] };
        fetchCompaniesFiltered(`${SERVER_URL}/companies?filter=${JSON.stringify(filterCompanies)}`, []);
        const companiesId =
          companiesFiltered &&
          companiesFiltered.data &&
          companiesFiltered.data.items &&
          companiesFiltered.data.items.map((item) => item._id);
        if (companiesId && companiesId.length !== 0) {
          const filterData = { company: { $in: companiesId ? companiesId : [] } };
          fetchEmployees(`${SERVER_URL}/data?filter=${JSON.stringify(filterData)}`);
        }
      } else {
        const filter = { client: currentuser.data.id };
        fetchCompaniesFiltered(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`);
        const companiesId =
          companiesFiltered &&
          companiesFiltered.data &&
          companiesFiltered.data.items &&
          companiesFiltered.data.items.map((item) => item._id);
        if (companiesId && companiesId.length !== 0) {
          const filterBranch = { mainCompany: companiesId[0] };
          fetchBranches(`${SERVER_URL}/companies?filter=${JSON.stringify(filterBranch)}`);
          branches &&
            branches.data &&
            branches.data.items &&
            branches.data.items.forEach((item) => companiesId.push(item._id));
          const filterData = { company: { $in: companiesId ? companiesId : [] } };
          fetchEmployees(`${SERVER_URL}/data?filter=${JSON.stringify(filterData)}`);
        }
      }
      if (employees && employees.data && employees.data.items) {
        let employeeIDs = [];
        for (const employee of employees.data.items) {
          employeeIDs.push(employee._id);
        }
        const filter = { month, employee: { $in: employeeIDs }, year, company };

        async function fetchSalariesFunction() {
          try {
            await Axios.post(`${SERVER_URL}/salary-excel-fetch`, filter,{
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            }).then((res) => {
              setSalaries(res);
            });
          } catch (err) {
            console.log('error fetch salaries', err)
          }
        }

        fetchSalariesFunction();
      }
    }
  }, [
    fetchCompanies,
    fetchEmployees,
    month,
    company,
    employees,
    fetchCompaniesFiltered,
    companiesFiltered,
    year,
    currentuser.data.role,
    currentuser.data.id,
    companies,
    fetchBranches,
    branches,
    yearFilter,
    currentuser.data.token
  ]);

  let selectedCompany;
  selectedCompany =
    companies && companies.data && companies.data.items && companies.data.items.filter((item) => item._id === company);

  if (!month) {
    if (
      parseInt(moment().format('DD')) > 15 &&
      moment().isSameOrAfter(moment().startOf('month').set('date', 15), 'days') &&
      moment().isSameOrBefore(moment().add(1, 'months').startOf('month').set('date', 15), 'days')
    ) {
      setMonth(moment().format('MMMM'));
    } else if (
      parseInt(moment().format('DD')) <= 15 &&
      moment().isSameOrAfter(moment().subtract(1, 'months').startOf('month').set('date', 15), 'days') &&
      moment().isSameOrBefore(moment().startOf('month').set('date', 15), 'days')
    ) {
      setMonth(moment().subtract(1, 'months').format('MMMM'));
    }
  }

  if (!year) {
    if (
      (parseInt(moment().format('DD')) > 15 &&
        moment().isSameOrAfter(moment().startOf('month').set('date', 15), 'days') &&
        moment().isSameOrBefore(moment().add(1, 'months').startOf('month').set('date', 15), 'days')) ||
      (parseInt(moment().format('DD')) <= 15 &&
        moment().isSameOrAfter(moment().subtract(1, 'months').startOf('month').set('date', 15), 'days') &&
        moment().isSameOrBefore(moment().startOf('month').set('date', 15), 'days') &&
        moment().subtract(1, 'months').startOf('month').set('date', 15).format('YYYY') === moment().format('YYYY'))
    ) {
      setYear(moment().format('YYYY'));
    } else if (
      parseInt(moment().format('DD')) <= 15 &&
      moment().isSameOrAfter(moment().subtract(1, 'months').startOf('month').set('date', 15), 'days') &&
      moment().isSameOrBefore(moment().startOf('month').set('date', 15), 'days') &&
      moment().subtract(1, 'months').startOf('month').set('date', 15).format('YYYY') < moment().format('YYYY')
    ) {
      setYear(moment().subtract(1, 'years').format('YYYY'));
    }
  }

  // assigning 0 in soe of the cases, to show 0 in Excel cells if there is no actual value
  useEffect(() => {
    if (salaries && salaries.data && salaries.data.items) {
      const updatedSalaries = salaries.data.items.map(item => {
        if (typeof item.bonus === 'undefined' || typeof item.workFromHome === 'undefined' || typeof item.workFromHome1 === 'undefined' || typeof item.workFromHome2 === 'undefined') {
          return {
            ...item,
            bonus: item.bonus || 0,
            workFromHome: item.workFromHome || 0,
            workFromHome1: item.workFromHome1 || 0,
            workFromHome2: item.workFromHome2 || 0,
          };
        }
        return item;
      });

      setNewSalaries(updatedSalaries);
      setAllFetched(true);
    }
  }, [salaries]);

  useEffect(() => {
    setAllFetched(false);
  }, [company, month, year]);

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card bordered={false}>
          <p>Choose company:</p>
          <Select
            onChange={(value) => setCompany(value)}
            style={{ width: '60%' }}
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {companies &&
              companies.data &&
              companies.data.items &&
              companies.data.items.map((item) => (
                <Option value={item._id} key={item._id}>
                  {item.name} {item.mainCompany ? ' - ' + item.mainCompany.name : ''}
                </Option>
              ))}
          </Select>
          <p style={{ marginTop: '20px' }}>Choose month:</p>
          <Select onChange={(value) => setMonth(value)} style={{ width: '60%' }} defaultValue={month}>
            <Option value='January'>January</Option>
            <Option value='February'>February</Option>
            <Option value='March'>March</Option>
            <Option value='April'>April</Option>
            <Option value='May'>May</Option>
            <Option value='June'>June</Option>
            <Option value='July'>July</Option>
            <Option value='August'>August</Option>
            <Option value='September'>September</Option>
            <Option value='October'>October</Option>
            <Option value='November'>November</Option>
            <Option value='December'>December</Option>
          </Select>
          <p style={{ marginTop: '20px' }}>Choose year:</p>
          <Select onChange={(value) => setYear(value)} style={{ width: '60%' }} defaultValue={year}>
            {uniqueYears.length > 0 && uniqueYears.map((item) => <Option value={item}>{item}</Option>)}
          </Select>
          <div style={{ marginTop: '30px' }}>
            {month && company && year && (
              <ExportExcel
                csvData={newSalaries ? newSalaries : []}
                fileName={'salaries ' + selectedCompany[0].name + ' ' + month + '-' + year}
                propsToRemove={[
                  '_id',
                  'month',
                  '__v',
                  'createdAt',
                  'updatedAt',
                  'workHours',
                  'totalWork',
                  'hoursByType',
                  'year',
                  'createdBy',
                  'hotMealInitial',
                  'regresInitial',
                  'travelExpensesInitial',
                  'halfWorkTime',
                  'hoursByDay',
                  'mealCurrency',
                  'holidayCurrency',
                  'travelExpCurrency',
                  'attachments',
                  'stoppedRegularWork',
                  'otherLeave'
                ]}
                format='salaries'
                user={currentuser}
                selectedCompany={selectedCompany}
                allFetched={allFetched}
              />
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Export;
