import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import { DownloadOutlined, LoadingOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, notification, Select, Upload, Tooltip, Modal, Spin, Row, Col, Carousel } from 'antd';
import * as XLSX from 'xlsx';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import CarouselForm from '../../components/CarouselForm';

const { Option } = Select;

const UploadEmployees = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [isError, setIsError] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [company, setCompanies] = useState([]);
  const [companyAndBranches, setCompanyAndBranches] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [executed, setExecuted] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [fileUpload, setFileUpload] = useState(false);
  let initialValues = {};
  useEffect(() => {
    if (excelData && excelData.length > 0) {
      setFileList([]);
    }
  }, [excelData, isError]);
  const getAllCompanies = async () => {
    try {
      const responseCompanies = await Axios.get(`${SERVER_URL}/companies`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setCompanies(responseCompanies.data);
      setExecuted(false);
    } catch (error) {
      console.log('error message', error.message);
    }
  };
  useEffect(() => {
    if (company && company.items && currentuser.data.role.includes('hrOfficer')) {
      const foundCompany = company.items.filter((item) => item._id === localStorage.getItem('selectedCompany'));
      setSelectedCompany(foundCompany[0]);
    }
  }, [company, currentuser]);
  useEffect(() => {
    if (executed) {
      getAllCompanies();
    }
  }, [executed, getAllCompanies]);
  const onFinish = async () => {
    const postData = {
      excelData,
      company: selectedCompany ? selectedCompany._id : currentuser.data.company._id,
    };
    try {
      await Axios.post(`${SERVER_URL}/employees-upload`, postData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((res) => {
        if (res.data) {
          let message = res.data.message;
          if (res.data.failedEmployees && res.data.failedEmployees.length) {
            message += ` Failed employees: ${res.data.failedEmployees.join(', ')}`;
          }
          notification.success({
            message: message,
            placement: 'bottomRight',
            duration: 0,
            style: {
              maxHeight: '80vh',
              overflowY: 'auto',
            }
          });
          form.resetFields();
          setFileList([]);
          setSubmitClicked(false);
          setSubmitDisabled(true);
          history.push('/admin/data');
        }
      });
    } catch (error) {
      console.log('error in saving employees upload categories', error);
      notification.error({
        message: 'Problem with Employees excel upload. Please contact admin or try again.',
        placement: 'bottomRight',
      });
      setSubmitClicked(false);
    }
  };
  const handleRemove = (file) => {
    setFileList((prevFileList) => prevFileList.filter((item) => item.uid !== file.uid));
    setExcelData([]);
  };

  const handleUpload = async (file) => {
    setFileUpload(true);
    setFileList([]);
    setExcelData([]);

    const requiredColumns = [
      'Name',
      'Surname',
      'Personal number',
      'Email',
      'Position - Serbian',
      'Position - English',
      'Address',
      'City',
      'Bank account number',
      'Start date of work',
      'End date of work',
      'Work time type',
      'Holiday days defined in contract',
      'Holiday days left from past year',
      'Holiday days used from current year',
      'Total holiday days left',
      'Meal allowance',
      'Holiday allowance',
      'Travel expenses',
      'Other expenses',
      'Suspension',
      'Other income',
      'Bonus',
      'Work from home',
    ];

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      let excelItems = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      excelItems = excelItems.filter(item => item.length > 0);

      const sheetColumns = excelItems[0] || [];
      const missingColumns = requiredColumns.filter((col) => !sheetColumns.includes(col));

      if (missingColumns.length > 0) {
        notification.error({
          message: `Problem with Employees excel upload. Missing columns: ${missingColumns.join(', ')}`,
          placement: 'bottomRight',
          duration: 0,
        });
        setFileUpload(false);
        return;
      }

      const invalidRecords = [];

      const mappedData = excelItems.slice(1).map((item, index) => {
        const record = {
          firstName: item[sheetColumns.indexOf('Name')],
          lastName: item[sheetColumns.indexOf('Surname')],
          JMBG: item[sheetColumns.indexOf('Personal number')],
          email: item[sheetColumns.indexOf('Email')],
          position: item[sheetColumns.indexOf('Position - Serbian')],
          positionEng: item[sheetColumns.indexOf('Position - English')],
          address: item[sheetColumns.indexOf('Address')],
          city: item[sheetColumns.indexOf('City')],
          bankAccount: item[sheetColumns.indexOf('Bank account number')],
          startDate: item[sheetColumns.indexOf('Start date of work')],
          endDate: item[sheetColumns.indexOf('End date of work')],
          workTimeType: item[sheetColumns.indexOf('Work time type')],
          numberOfHolidayDaysContract: item[sheetColumns.indexOf('Holiday days defined in contract')],
          numberOfHolidayDaysPast: item[sheetColumns.indexOf('Holiday days left from past year')],
          numberOfHolidayDaysUsed: item[sheetColumns.indexOf('Holiday days used from current year')],
          numberOfHolidayDaysCurrent: item[sheetColumns.indexOf('Total holiday days left')],
          hotMeal: item[sheetColumns.indexOf('Meal allowance')],
          regres: item[sheetColumns.indexOf('Holiday allowance')],
          travelExpenses: item[sheetColumns.indexOf('Travel expenses')],
          otherExpensesValue: item[sheetColumns.indexOf('Other expenses')],
          suspensionValue: item[sheetColumns.indexOf('Suspension')],
          otherIncomeValue: item[sheetColumns.indexOf('Other income')],
          bonusValue: item[sheetColumns.indexOf('Bonus')],
          workFromHome: item[sheetColumns.indexOf('Work from home')],
        };
        
        // Error handling related to required columns in Excel
        if (!record.firstName || !record.lastName || !record.JMBG || !record.email || !record.startDate) {
          invalidRecords.push(index + 1);
        }
        return record;
      });

      if (invalidRecords.length > 0) {
        notification.error({
          message: `Problem with Employees excel upload. Missing required fields in rows: ${invalidRecords.join(', ')}`,
          placement: 'bottomRight',
          duration: 0,
        });
        setFileUpload(false);
        return;
      }

      setFileList([file]);
      setExcelData(mappedData);
      setSubmitDisabled(false);
      setFileUpload(false);
    };

    reader.readAsArrayBuffer(file);
  };

  const selectCompany = (companyId) => {
    const foundCompany = company.items.filter((item) => item._id === companyId);
    setSelectedCompany(foundCompany[0]);
  };

  // download the sample Excel file
  const downloadSampleFile = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/excel`, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'employees_sample.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('Error downloading sample file:', error);
      notification.error({
        message: 'Error downloading sample file.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ marginTop: '15px' }}>
        <Card title='Upload excel' bordered={false}>
          <h4 style={{ paddingBottom: '5px' }}>Here you can upload excel file with employees.</h4>
          <h4 style={{ paddingBottom: '15px' }}>
            Please use the sample excel file and fill it according to explanation.
          </h4>
          <Form
            name='basic'
            initialValues={initialValues}
            onFinish={async (values) => {
              setSubmitClicked(true);
              setSubmitDisabled(true);
              await onFinish(values);
            }}
            layout='horizontal'
            form={form}
          >
            {(currentuser.data.role.includes('SuperAdmin') ||
              currentuser.data.role.includes('admin') ||
              (companyAndBranches && companyAndBranches.length > 1)) && (
              <Form.Item
                label='Company'
                name='company'
                rules={[
                  {
                    required: true,
                    message: 'Please choose company!',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(value) => selectCompany(value)}
                  className='select-company'
                  dropdownStyle={{ width: '320px' }}
                >
                  {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) &&
                    company &&
                    company.items &&
                    company.items.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item.name}
                      </Option>
                    ))}
                  {(!currentuser.data.role.includes('SuperAdmin') || !currentuser.data.role.includes('admin')) &&
                    companyAndBranches &&
                    companyAndBranches.length !== 0 &&
                    companyAndBranches.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  name='fileOption'
                  label='Attach file'
                  className='attach'
                  rules={[
                    {
                      required: true,
                      message: 'Please upload the excel file!',
                    },
                  ]}
                >
                  <Upload
                    accept='.xlsx,.xls'
                    onRemove={handleRemove}
                    maxCount={1}
                    fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
                    name='file'
                    multiple={false}
                    beforeUpload={async (file) => {
                      setIsError(false);
                      if (file.size / 1000000 > 5) {
                        notification.error({
                          message: 'File is larger than 5 MB!',
                          placement: 'bottomRight',
                        });
                        return Promise.reject();
                      }
                      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        notification.error({
                          message: 'Please upload an Excel file!',
                          placement: 'bottomRight',
                        });
                        return Promise.reject();
                      }
                      if (fileList.length > 0) {
                        notification.error({
                          message: 'You can only upload one file at a time!',
                          placement: 'bottomRight',
                        });
                        return Promise.reject();
                      }
                      await handleUpload(file);
                      return false;
                    }}
                  >
                    <Button id='uploadButton' icon={<UploadOutlined />}>
                      Upload excel
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip
                  title={
                    <>
                      <strong>Note:</strong> You cannot upload the file larger than 5 MB and it HAS to be .xlsx file.
                    </>
                  }
                  color='#b1b1b1'
                  placement='bottom'
                  autoAdjustOverflow={true}
                >
                  <QuestionCircleOutlined style={{ color: '#b1b1b1', marginLeft: '10px', marginBottom: '20px' }} />
                </Tooltip>
              </Col>

              <Col
                span={8}
                style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
              >
                <Form.Item>
                  <Button
                    type='default'
                    icon={<DownloadOutlined />}
                    style={{ marginLeft: '8px' }}
                    onClick={downloadSampleFile}
                  >
                    Download excel sample
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ marginTop: '30px' }}>
              <CarouselForm excelData={excelData} />
            </div>
            <Form.Item style={{ textAlign: 'right', marginTop: '15px' }}>
              <Button
                className='text-right'
                type='primary'
                htmlType='submit'
                style={{ marginTop: '15px' }}
                disabled={submitDisabled || (!selectedCompany && !currentuser?.data?.company?._id)}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>

          <Modal visible={fileUpload} maskClosable={false} closable={false} footer={null}>
            <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
              <p>Please wait...</p>
              <p>
                <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </p>
            </div>
          </Modal>
          <Modal visible={submitClicked} maskClosable={false} closable={false} footer={null}>
            <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
              <p>Please wait...</p>
              <p>
                <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </p>
            </div>
          </Modal>
        </Card>
      </div>
    </div>
  );
};
export default UploadEmployees;
