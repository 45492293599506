import React from 'react';

export default function ViewRequestIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_79_171)'>
        <path
          d='M18.3732 0C18.7533 0 19.1203 0.138529 19.4056 0.38965L22.5168 3.12824C22.8538 3.42487 23.0469 3.85213 23.0469 4.30109V22.6562C23.0469 23.9507 21.9975 25 20.7031 25H4.29688C3.00246 25 1.95312 23.9507 1.95312 22.6562V2.34375C1.95312 1.04933 3.00246 0 4.29688 0H18.3732Z'
          fill='url(#paint0_linear_79_171)'
        />
        <path
          d='M18.3732 0C18.7533 0 19.1203 0.138529 19.4056 0.38965L22.5168 3.12824C22.8538 3.42487 23.0469 3.85213 23.0469 4.30109V5.46875H20.7031C19.4087 5.46875 18.3594 4.41942 18.3594 3.125V0H18.3732Z'
          fill='url(#paint1_linear_79_171)'
        />
        <path
          d='M16.7969 8.59375C17.2283 8.59375 17.5781 8.94353 17.5781 9.375C17.5781 9.80647 17.2283 10.1562 16.7969 10.1562H8.20312C7.77165 10.1562 7.42188 9.80647 7.42188 9.375C7.42188 8.94353 7.77165 8.59375 8.20312 8.59375H16.7969Z'
          fill='url(#paint2_linear_79_171)'
        />
        <path
          d='M16.7969 11.7188C17.2283 11.7188 17.5781 12.0685 17.5781 12.5C17.5781 12.9315 17.2283 13.2812 16.7969 13.2812H8.20312C7.77165 13.2812 7.42188 12.9315 7.42188 12.5C7.42188 12.0685 7.77165 11.7188 8.20312 11.7188H16.7969Z'
          fill='url(#paint3_linear_79_171)'
        />
        <path
          d='M16.7969 14.8438C17.2283 14.8438 17.5781 15.1935 17.5781 15.625C17.5781 16.0565 17.2283 16.4062 16.7969 16.4062H8.20312C7.77165 16.4062 7.42188 16.0565 7.42188 15.625C7.42188 15.1935 7.77165 14.8438 8.20312 14.8438H16.7969Z'
          fill='url(#paint4_linear_79_171)'
        />
        <path
          d='M18.6975 10.1652C16.8117 10.7692 15.5746 12.2539 14.9861 14.6195C14.1033 18.1678 13.0093 18.6203 9.44763 17.3582C10.7268 19.7208 13.3194 20.6681 14.9861 20.6681C16.6528 20.6681 20.9694 18.3476 20.4748 13.9366C20.0455 12.6519 19.453 11.3948 18.6975 10.1652Z'
          fill='#5C5656'
        />
        <mask
          id='mask0_79_171'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='9'
          y='10'
          width='12'
          height='11'
        >
          <path
            d='M18.6975 10.1652C16.8117 10.7692 15.5746 12.2539 14.9861 14.6195C14.1033 18.1678 13.0093 18.6203 9.44763 17.3582C10.7268 19.7208 13.3194 20.6681 14.9861 20.6681C16.6528 20.6681 20.9694 18.3476 20.4748 13.9366C20.0455 12.6519 19.453 11.3948 18.6975 10.1652Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_79_171)'>
          <path d='M26.5166 6.31665H6.31665V26.5166H26.5166V6.31665Z' fill='#FFA0A0' />
        </g>
        <path
          d='M19.9341 18.7482L24.5893 23.411C24.9147 23.7364 24.9147 24.2639 24.5893 24.5893C24.2639 24.9147 23.7364 24.9147 23.411 24.5893L18.7568 19.9274C17.6274 20.8068 16.2075 21.3306 14.6653 21.3306C10.9842 21.3306 8 18.3464 8 14.6653C8 10.9842 10.9842 8 14.6653 8C18.3464 8 21.3306 10.9842 21.3306 14.6653C21.3306 16.2036 20.8095 17.6202 19.9341 18.7482ZM14.6653 19.6643C17.4262 19.6643 19.6643 17.4262 19.6643 14.6653C19.6643 11.9044 17.4262 9.66633 14.6653 9.66633C11.9044 9.66633 9.66633 11.9044 9.66633 14.6653C9.66633 17.4262 11.9044 19.6643 14.6653 19.6643Z'
          fill='black'
        />
        <mask
          id='mask1_79_171'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='8'
          y='8'
          width='17'
          height='17'
        >
          <path
            d='M19.9341 18.7482L24.5893 23.411C24.9147 23.7364 24.9147 24.2639 24.5893 24.5893C24.2639 24.9147 23.7364 24.9147 23.411 24.5893L18.7568 19.9274C17.6274 20.8068 16.2075 21.3306 14.6653 21.3306C10.9842 21.3306 8 18.3464 8 14.6653C8 10.9842 10.9842 8 14.6653 8C18.3464 8 21.3306 10.9842 21.3306 14.6653C21.3306 16.2036 20.8095 17.6202 19.9341 18.7482ZM14.6653 19.6643C17.4262 19.6643 19.6643 17.4262 19.6643 14.6653C19.6643 11.9044 17.4262 9.66633 14.6653 9.66633C11.9044 9.66633 9.66633 11.9044 9.66633 14.6653C9.66633 17.4262 11.9044 19.6643 14.6653 19.6643Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask1_79_171)'>
          <path d='M26.5167 6.31671H6.31671V26.5167H26.5167V6.31671Z' fill='#7600FF' />
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_79_171'
          x1='1056.64'
          y1='0'
          x2='1056.64'
          y2='2500'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.97' stop-color='#D7D8E6' />
          <stop offset='1' stop-color='#D7D8E6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_79_171'
          x1='252.734'
          y1='0'
          x2='252.734'
          y2='539.345'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#515151' />
          <stop offset='1' stop-color='#CFD0E8' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_79_171'
          x1='1023.05'
          y1='86.7188'
          x2='7.42188'
          y2='86.7188'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#AAAAAA' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_79_171'
          x1='1023.05'
          y1='89.8438'
          x2='7.42188'
          y2='89.8438'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#AAAAAA' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_79_171'
          x1='1023.05'
          y1='92.9688'
          x2='7.42188'
          y2='92.9688'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#AAAAAA' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <clipPath id='clip0_79_171'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
