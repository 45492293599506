import {
  CommentOutlined,
  DeleteFilled,
  DownloadOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Col, Collapse, Drawer, Form, Input, Select, Tooltip, Upload, notification, Divider } from 'antd';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { SERVER_URL } from '../../config';
import FileSaver from 'file-saver';
import Axios from 'axios';

const { Option } = Select;

export default function EmployeeSalaryDrawer({
  selectedEmployee,
  employee,
  fileList,
  showCalendar,
  selectedType,
  selectedTypes,
  findedSalary,
  files,
  hrDocumentsList,
  changeFormData,
  showUploadList,
  employeeReq,
  currentuser,
  chosenCompany,
  handleIsDrawerOpen,
  isEmployeeDrawerOpen,
  setSelectedType,
  setSelectedTypes,
  setFiles,
  setFileList,
  openCommentModal,
}) {
  const form = Form.useFormInstance();
  const uploadBtnRef = useRef(null);
  const [isPanelExpanded, setIsPanelExpanded] = useState(false);
  const [randomKey, setRandomKey] = useState(Math.random());

  useEffect(() => {
    setIsPanelExpanded(fileList.length && fileList.length < 4);
  }, [fileList]);

  const triggerUpload = useCallback(() => {
    if (!uploadBtnRef.current) return;

    uploadBtnRef.current.click();
  }, [uploadBtnRef]);

  const handleDocumentUploadOnChange = (info) => {
    const updatedFileList = info.fileList.map((file) => {
      let nameArray = file.name.split('.');
      switch (nameArray[nameArray.length - 1]) {
        case 'pdf':
          file.thumbUrl = '/pdf.png';
          break;
        case 'doc':
        case 'docx':
          file.thumbUrl = '/docx.png';
          break;
        case 'png':
          file.thumbUrl = '/png.png';
          break;
        case 'jpg':
          file.thumbUrl = '/jpg.png';
          break;
        case 'xlsx':
          file.thumbUrl = '/xlsx.png';
          break;
        default:
          file.thumbUrl = '/other.png';
          break;
      }

      return {
        ...file,
        showRemoveIcon: !file.requestId,
      };
    });

    if (info.file.status === 'done') {
      setSelectedTypes([...selectedTypes, selectedType]);
      setFiles([...files, { location: info.file.response.key, originalName: info.file.name }]);
      for (const file of info.fileList) {
        let nameArray = file.name.split('.');
        switch (nameArray[nameArray.length - 1]) {
          case 'pdf':
            file.thumbUrl = '/pdf.png';
            break;
          case 'doc':
          case 'docx':
            file.thumbUrl = '/docx.png';
            break;
          case 'png':
            file.thumbUrl = '/png.png';
            break;
          case 'jpg':
            file.thumbUrl = '/jpg.png';
            break;
          case 'xlsx':
            file.thumbUrl = '/xlsx.png';
            break;
          default:
            file.thumbUrl = '/other.png';
            break;
        }
      }
      notification.success({
        message: 'Upload successful',
        placement: 'bottomRight',
      });
    } else if (info.file.status === 'error') {
      notification.error({
        message: 'Upload failed',
        placement: 'bottomRight',
      });
    }

    setFileList(updatedFileList.filter((item) => item.status && item.status !== 'error'));
  };

  const handleDocumentBeforeUpload = (file) => {
    if (!selectedType) {
      notification.error({
        message: 'Please select from above dropdown!',
        placement: 'bottomRight',
      });
      return false;
    }
    if (file.size / 1000000 > 5) {
      notification.error({
        message: 'File is larger than 5 MB!',
        placement: 'bottomRight',
      });
      return false;
    }
    return true;
  };

  const handleDocumentOnRemove = async (file) => {
    notification.info({
      message: 'Removing file in progress.',
      placement: 'bottomRight',
    });
    let newFileList = [...fileList];
    let newFiles = [...files];
    let types = [...selectedTypes];
    let foundFile;
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].name === file.name) {
        foundFile = fileList[i];
        newFileList.splice(i, 1);
        if (findedSalary && findedSalary.length !== 0 && findedSalary[0].attachments.length !== 0) {
          findedSalary[0].attachments.splice(i, 1);
        }
      }
    }
    for (let i = 0; i < files.length; i++) {
      if (files[i].originalName === file.name) {
        newFiles.splice(i, 1);
        types.splice(i, 1);
      }
    }

    let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');

    if (linkParts && linkParts.length !== 0) {
      await Axios.post(`${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`, {
        withCredentials: false,
      });
    }
    setFileList(newFileList);
    setRandomKey(Math.random());
    setFiles(newFiles);
    setSelectedTypes(types);
  };

  const handleDocumentOnDownload = async (file) => {
    try {
      notification.info({
        message: 'Downloading in progress.',
        placement: 'bottomRight',
        duration: 1,
      });
      if((file.response.key.length 
          && (chosenCompany.documentReturnType === 'word-pdf' 
              || chosenCompany.documentReturnType === 'word' 
              || !chosenCompany.documentReturnType)) 
          || (file.response.key.length 
                && file.response.keyPDF.length === 0 
                && chosenCompany.documentReturnType === 'pdf')) {
        const urlParts = file.response.key.split('/');
        const response = await Axios.get(`${SERVER_URL}/file/${urlParts[0]}/${urlParts[1]}`, {
          withCredentials: false,
        });
        FileSaver.saveAs(response.config.url, urlParts[1]);
      }

      if (file.response.keyPDF.length && chosenCompany.documentReturnType === 'pdf') {
        const urlPartsPDF = file.response.keyPDF.split('/');
        const response = await Axios.get(`${SERVER_URL}/file/${urlPartsPDF[0]}/${urlPartsPDF[1]}`, {
          withCredentials: false,
        });
        if (response.headers['content-type'] === 'application/zip') {
          const filename = response.headers['x-filename'] || 'downloaded-file.zip';
          FileSaver.saveAs(response.config.url, filename);
        } else {
          FileSaver.saveAs(response.config.url, urlPartsPDF[1]);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Problem with downloading file.',
        placement: 'bottomRight',
      });
    }
  };

  const downloadTermination = async (file) => {
    const typeOfHr = employee?.data?.items[0].company?.typeOfHr ? employee?.data?.items[0].company?.typeOfHr : 'serbian';
    const employeeInCharge = employee?.data.items[0];
    try {
      if (typeOfHr === 'serbian' || !typeOfHr) {
        if(file.response.key.length && (chosenCompany.documentReturnType === 'word-pdf' || chosenCompany.documentReturnType === 'word')) {
          const urlParts = file.response.key.split('/');
          const termination = await Axios.get(
              `${SERVER_URL}/download-termination/${file.response.key}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
              },
            );
            FileSaver.saveAs(termination.config.url, urlParts[1]);
        } else if(file.response.key.length && chosenCompany.documentReturnType === 'pdf') {
        const urlPartsPDF = file.response.keyPDF.split('/');
        const termination = await Axios.get(
            `${SERVER_URL}/download-termination/${file.response.keyPDF}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
          FileSaver.saveAs(termination.config.url, urlPartsPDF[1]);
        }
      } else if (typeOfHr === 'bilingual') {
        if (file.response.key.length && (chosenCompany.documentReturnType === 'word-pdf' || chosenCompany.documentReturnType === 'word')) {
          const termination = await Axios.get(
            `${SERVER_URL}/download-termination/${employeeInCharge.firstName}_${employeeInCharge.lastName}_${employeeInCharge._id ? employeeInCharge._id : currentuser.data.employee}/${file.name}.doc`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );

          FileSaver.saveAs(termination.config.url, `${file.name}.doc`);
        } else if(file.response.key.length && chosenCompany.documentReturnType === 'pdf') {

        const termination = await Axios.get(
            `${SERVER_URL}/download-termination/${employeeInCharge.firstName}_${employeeInCharge.lastName}_${employeeInCharge._id ? employeeInCharge._id : currentuser.data.employee}/${file.name}.pdf`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );

          FileSaver.saveAs(termination.config.url,`${file.name}.pdf`);
        }
      }
    } catch (err) {
      notification.error({
        message: 'Problem with download. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
      <Drawer width={520} closable={false} onClose={() => handleIsDrawerOpen(false)} open={isEmployeeDrawerOpen} forceRender={true}>
        <div className='employee-salary-drawer__wrapper'>
          {selectedEmployee && (
              <Col xs={25} md={25} className='form-items__wrapper'>
                {showCalendar && (
                    <div className='items-scrollable__wrapper'>
                      <div>
                        <Form.Item
                            label='Meal allowance'
                            name='hotMeal'
                            className='salaryInputField'
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            labelAlign='left'
                        >
                          <div className='salaryFormInput'>
                            <Input
                                onChange={(event) => changeFormData(event)}
                                type='number'
                                defaultValue={form.getFieldValue('hotMeal')}
                                className='customSuffix'
                                suffix={
                                  employee?.data &&
                                  employee?.data?.items &&
                                  employee?.data?.items[0]?.company &&
                                  employee?.data?.items[0]?.company?.mealCurrency
                                      ? employee?.data?.items[0]?.company?.mealCurrency.toUpperCase()
                                      : 'RSD'
                                }
                            />
                          </div>
                        </Form.Item>
                        <Form.Item
                            label='Holiday allowance'
                            name='regres'
                            className='salaryInputField'
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            labelAlign='left'
                        >
                          <div className='salaryFormInput'>
                            <Input
                                onChange={(event) => changeFormData(event)}
                                type='number'
                                defaultValue={form.getFieldValue('regres')}
                                className='customSuffix'
                                suffix={
                                  employee?.data &&
                                  employee?.data?.items &&
                                  employee?.data?.items[0]?.company &&
                                  employee?.data?.items[0]?.company?.holidayCurrency
                                      ? employee?.data?.items[0]?.company?.holidayCurrency.toUpperCase()
                                      : 'RSD'
                                }
                            />
                          </div>
                        </Form.Item>
                        <span id='bonusText'>
                    {`Bonus received by employee in ${
                        employee?.data &&
                        employee?.data?.items &&
                        employee?.data?.items?.length !== 0 &&
                        employee?.data?.items[0]?.company &&
                        employee?.data?.items[0]?.company?.bonusCurrency
                            ? employee.data.items[0]?.company?.bonusCurrency.toUpperCase()
                            : 'RSD'
                    }`}
                          .
                  </span>
                        <Form.Item
                            label='Travel expenses'
                            name='travelExpenses'
                            className='salaryInputField'
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            labelAlign='left'
                        >
                          <div className='salaryFormInput'>
                            <Input
                                onChange={(event) => changeFormData(event)}
                                defaultValue={form.getFieldValue('travelExpenses')}
                                type='number'
                                className='customSuffix'
                                suffix={
                                  employee?.data &&
                                  employee?.data?.items &&
                                  employee?.data?.items?.length !== 0 &&
                                  employee?.data?.items[0]?.company &&
                                  employee?.data?.items[0]?.company?.travelExpCurrency
                                      ? employee?.data?.items[0]?.company?.travelExpCurrency.toUpperCase()
                                      : 'RSD'
                                }
                            />
                          </div>
                        </Form.Item>
                        <Form.Item
                            label='Work from home'
                            name='workFromHome'
                            className='salaryInputField'
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            labelAlign='left'
                        >
                          <div className='salaryFormInput'>
                            <Input
                                onChange={(event) => changeFormData(event)}
                                defaultValue={form.getFieldValue('workFromHome')}
                                type='string'
                                className='customSuffix'
                                suffix={
                                  employee?.data &&
                                  employee?.data?.items &&
                                  employee?.data?.items?.length !== 0 &&
                                  employee?.data?.items[0]?.company &&
                                  employee?.data?.items[0]?.company?.workFromHomeCurrency
                                      ? employee?.data?.items[0]?.company?.workFromHomeCurrency.toUpperCase()
                                      : 'RSD'
                                }
                            />
                          </div>
                        </Form.Item>
                        {employee?.data &&
                            employee?.data?.items &&
                            employee?.data?.items[0]?.company?.otherExpenses &&
                            employee?.data?.items[0]?.company?.otherExpensesCurrency && (
                                <Form.Item
                                    label='Other expenses'
                                    name='otherExpenses'
                                    className='salaryInputField'
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    labelAlign='left'
                                >
                                  <div className='salaryFormInput'>
                                    <Input
                                        onChange={(event) => changeFormData(event)}
                                        defaultValue={form.getFieldValue('otherExpenses')}
                                        type='number'
                                        className='customSuffix'
                                        suffix={
                                          employee?.data &&
                                          employee?.data?.items &&
                                          employee?.data?.items?.length !== 0 &&
                                          employee?.data?.items[0]?.company &&
                                          employee?.data?.items[0]?.company?.otherExpensesCurrency
                                              ? employee?.data?.items[0]?.company?.otherExpensesCurrency.toUpperCase()
                                              : 'RSD'
                                        }
                                    />
                                  </div>
                                </Form.Item>
                            )}

                        {employee?.data &&
                            employee?.data?.items &&
                            employee?.data?.items[0]?.company?.otherIncome &&
                            employee?.data?.items[0]?.company?.otherIncomeCurrency && (
                                <Form.Item
                                    label='Other income'
                                    name='otherIncome'
                                    className='salaryInputField'
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    labelAlign='left'
                                >
                                  <div className='salaryFormInput'>
                                    <Input
                                        onChange={(event) => changeFormData(event)}
                                        type='number'
                                        defaultValue={form.getFieldValue('otherIncome')}
                                        className='customSuffix'
                                        suffix={
                                          employee?.data &&
                                          employee?.data?.items &&
                                          employee?.data?.items?.length !== 0 &&
                                          employee?.data?.items[0]?.company &&
                                          employee?.data?.items[0]?.company?.otherIncomeCurrency
                                              ? employee?.data?.items[0]?.company?.otherIncomeCurrency.toUpperCase()
                                              : 'RSD'
                                        }
                                    />
                                  </div>
                                </Form.Item>
                            )}
                        {employee?.data &&
                            employee?.data?.items &&
                            employee?.data?.items[0]?.company?.suspension &&
                            employee?.data?.items[0]?.company?.suspensionCurrency && (
                                <Form.Item
                                    label='Suspension'
                                    name='suspension'
                                    className='salaryInputField'
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    labelAlign='left'
                                >
                                  <div className='salaryFormInput'>
                                    <Input
                                        onChange={(event) => changeFormData(event)}
                                        type='number'
                                        defaultValue={form.getFieldValue('suspension')}
                                        className='customSuffix'
                                        suffix={
                                          employee?.data &&
                                          employee?.data?.items &&
                                          employee?.data?.items?.length !== 0 &&
                                          employee?.data?.items[0]?.company &&
                                          employee?.data?.items[0]?.company?.suspensionCurrency
                                              ? employee?.data?.items[0]?.company?.suspensionCurrency.toUpperCase()
                                              : 'RSD'
                                        }
                                    />
                                  </div>
                                </Form.Item>
                            )}
                        {employee?.data &&
                            employee?.data?.items &&
                            employee?.data?.items[0]?.company?.bonus &&
                            employee?.data?.items[0]?.company?.bonusCurrency && (
                                <Form.Item
                                    label='Bonus'
                                    name='bonus'
                                    className='salaryInputField'
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    labelAlign='left'
                                    style={{
                                      position: 'relative',
                                    }}
                                >
                                  <div className='salaryFormInput'>
                                    <Input
                                        onChange={(event) => changeFormData(event)}
                                        type='number'
                                        defaultValue={form.getFieldValue('bonus')}
                                        className='customSuffix'
                                        suffix={
                                          employee?.data &&
                                          employee?.data?.items &&
                                          employee?.data?.items[0]?.company &&
                                          employee?.data?.items[0]?.company?.bonusCurrency
                                              ? employee?.data?.items[0]?.company?.bonusCurrency.toUpperCase()
                                              : 'RSD'
                                        }
                                    />
                                  </div>
                                </Form.Item>
                            )}
                        {showCalendar && (
                            <div>
                              <Divider style={{ backgroundColor: '#2db1ab50' }} />
                              <Form.Item
                                  name='fileOption'
                                  label='Attach file'
                                  className='attach upload-field__wrapper'
                                  labelCol={{ span: 14 }}
                                  labelAlign='left'
                                  style={{ position: 'relative' }}
                              >
                                <div className='upload-elements__wrapper'>
                                  <Select
                                      onChange={(value) => setSelectedType(value)}
                                      placeholder='Select type of file'
                                      className='upload-select__element'
                                      onSelect={() => {
                                        triggerUpload();
                                      }}
                                  >
                                    <Option value='sick leave'>Sick leave</Option>
                                    <Option value='holiday leave'>Holiday leave</Option>
                                    <Option value='other leave'>Other leave</Option>
                                    <Option value='fuel bills'>Fuel bills</Option>
                                    <Option value='travel expenses'>Travel expenses</Option>
                                    <Option value='other'>Other</Option>
                                  </Select>
                                  <Tooltip
                                      title={
                                        <>
                                          Please make that type of file is selected before upload.
                                          <br />
                                          Note: You cannot upload file larger than 5 MB.
                                        </>
                                      }
                                      color='#b1b1b1'
                                      placement='top'
                                      autoAdjustOverflow={true}
                                  >
                                    <QuestionCircleOutlined className='question-circle-icon' />
                                  </Tooltip>
                                </div>
                              </Form.Item>
                              <Collapse defaultActiveKey={isPanelExpanded ? 'uploadFiles' : undefined}>
                                <Collapse.Panel
                                    forceRender={true}
                                    header={`Uploaded Files: ${fileList.length}`}
                                    className='file-upload-collapse__wrapper'
                                    key='uploadFiles'
                                    onClick={() => setIsPanelExpanded(true)}
                                >
                                  <Upload
                                      key={randomKey}
                                      className='salaryUpload'
                                      fileList={fileList.length === 0 ? undefined : [...fileList]}
                                      listType='text'
                                      showUploadList={showUploadList}
                                      action={`${SERVER_URL}/upload-s3?employee=${JSON.stringify({
                                        employeeID: selectedEmployee,
                                        employeeName: employeeReq[0]?.firstName + '_' + employeeReq[0]?.lastName,
                                      })}`}
                                      name='file'
                                      headers={{
                                        Authorization: `Bearer ${currentuser.data.token}`,
                                        uri: 'static/pdf/',
                                        timestamp: Date.now().toString(),
                                      }}
                                      onChange={(info) => {
                                        handleDocumentUploadOnChange(info);
                                      }}
                                      itemRender={(_, file, __, { download, remove }) => {
                                        return (
                                            <div className='file-list-item__wrapper'>
                                              <img src={file.thumbUrl} alt={file.name} className='file-icon-img__element' />
                                              <Tooltip
                                                  title={`${file.name}`}
                                                  color='#b1b1b1'
                                                  placement='top'
                                                  autoAdjustOverflow={true}
                                              >
                                                <span className='file-name__element'>{file.name}</span>
                                              </Tooltip>
                                              <span className='file-actions__wrapper'>
                                                <Tooltip
                                                    title='Download file'
                                                    color='#b1b1b1'
                                                    placement='top'
                                                    autoAdjustOverflow={true}
                                                >
                                                  <Button
                                                      size='small'
                                                      icon={<DownloadOutlined className='icon-span-fix' />}
                                                      onClick={() => download()}
                                                  ></Button>
                                                </Tooltip>
                                                {!file.requestId ? (
                                                    <Tooltip
                                                        title='Remove file'
                                                        color='#b1b1b1'
                                                        placement='top'
                                                        autoAdjustOverflow={true}
                                                    >
                                                      <Button
                                                          size='small'
                                                          icon={<DeleteFilled className='icon-span-fix' />}
                                                          onClick={() => remove()}
                                                      ></Button>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip
                                                        title='Not removable'
                                                        color='#b1b1b1'
                                                        placement='top'
                                                        autoAdjustOverflow={true}
                                                    >
                                                      <Button
                                                          disabled={true}
                                                          size='small'
                                                          icon={<DeleteFilled className='icon-span-fix' />}
                                                      ></Button>
                                                    </Tooltip>
                                                )}
                                              </span>
                                            </div>
                                        );
                                      }}
                                      beforeUpload={(file) => {
                                        handleDocumentBeforeUpload(file);
                                      }}
                                      onRemove={async (file) => {
                                        await handleDocumentOnRemove(file);
                                      }}
                                      onDownload={async (file) => {
                                        (file.response.key !== '' || file.response.keyPDF !== '') && file.status !== 'termination'
                                            ? await handleDocumentOnDownload(file)
                                            : await downloadTermination(file)
                                      }}
                                  >
                                    <Button
                                        ref={uploadBtnRef}
                                        style={{ display: 'none' }}
                                        id='uploadButton'
                                        icon={<UploadOutlined />}
                                    >
                                      Upload
                                    </Button>
                                  </Upload>
                                </Collapse.Panel>
                              </Collapse>

                              <Upload
                                  listType='picture'
                                  fileList={hrDocumentsList?.length === 0 ? undefined : [...hrDocumentsList]}
                                  showUploadList={showUploadList}
                                  onDownload={async (file) => {
                                    try {
                                      notification.info({
                                        message: 'Downloading in progress.',
                                        placement: 'bottomRight',
                                        duration: 1,
                                      });

                                      const folderName = `${employee?.data?.items[0]?.firstName}${employee?.data?.items[0]?.lastName}_${employee?.data?.items[0]?._id}`;
                                      const response = await Axios.get(`${SERVER_URL}/get-hr/${folderName}/${file.name}`, {
                                        withCredentials: false,
                                      });
                                      FileSaver.saveAs(response.config.url, file.name);
                                    } catch (error) {
                                      notification.error({
                                        message: 'Problem with downloading file.',
                                        placement: 'bottomRight',
                                      });
                                    }
                                  }}
                              ></Upload>
                            </div>
                        )}
                      </div>
                    </div>
                )}
                <div className='submit-button-group__wrapper'>
                  <div className='text-right comment-button__wrapper'>
                    {form.getFieldValue('comment') && <CommentOutlined className='comment-outlined-icon__element' />}
                    <Button className='commentButton' onClick={openCommentModal}>
                      {!form.getFieldValue('comment') ? 'Add comment' : 'Edit comment'}
                    </Button>
                  </div>
                </div>
              </Col>
          )}
        </div>
      </Drawer>
  );
}
