import React from 'react';
import { Form, Input, Radio, Select } from 'antd';
import DisplayShareholderCompany from './DisplayShareholderCompany';

const { Option } = Select;

const DisplayShareholder = ({
  shareholderData,
  citizenship,
  handleChange,
  handleRadioChange,
  handleShareholderCountry,
  countries,
  shareholderNumeration,
  shareholderCompanyData,
  handleRadioCompanyChange,
  handleShareholderCompanyCountry,
  companyShareholderNum,
  setShareholderCompanyData,
  type,
  setType,
}) => {
  return (
    <>
      {/* <Form.Item label='Type:'>
        <Select
          // value={type}
          style={{
            width: 150,
          }}
          onChange={(value) => {
            if (value === 'natural person') {
              setType('natural person');
            } else if (value === 'company') {
              setType('company');
            }
          }}
        >
          <Option value='natural person'>Natural Person</Option>
          <Option value='company'>Company</Option>
        </Select>
      </Form.Item> */}
      {type === 'natural person' ? (
        <>
          <Form.Item label='Citizenship:'>
            <Radio.Group name='citizenship' defaultValue={citizenship} onChange={handleRadioChange}>
              <Radio value='serbian'>Serbian Citizen</Radio>
              <Radio value='foreign'>Foreign Citizen</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item required={true} label={`${companyShareholderNum}${shareholderNumeration}.1 Ownership Percentage:`}>
            <input
              required
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: 70,
                outline: 'none',
              }}
              type='number'
              name='ownershipPercentage'
              value={shareholderData?.ownershipPercentage}
              onChange={(e) => handleChange(shareholderData, e)}
            />{' '}
            %
          </Form.Item>
          <Form.Item required={true} label={`${companyShareholderNum}${shareholderNumeration}.2 First Name:`}>
            <input
              required
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='text'
              name='firstName'
              value={shareholderData?.firstName}
              onChange={(e) => handleChange(shareholderData, e)}
            />
          </Form.Item>
          <Form.Item required={true} label={`${companyShareholderNum}${shareholderNumeration}.3 Last Name:`}>
            <input
              required
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='text'
              name='lastName'
              value={shareholderData?.lastName}
              onChange={(e) => handleChange(shareholderData, e)}
            />
          </Form.Item>
          {citizenship === 'serbian' ? (
            <>
              <Form.Item required={true} label={`${companyShareholderNum}${shareholderNumeration}.4 ID number:`}>
                <input
                  required
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '2px',
                    padding: '4px 11px',
                    width: '100%',
                    outline: 'none',
                  }}
                  type='number'
                  name='idNum'
                  value={shareholderData?.idNum}
                  onChange={(e) => handleChange(shareholderData, e)}
                />
              </Form.Item>
              <Form.Item required={true} label={`${companyShareholderNum}${shareholderNumeration}.5 JMBG:`}>
                <input
                  required
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '2px',
                    padding: '4px 11px',
                    width: '100%',
                    outline: 'none',
                  }}
                  type='number'
                  name='jmbg'
                  value={shareholderData?.jmbg}
                  onChange={(e) => handleChange(shareholderData, e)}
                />
              </Form.Item>
            </>
          ) : citizenship === 'foreign' ? (
            <>
              <Form.Item required={true} label={`${companyShareholderNum}${shareholderNumeration}.4 Passport number:`}>
                <input
                  required
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '2px',
                    padding: '4px 11px',
                    width: '100%',
                    outline: 'none',
                  }}
                  type='number'
                  name='passportNum'
                  value={shareholderData?.passportNum}
                  onChange={(e) => handleChange(shareholderData, e)}
                />
              </Form.Item>
            </>
          ) : (
            ''
          )}
          <p>Address:</p>
          <Form.Item
            required={true}
            label={`${companyShareholderNum}${shareholderNumeration}.${citizenship === 'serbian' ? '6' : '5'} Street:`}
          >
            <input
              required
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='text'
              name='street'
              value={shareholderData?.address.street}
              onChange={(e) => handleChange(shareholderData.address, e)}
            />
          </Form.Item>
          <Form.Item
            required={true}
            label={`${companyShareholderNum}${shareholderNumeration}.${
              citizenship === 'serbian' ? '7' : '6'
            } Street Number:`}
          >
            <input
              required
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='text'
              name='streetNum'
              value={shareholderData?.address.streetNum}
              onChange={(e) => handleChange(shareholderData.address, e)}
            />
          </Form.Item>
          <Form.Item
            label={`${companyShareholderNum}${shareholderNumeration}.${
              citizenship === 'serbian' ? '8' : '7'
            } Apartment Number:`}
          >
            <Input
              type='text'
              name='aptNum'
              value={shareholderData?.address.aptNum}
              onChange={(e) => handleChange(shareholderData.address, e)}
            />
          </Form.Item>
          <Form.Item
            required={true}
            label={`${companyShareholderNum}${shareholderNumeration}.${citizenship === 'serbian' ? '9' : '8'} City`}
          >
            <input
              required
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='text'
              name='city'
              value={shareholderData?.address.city}
              onChange={(e) => handleChange(shareholderData.address, e)}
            />
          </Form.Item>
          <Form.Item
            required={true}
            label={`${companyShareholderNum}${shareholderNumeration}.${
              citizenship === 'serbian' ? '10' : '9'
            } Country:`}
          >
            <Select
              showSearch
              style={{
                width: 140,
              }}
              value={shareholderData?.address.country}
              name='country'
              onSelect={(values) => handleShareholderCountry(values)}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {countries.map((country, id) => {
                return (
                  <Option key={id} value={country}>
                    {country}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </>
      ) : type === 'company' ? (
        <DisplayShareholderCompany
          shareholderCompanyData={shareholderCompanyData}
          handleRadioCompanyChange={handleRadioCompanyChange}
          handleShareholderCompanyCountry={handleShareholderCompanyCountry}
          companyShareholderNum={companyShareholderNum}
          handleChange={handleChange}
          countries={countries}
          setShareholderCompanyData={setShareholderCompanyData}
        />
      ) : null}
    </>
  );
};

export default DisplayShareholder;
