import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserForm } from '../../components/forms';
import EmployeeForm from '../../components/forms/EmployeeForm';

const EditUser = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [isAdminRole, setIsAdminRole] = useState(false);
  const history = useHistory();
  const { userId } = props.match.params;

  useEffect(() => {
    if (userId) {
      fetchUser(`${SERVER_URL}/users/${userId}`);
    }
  }, [userId, fetchUser]);

  const updateUser = async (data) => {
    try {
      if (
        (data.newPassword !== '' && data.confirmPassword !== '' && data.newPassword !== data.confirmPassword) ||
        (!data.newPassword && data.confirmPassword)
      ) {
        notification.error({
          message: 'New and confirmed password are not same',
          placement: 'bottomRight',
        });
      } else {
        if (!data.newPassword && !data.confirmPassword) {
          delete data.newPassword;
          delete data.confirmPassword;
        }
        await Axios.put(
          `${SERVER_URL}/users/${userId}`,
          { ...data },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        notification.success({
          message: currentuser.data.role[0] === 'employee' ? 'Password is changed.' : 'Client is edited.',
          placement: 'bottomRight',
        });
        history.push('/admin');
        history.push('/admin/users');
      }
    } catch (err) {
      notification.error({
        message: 'Problem with edit. Please try later',
        placement: 'bottomRight',
      });
    }
  };

  const createUser = async (data) => {
    try {
      let isError = false;
      if (data.firstName.trim() === '') {
        isError = true;
        notification.error({
          message: 'Invalid first name!',
          placement: 'bottomRight',
        });
      }
      if (data.lastName.trim() === '') {
        isError = true;
        notification.error({
          message: 'Invalid last name!',
          placement: 'bottomRight',
        });
      }
      if (data.password.includes(' ')) {
        isError = true;
        notification.error({
          message: 'Invalid password!',
          placement: 'bottomRight',
        });
      }
      if (data.city.trim() === '') {
        isError = true;
        notification.error({
          message: 'Invalid city!',
          placement: 'bottomRight',
        });
      }
      if (data.country.trim() === '') {
        isError = true;
        notification.error({
          message: 'Invalid country!',
          placement: 'bottomRight',
        });
      }
      if (data.companyName && data.companyName.trim() === '') {
        isError = true;
        notification.error({
          message: 'Invalid name of company!',
          placement: 'bottomRight',
        });
      }
      if (data.taxId && data.taxId.trim() === '') {
        isError = true;
        notification.error({
          message: 'Invalid TIN!',
          placement: 'bottomRight',
        });
      }
      if (isError === false) {
        await Axios.post(`${SERVER_URL}/register`, { ...data });
        notification.success({
          message: 'Client is created.',
          placement: 'bottomRight',
        });
        setResult(true);
      }
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem with create. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <>
      {currentuser.data.role[0].includes('employee') ? (
        <EmployeeForm
          data={user.data}
          updateHandler={updateUser}
          SERVER_URL={SERVER_URL}
          token={currentuser.data.token}
          loggedUser={currentuser.data}
        />
      ) : (
        <div style={{ padding: '8px' }}>
          <div style={{ textAlign: 'center' }}>
            {userId && user.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
            {userId && !user.isLoading && !user.isError && user.data && user.data.email && (
              <UserForm
                data={user.data}
                updateHandler={updateUser}
                SERVER_URL={SERVER_URL}
                token={currentuser.data.token}
                loggedUser={currentuser.data}
              />
            )}
            {userId && !user.isLoading && user.isError && (
              <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
            )}
            {!userId && user && !user.data && (
              <UserForm
                data={null}
                createHandler={createUser}
                result={result}
                setResult={setResult}
                SERVER_URL={SERVER_URL}
                token={currentuser.data.token}
                loggedUser={currentuser.data}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EditUser;
