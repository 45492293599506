import moment from 'moment';

// TODO update public holiday days array every year
export const holidaysArray = [
    moment('1.2.2023.'),
    moment('1.3.2023.'),
    moment('2.15.2023.'),
    moment('2.16.2023.'),
    moment('4.14.2023.'),
    moment('4.17.2023.'),
    moment('5.1.2023.'),
    moment('5.2.2023.'),
    moment('1.1.2024.'),
    moment('1.2.2024.'),
    moment('2.15.2024.'),
    moment('2.16.2024.'),
    moment('5.1.2024.'),
    moment('5.2.2024.'),
    moment('5.3.2024.'),
    moment('5.6.2024.'),
    moment('11.11.2024.'),
    ///////////////////////////////// 2025 holidays
    moment('1.1.2025.'),
    moment('1.2.2025.'),
    moment('1.7.2025.'),
    // moment('2.15.2025.'), saturday
    // moment('2.16.2025.'), sunday
    moment('2.17.2025.'),
    moment('4.18.2025.'),
    moment('4.21.2025.'),
    moment('5.1.2025.'),
    moment('5.2.2025.'),
    moment('11.11.2025.'),
];