import React from 'react';

export default function ThemeSwitch({ handleChange, isChecked }) {
  return (
    <div className='theme-switch__wrapper'>
      <input type='checkbox' id='check' className='toggle' onChange={handleChange} checked={isChecked} />
      <label htmlFor='check'></label>
    </div>
  );
}
