import React from 'react';

export default function CalculatorIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M24.9999 2.0231V12.5011H12.4999V0H22.9333C23.1871 0 23.4328 0.0434082 23.6584 0.130176C24.2183 0.335254 24.6614 0.769092 24.8669 1.31323C24.9515 1.53408 24.9999 1.77461 24.9999 2.0231Z'
        fill='url(#paint0_linear_13_190)'
      />
      <path
        d='M12.4999 0V12.5011H0V2.0231C0 0.907031 0.926534 0 2.06655 0H12.4999Z'
        fill='url(#paint1_linear_13_190)'
      />
      <path
        d='M25.0001 12.5005V22.9775C25.0001 24.0959 24.0748 25 22.9341 25H12.4991V12.5005H25.0001Z'
        fill='url(#paint2_linear_13_190)'
      />
      <path
        d='M12.4999 12.5011V24.9982H2.06655C1.78054 24.9982 1.51066 24.9429 1.26491 24.8405C0.765378 24.6354 0.366603 24.2411 0.157116 23.756C0.056412 23.5154 0 23.2552 0 22.979V12.5011H12.4999Z'
        fill='url(#paint3_linear_13_190)'
      />
      <path
        d='M6.79104 5.75498V4.2002H5.70904V5.75498H4.08441V6.73535H5.70904V8.30298H6.79104V6.73535H8.41582V5.75498H6.79104Z'
        fill='white'
      />
      <path d='M20.3897 5.72119H17.1095V6.78184H20.3897V5.72119Z' fill='white' />
      <path
        d='M8.18344 17.5552L7.51612 16.925L6.25007 18.1209L4.98421 16.925L4.31689 17.5552L5.5828 18.751L4.31689 19.9466L4.98421 20.5771L6.25007 19.3812L7.51612 20.5771L8.18344 19.9466L6.91738 18.751L8.18344 17.5552Z'
        fill='white'
      />
      <path
        opacity='0.4'
        d='M16.8908 19.2524V19.9386H20.609V19.2524H16.8908ZM16.8908 17.5645V18.2507H20.609V17.5645H16.8908Z'
        fill='url(#paint4_linear_13_190)'
      />
      <path d='M12.661 0H12.3387V24.9981H12.661V0Z' fill='#4D4C4C' />
      <path d='M24.9999 12.3433H0V12.6588H24.9999V12.3433Z' fill='#4D4C4C' />
      <path
        d='M16.7941 19.1933V19.8755H20.5163V19.1933H16.7941ZM16.7941 17.5054V18.1916H20.5163V17.5054H16.7941Z'
        fill='white'
      />
      <path
        d='M6.69662 5.69546V4.14067H5.61462V5.69546H3.98999V6.67588H5.61462V8.2435H6.69662V6.67588H8.3214V5.69546H6.69662Z'
        fill='#979696'
      />
      <path d='M20.2952 5.66172H17.015V6.72236H20.2952V5.66172Z' fill='#979696' />
      <path
        d='M8.0889 17.4957L7.42159 16.8655L6.15553 18.0614L4.88968 16.8655L4.22241 17.4957L5.48827 18.6916L4.22241 19.8871L4.88968 20.5176L6.15553 19.3217L7.42159 20.5176L8.0889 19.8871L6.82285 18.6916L8.0889 17.4957Z'
        fill='#979696'
      />
      <defs>
        <linearGradient
          id='paint0_linear_13_190'
          x1='12.4999'
          y1='6.25055'
          x2='24.9999'
          y2='6.25055'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0918' stopColor='#FBFBFC' />
          <stop offset='1' stopColor='#E6E1DF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_13_190'
          x1='0'
          y1='6.25055'
          x2='12.4999'
          y2='6.25055'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0918' stopColor='#FBFBFC' />
          <stop offset='1' stopColor='#E6E1DF' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_13_190'
          x1='-0.00175401'
          y1='18.7503'
          x2='25.0001'
          y2='18.7503'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0338' stopColor='#DE551A' />
          <stop offset='1' stopColor='#DD5121' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_13_190'
          x1='0'
          y1='18.7496'
          x2='12.4999'
          y2='18.7496'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0918' stopColor='#FBFBFC' />
          <stop offset='1' stopColor='#E6E1DF' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_13_190'
          x1='16.8908'
          y1='18.7516'
          x2='20.6089'
          y2='18.7516'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.0338' stopColor='white' />
          <stop offset='1' stopColor='#DD5121' />
        </linearGradient>
      </defs>
    </svg>
  );
}
