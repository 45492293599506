import React from 'react';

export default function RulesIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_38_709)'>
        <path
          d='M20.1875 25.1667H4.5625C4.5625 25.1667 3 25.1146 3 21H21.75C21.75 25.1146 20.1875 25.1667 20.1875 25.1667Z'
          fill='#42A5F5'
        />
        <path d='M4 0H21V21H4V0Z' fill='#90CAF9' />
        <path
          d='M9.375 7.8125H17.7083V8.85417H9.375V7.8125ZM9.375 9.89583H17.7083V10.9375H9.375V9.89583ZM9.375 11.9792H17.7083V13.0208H9.375V11.9792ZM9.375 14.0625H17.7083V15.1042H9.375V14.0625ZM9.375 16.1458H17.7083V17.1875H9.375V16.1458Z'
          fill='#1976D2'
        />
        <path
          d='M9.375 1.60001H17.7083V2.64167H9.375V1.60001ZM9.375 3.68334H17.7083V4.72501H9.375V3.68334ZM9.375 5.76667H17.7083V6.80834H9.375V5.76667ZM9.375 7.85001H17.7083V8.89167H9.375V7.85001ZM9.375 9.93334H17.7083V10.975H9.375V9.93334Z'
          fill='#1976D2'
        />
        <path
          d='M7.29163 7.8125H8.33329V8.85417H7.29163V7.8125ZM7.29163 9.89583H8.33329V10.9375H7.29163V9.89583ZM7.29163 11.9792H8.33329V13.0208H7.29163V11.9792ZM7.29163 14.0625H8.33329V15.1042H7.29163V14.0625ZM7.29163 16.1458H8.33329V17.1875H7.29163V16.1458Z'
          fill='#1976D2'
        />
        <path
          d='M7.29163 1.60001H8.33329V2.64167H7.29163V1.60001ZM7.29163 3.68334H8.33329V4.72501H7.29163V3.68334ZM7.29163 5.76667H8.33329V6.80834H7.29163V5.76667ZM7.29163 7.85001H8.33329V8.89167H7.29163V7.85001ZM7.29163 9.93334H8.33329V10.975H7.29163V9.93334Z'
          fill='#1976D2'
        />
      </g>
      <defs>
        <clipPath id='clip0_38_709'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
