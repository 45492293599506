import React, { useContext, useState } from 'react';
import { Select, Button, Form, Card, DatePicker, Radio, Space, notification, Upload } from 'antd';
import { UserContext } from '../../App';
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import Axios from 'axios';

const { Option } = Select;

const ExtendEmploymentForm = ({ data, SERVER_URL, onSubmit, companies, setCompany, chosenEmployee, setChosenEmployee }) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [employmentType, setEmploymentType] = useState(data && data.employmentType);
  const [isError, setIsError] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);

  let initialValues = {
    employee: undefined,
    employmentType: 'unlimited',
  };

  const onFinish = (values) => {
    if (values.employmentType === 'unlimited') {
      delete values.endDate;
    }
    let attachments = [];
    for (let i = 0; i < files.length; i++) {
      attachments.push({
        location: files[i].location,
        originalName: files[i].originalName,
      });
    }
    values.attachments = attachments;
    onSubmit(values);
    form.resetFields();
    setFileList([]);
    setFiles([]);
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title='Extend employment' bordered={false}>
            <Form
              name='basic'
              initialValues={initialValues}
              onFinish={(values) => {
                document.getElementById('extend').disabled = true;
                onFinish(values);
              }}
              layout='horizontal'
              form={form}
            >
              {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) && (
                <Form.Item
                  label='Choose company'
                  name='company'
                  rules={[
                    {
                      required: true,
                      message: 'Please choose company!',
                    },
                  ]}
                >
                  <Select
                    allowClear={true}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(value) => {
                      localStorage.setItem('selectedCompany', value);
                      setCompany(value);
                    }}
                    style={{ marginLeft: '3px', width: '99.5%' }}
                  >
                    {companies &&
                      companies.data &&
                      companies.data.items &&
                      companies.data.items.map((item) => <Option value={item._id} key={item._id}>{item.name}</Option>)}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                label='Choose employee'
                name='employee'
                rules={[
                  {
                    required: true,
                    message: 'Please choose employee!',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => setChosenEmployee(data.filter((item) => item._id === value))}
                >
                  {data &&
                    data?.map((item) => (
                      <Option value={item._id} key={item._id}>
                        {' '}
                        {item.firstName} {item.lastName} - {item.JMBG}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item name='employmentType'>
                <Radio.Group onChange={(event) => setEmploymentType(event.target.value)}>
                  <Space direction='vertical'>
                    <Radio value='unlimited'>Unlimited employment</Radio>
                    <Radio value='limited'>Limited employment</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

              {employmentType === 'limited' && (
                <Form.Item
                  label='End date of work'
                  name='endDate'
                  rules={[
                    {
                      required: true,
                      message: 'Please select end date!',
                    },
                  ]}
                >
                  <DatePicker
                      format='DD-MM-YYYY'
                      style={{marginLeft: '6px'}}
                  />
                </Form.Item>
              )}
              <div style={{ display: 'flex' }}>
                <Form.Item name='fileOption' label='Attach file' className='attach'>
                  <Upload
                    fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
                    listType='picture'
                    action={`${SERVER_URL}/upload-s3?employee=${JSON.stringify({
                      employeeID: chosenEmployee && chosenEmployee?.length !== 0 && chosenEmployee[0].JMBG,
                      employeeName:
                          chosenEmployee && chosenEmployee?.length !== 0 && chosenEmployee[0].firstName + '_' + chosenEmployee[0].lastName,
                    })}`}
                    name='file'
                    headers={{
                      Authorization: `Bearer ${currentuser.data.token}`,
                      uri: 'static/pdf/',
                      timestamp: Date.now().toString(),
                    }}
                    onChange={(info) => {
                      if (!info.file.status) {
                        setIsError(true);
                      }
                      if (info.file.status === 'done') {
                        setIsError(false);
                        setFiles([...files, { location: info.file.response.key, originalName: info.file.name }]);
                        for (const file of info.fileList) {
                          let nameArray = file.name.split('.');
                          switch (nameArray[nameArray.length - 1]) {
                            case 'pdf':
                              file.thumbUrl = '/pdf.png';
                              break;
                            case 'docx':
                              file.thumbUrl = '/docx.png';
                              break;
                            case 'png':
                              file.thumbUrl = '/png.png';
                              break;
                            case 'jpg':
                              file.thumbUrl = '/jpg.png';
                              break;
                            case 'xlsx':
                              file.thumbUrl = '/xlsx.png';
                              break;

                            default:
                              file.thumbUrl = '/other.png';
                              break;
                          }
                        }
                        notification.success({
                          message: 'Upload successfull',
                          placement: 'bottomRight',
                        });
                      } else if (info.file.status === 'error') {
                        notification.error({
                          message: 'Upload failed',
                          placement: 'bottomRight',
                        });
                      }
                      setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
                    }}
                    beforeUpload={(file) => {
                      setIsError(false);
                      if (chosenEmployee.length === 0) {
                        notification.error({
                          message: 'You must choose employee before upload.',
                          placement: 'bottomRight',
                        });
                        return false;
                      }
                      if (file.size / 1000000 > 5) {
                        notification.error({
                          message: 'File is larger than 5 MB!',
                          placement: 'bottomRight',
                        });
                        return false;
                      }
                      return true;
                    }}
                    onRemove={async (file) => {
                      notification.info({
                        message: 'Removing file in progress.',
                        placement: 'bottomRight',
                      });
                      let newFileList = [...fileList];
                      let newFiles = [...files];
                      let foundFile;
                      for (let i = 0; i < fileList.length; i++) {
                        if (fileList[i].name === file.name) {
                          foundFile = fileList[i];
                          newFileList.splice(i, 1);
                          if (data && data.attachments && data.attachments.length !== 0) {
                            data.attachments.splice(i, 1);
                            await Axios.put(
                              `${SERVER_URL}/data/${data._id}`,
                              { attachments: data.attachments },
                              {
                                withCredentials: false,
                                headers: { Authorization: `Bearer ${currentuser.data.token}` },
                              },
                            );
                          }
                        }
                      }
                      for (let i = 0; i < files.length; i++) {
                        if (files[i].originalName === file.name) {
                          newFiles.splice(i, 1);
                        }
                      }
                      let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
                      if (linkParts && linkParts.length !== 0) {
                        await Axios.post(`${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`, {
                          withCredentials: false,
                        });
                      }
                      setFileList(newFileList);
                      setFiles(newFiles);
                    }}
                  >
                    <Button id='uploadButton' icon={<UploadOutlined />} style={{marginLeft: '65px', marginBottom: '10px'}}>
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
                <QuestionCircleOutlined
                  style={{ color: '#b1b1b1', marginLeft: '-5px' }}
                  className='attachDescription'
                  onMouseEnter={() => (document.getElementById('attachTerminationText').style.display = 'initial')}
                  onMouseLeave={() => (document.getElementById('attachTerminationText').style.display = 'none')}
                />
              </div>
              <span id='attachTerminationText'>
                Please make sure that employee is chosen before upload.
                <br />
                Note: You cannot upload file larger than 5 MB.
              </span>
              <div className='text-right'>
                <Button type='primary' id='extend' htmlType='submit'>
                  Submit
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ExtendEmploymentForm;
