import React from 'react';
import { Button, Col, Row, Select } from 'antd';
import ClientExcel from './csv/clientExcel.js';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const SalaryFormDownloadSection = ({
  currentuser,
  sendMail,
  salaries,
  selectedYear,
  selectedMonth,
  selectedCompany,
  selectedLang,
  setSelectedLang,
  allDone,
  foundCompany,
}) => {
  return (
    <>
      <Row type='flex' gutter={16}>
        <Col md={24}>
          <div className='panel-body'>
            <div className='text-right salary-form-download-section__wrapper'>
              <Button
                onClick={sendMail}
                disabled={!salaries.data.items.length || !allDone}
                id='payrollButton'
                type='primary'
                style={{ marginTop: '20px' }}
              >
                Create and submit timesheet
              </Button>
              <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                <ClientExcel
                  salaries={
                    salaries
                      ? salaries.data.items.filter(
                          (item) =>
                            item.employee &&
                            item.month === selectedMonth &&
                            selectedYear &&
                            selectedYear === item.year &&
                            (item?.employee?.company?._id === selectedCompany ||
                              item?.employee?.company?.mainCompany?._id === selectedCompany ||
                              item?.employee?.company?._id === foundCompany ||
                              item?.employee?.company?.mainCompany?._id === foundCompany),
                        )
                      : []
                  }
                  month={selectedMonth}
                  year={selectedYear}
                  user={currentuser}
                  selectedCompany={selectedCompany}
                  language={selectedLang}
                />
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                <Select className='langSelector' defaultValue='english' onChange={(value) => setSelectedLang(value)}>
                  <Option value='english'>English</Option>
                  {/*<Option value='chinese'>Chinese</Option>*/}
                </Select>
                <QuestionCircleOutlined
                  style={{ marginLeft: '3px', color: '#b1b1b1' }}
                  onMouseEnter={() => (document.getElementById('langText').style.display = 'flex')}
                  onMouseLeave={() => (document.getElementById('langText').style.display = 'none')}
                />
                <span id='langText'>Language for client Excel.</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SalaryFormDownloadSection;
