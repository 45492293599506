import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { Button, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import Table from '../../components/tables/GuidelineTable';
import Axios from 'axios';
import moment from 'moment';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'index'
];

const GuidelineCategory = () => {
  const currentuser = useContext(UserContext);
  const [guidelineCategories, fetchGuidelineCategories] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchGuidelineCategories(`${SERVER_URL}/guideline-category`, []);
  }, [fetchGuidelineCategories]);

  let columnKeys;
  if (guidelineCategories.data && guidelineCategories.data.items && guidelineCategories.data.items.length > 0) {
    const keys = Object.keys(guidelineCategories.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (guidelineCategories.data && guidelineCategories.data.items && guidelineCategories.data.items.length > 0) {
    tableData = guidelineCategories.data.items.map((item) => {
      item.createdAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      item.updatedAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      return item;
    });
  }

  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/guideline-category/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/guideline-categories');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      {(currentuser.data.role.includes('admin') || currentuser.data.role.includes("SuperAdmin")) && <div className='actions-block'>
        <Link to='/admin/new-category'>
          <Button type='primary'>New category</Button>
        </Link>
      </div>}

      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {guidelineCategories?.isLoading && (
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
        )}
        {!guidelineCategories.isLoading && guidelineCategories.data && guidelineCategories.data.items && guidelineCategories.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteHandler}
            columnKeys={columnKeys}
            title='Category'
            editPath='/admin/edit-category/'
          />
        )}
        {!guidelineCategories.isLoading && guidelineCategories.data && guidelineCategories.data.items && guidelineCategories.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );

};

export default GuidelineCategory;