import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import SectorForm from '../../components/forms/SectorForm';
import { useHistory } from 'react-router-dom';

const EditSector = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const [clients, setClients] = useState([]);
  const { companyId } = props.match.params;

  /** Roles definition **/
  const allowedToAdmins = currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin');
  /** end of roles definition **/

  useEffect(() => {
    let filter = { role: { $in: ['hrOfficer'] } };
    if (allowedToAdmins) {
      fetchClients(filter);
    }
    fetchData(`${SERVER_URL}/sector/${companyId}`, {});
  }, []);

  const fetchClients = async(filter) => {
    await Axios.get(`${SERVER_URL}/users?filter=${JSON.stringify(filter)}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    }).then((res) => {
      setClients(res?.data);
    });
  };

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/sectors` : `${SERVER_URL}/sector/${companyId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Sector is ${isNew ? 'added.' : 'edited.'}`,
        placement: 'bottomRight',
      });
      if (!isNew) {
        history.push('/admin/sectors');
      }
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !data.isLoading && data.data && currentuser.language;
  return (
    <div style={{ padding: '8px' }}>
      <div>
        {!companyId && isDataFetched && (
          <SectorForm
            isNew={true}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            clients={clients.items}
          />
        )}
        {companyId && !data.isError && data.data && (
          <SectorForm
            isNew={false}
            data={data.data}
            clients={clients.items}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            location={props.location.search}
          />
        )}
      </div>
    </div>
  );
};

export default EditSector;
