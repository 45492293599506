import React, { useState, useContext } from 'react';
import { Form, Input, DatePicker, Button, Modal } from 'antd';
import moment from 'moment';
import { UserContext } from '../App';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { holidaysArray } from '../constants/holidays.js';

const RequestModal = ({
  open,
  handleOkRequestModal,
  companyTypeOfHR,
  typeOfLeave,
  handleCancelRequestModal,
  selectedDateLocalForModal,
}) => {
  const user = useContext(UserContext);
  const [form] = Form.useForm();
  const [reasonOfLeave, setReasonOfLeave] = useState('');
  const [reasonOfLeaveEng, setReasonOfLeaveEng] = useState('');
  const [otherReasonOfLeaveEngFieldVisible, setOtherReasonOfLeaveEngFieldVisible] = useState(false);
  const [otherReasonOfLeaveFieldVisible, setOtherReasonOfLeaveFieldVisible] = useState(false);
  const [dateOfCreation, setDateOfCreation] = useState(undefined);
  const [showText, setShowText] = useState(false);

  const onFinish = (values) => {
    values.startDate = selectedDateLocalForModal;
    values.typeOfLeave = typeOfLeave;
    handleOkRequestModal(values);
  };

  // Function to disable weekends and public holidays
  const disabledDate = (current) => {
    const isWeekend = moment(current).isoWeekday() >= 6;

    const isHoliday = holidaysArray.some((holiday) => moment(holiday).isSame(current, 'day'));

    return isWeekend || isHoliday;
  };

  // const handleReasonOfLeaveEng = (val) => {
  //   setReasonOfLeaveEng(val);
  //   if (val === 'Other') {
  //     setOtherReasonOfLeaveEngFieldVisible(true);
  //   } else {
  //     setOtherReasonOfLeaveEngFieldVisible(false);
  //   }
  // };
  //
  // const handleReasonOfLeave = (val) => {
  //   setReasonOfLeave(val);
  //   if (val === 'Ostalo') {
  //     setOtherReasonOfLeaveFieldVisible(true);
  //   } else {
  //     setOtherReasonOfLeaveFieldVisible(false);
  //   }
  // };

  // this handles show of question circle explanation
  const handleMouseEnter = () => {
    setShowText(true);
  };

  const handleMouseLeave = () => {
    setShowText(false);
  };

  const displayForm = (user) => {
    if (typeOfLeave === 'Holiday leave') {
      return (
        <Form.Item
          label='Date of request creation'
          name='dateOfCreation'
          labelCol={{ span: 3.5 }}
          labelAlign='left'
          style={{ marginTop: '-7px' }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <DatePicker
              disabledDate={disabledDate}
              onChange={(val) => setDateOfCreation(val)}
              format={'DD-MM-YYYY'}
              style={{ marginLeft: '8px' }}
            />
            <QuestionCircleOutlined
              style={{ marginLeft: '5px', marginTop: '1px', color: '#b1b1b1' }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            <span
              id='attachText'
              style={{ marginLeft: '200px', display: showText ? 'initial' : 'none' }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Select a date to manually set the request date. Otherwise, today's date will be the request date.
            </span>
          </div>
        </Form.Item>
      );
    }

    if (typeOfLeave === 'Non-paid leave') {
      return (
        <>
          {(user?.data?.role?.includes('admin') || user?.data?.role?.includes('SuperAdmin')) && (
            <div>
              <Form.Item
                label='Reason of leave - Serbian'
                name='reasonOfLeave'
                labelCol={{ span: 6 }}
                labelAlign='left'
              >
                {typeOfLeave === 'Non-paid leave' && (
                  <Input
                    placeholder='Molimo, unesite razlog odsustva'
                    onChange={(e) => setReasonOfLeave(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter reason of leave in Serbian!',
                      },
                    ]}
                  />
                )}
              </Form.Item>
              <Form.Item
                label='Reason of leave - English'
                name='reasonOfLeaveEng'
                labelCol={{ span: 6 }}
                labelAlign='left'
              >
                {typeOfLeave === 'Non-paid leave' && (
                  <Input
                    onChange={(e) => setReasonOfLeaveEng(e.target.value)}
                    placeholder='Please, specify the reason'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter reason of leave in English!',
                      },
                    ]}
                  />
                )}
              </Form.Item>
            </div>
          )}
          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('user') || user.data.role.includes('userOfficer')) &&
            user.data.company.typeOfHr === 'bilingual' && (
              <div>
                <Form.Item
                  label='Reason of leave - Serbian'
                  name='reasonOfLeave'
                  labelCol={{ span: 6 }}
                  labelAlign='left'
                >
                  {typeOfLeave === 'Non-paid leave' && (
                    <Input
                      onChange={(e) => setReasonOfLeave(e.target.value)}
                      placeholder='Molimo, unesite razlog odsustva'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave in Serbian!',
                        },
                      ]}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label='Reason of leave - English'
                  name='reasonOfLeaveEng'
                  labelCol={{ span: 6 }}
                  labelAlign='left'
                >
                  {typeOfLeave === 'Non-paid leave' && (
                    <Input
                      onChange={(e) => setReasonOfLeaveEng(e.target.value)}
                      placeholder='Please, specify the reason'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave in English!',
                        },
                      ]}
                    />
                  )}
                </Form.Item>
              </div>
            )}

          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('user') || user.data.role.includes('userOfficer')) &&
            user?.data?.company?.typeOfHr === 'serbian' && (
              <Form.Item label='Reason of leave' name='reasonOfLeave' labelCol={{ span: 6 }} labelAlign='left'>
                {typeOfLeave === 'Non-paid leave' && (
                  <Input
                    placeholder='Molimo, unesite razlog odsustva'
                    onChange={(e) => setReasonOfLeave(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter reason of leave!',
                      },
                    ]}
                  />
                )}
              </Form.Item>
            )}

          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('employee') ||
              (user.data.role.includes('hrOfficer') && user.data.role.includes('employee')) ||
              user.data.role.includes('hrOfficer')) &&
            (user?.data?.employeeCompany?.typeOfHr === 'bilingual' || companyTypeOfHR === 'bilingual') && (
              <div>
                <Form.Item
                  label='Reason of leave - Serbian'
                  name='reasonOfLeave'
                  labelCol={{ span: 6 }}
                  labelAlign='left'
                >
                  {typeOfLeave === 'Non-paid leave' && (
                    <Input
                      placeholder='Molimo, unesite razlog odsustva'
                      onChange={(e) => setReasonOfLeave(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave in Serbian!',
                        },
                      ]}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label='Reason of leave - English'
                  name='reasonOfLeaveEng'
                  labelCol={{ span: 6 }}
                  labelAlign='left'
                >
                  {typeOfLeave === 'Non-paid leave' && (
                    <Input
                      onChange={(e) => setReasonOfLeaveEng(e.target.value)}
                      placeholder='Please, specify the reason'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave in English!',
                        },
                      ]}
                    />
                  )}
                </Form.Item>
              </div>
            )}

          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('employee') ||
              (user.data.role.includes('hrOfficer') && user.data.role.includes('employee')) ||
              user.data.role.includes('hrOfficer')) &&
            (user?.data?.employeeCompany?.typeOfHr === 'serbian' || companyTypeOfHR === 'serbian') && (
              <Form.Item label='Reason of leave' name='reasonOfLeave' labelCol={{ span: 6 }} labelAlign='left'>
                {typeOfLeave === 'Non-paid leave' && (
                  <Input
                    placeholder='Molimo, unesite razlog odsustva'
                    onChange={(e) => setReasonOfLeave(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter reason of leave!',
                      },
                    ]}
                  />
                )}
              </Form.Item>
            )}
        </>
      );
    }
  };

  return (
    <Modal
      centered
      width={800}
      open={open}
      title={'Request creation'}
      // onCancel={resetRequestModal}
      footer={[
        <Button key='cancel' type='danger' onClick={handleCancelRequestModal} style={{ marginLeft: '10px' }}>
          Cancel
        </Button>,
        <Button type='primary' onClick={() => onFinish(form.getFieldsValue())}>
          Create
        </Button>,
      ]}
    >
      <Form
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        className='form-horizontal dataForm'
        layout='horizontal'
        form={form}
        labelAlign='left'
      >
        {displayForm(user)}
      </Form>
    </Modal>
  );
};

export default RequestModal;
