import React from 'react';

export default function CalculatorSubIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19.7896 0H5.21045C4.14893 0 3.2854 0.863574 3.2854 1.92505V23.075C3.2854 24.1365 4.14897 25 5.21045 25H19.7896C20.8511 25 21.7146 24.1364 21.7146 23.075V1.92505C21.7146 0.863574 20.851 0 19.7896 0Z'
        fill='#808080'
      />
      <path
        d='M6.69922 9.0606C5.85005 9.0606 5.15918 8.36973 5.15918 7.52056V2.90039C5.15918 2.05122 5.85005 1.36035 6.69922 1.36035H18.3009C19.15 1.36035 19.8409 2.05122 19.8409 2.90039V7.52051C19.8409 8.36968 19.15 9.06055 18.3009 9.06055H6.69922V9.0606Z'
        fill='#666666'
      />
      <path
        d='M18.3009 8.29058H6.69922C6.27397 8.29058 5.9292 7.9458 5.9292 7.52056V2.90039C5.9292 2.47515 6.27397 2.13037 6.69922 2.13037H18.3009C18.7261 2.13037 19.0709 2.47515 19.0709 2.90039V7.52051C19.0709 7.9458 18.7261 8.29058 18.3009 8.29058Z'
        fill='#AFF078'
      />
      <path d='M8.34194 2.13037L5.9292 4.54312V5.49282L9.29165 2.13037H8.34194Z' fill='white' />
      <path
        d='M10.6263 2.13037L5.9292 6.82749V7.52051C5.9292 7.79229 6.07036 8.03066 6.28301 8.16772L12.3204 2.13037H10.6263Z'
        fill='white'
      />
      <path
        d='M8.44458 13.2098H6.69922C6.27397 13.2098 5.9292 12.865 5.9292 12.4398V10.7033C5.9292 10.2781 6.27397 9.93329 6.69922 9.93329H8.44458C8.86982 9.93329 9.2146 10.2781 9.2146 10.7033V12.4398C9.2146 12.865 8.86982 13.2098 8.44458 13.2098Z'
        fill='#FFD652'
      />
      <path
        d='M6.95591 12.4398V10.7033C6.95591 10.2781 7.30068 9.93329 7.72593 9.93329H6.69922C6.27397 9.93329 5.9292 10.2781 5.9292 10.7033V12.4398C5.9292 12.865 6.27397 13.2098 6.69922 13.2098H7.72593C7.30068 13.2098 6.95591 12.865 6.95591 12.4398Z'
        fill='#FB9D46'
      />
      <path
        d='M8.44458 18.1468H6.69922C6.27397 18.1468 5.9292 17.802 5.9292 17.3768V15.6403C5.9292 15.2151 6.27397 14.8703 6.69922 14.8703H8.44458C8.86982 14.8703 9.2146 15.2151 9.2146 15.6403V17.3768C9.2146 17.802 8.86982 18.1468 8.44458 18.1468Z'
        fill='#FFD652'
      />
      <path
        d='M6.95591 17.3768V15.6403C6.95591 15.2151 7.30068 14.8703 7.72593 14.8703H6.69922C6.27397 14.8703 5.9292 15.2151 5.9292 15.6403V17.3768C5.9292 17.802 6.27397 18.1468 6.69922 18.1468H7.72593C7.30068 18.1468 6.95591 17.802 6.95591 17.3768Z'
        fill='#FB9D46'
      />
      <path
        d='M13.3727 18.1468H11.6273C11.2021 18.1468 10.8573 17.802 10.8573 17.3768V15.6403C10.8573 15.2151 11.2021 14.8703 11.6273 14.8703H13.3727C13.7979 14.8703 14.1427 15.2151 14.1427 15.6403V17.3768C14.1427 17.802 13.7979 18.1468 13.3727 18.1468Z'
        fill='#FFD652'
      />
      <path
        d='M11.884 17.3768V15.6403C11.884 15.2151 12.2288 14.8703 12.654 14.8703H11.6273C11.2021 14.8703 10.8573 15.2151 10.8573 15.6403V17.3768C10.8573 17.802 11.2021 18.1468 11.6273 18.1468H12.654C12.2288 18.1468 11.884 17.802 11.884 17.3768Z'
        fill='#FB9D46'
      />
      <path
        d='M8.44458 23.0661H6.69922C6.27397 23.0661 5.9292 22.7213 5.9292 22.296V20.5596C5.9292 20.1343 6.27397 19.7896 6.69922 19.7896H8.44458C8.86982 19.7896 9.2146 20.1343 9.2146 20.5596V22.296C9.2146 22.7213 8.86982 23.0661 8.44458 23.0661Z'
        fill='#FFD652'
      />
      <path
        d='M6.95591 22.296V20.5596C6.95591 20.1343 7.30068 19.7896 7.72593 19.7896H6.69922C6.27397 19.7896 5.9292 20.1343 5.9292 20.5596V22.296C5.9292 22.7213 6.27397 23.0661 6.69922 23.0661H7.72593C7.30068 23.0661 6.95591 22.7213 6.95591 22.296Z'
        fill='#FB9D46'
      />
      <path
        d='M13.3727 13.2098H11.6273C11.2021 13.2098 10.8573 12.865 10.8573 12.4398V10.7033C10.8573 10.2781 11.2021 9.93329 11.6273 9.93329H13.3727C13.7979 9.93329 14.1427 10.2781 14.1427 10.7033V12.4398C14.1427 12.865 13.7979 13.2098 13.3727 13.2098Z'
        fill='#FFD652'
      />
      <path
        d='M11.884 12.4398V10.7033C11.884 10.2781 12.2288 9.93329 12.654 9.93329H11.6273C11.2021 9.93329 10.8573 10.2781 10.8573 10.7033V12.4398C10.8573 12.865 11.2021 13.2098 11.6273 13.2098H12.654C12.2288 13.2098 11.884 12.865 11.884 12.4398Z'
        fill='#FB9D46'
      />
      <path
        d='M18.3008 18.1468H16.5554C16.1302 18.1468 15.7854 17.8021 15.7854 17.3768V10.7033C15.7854 10.278 16.1302 9.93326 16.5554 9.93326H18.3008C18.726 9.93326 19.0708 10.278 19.0708 10.7033V17.3768C19.0708 17.8021 18.726 18.1468 18.3008 18.1468Z'
        fill='#5AC779'
      />
      <path
        d='M16.8121 17.3768V10.7033C16.8121 10.278 17.1569 9.93326 17.5821 9.93326H16.5554C16.1302 9.93326 15.7854 10.278 15.7854 10.7033V17.3768C15.7854 17.8021 16.1302 18.1468 16.5554 18.1468H17.5821C17.1569 18.1468 16.8121 17.8021 16.8121 17.3768Z'
        fill='#00A085'
      />
      <path
        d='M18.3008 23.0661H16.5554C16.1302 23.0661 15.7854 22.7213 15.7854 22.296V20.5596C15.7854 20.1343 16.1302 19.7896 16.5554 19.7896H18.3008C18.726 19.7896 19.0708 20.1343 19.0708 20.5596V22.296C19.0708 22.7213 18.726 23.0661 18.3008 23.0661Z'
        fill='#FF7452'
      />
      <path
        d='M16.8121 22.296V20.5596C16.8121 20.1343 17.1569 19.7896 17.5821 19.7896H16.5554C16.1302 19.7896 15.7854 20.1343 15.7854 20.5596V22.296C15.7854 22.7213 16.1302 23.0661 16.5554 23.0661H17.5821C17.1569 23.0661 16.8121 22.7213 16.8121 22.296Z'
        fill='#E24642'
      />
      <path
        d='M13.3727 23.0661H11.6273C11.2021 23.0661 10.8573 22.7213 10.8573 22.296V20.5596C10.8573 20.1343 11.2021 19.7896 11.6273 19.7896H13.3727C13.7979 19.7896 14.1427 20.1343 14.1427 20.5596V22.296C14.1427 22.7213 13.7979 23.0661 13.3727 23.0661Z'
        fill='#FFD652'
      />
      <path
        d='M11.884 22.296V20.5596C11.884 20.1343 12.2288 19.7896 12.654 19.7896H11.6273C11.2021 19.7896 10.8573 20.1343 10.8573 20.5596V22.296C10.8573 22.7213 11.2021 23.0661 11.6273 23.0661H12.654C12.2288 23.0661 11.884 22.7213 11.884 22.296Z'
        fill='#FB9D46'
      />
      <path
        d='M16.6067 4.82543H16.273V3.7474H17.1201C17.3327 3.7474 17.5051 3.57509 17.5051 3.36239C17.5051 3.14969 17.3327 2.97738 17.1201 2.97738H16.273V2.84906C16.273 2.63636 16.1007 2.46405 15.888 2.46405C15.6754 2.46405 15.503 2.63636 15.503 2.84906V2.97738H15.1693C14.674 2.97738 14.271 3.38041 14.271 3.87572V4.69706C14.271 5.19237 14.674 5.5954 15.1693 5.5954H15.503V6.67342H14.656C14.4434 6.67342 14.271 6.84574 14.271 7.05844C14.271 7.27113 14.4434 7.44344 14.656 7.44344H15.503V7.57177C15.503 7.78446 15.6754 7.95677 15.888 7.95677C16.1007 7.95677 16.273 7.78446 16.273 7.57177V7.44344H16.6067C17.1021 7.44344 17.5051 7.04042 17.5051 6.54511V5.72377C17.5051 5.22845 17.1021 4.82543 16.6067 4.82543ZM15.1693 4.82543C15.0986 4.82543 15.041 4.76786 15.041 4.69711V3.87577C15.041 3.80497 15.0986 3.74745 15.1693 3.74745H15.503V4.82548L15.1693 4.82543ZM16.7351 6.54515C16.7351 6.61595 16.6774 6.67347 16.6067 6.67347H16.273V5.59545H16.6067C16.6775 5.59545 16.7351 5.65302 16.7351 5.72377V6.54515Z'
        fill='#00A085'
      />
      <path
        d='M4.93442 23.075V1.92505C4.93442 0.863574 5.798 0 6.85947 0H5.21045C4.14893 0 3.2854 0.863574 3.2854 1.92505V23.075C3.2854 24.1365 4.14897 25 5.21045 25H6.85947C5.79805 25 4.93442 24.1364 4.93442 23.075Z'
        fill='#666666'
      />
      <path
        d='M7.57178 12.577C7.35913 12.577 7.18677 12.4047 7.18677 12.192V10.96C7.18677 10.7473 7.35913 10.575 7.57178 10.575C7.78442 10.575 7.95679 10.7473 7.95679 10.96V12.192C7.95679 12.4047 7.78438 12.577 7.57178 12.577Z'
        fill='#4C4C4C'
      />
      <path
        d='M8.18784 11.961H6.95581C6.74316 11.961 6.5708 11.7887 6.5708 11.576C6.5708 11.3633 6.74316 11.191 6.95581 11.191H8.18784C8.40049 11.191 8.57285 11.3633 8.57285 11.576C8.57285 11.7887 8.40049 11.961 8.18784 11.961Z'
        fill='#4C4C4C'
      />
      <path
        d='M8.00739 17.3247C7.90885 17.3247 7.81032 17.2871 7.73517 17.212L6.86398 16.3408C6.71364 16.1904 6.71364 15.9466 6.86398 15.7963C7.01432 15.646 7.25812 15.646 7.40841 15.7963L8.2796 16.6675C8.42994 16.8179 8.42994 17.0616 8.2796 17.212C8.2045 17.2871 8.10597 17.3247 8.00739 17.3247Z'
        fill='#4C4C4C'
      />
      <path
        d='M7.13619 17.3247C7.03766 17.3247 6.93912 17.2871 6.86398 17.212C6.71364 17.0616 6.71364 16.8178 6.86398 16.6674L7.73517 15.7963C7.88551 15.646 8.12931 15.646 8.2796 15.7963C8.42994 15.9466 8.42994 16.1904 8.2796 16.3407L7.40841 17.2119C7.33326 17.2871 7.23473 17.3247 7.13619 17.3247Z'
        fill='#4C4C4C'
      />
      <path
        d='M12.0893 17.3247C11.9908 17.3247 11.8922 17.2871 11.8171 17.212C11.6668 17.0616 11.6668 16.8178 11.8171 16.6674L12.6883 15.7963C12.8386 15.646 13.0824 15.646 13.2327 15.7963C13.3831 15.9466 13.3831 16.1904 13.2327 16.3407L12.3615 17.2119C12.2864 17.2871 12.1879 17.3247 12.0893 17.3247Z'
        fill='#4C4C4C'
      />
      <path
        d='M7.10983 22.3042C7.01129 22.3042 6.91276 22.2666 6.83761 22.1914C6.68727 22.041 6.68727 21.7973 6.83761 21.6469L7.7088 20.7757C7.85914 20.6255 8.10294 20.6255 8.25323 20.7757C8.40358 20.9261 8.40358 21.1699 8.25323 21.3203L7.38204 22.1915C7.3069 22.2666 7.20831 22.3042 7.10983 22.3042Z'
        fill='#4C4C4C'
      />
      <path
        d='M6.9045 21.2526C6.80597 21.2526 6.70743 21.215 6.63229 21.1399C6.48195 20.9895 6.48195 20.7457 6.63229 20.5953L6.73497 20.4926C6.88531 20.3423 7.12911 20.3423 7.27941 20.4926C7.42975 20.643 7.42975 20.8868 7.27941 21.0371L7.17672 21.1398C7.10157 21.215 7.00299 21.2526 6.9045 21.2526Z'
        fill='#4C4C4C'
      />
      <path
        d='M8.13656 22.4846C8.03802 22.4846 7.93949 22.447 7.86434 22.3719C7.714 22.2215 7.714 21.9777 7.86434 21.8273L7.96703 21.7247C8.11737 21.5744 8.36117 21.5744 8.51146 21.7247C8.6618 21.875 8.6618 22.1188 8.51146 22.2692L8.40878 22.3719C8.33363 22.447 8.23505 22.4846 8.13656 22.4846Z'
        fill='#4C4C4C'
      />
      <path
        d='M13.1159 12.0123H11.8839C11.6713 12.0123 11.4989 11.84 11.4989 11.6273C11.4989 11.4146 11.6713 11.2423 11.8839 11.2423H13.1159C13.3286 11.2423 13.501 11.4146 13.501 11.6273C13.501 11.84 13.3286 12.0123 13.1159 12.0123Z'
        fill='#4C4C4C'
      />
      <path
        d='M18.044 13.6037H16.812C16.5994 13.6037 16.427 13.4314 16.427 13.2187C16.427 13.006 16.5994 12.8337 16.812 12.8337H18.044C18.2567 12.8337 18.4291 13.006 18.4291 13.2187C18.4291 13.4314 18.2567 13.6037 18.044 13.6037Z'
        fill='#4C4C4C'
      />
      <path
        d='M18.044 14.8358H16.812C16.5994 14.8358 16.427 14.6634 16.427 14.4507C16.427 14.238 16.5994 14.0657 16.812 14.0657H18.044C18.2567 14.0657 18.4291 14.238 18.4291 14.4507C18.4291 14.6634 18.2567 14.8358 18.044 14.8358Z'
        fill='#4C4C4C'
      />
      <path
        d='M17.4793 22.5359C16.8991 22.5359 16.427 22.0639 16.427 21.4836C16.427 20.9033 16.8991 20.4312 17.4793 20.4312C17.7523 20.4312 18.0111 20.5353 18.2081 20.7243C18.3614 20.8716 18.3664 21.1153 18.2192 21.2686C18.072 21.422 17.8283 21.427 17.6749 21.2798C17.6221 21.2291 17.5527 21.2012 17.4794 21.2012C17.3237 21.2012 17.1971 21.3279 17.1971 21.4835C17.1971 21.6392 17.3238 21.7659 17.4794 21.7659C17.5527 21.7659 17.6221 21.7379 17.675 21.6872C17.8284 21.5401 18.0721 21.5451 18.2193 21.6985C18.3665 21.8519 18.3614 22.0956 18.208 22.2429C18.011 22.4319 17.7522 22.5359 17.4793 22.5359Z'
        fill='#4C4C4C'
      />
      <path
        d='M12.5 21.8172C12.2874 21.8172 12.115 21.6449 12.115 21.4322V21.3295C12.115 21.1168 12.2874 20.9445 12.5 20.9445C12.7126 20.9445 12.885 21.1168 12.885 21.3295V21.4322C12.885 21.6449 12.7126 21.8172 12.5 21.8172Z'
        fill='#4C4C4C'
      />
    </svg>
  );
}
