import React, { useContext } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const SectorsTable = ({ data, deleteHandler, columnKeys, editPath, viewPath, page }) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item.originalName,
    title: item.alternativeName.toUpperCase(),
    dataIndex: ['title', 'category'].includes(item.originalName)
      ? [item.originalName, user.language ? user.language.selected.code : '']
      : item.originalName,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item.originalName)
        ? [item.originalName, user.language ? user.language.selected.code : '']
        : item.originalName,
    ),
  }));

  function onChange(page) {
    page && localStorage.setItem('pageNumber2', page);
    localStorage.getItem('pageNumber2') &&
      parseInt(localStorage.getItem('pageNumber2')) === 1 &&
      localStorage.removeItem('pageNumber2');
  }

  if (!page) {
    columns.push({
      title: 'Options',
      render: (text, record) => {
         return (
              <div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px'}}>
                  {!user.data.role.includes('hrOfficer') &&
                      <div style={{margin: '2px', padding: '4px', cursor: 'pointer'}}>
                          <Link to={editPath + record._id}>
                              <EditOutlined title={'Edit sector'} style={{textDecoration: 'none', color: 'black'}}/>
                          </Link>
                      </div>
                  }
                  <div style={{margin: '2px', padding: '4px', cursor: 'pointer'}}>
                      <Link to={viewPath + record._id}>
                          <EyeOutlined title={'View sector'} style={{textDecoration: 'none', color: 'black'}}/>
                      </Link>
                  </div>

                  {!user.data.role.includes('hrOfficer') &&
                      <div style={{margin: '2px', padding: '4px'}}>
                          <Popconfirm
                              disabled={record._id === user.data.id}
                              placement='left'
                              title={'This will delete this sector for this company. Do you want to proceed ?'}
                              onConfirm={() => {
                                  deleteHandler(record._id);
                              }}
                              okText='Ok'
                              cancelText='Cancel'
                          >
                              <DeleteOutlined title={'Delete sectors'}/>
                          </Popconfirm>
                      </div>
                  }
              </div>
          )
      },
    });
  }

  return (
    <div style={{ width: '60%' }}>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
          onChange: onChange,
          defaultCurrent: localStorage.getItem('pageNumber2') ? parseInt(localStorage.getItem('pageNumber2')) : 1,
        }}
      />
    </div>
  );
};

export default SectorsTable;
