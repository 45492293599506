import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Card, Layout, Tabs, Typography } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import moment from 'moment';

const { Content, Sider } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const ViewUser = (props, editPath) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [log, fetchLog] = useAxios('', [], currentuser.data.token, 'get');
  const [csv, fetchCSV] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
      fetchLog(`${SERVER_URL}/logs/${id}`, []);
    }
  }, [id, fetchUser, fetchLog]);

  const getUrl = (itemUrl) => {
    return itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
  };

  useEffect(() => {
    if (id) {
      let sevenDayBefor = moment().subtract(7, 'day').format('YYYY-MM-DD');
      let from = moment(sevenDayBefor).format('YYYY-MM-DD');
      let to = new Date();
      let fromTo = from + '||' + to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  }, [id, fetchCSV]);

  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>All clients</Button>
        </Link>
      </div>

      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background'>
            <Sider className='site-layout-background' width={300} theme='light'>
              <div className='logo' style={{ height: 'auto' }}>
                <Avatar size={100} src={user.data && user.data.avatar ? getUrl(user.data.avatar.url) : 'Logo'} />

                <Link to={'/admin/edit-user/' + id}>
                  <Button type='primary' block icon={<EditOutlined />} style={{ marginTop: '1rem' }}>
                    Edit client
                  </Button>
                </Link>
              </div>
              <Content style={{ padding: '15px', minHeight: 280 }}>
                <Title style={{ fontSize: '14px' }}> Role</Title>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px', textTransform: 'capitalize' }}>
                  {' '}
                  {user.data ? user.data.role[0] : 'Role'}
                </p>
                <Title style={{ fontSize: '14px' }}> EMAIL</Title>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  {' '}
                  {user.data ? user.data.email : 'Email'}
                </p>
              </Content>
            </Sider>
            <Content style={{ padding: '0 15px', minHeight: 280 }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PROFILE' key='1'>
                  <div className='card-wrapper'>
                    <Card title='Client information' bordered={false}>
                      <p>
                        <Text strong>Name: </Text>
                        {user.data ? user.data.firstName : 'No data'}
                      </p>
                      <p>
                        <Text strong>Surname: </Text>
                        {user.data ? user.data.lastName : 'No data'}
                      </p>
                      <p>
                        <Text strong>Email: </Text>
                        {user.data ? user.data.email : 'No data'}
                      </p>
                      <p>
                        <Text strong>Role: </Text>
                        {user.data ? user.data.role[0] : 'No data'}
                      </p>
                      <p>
                        <Text strong>Status: </Text>
                        {user.data ? user.data.status : 'No data'}
                      </p>
                    </Card>
                  </div>
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
    </div>
  );
};

export default ViewUser;
