import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';

const UsersTable = ({ data, deleteHandler, columnKeys, editPath, viewPath }) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const renderTextWithTooltip = (text) => {
    const maxLength = 30;
    if (text?.length > maxLength) {
      return (
        <Tooltip title={text}>
          <span
            style={{
              display: 'inline-block',
              maxWidth: '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {text}
          </span>
        </Tooltip>
      );
    }
    return text;
  };

  const columns = columnKeys.map((item) => ({
    key: item?.originalName,
    title: item?.alternativeName?.toUpperCase(),
    dataIndex: ['title', 'category'].includes(item.originalName)
      ? [item.originalName, user.language ? user.language.selected.code : '']
      : item.originalName,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item.originalName)
        ? [item.originalName, user.language ? user.language.selected.code : '']
        : item.originalName,
    ),
    render: renderTextWithTooltip,
  }));

  columns.push({
    title: 'Options',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {(user.data.role.includes('SuperAdmin') || user.data.role.includes('admin')) && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={editPath + record._id}>
              <EditOutlined title={`Edit client`} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )}

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined title={`View client`} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )}

        {(user.data.role.includes('SuperAdmin') || user.data.role.includes('admin')) && (
          <div style={{ margin: '2px', padding: '4px' }}>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={`This will delete client and ALL HIS RECORDS. Do you want do delete client?`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText='Ok'
              cancelText='Cancel'
            >
              <DeleteOutlined title={`Delete client`} />
            </Popconfirm>
          </div>
        )}
      </div>
    ),
  });

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default UsersTable;
