import React, { useEffect } from 'react';
import { Col, Select } from 'antd';

const { Option } = Select;

const SalaryFormDatePicker = ({
  setSelectedDate,
  setSelectedMonth,
  years,
  selectedMonth,
  selectedDate,
  setSelectedYear,
  selectedYear,
  setCalendarVisible,
  setShowCalendar,
  setSelectedEmployee,
}) => {
  useEffect(() => {
    if (localStorage.getItem('selectedYear')) {
      setSelectedDate(localStorage.getItem('selectedYear'));
    }
  }, []);
  return (
    <>
      <Col xs={20} md={10} className='salary-form-date-picker__wrapper'>
        <div className='panel-body'>
          <Select
            onChange={(value) => {
              setSelectedDate(value.toString());
              localStorage.setItem('selectedYear', value);
              setSelectedYear(value.toString());
              setCalendarVisible(false);
              setShowCalendar(false);
              setSelectedEmployee(undefined);
              localStorage.removeItem('calendarData');
              localStorage.removeItem('calendarDataBackup');
            }}
            className='select__year'
            defaultValue={selectedDate}
            value={selectedYear}
          >
            {years}
          </Select>

          <Select
            className='select_month'
            onChange={(value) => {
              setSelectedMonth(value);
              localStorage.setItem('selectedMonth', value);
              setCalendarVisible(false);
              setShowCalendar(false);
              setSelectedEmployee(undefined);
              localStorage.removeItem('calendarData');
              localStorage.removeItem('calendarDataBackup');
            }}
            value={selectedMonth}
          >
            <Option value='January'>January</Option>
            <Option value='February'>February</Option>
            <Option value='March'>March</Option>
            <Option value='April'>April</Option>
            <Option value='May'>May</Option>
            <Option value='June'>June</Option>
            <Option value='July'>July</Option>
            <Option value='August'>August</Option>
            <Option value='September'>September</Option>
            <Option value='October'>October</Option>
            <Option value='November'>November</Option>
            <Option value='December'>December</Option>
          </Select>
        </div>
      </Col>
    </>
  );
};

export default SalaryFormDatePicker;
