import React from 'react';

export default function ExcelIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.9551 2.13392H15.2723V0L1 2.29751V22.4361L15.2723 25V21.8371H23.9551C24.2181 21.851 24.4757 21.7557 24.6715 21.5721C24.8673 21.3885 24.9854 21.1315 25 20.8573V3.11282C24.9852 2.83878 24.8669 2.58201 24.6712 2.39859C24.4754 2.21517 24.218 2.12002 23.9551 2.13392ZM24.0923 21.02H15.2431L15.2286 19.3313H17.3603V17.3646H15.2123L15.202 16.2024H17.3603V14.2357H15.1857L15.1754 13.0735H17.3603V11.1067H15.1686V9.94457H17.3603V7.97783H15.1686V6.81566H17.3603V4.84892H15.1686V3.06097H24.0923V21.02Z'
        fill='#20744A'
      />
      <path d='M23 5H19V7H23V5Z' fill='#20744A' />
      <path d='M23 8H19V10H23V8Z' fill='#20744A' />
      <path d='M23 11H19V14H23V11Z' fill='#20744A' />
      <path d='M23 15H19V17H23V15Z' fill='#20744A' />
      <path d='M23 18H19V20H23V18Z' fill='#20744A' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.14881 8.37838L5.88435 8.26174L6.97533 11.779L8.26446 8.11664L10 8L7.89244 12.9938L10 18L8.16498 17.8549L6.926 14.0389L5.68621 17.7098L4 17.5353L5.95875 13.1133L4.14881 8.37838Z'
        fill='white'
      />
    </svg>
  );
}
