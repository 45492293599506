import React, { useEffect, useState } from 'react';
import { Form, Button, Select, notification, Checkbox, Typography, Divider, Tooltip } from 'antd';
import Axios from 'axios';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Title } = Typography;

const StatuaryDocsForm = ({ data, user, SERVER_URL }) => {
  const [form] = Form.useForm();
  const [allAuthorizedAdmins, setAllAuthorizedAdmins] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const options = [
    { label: 'PEP HLB', value: 'pep' },
    { label: 'CROSO HLB', value: 'croso' },
    { label: 'PoA certificate HLB', value: 'poa' },
  ];

  useEffect(() => {
    fetchAllAuthorizedAdmins().then((r) => setAllAuthorizedAdmins(r));
  }, []);

  const fetchAllAuthorizedAdmins = async () => {
    const filter = { statuaryAuthorization: true };
    try {
      const response = await Axios.get(`${SERVER_URL}/users?filter=${JSON.stringify(filter)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });

      return response.data.items;
    } catch (err) {
      console.log('err', err);
      notification.error({
        message: 'Problem with fetching authorized admins. Please try again later.',
        placement: 'bottomRight',
      });
    }
  };

  const onFinish = async (values) => {
    values.company = data._id;
    values.companyName = data.name;
    try {
      const response = await Axios.post(`${SERVER_URL}/statuary`, values, {
        responseType: 'blob',
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));

      let fileName = `statuary_docs_${data.name}.zip`;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      console.log('Error in creating statuary document:', err);
      let errorMessage = 'An error occurred';
      if (err.response && err.response.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const json = JSON.parse(reader.result);
            const { error, fields } = json;

            errorMessage = fields ? `${error}: ${fields.join(', ')}` : error;

            notification.error({
              message: errorMessage,
              placement: 'bottomRight',
              duration: 0,
            });
          } catch (parseError) {
            notification.error({
              message: errorMessage,
              placement: 'bottomRight',
              duration: 0,
            });
          }
        };
        reader.readAsText(err.response.data);
      } else {
        notification.error({
          message: errorMessage,
          placement: 'bottomRight',
          duration: 0,
        });
      }
    }
  };

  const onChange = (list) => {
    setCheckedList(list);
    setCheckAll(list.length === options.length);
    form.setFieldsValue({ statuaryType: list });
  };

  const onCheckAllChange = (e) => {
    const newCheckedList = e.target.checked ? options.map((option) => option.value) : [];
    setCheckedList(newCheckedList);
    setCheckAll(e.target.checked);
    form.setFieldsValue({ statuaryType: newCheckedList });
  };

  return (
    <div
      style={{
        margin: '20px',
        maxWidth: '900px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        padding: '20px',
        backgroundColor: '#fff',
      }}
    >
      <Title level={3} style={{ textAlign: 'left' }}>
        Statuary Document Form
      </Title>
      <Divider style={{ backgroundColor: '#2db1ab50', margin: '20px 0' }} />
      <Form form={form} layout='vertical' onFinish={onFinish}>
        <Form.Item
          name='statuaryType'
          label='Type of statuary document'
          rules={[
            {
              required: true,
              message: 'You have to choose at least one!',
            },
          ]}
        >
          <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} value={checkedList} onChange={onChange}>
            {options.map((option) => (
              <Checkbox key={option.value} value={option.value} style={{ margin: '5px 0' }}>
                {option.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              onChange={onCheckAllChange}
              checked={checkAll}
              indeterminate={checkedList.length > 0 && checkedList.length < options.length}
            >
              Check all documents
            </Checkbox>
          </div>
        </Form.Item>
        <Divider style={{ backgroundColor: '#2db1ab50', margin: '20px 0' }} />
        <Form.Item
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Grant authorization for:</span>
              <Tooltip
                title='The first authorized one will be used for authorization for PoA certificate.'
                color='#b1b1b1'
                placement='bottom'
                autoAdjustOverflow={true}
              >
                <QuestionCircleOutlined
                  style={{
                    marginLeft: '8px',
                    color: '#b1b1b1',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </div>
          }
          name='authorizedAdmins'
          rules={[
            {
              required: true,
              message: 'You have to choose at least one!',
            },
          ]}
        >
          <Select mode='multiple' placeholder='Select authorized admins'>
            {allAuthorizedAdmins?.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.firstName} {item.lastName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' block style={{ marginTop: '20px' }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default StatuaryDocsForm;
