import React, { useState, useContext } from 'react';
import { Form, Input, Button, Card, Checkbox, Modal, notification } from 'antd';
import { UserContext } from '../App';

import Axios from 'axios';
import { SERVER_URL } from '../config';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const Login = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const user = useContext(UserContext);

  const onFinish = async (values) => {
    user.handleLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    form
      .validateFields()
      .then(async (values) => {
        form.resetFields();
        try {
          await Axios.post(
            `${SERVER_URL}/forgot-password`,
            { email: values.email },
            { withCredentials: false },
          );
          notification.success({
            message: 'Email sent. Please check your inbox and follow instructions.',
            placement: 'bottomRight',
          });
        } catch (err) {
          console.log('\n Reset error = ', err.response.data.message);
          notification.error({
            message: 'Something went wrong! Please try later or contact support.',
            placement: 'bottomRight',
          });
        }
      })
      .then(() => setIsModalOpen(false))
      .catch((error) => {
        console.log('Validate Failed:', error);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='log'>
      <div className='card-wrapper'>
        <img style={{ marginBottom: '20px' }} className='login-logo' src='/logoHLB.png' alt='HLB Logo' />
        <Card title='Welcome to HLB dashboard' className='login-header' bordered={false}>
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item label='Username' name='email' rules={[{ required: true, message: 'Please enter username!' }]}>
              <Input />
            </Form.Item>

            <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please enter password!' }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item name='rememberMe' valuePropName='checked' style={{ marginTop: '-15px' }}>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Button
              type='link'
              style={{ position: 'absolute', bottom: '84px', right: '24px' }}
              onClick={() => showModal()}
            >
              Forgot Password?
            </Button>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button
                type='primary'
                htmlType='submit'
                style={{ backgroundColor: '#2db1ab', borderRadius: '0', width: '120px', color: 'white' }}
              >
                LOG IN
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
      <Modal
        title='Forgot Password'
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Submit'
        cancelText='Return'
      >
        <Form form={form} layout='vertical'>
          <Form.Item
            name='email'
            label='Enter your email to reset your password'
            rules={[{ required: true, message: 'Please enter email!' }]}
          >
            <Input type='email' />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Login;
