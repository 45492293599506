import React from 'react';
import { Form, Input, Radio, Select } from 'antd';

const { Option } = Select;

const DisplayDirector = ({
  directorData,
  citizenship,
  handleChange,
  handleRadioChange,
  handleDirectorCountry,
  countries,
  directorNumeration,
  shareholderDirectorNum,
}) => {
  return (
    <>
      <Form.Item label='Citizenship:'>
        <Radio.Group name='citizenship' defaultValue={citizenship} onChange={handleRadioChange}>
          <Radio value='serbian'>Serbian Citizen</Radio>
          <Radio value='foreign'> Foreign Citizen </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        required
        label={`${shareholderDirectorNum}${directorNumeration}.1 First Name:`}
      >
        <input
          required
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='firstName'
          value={directorData?.firstName}
          onChange={(e) => handleChange(directorData, e)}
        />
      </Form.Item>
      <Form.Item
        required
        label={`${shareholderDirectorNum}${directorNumeration}.2 Last Name:`}
      >
        <input
          required
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='lastName'
          value={directorData?.lastName}
          onChange={(e) => handleChange(directorData, e)}
        />
      </Form.Item>
      {citizenship === 'serbian' ? (
        <>
          <Form.Item
            // name={`jmbg${directorFormName}`}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please enter JMBG!',
            //   },
            // ]}
            required
            label={`${shareholderDirectorNum}${directorNumeration}.3 JMBG:`}
          >
            <input
              required
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='number'
              name='jmbg'
              value={directorData?.jmbg}
              onChange={(e) => handleChange(directorData, e)}
            />
          </Form.Item>
          <Form.Item
            // name='idNum'
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please enter ID number!',
            //   },
            // ]}
            required
            label={`${shareholderDirectorNum}${directorNumeration}.4 ID number:`}
          >
            <input
              required
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='number'
              name='idNum'
              value={directorData?.idNum}
              onChange={(e) => handleChange(directorData, e)}
            />
          </Form.Item>
        </>
      ) : citizenship === 'foreign' ? (
        <>
          <Form.Item
            // name='passportNum'
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please enter passport number!',
            //   },
            // ]}
            required
            label={`${shareholderDirectorNum}${directorNumeration}.3 Passport Number:`}
          >
            <input
              required
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '4px 11px',
                width: '100%',
                outline: 'none',
              }}
              type='number'
              name='passportNum'
              value={directorData?.passportNum}
              onChange={(e) => handleChange(directorData, e)}
            />
          </Form.Item>
        </>
      ) : (
        ''
      )}
      <p>Address:</p>
      <Form.Item
        // name='street'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please enter street!',
        //   },
        // ]}
        required
        label={`${shareholderDirectorNum}${directorNumeration}.${citizenship === 'serbian' ? '5' : '4'} Street:`}
      >
        <input
          required
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='street'
          value={directorData?.address?.street}
          onChange={(e) => handleChange(directorData.address, e)}
        />
      </Form.Item>
      <Form.Item
        // name='streetNum'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please enter street number!',
        //   },
        // ]}
        required
        label={`${shareholderDirectorNum}${directorNumeration}.${citizenship === 'serbian' ? '6' : '5'} Street Number:`}
      >
        <Input
          type='text'
          name='streetNum'
          value={directorData?.address?.streetNum}
          onChange={(e) => handleChange(directorData.address, e)}
        />
      </Form.Item>
      <Form.Item
        label={`${shareholderDirectorNum}${directorNumeration}.${
          citizenship === 'serbian' ? '7' : '6'
        } Apartment Number:`}
      >
        <Input
          type='text'
          name='aptNum'
          value={directorData?.address?.aptNum}
          onChange={(e) => handleChange(directorData.address, e)}
        />
      </Form.Item>
      <Form.Item
        // name='city'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please enter city!',
        //   },
        // ]}
        required
        label={`${shareholderDirectorNum}${directorNumeration}.${citizenship === 'serbian' ? '8' : '7'} City:`}
      >
        <input
          required
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='city'
          value={directorData?.address?.city}
          onChange={(e) => handleChange(directorData.address, e)}
        />
      </Form.Item>
      <Form.Item
        // name='country'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please enter country!',
        //   },
        // ]}
        required
        label={`${shareholderDirectorNum}${directorNumeration}.${citizenship === 'serbian' ? '9' : '8'} Country:`}
      >
        <Select
          showSearch
          // defaultValue='Serbia'
          style={{
            width: 140,
          }}
          value={directorData?.address?.country}
          name='country'
          onSelect={(values) => handleDirectorCountry(values)}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
          {countries.map((country, id) => {
            return (
              <Option key={id} value={country}>
                {country}
              </Option>
            );
          })}
        </Select>
        {/* <Input
          type='text'
          name='country'
          value={directorData.address.country}
          onChange={(e) => handleChange(directorData.address, e)}
        /> */}
      </Form.Item>
    </>
  );
};

export default DisplayDirector;
