import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';

const EmailSettings = ({ adminEmailsDrawer, setAdminEmailsDrawer }) => {
  return (
    <Form.Item label='Email notifications' name='adminEmailsSettings' style={{ alignItems: 'center' }}>
      <Checkbox
        checked={adminEmailsDrawer}
        onChange={() => setAdminEmailsDrawer(!adminEmailsDrawer)}
        style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip
            title='Here you can specify which email notifications the admin wants to receive.'
            color='#b1b1b1'
            placement='bottom'
            autoAdjustOverflow={true}
          >
            <QuestionCircleOutlined style={{ marginTop: '5px', marginLeft: '3px', color: '#b1b1b1' }} />
          </Tooltip>
        </div>
      </Checkbox>
    </Form.Item>
  );
};

export default EmailSettings;
