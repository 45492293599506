import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

export default function AddSectorIcon(props) {
  const { theme } = useThemeContext();
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_32_39)'>
        <path
          d='M25 12.4997C25 5.6055 19.3924 0 12.5004 0C5.60835 0 0 5.6055 0 12.4997C0 19.0127 5.00712 24.3774 11.3741 24.948H11.4048C11.5092 24.957 11.614 24.9649 11.7189 24.9715L11.7795 24.9751C11.8744 24.9808 11.9708 24.9851 12.0687 24.9879H12.1449C12.2631 24.9879 12.3814 24.9936 12.5011 24.9936C12.6207 24.9936 12.7269 24.9936 12.8394 24.9886H12.8872C12.9864 24.9858 13.0852 24.9817 13.1835 24.9765H13.2034C13.4252 24.9642 13.6456 24.9459 13.8645 24.9217C20.1168 24.2435 25 18.9315 25 12.4997ZM13.8218 23.732L13.7014 20.275L16.7203 18.4229C17.4268 18.9071 18.3453 18.9537 19.0972 18.5435C19.849 18.1333 20.3069 17.3357 20.2821 16.4796C20.2337 15.2806 19.2728 14.3197 18.0738 14.2713C17.3945 14.2469 16.7402 14.5292 16.292 15.0402C15.8437 15.5512 15.6489 16.2366 15.7615 16.907L13.6309 18.2248L13.4763 13.8688L16.4475 12.0516C17.4913 12.8311 18.9446 12.7465 19.8909 11.8512C20.8372 10.9559 21.0022 9.50955 20.2817 8.42418C19.5612 7.33882 18.1643 6.92925 16.9718 7.45375C15.7793 7.97824 15.1371 9.28468 15.4502 10.5492L13.405 11.8186L13.2839 8.34236C14.257 7.96611 14.8824 7.01189 14.839 5.96951C14.7883 4.75407 13.8484 3.76252 12.6373 3.64709C11.4263 3.53165 10.3159 4.32776 10.0366 5.51175C9.75718 6.69573 10.3945 7.90425 11.5294 8.34236L11.3086 15.545L9.08249 14.1267C9.23101 13.5197 9.16011 12.8798 8.88232 12.3201C8.4371 11.3941 7.53954 10.7373 6.51589 10.6732C5.24991 10.5906 4.08445 11.363 3.66735 12.5611C3.25024 13.7593 3.68405 15.0885 4.72765 15.8099C5.77124 16.5313 7.16798 16.4675 8.14147 15.6539L11.2445 17.6172L11.0578 23.7185C5.5015 23.0097 1.18607 18.249 1.18607 12.4997C1.19034 6.26372 6.26371 1.18892 12.5004 1.18892C18.737 1.18892 23.8104 6.26229 23.8104 12.4989C23.8104 18.2889 19.4372 23.0752 13.8218 23.732Z'
          fill={theme === 'dark' ? '#D1D1D1' : '#333E48'}
          // fill={theme === 'dark' ? '#D1D1D1' : '#D1D1D1'}
        />
        <path
          d='M12.429 4.60583C13.2418 4.60661 13.9005 5.26533 13.9013 6.07813C13.9013 6.89126 13.2421 7.55043 12.429 7.55043C11.6158 7.55043 10.9567 6.89126 10.9567 6.07813C10.9567 5.265 11.6158 4.60583 12.429 4.60583ZM17.9794 15.2152C18.7167 15.2148 19.3154 15.811 19.3182 16.5483C19.3196 17.0891 18.9951 17.5774 18.496 17.7855C17.9969 17.9937 17.4216 17.8806 17.0384 17.499C16.6552 17.1175 16.5397 16.5426 16.7457 16.0426C16.9517 15.5427 17.4387 15.2161 17.9794 15.2152ZM18.8033 11.3374C18.0222 11.7169 17.0814 11.3925 16.7003 10.6122C16.4215 10.0402 16.5148 9.35661 16.9366 8.8802C17.3584 8.40378 18.0257 8.22839 18.6273 8.43582C19.2288 8.64324 19.6462 9.19263 19.6847 9.82778C19.7232 10.4629 19.3753 11.0587 18.8033 11.3374ZM6.28516 11.7749C7.22191 11.7757 7.98111 12.5348 7.98189 13.4716C7.9815 14.4081 7.22213 15.167 6.28563 15.1669C5.34913 15.1668 4.58997 14.4076 4.58984 13.4711C4.58971 12.5346 5.34865 11.7753 6.28516 11.7749Z'
          fill='#009BDE'
        />
      </g>
      <path
        d='M9.76562 7.7381C9.76562 9.53383 10.5146 11.256 11.8479 12.5258C13.1812 13.7956 14.9895 14.5089 16.875 14.5089C18.7605 14.5089 20.5688 13.7956 21.9021 12.5258C23.2354 11.256 23.9844 9.53383 23.9844 7.7381C23.9844 5.94236 23.2354 4.22017 21.9021 2.95039C20.5688 1.68062 18.7605 0.967262 16.875 0.967262C14.9895 0.967262 13.1812 1.68062 11.8479 2.95039C10.5146 4.22017 9.76563 5.94236 9.76562 7.7381Z'
        fill='#4CAF50'
      />
      <path d='M15.8594 4.51389H17.8906V10.9623H15.8594V4.51389Z' fill='white' />
      <path d='M13.4896 6.77083H20.2605V8.70536H13.4896V6.77083Z' fill='white' />
      <defs>
        <clipPath id='clip0_32_39'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
