import React, { useEffect, useContext } from 'react';
import { Card, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import CustomerInvoiceForm from '../../components/forms/CustomerInvoiceForm';

const EditBuyer = (props) => {
  const currentuser = useContext(UserContext);
  const [customer, setCustomer] = useAxios('', null, currentuser.data.token, 'get');
  const [form] = Form.useForm();
  const { customerId } = props.match.params;
  useEffect(() => {
    let companyId = currentuser.data.company._id;
    setCustomer(`${SERVER_URL}/customer/${customerId}?filter=${JSON.stringify(companyId)}`, []);
  }, [customer, setCustomer]);

  return (
    <div style={{ padding: '8px' }}>
      <div style={{ textAlign: 'center' }}>
        {customerId && customer.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        <div className='dashboard'>
          <div className='card-wrapper'>
            {/* eslint-disable-next-line no-useless-concat */}
            <Card title='EDIT CUSTOMER' bordered={false} style={{ width: '100%' }}>
              {customerId && !customer.isLoading && !customer.isError && customer.data && (
                <CustomerInvoiceForm
                  customerForm={form}
                  SERVER_URL={SERVER_URL}
                  data={customer?.data}
                  setData={setCustomer}
                />
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBuyer;
