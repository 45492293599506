import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Select, notification, Button } from 'antd';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import Table from '../../components/tables/SectorsTable';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';

const { Option } = Select;

const Sectors = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [companiesFiltered, fetchCompaniesFiltered] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  const [company, setCompany] = useState(undefined);

  /** Roles definition **/
  const allowedRoles =
    currentuser.data.role.includes('SuperAdmin') ||
    currentuser.data.role.includes('admin') ||
    currentuser.data.role.includes('user') ||
    currentuser.data.role.includes('userOfficer');

  const allowedToAdmins = currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin');
  const allowedToOtherRoles = currentuser.data.role.includes('hrOfficer');
  const allowedToUser = currentuser.data.role.includes('user') || currentuser.data.role.includes('userOfficer');
  /** end of roles definition **/

  useEffect(() => {
    let initialCompany = localStorage.getItem('selectedCompany')
      ? localStorage.getItem('selectedCompany')
      : currentuser?.data?.company?._id;
    if (currentuser.data.sectorId) initialCompany = currentuser.data.sectorId;
    setCompany(initialCompany);
    localStorage.setItem('selectedCompany', initialCompany);
  }, []);

  useEffect(() => {
    if (allowedRoles) {
      const filter = { $or: [{ _id: company }, { mainCompany: company }] };
      fetchCompaniesFiltered(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      const filterData = { isSector: true };
      const filterForCompany = { company: company };
      fetchData(`${SERVER_URL}/sectors?filter=${JSON.stringify(filterForCompany)}`, []);
      fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filterData)}`, []);
    }

    if (allowedToOtherRoles) {
      const filterForCompany = { _id: currentuser.data.sectorId };
      fetchData(`${SERVER_URL}/sectors?filter=${JSON.stringify(filterForCompany)}`, []);
    }
  }, [
    fetchData,
    companies,
    fetchCompanies,
    currentuser.data.role,
    currentuser.data.id,
    company,
    fetchCompaniesFiltered,
    companiesFiltered,
  ]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/sector/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Sector is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/sectors');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let companiesList = [];

  if (companies?.data?.items) {
    companiesList = companies.data.items;
  }

  let columnKeys;
  let newColumnKeys;
  columnKeys = ['sectorName'];
  let alternativeNames = [];
  for (const columnKey of columnKeys) {
    if (columnKey === 'sectorName') {
      alternativeNames.push('sectors');
    }
  }
  let keyArray = columnKeys;
  newColumnKeys = columnKeys.map((item, index) => ({
    originalName: keyArray[index],
    alternativeName: alternativeNames[index],
  }));

  let tableData = [];
  if (data.data) {
    tableData = data.data.map((item, index) => {
      return item;
    });
  }

  if (currentuser.data.role.includes('hrOfficer') && currentuser.data.sectorId) {
    tableData = data.data.filter((item) => {
      if (item._id === currentuser.data.sectorId) {
        return item;
      }
    });
  }

  // if hrOfficer role is not connected yet with any company, prevent showing all sectors
  if (currentuser.data.role.includes('hrOfficer') && !currentuser.data.sectorId) {
    tableData = [];
  }

  return (
    <div>
      {!currentuser.data.role.includes('hrOfficer') && (
        <div style={{ padding: '8px' }}>
          {allowedRoles && (
            <div className='actions-block'>
              <Link to='/admin/add-sectors'>
                <Button type='primary'>New sector</Button>
              </Link>
            </div>
          )}
          {allowedToAdmins && (
            <>
              <p style={{ marginTop: '20px' }}>Choose company:</p>
              <Select
                allowClear={true}
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  localStorage.setItem('selectedCompany', value);
                  setCompany(value);
                }}
                style={{ width: '45%', marginBottom: '20px' }}
                defaultValue={localStorage.getItem('selectedCompany')}
              >
                {companiesList.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </>
          )}
        </div>
      )}

      <div
        style={{
          textAlign: 'center',
          padding: '0 8px 8px 8px',
          marginTop: allowedToUser || allowedToOtherRoles ? '50px' : '',
        }}
      >
        {tableData && tableData.length ? (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={newColumnKeys}
            editPath='/admin/edit-sector/'
            viewPath='/admin/view-sector/'
            companyId={company}
          />
        ) : (
          <div style={{ marginTop: '100px' }}>No Data</div>
        )}
      </div>
    </div>
  );
};

export default Sectors;
