import React from 'react';

export default function NewRequestIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_79_161)'>
        <path
          d='M17.3732 0C17.7533 0 18.1203 0.138529 18.4056 0.38965L21.5168 3.12824C21.8538 3.42487 22.0469 3.85213 22.0469 4.30109V22.6562C22.0469 23.9507 20.9975 25 19.7031 25H3.29688C2.00246 25 0.953125 23.9507 0.953125 22.6562V2.34375C0.953125 1.04933 2.00246 0 3.29688 0H17.3732Z'
          fill='url(#paint0_linear_79_161)'
        />
        <path
          d='M17.3732 0C17.7533 0 18.1203 0.138529 18.4056 0.38965L21.5168 3.12824C21.8538 3.42487 22.0469 3.85213 22.0469 4.30109V5.46875H19.7031C18.4087 5.46875 17.3594 4.41942 17.3594 3.125V0H17.3732Z'
          fill='url(#paint1_linear_79_161)'
        />
        <path
          d='M15.7969 8.59375C16.2283 8.59375 16.5781 8.94353 16.5781 9.375C16.5781 9.80647 16.2283 10.1562 15.7969 10.1562H7.20312C6.77165 10.1562 6.42188 9.80647 6.42188 9.375C6.42188 8.94353 6.77165 8.59375 7.20312 8.59375H15.7969Z'
          fill='url(#paint2_linear_79_161)'
        />
        <path
          d='M15.7969 11.7188C16.2283 11.7188 16.5781 12.0685 16.5781 12.5C16.5781 12.9315 16.2283 13.2812 15.7969 13.2812H7.20312C6.77165 13.2812 6.42188 12.9315 6.42188 12.5C6.42188 12.0685 6.77165 11.7188 7.20312 11.7188H15.7969Z'
          fill='url(#paint3_linear_79_161)'
        />
        <path
          d='M15.7969 14.8438C16.2283 14.8438 16.5781 15.1935 16.5781 15.625C16.5781 16.0565 16.2283 16.4062 15.7969 16.4062H7.20312C6.77165 16.4062 6.42188 16.0565 6.42188 15.625C6.42188 15.1935 6.77165 14.8438 7.20312 14.8438H15.7969Z'
          fill='url(#paint4_linear_79_161)'
        />
        <path
          d='M10.7656 8.125C10.7656 10.0105 11.5146 11.8188 12.8479 13.1521C14.1812 14.4854 15.9895 15.2344 17.875 15.2344C19.7605 15.2344 21.5688 14.4854 22.9021 13.1521C24.2354 11.8188 24.9844 10.0105 24.9844 8.125C24.9844 6.23948 24.2354 4.43118 22.9021 3.09791C21.5688 1.76465 19.7605 1.01563 17.875 1.01562C15.9895 1.01563 14.1812 1.76465 12.8479 3.09791C11.5146 4.43118 10.7656 6.23948 10.7656 8.125Z'
          fill='#4CAF50'
        />
        <path d='M16.8594 4.73959H18.8906V11.5104H16.8594V4.73959Z' fill='white' />
        <path d='M14.4896 7.10938H21.2605V9.14062H14.4896V7.10938Z' fill='white' />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_79_161'
          x1='1055.64'
          y1='0'
          x2='1055.64'
          y2='2500'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#EDEEF4' />
          <stop offset='1' stop-color='#D7D8E6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_79_161'
          x1='251.734'
          y1='0'
          x2='251.734'
          y2='539.345'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#DEDEE7' />
          <stop offset='1' stop-color='#CFD0E8' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_79_161'
          x1='1022.05'
          y1='86.7188'
          x2='6.42188'
          y2='86.7188'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C3C4D4' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_79_161'
          x1='1022.05'
          y1='89.8438'
          x2='6.42188'
          y2='89.8438'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C3C4D4' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_79_161'
          x1='1022.05'
          y1='92.9688'
          x2='6.42188'
          y2='92.9688'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C3C4D4' />
          <stop offset='1' stop-color='#AEAFC8' />
        </linearGradient>
        <clipPath id='clip0_79_161'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
