import React from 'react';

export default function AllClientsIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.314 2.61914C16.3023 2.61914 14.6658 4.25576 14.6658 6.26739C14.6658 8.27906 16.3023 9.91569 18.314 9.91569C20.3257 9.91569 21.9623 8.27911 21.9623 6.26739C21.9623 4.25572 20.3257 2.61914 18.314 2.61914Z'
        fill='#FFC44F'
      />
      <path
        d='M17.9725 13C16.7371 13 15.5217 13.4914 14.4578 14.4211C14.0195 14.8041 13.8699 15.651 14.1233 16.3131C14.3767 16.9747 14.937 17.2007 15.375 16.8189L17.5146 22.4172C17.2406 22.6567 17.0558 23.103 17.0558 23.6154C17.0558 24.3801 17.4662 25 17.9724 25H24.0834C24.5896 25 25 24.38 25 23.6154C25.0001 17.762 21.8475 13 17.9725 13Z'
        fill='#D6E5F6'
      />
      <path
        d='M6.68609 2.61914C4.67442 2.61914 3.03784 4.25576 3.03784 6.26739C3.03784 8.27906 4.67442 9.91569 6.68609 9.91569C8.69776 9.91569 10.3343 8.27911 10.3343 6.26739C10.3343 4.25572 8.69781 2.61914 6.68609 2.61914Z'
        fill='#FFC44F'
      />
      <path
        d='M10.5423 14.4211C9.47836 13.4913 8.26295 13 7.02753 13C3.15256 13 0 17.7621 0 23.6154C0 24.3801 0.410423 25 0.916612 25H7.02753C7.53377 25 7.94414 24.38 7.94414 23.6154C7.94414 23.1029 7.75943 22.6565 7.48537 22.4172L9.62494 16.819C10.0629 17.2009 10.6233 16.9748 10.8766 16.3132C11.1302 15.651 10.9805 14.8041 10.5423 14.4211Z'
        fill='#A4C6EC'
      />
      <path
        d='M11 13C6.58881 13 3 17.7621 3 23.6154C3 24.3801 3.46722 25 4.04345 25H17.9565C18.5328 25 19 24.38 19 23.6154C19 17.762 15.4112 13 11 13Z'
        fill='#D6E5F6'
      />
      <path d='M12 13V25H20.6957C21.4161 25 22 24.38 22 23.6154C22 17.762 17.514 13 12 13Z' fill='#A4C6EC' />
      <path
        d='M12.5 12.6599C12.0184 12.6599 11.6279 13.1399 11.6279 13.7319V22.3079C11.6279 22.9 12.0184 23.3799 12.5 23.3799C12.9816 23.3799 13.3721 22.8999 13.3721 22.3079V13.7319C13.3721 13.1398 12.9816 12.6599 12.5 12.6599Z'
        fill='#1D3366'
      />
      <path
        d='M12.5 1C10.0187 1 8 3.01871 8 5.49997C8 7.98129 10.0187 10 12.5 10C14.9813 10 17 7.98135 17 5.49997C17 3.01865 14.9813 1 12.5 1Z'
        fill='#FFAB33'
      />
    </svg>
  );
}
