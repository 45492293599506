import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import GuideCategoryForm from '../../components/forms/GuideCategoryForm';


const EditGuidelineCategory = (props) => {
  const currentuser = useContext(UserContext);
  const { id } = props.match.params;
  const [guideCategory, fetchGuideCategory] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  useEffect(() => {
    if (id) {
      fetchGuideCategory(`${SERVER_URL}/guideline-category/${id}`, []);
    }
  }, [id, fetchGuideCategory]);

  const createCategory = async (data) => {
    try {
      await Axios.post(
        `${SERVER_URL}/guideline-category`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Category is created.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/guideline-categories');
    } catch (err) {
      notification.error({
        message: 'Problem with create. Please try later',
        placement: 'bottomRight',
      });
    }
  };


  const updateCategory = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/guideline-category/${id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Category is edited.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/guideline-categories');
    } catch (err) {
      notification.error({
        message: 'Problem with edit. Please try later',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div style={{ textAlign: 'center' }}>
        {id && guideCategory.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {guideCategory && !guideCategory.isLoading && !guideCategory.isError && guideCategory.data && (
          <GuideCategoryForm data={guideCategory.data} updateHandler={updateCategory} SERVER_URL={SERVER_URL} token={currentuser.data.token}
            user={currentuser.data} />
        )}
        {guideCategory && !guideCategory.isLoading && guideCategory.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!id && guideCategory && !guideCategory.data && (
          <GuideCategoryForm data={null} createHandler={createCategory} SERVER_URL={SERVER_URL} token={currentuser.data.token}
            user={currentuser.data} />
        )}
      </div>
    </div>
  );
};
export default EditGuidelineCategory;
