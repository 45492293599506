import React, { useContext } from 'react';
import { Table, Popconfirm, Input, Button, notification, Spin, Tooltip } from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
  EyeOutlined,
  SearchOutlined,
  VerticalAlignBottomOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import * as FileSaver from 'file-saver';

const HRTable = ({ data, deleteHandler, columnKeys, title, typeOfHr, focusedCompany }) => {
  const user = useContext(UserContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  let searchInput;

  const acceptRequest = async (record) => {
    setLoading(true);
    try {
      if (typeOfHr === 'serbian' || !typeOfHr) {
        if (record.typeOfLeave === 'Annual leave') {
          notification.info({
            message: 'Calculating number of days left of annual leave...',
            placement: 'bottomRight',
          });
          // this call is for calculating holiday days
          focusedCompany?.holidayDaysTracker && await Axios.put(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Overtime') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Paid days off') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Unpaid days off') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Slava - Saint day') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Pregnancy leave') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Maternity leave') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Bonus') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        }
        setLoading(false);
        notification.success({
          message: 'The leave has been accepted.',
          placement: 'bottomRight',
        });
        setTimeout(() => {
          history.go(0);
        }, 1800);
      } else if (typeOfHr === 'bilingual') {
        if (record.typeOfLeave === 'Annual leave') {
          notification.info({
            message: 'Calculating number of days left of annual leave...',
            placement: 'bottomRight',
          });
          // this call is for calculating holiday days
          focusedCompany?.holidayDaysTracker && await Axios.put(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );

          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Overtime') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Paid days off') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Unpaid days off') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Slava - Saint day') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Pregnancy leave') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Maternity leave') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Bonus') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });
          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        }
        setLoading(false);
        notification.success({
          message: 'The leave has been accepted.',
          placement: 'bottomRight',
        });
        setTimeout(() => {
          history.go(0);
        }, 1800);
      }
    } catch (err) {
      setLoading(false);
      notification.error({
        message: 'Problem with accepting. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const downloadRequest = async (record) => {
    try {
      if (typeOfHr === 'serbian' || !typeOfHr) {
        if (record.typeOfLeave === 'Annual leave') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestAnnualLeave = await Axios.get(
              `${SERVER_URL}/download-go/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
                requestAnnualLeave.config.url,
              `${record.documentName} - Godišnji - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestAnnualLeavePdf = await Axios.get(
              `${SERVER_URL}/download-go/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestAnnualLeavePdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                  requestAnnualLeavePdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Godišnji - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestAnnualLeavePdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                  requestAnnualLeavePdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Godišnji - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Overtime') {
          const requestOvertime = await Axios.get(
            `${SERVER_URL}/download-overtime/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );

          FileSaver.saveAs(
            requestOvertime.config.url,
            `${record.documentName} - Prekovremeni - ${record.firstName} ${record.lastName}.doc`,
          );
        } else if (record.typeOfLeave === 'Paid days off') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestPaid = await Axios.get(
              `${SERVER_URL}/download-paid/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPaid.config.url,
              `${record.documentName} - Plaćeno - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestPaidPdf = await Axios.get(
              `${SERVER_URL}/download-paid/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestPaidPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                requestPaidPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Plaćeno - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestPaidPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                requestPaidPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Plaćeno - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Unpaid days off') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestUnpaid = await Axios.get(
              `${SERVER_URL}/download-unpaid/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestUnpaid.config.url,
              `${record.documentName} - Neplaćeno - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestUnpaidPdf = await Axios.get(
              `${SERVER_URL}/download-unpaid/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestUnpaidPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                requestUnpaidPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Neplaćeno - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            }
            else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestUnpaidPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                requestUnpaidPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Neplaćeno - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Slava - Saint day') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestHoliday = await Axios.get(
              `${SERVER_URL}/download-holiday/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestHoliday.config.url,
              `${record.documentName} - Slava - ${record.firstName} ${record.lastName}.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestHolidayPdf = await Axios.get(
              `${SERVER_URL}/download-holiday/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestHolidayPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                requestHolidayPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Slava - ${record.firstName} ${record.lastName}.zip`,
              );
            }
            else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestHolidayPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                requestHolidayPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Slava - ${record.firstName} ${record.lastName}.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Pregnancy leave') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestPregnancy = await Axios.get(
              `${SERVER_URL}/download-pregnancy/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPregnancy.config.url,
              `${record.documentName} - Trudničko - ${record.firstName} ${record.lastName}.doc`,
            );
          }  else if (focusedCompany.documentReturnType === 'pdf') {
            const requestPregnancyPdf = await Axios.get(
              `${SERVER_URL}/download-pregnancy/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestPregnancyPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Trudničko - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            }
            else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestPregnancyPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Trudničko - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Maternity leave') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestPregnancy = await Axios.get(
              `${SERVER_URL}/download-maternity/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPregnancy.config.url,
              `${record.documentName} - Porodiljsko - ${record.firstName} ${record.lastName}.doc`,
            );
          }  else if (focusedCompany.documentReturnType === 'pdf') {
            const requestPregnancyPdf = await Axios.get(
              `${SERVER_URL}/download-maternity/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestPregnancyPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Porodiljsko - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            }
            else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestPregnancyPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Porodiljsko - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Bonus') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestBonus = await Axios.get(
              `${SERVER_URL}/download-bonus/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestBonus.config.url,
              `${record.documentName} - Bonus - ${record.firstName} ${record.lastName}.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestBonusPdf = await Axios.get(
              `${SERVER_URL}/download-bonus/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestBonusPdf.config.url,
              `${record.documentName} - Bonus - ${record.firstName} ${record.lastName}.pdf`,
            );
          }
        }
      } else if (typeOfHr === 'bilingual') {
        if (record.typeOfLeave === 'Annual leave') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestGodisnji = await Axios.get(
              `${SERVER_URL}/download-annual/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            FileSaver.saveAs(
              requestGodisnji.config.url,
              `${record.documentName} - Annual-leave - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestGodisnjiPdf = await Axios.get(
              `${SERVER_URL}/download-annual/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestGodisnjiPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                  requestGodisnjiPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Annual-leave - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            }
            else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestGodisnjiPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                  requestGodisnjiPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Annual-leave - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Overtime') {
          const requestOvertime = await Axios.get(
            `${SERVER_URL}/download-overtime-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
          FileSaver.saveAs(
            requestOvertime.config.url,
            `${record.documentName} - Overtime - ${record.firstName} ${record.lastName}.doc`,
          );
        } else if (record.typeOfLeave === 'Paid days off') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestPaid = await Axios.get(
              `${SERVER_URL}/download-paid-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPaid.config.url,
              `${record.documentName} - Paid-leave - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestPaidPdf = await Axios.get(
              `${SERVER_URL}/download-paid-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestPaidPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                requestPaidPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Paid-leave - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            }
            else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestPaidPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                requestPaidPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Paid-leave - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Unpaid days off') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestUnpaid = await Axios.get(
              `${SERVER_URL}/download-unpaid-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestUnpaid.config.url,
              `${record.documentName} - Unpaid-leave - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestUnpaidPdf = await Axios.get(
              `${SERVER_URL}/download-unpaid-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestUnpaidPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                requestUnpaidPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Unpaid-leave - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            }

            if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestUnpaidPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                requestUnpaidPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Unpaid-leave - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Slava - Saint day') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestHoliday = await Axios.get(
              `${SERVER_URL}/download-holiday-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestHoliday.config.url,
              `${record.documentName} - Holiday - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestHolidayPdf = await Axios.get(
              `${SERVER_URL}/download-holiday-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestHolidayPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                requestHolidayPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Saint day - slava - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            }
            else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestHolidayPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                requestHolidayPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Saint day - slava - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Pregnancy leave') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestPregnancy = await Axios.get(
              `${SERVER_URL}/download-pregnancy-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPregnancy.config.url,
              `${record.documentName} - Pregnancy - leave - ${record.firstName} ${record.lastName}.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestPregnancyPdf = await Axios.get(
              `${SERVER_URL}/download-pregnancy-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestPregnancyPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Pregnancy - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            }
            else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestPregnancyPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Pregnancy - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Maternity leave') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestPregnancy = await Axios.get(
              `${SERVER_URL}/download-maternity-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPregnancy.config.url,
              `${record.documentName} - Maternity - leave - ${record.firstName} ${record.lastName}.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestPregnancyPdf = await Axios.get(
              `${SERVER_URL}/download-maternity-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (focusedCompany.documentSeparateRequestResponse === 'separated' && requestPregnancyPdf.headers['content-type'] === 'application/zip') {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Maternity - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            }
            else if (focusedCompany.documentSeparateRequestResponse !== 'separated' && requestPregnancyPdf.headers['content-type'] === 'application/pdf') {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Maternity - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        }
        else if (record.typeOfLeave === 'Bonus') {
          if ((focusedCompany.documentReturnType === 'word-pdf' || focusedCompany.documentReturnType === 'word') || !record.documentNamePdf) {
            const requestBonus = await Axios.get(
              `${SERVER_URL}/download-bonus-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestBonus.config.url,
              `${record.documentName} - Bonus-bil - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (focusedCompany.documentReturnType === 'pdf') {
            const requestBonusPdf = await Axios.get(
              `${SERVER_URL}/download-bonus-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestBonusPdf.config.url,
              `${record.documentName} - Bonus-bil - ${record.firstName} ${
                record.lastName
              }.pdf`,
            );
          }
        }
      }
    } catch (err) {
      notification.error({
        message: 'Problem with download. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const denyRequest = async (record) => {
    try {
      await Axios.post(
        `${SERVER_URL}/denied`,
        {
          record,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.data.token}` },
        },
      );
      notification.success({
        message: 'The leave has been denied.',
        placement: 'bottomRight',
      });
      history.go(0);
    } catch (err) {
      notification.error({
        message: 'Problem with denying. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title:
        item === 'firstName'
            ? 'first name'
            : item === 'lastName'
                ? 'surname'
                : item === '_id'
                    ? 'id'
                    : item === 'typeOfLeave'
                        ? 'type of leave'
                        : item === 'createdAt'
                            ? 'created at'
                            : item === 'status'
                                ? 'status of leave'
                                : item,
    dataIndex:
        item === 'createdBy'
            ? 'user'
            : ['title', 'category'].includes(item)
                ? [item, user.language ? user.language.selected.code : '']
                : item,
    render: (text) => {
      const displayText = text.length > 35 ? `${text.slice(0, 35)}...` : text;
      return (
          <Tooltip title={text.length > 35 ? text : null}>
            <span>{displayText}</span>
          </Tooltip>
      );
    },
    ...getColumnSearchProps(
        item === 'createdBy'
            ? 'user'
            : ['title', 'category'].includes(item)
                ? [item, user.language ? user.language.selected.code : '']
                : item,
    ),
  }));

  columns.push({
    title: 'Options',
    render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px', gap: '10px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={`/admin/view-request/${record._id}`}>
              <EyeOutlined title={`View ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>

          {record.status === 'in progress' &&
          (user.data.role.includes('SuperAdmin') ||
              user.data.role.includes('admin') ||
              user.data.role.includes('hrOfficer') ||
              user.data.role.includes('user') ||
              user.data.role.includes('userOfficer')) ? (
              <>
                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                  <CheckOutlined onClick={() => acceptRequest(record)} title='Approve request' />
                </div>

                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                  <CloseOutlined onClick={() => denyRequest(record)} title='Deny request' />
                </div>
              </>
          ) : (
              ''
          )}

          {record.status === 'in progress' ? (
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                <Link to={`/admin/edit-request/${record._id}`}>
                  <EditOutlined title='Edit request' style={{ textDecoration: 'none', color: 'black' }} />
                </Link>
              </div>
          ) : (
              ''
          )}

          {record.status === 'accepted' ? (
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                <VerticalAlignBottomOutlined title='Download document' onClick={() => downloadRequest(record)} />
              </div>
          ) : (
              ''
          )}

          {user.data.role.includes('SuperAdmin') ||
          user.data.role.includes('admin') ||
          user.data.role.includes('hrOfficer') ||
          user.data.role.includes('user') ||
          user.data.role.includes('userOfficer') ? (
              <div style={{ margin: '2px', padding: '4px' }}>
                <Popconfirm
                    disabled={record._id === user.data.id}
                    placement='left'
                    title={`This will delete ${title.toLowerCase()}`}
                    onConfirm={() => {
                      deleteHandler(record._id);
                    }}
                    okText='Ok'
                    cancelText='Cancel'
                >
                  <DeleteOutlined title='Delete request' />
                </Popconfirm>
              </div>
          ) : (
              ''
          )}
        </div>
    ),
  });

  return (
      <Spin spinning={loading} size='large'>
        <div style={{ marginTop: '13px' }}>
          <Table
              size='middle'
              bordered
              dataSource={data}
              columns={columns}
              rowKey='_id'
              pagination={{
                defaultPageSize: 20,
                position: 'bottom',
                showSizeChanger: false,
                pageSizeOptions: ['10', '20', '50', '100'],
                hideOnSinglePage: true,
              }}
          />
        </div>
      </Spin>
  );
};

export default HRTable;
