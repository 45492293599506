import React from 'react';

export default function EmployeeIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19.5685 19.6295L18.6828 14.3272H18.6802C18.5572 13.6102 18.0643 12.8677 17.2251 12.6328C17.2147 12.6298 17.2044 12.6276 17.1937 12.6261L15.1272 12.316C15.1145 12.3141 15.0875 12.313 15.0746 12.313C14.9439 12.313 14.8336 12.4105 14.8177 12.5403C14.7077 13.4338 13.969 14.1353 13.0184 14.3272H11.9812C11.0306 14.1353 10.2918 13.4339 10.1821 12.5403C10.1659 12.4067 10.0455 12.3063 9.91859 12.3132C9.90341 12.3123 9.88851 12.3136 9.8726 12.316L7.80604 12.6261C7.79545 12.6276 7.78505 12.6299 7.7747 12.6328C6.93544 12.8677 6.44252 13.6103 6.31957 14.3272H6.31698L5.43124 19.6295C5.24799 20.5923 5.98617 21.4844 6.9663 21.4844H7.03124H17.9687H18.0337C19.0138 21.4844 19.7519 20.5923 19.5685 19.6295Z'
        fill='#FF4F19'
      />
      <path
        d='M12.5 12.0542C11.5957 12.0542 10.7753 11.6662 10.17 11.039C10.1695 11.4149 10.1559 11.9574 10.0562 12.3532C10.1216 12.3932 10.1723 12.4588 10.1822 12.5404C10.3114 13.5916 11.3077 14.3842 12.5 14.3842C13.6921 14.3842 14.6884 13.5915 14.8178 12.5404C14.8279 12.4586 14.8775 12.3932 14.9439 12.3533C14.8442 11.9575 14.8305 11.4149 14.83 11.039C14.2247 11.6662 13.4043 12.0542 12.5 12.0542Z'
        fill='#FDC88E'
      />
      <path
        d='M14.7795 11.0867C14.1792 11.6846 13.3801 12.0542 12.5 12.0542C11.5957 12.0542 10.7753 11.6662 10.17 11.039C10.1695 11.4149 10.1559 11.9574 10.0562 12.3532C10.1216 12.3932 10.1723 12.4588 10.1822 12.5404C10.2393 13.005 10.4662 13.4188 10.8043 13.7362C12.363 13.5167 14.1321 11.9775 14.7795 11.0867Z'
        fill='#FFB980'
      />
      <path
        d='M14.8299 5.58208H10.4289C9.71517 5.58208 9.13446 6.16279 9.13446 6.87651V8.42983C9.13446 10.4283 10.6443 12.0542 12.4999 12.0542C14.3556 12.0542 15.8654 10.4283 15.8654 8.42983V6.61762C15.8655 6.04663 15.4011 5.58208 14.8299 5.58208Z'
        fill='#FFE1B2'
      />
      <path
        d='M14.3121 2.73438H10.9467C10.4598 2.73438 10.0504 3.07212 9.94022 3.52559C9.04906 3.63887 8.35785 4.40186 8.35785 5.32324V6.61768C8.35785 7.28486 8.6411 7.90728 9.13451 8.34961V6.87656C9.13451 6.16284 9.71522 5.58213 10.4289 5.58213H14.83C15.4011 5.58213 15.8655 6.04668 15.8655 6.61768V8.34932C16.359 7.90708 16.6422 7.28467 16.6422 6.61768V5.06436C16.6421 3.77964 15.597 2.73438 14.3121 2.73438Z'
        fill='#7E5449'
      />
      <path
        d='M17.1875 21.4844H7.8125C7.59678 21.4844 7.42188 21.3095 7.42188 21.0938V16.0156C7.42188 15.7999 7.59678 15.625 7.8125 15.625H17.1875C17.4032 15.625 17.5781 15.7999 17.5781 16.0156V21.0938C17.5781 21.3095 17.4032 21.4844 17.1875 21.4844Z'
        fill='#D8DCE1'
      />
      <path
        d='M24.6094 22.2656H0.390625C0.174707 22.2656 0 22.0907 0 21.875C0 21.6593 0.174707 21.4844 0.390625 21.4844H24.6094C24.8253 21.4844 25 21.6593 25 21.875C25 22.0907 24.8253 22.2656 24.6094 22.2656Z'
        fill='#9F6459'
      />
      <path
        d='M12.5 19.5312C12.9315 19.5312 13.2812 19.1815 13.2812 18.75C13.2812 18.3185 12.9315 17.9688 12.5 17.9688C12.0685 17.9688 11.7188 18.3185 11.7188 18.75C11.7188 19.1815 12.0685 19.5312 12.5 19.5312Z'
        fill='white'
      />
    </svg>
  );
}
