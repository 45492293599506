import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

export default function ChangePasswordIcon(props) {
  const { theme } = useThemeContext();
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M15.625 22.9167C15.1979 22.9167 14.8438 22.5625 14.8438 22.1354V2.86459C14.8438 2.43751 15.1979 2.08334 15.625 2.08334C16.0521 2.08334 16.4062 2.43751 16.4062 2.86459V22.1354C16.4062 22.5625 16.0521 22.9167 15.625 22.9167Z'
        // fill={theme === 'dark' ? 'white' : '#292D32'}
        fill={theme === 'dark' ? 'white' : 'white'}
      />
      <path
        opacity={theme === 'dark' ? '0.5' : '0.4'}
        d='M6.77085 20.8333H13.0209V4.16666H6.77085C4.46877 4.16666 2.60419 6.03124 2.60419 8.33332V16.6667C2.60419 18.9687 4.46877 20.8333 6.77085 20.8333Z'
        // fill={theme === 'dark' ? 'white' : '#292D32'}
        fill={theme === 'dark' ? 'white' : 'white'}
      />
      <path
        opacity={theme === 'dark' ? '0.5' : '0.4'}
        d='M18.75 20.8333H15.625V4.16666H18.75C21.0521 4.16666 22.9167 6.03124 22.9167 8.33332V16.6667C22.9167 18.9687 21.0521 20.8333 18.75 20.8333Z'
        // fill={theme === 'dark' ? 'white' : '#292D32'}
        fill={theme === 'dark' ? 'white' : 'white'}
      />
      <path
        d='M5.9896 13.5416C5.85419 13.5416 5.71877 13.5103 5.59377 13.4582C5.46877 13.4061 5.35419 13.3332 5.25002 13.2395C5.15627 13.1353 5.08335 13.0207 5.02085 12.8957C4.96877 12.7707 4.94794 12.6353 4.94794 12.4999C4.94794 12.2291 5.06252 11.9582 5.25002 11.7603C5.3021 11.7186 5.35419 11.677 5.40627 11.6353C5.46877 11.5936 5.53127 11.5624 5.59377 11.5416C5.65627 11.5103 5.71877 11.4895 5.78127 11.4791C6.13544 11.4061 6.4896 11.5207 6.72919 11.7603C6.91669 11.9582 7.03127 12.2291 7.03127 12.4999C7.03127 12.6353 7.00002 12.7707 6.94794 12.8957C6.89585 13.0207 6.82294 13.1353 6.72919 13.2395C6.62502 13.3332 6.51044 13.4061 6.38544 13.4582C6.26044 13.5103 6.12502 13.5416 5.9896 13.5416Z'
        // fill={theme === 'dark' ? 'white' : '#292D32'}
        fill={theme === 'dark' ? 'white' : 'white'}
      />
      <path
        d='M9.63542 13.5417C9.5 13.5417 9.36458 13.5104 9.23958 13.4583C9.11458 13.4062 9 13.3333 8.89583 13.2396C8.69792 13.0417 8.59375 12.7812 8.59375 12.5C8.59375 12.3646 8.625 12.2292 8.67708 12.1042C8.72917 11.9687 8.80208 11.8646 8.89583 11.7604C9.28125 11.375 9.97917 11.375 10.375 11.7604C10.5625 11.9583 10.6771 12.2292 10.6771 12.5C10.6771 12.6354 10.6458 12.7708 10.5938 12.8958C10.5417 13.0208 10.4687 13.1354 10.375 13.2396C10.2708 13.3333 10.1563 13.4062 10.0313 13.4583C9.90625 13.5104 9.77083 13.5417 9.63542 13.5417Z'
        // fill={theme === 'dark' ? 'white' : '#292D32'}
        fill={theme === 'dark' ? 'white' : 'white'}
      />
    </svg>
  );
}
