import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

export default function BookIcon(props) {
  const { theme } = useThemeContext();
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.3992 0.735291H19.4245V8.75823C19.4245 8.93051 19.2148 9.04735 19.0244 8.98132L17.0228 8.28676C16.9517 8.26213 16.872 8.26213 16.8009 8.28676L14.7993 8.98132C14.6089 9.04742 14.3992 8.93051 14.3992 8.75823L14.3992 0.735291Z'
        fill='#00969B'
      />
      <path
        d='M19.1356 9.73529C19.0153 9.73529 18.8968 9.71537 18.7832 9.67581L16.9119 9.02654L15.0403 9.67588C14.927 9.71529 14.8084 9.73529 14.6881 9.73529C14.1234 9.73529 13.6639 9.29698 13.6639 8.75823V0H20.1598V8.75823C20.1598 9.29698 19.7003 9.73529 19.1356 9.73529ZM16.9118 7.53301C17.0323 7.53301 17.1507 7.55287 17.2637 7.59206L18.6892 8.08669V1.47059H15.1345V8.08669L16.5598 7.59213C16.6729 7.55287 16.7913 7.53301 16.9118 7.53301Z'
        // fill={theme === 'dark' ? '#B9B9B9' : '#1F2C47'}
        fill={theme === 'dark' ? '#B9B9B9' : '#B9B9B9'}
      />
      <path
        d='M20.4412 25H5.51476C3.69027 25 2.20593 23.5157 2.20593 21.6912V3.30882C2.20593 1.48434 3.69027 0 5.51476 0H20.4412C21.7387 0 22.7942 1.05551 22.7942 2.35294V22.6471C22.7942 23.9445 21.7387 25 20.4412 25ZM5.51476 1.47059C4.50115 1.47059 3.67652 2.29522 3.67652 3.30882V21.6912C3.67652 22.7048 4.50115 23.5294 5.51476 23.5294H20.4412C20.9278 23.5294 21.3236 23.1336 21.3236 22.6471V2.35294C21.3236 1.8664 20.9278 1.47059 20.4412 1.47059H5.51476Z'
        // fill={theme === 'dark' ? '#B9B9B9' : '#1F2C47'}
        fill={theme === 'dark' ? '#B9B9B9' : '#B9B9B9'}
      />
      <path
        d='M16.9688 20.9314H11.2665C10.5225 20.9314 9.91711 20.326 9.91711 19.5819V17.7954H11.3877V19.4607H16.8476V17.9166H12.7942V16.446H16.9688C17.7128 16.446 18.3182 17.0513 18.3182 17.7954V19.5819C18.3182 20.326 17.7129 20.9314 16.9688 20.9314Z'
        // fill={theme === 'dark' ? '#B9B9B9' : '#1F2C47'}
        fill={theme === 'dark' ? '#B9B9B9' : '#B9B9B9'}
      />
      {/* <path d='M7.20594 0.735291H5.73535V24.2647H7.20594V0.735291Z' fill={theme === 'dark' ? '#B9B9B9' : '#1F2C47'} /> */}
      <path d='M7.20594 0.735291H5.73535V24.2647H7.20594V0.735291Z' fill={theme === 'dark' ? '#B9B9B9' : '#B9B9B9'} />
    </svg>
  );
}
