import React, { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import DisplayDirector from './DisplayDirector';
import DisplayShareholder from './DisplayShareholder';
import DisplayShareholderCompanyShareholder from './DisplayShareholderCompanyShareholder';

const { Option } = Select;

const DisplayShareholderCompany = ({
  shareholderCompanyData,
  handleRadioCompanyChange,
  handleShareholderCompanyCountry,
  handleChange,
  countries,
  setShareholderCompanyData,
}) => {
    const handleDirectorNum = (value) => {
    if (value === 1) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        directorInfo: {
          ...shareholderCompanyData.directorInfo,
          directorNum: 1,
        },
      });
    } else if (value === 2) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        directorInfo: {
          ...shareholderCompanyData.directorInfo,
          directorNum: 2,
        },
      });
    } else if (value === 3) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        directorInfo: {
          ...shareholderCompanyData.directorInfo,
          directorNum: 3,
        },
      });
    } else if (value === 4) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        directorInfo: {
          ...shareholderCompanyData.directorInfo,
          directorNum: 4,
        },
      });
    } else if (value === 5) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        directorInfo: {
          ...shareholderCompanyData.directorInfo,
          directorNum: 5,
        },
      });
    }
  };

  const handleShareholderNum = (value) => {
    if (value === 1) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        shareholderInfo: {
          ...shareholderCompanyData.shareholderInfo,
          shareholderNum: 1,
        },
      });
    } else if (value === 2) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        shareholderInfo: {
          ...shareholderCompanyData.shareholderInfo,
          shareholderNum: 2,
        },
      });
    } else if (value === 3) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        shareholderInfo: {
          ...shareholderCompanyData.shareholderInfo,
          shareholderNum: 3,
        },
      });
    } else if (value === 4) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        shareholderInfo: {
          ...shareholderCompanyData.shareholderInfo,
          shareholderNum: 4,
        },
      });
    } else if (value === 5) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        shareholderInfo: {
          ...shareholderCompanyData.shareholderInfo,
          shareholderNum: 5,
        },
      });
    }
  };

  const handleDirectorDisplay = () => {
    if (shareholderCompanyData.directorInfo.directorNum === 1) {
      return (
        <DisplayDirector
          directorData={shareholderCompanyData.directorInfo.director1}
          citizenship={shareholderCompanyData.directorInfo?.director1?.citizenship}
          handleChange={handleChange}
          handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector1}
          handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry1}
          countries={countries}
          directorNumeration={2}
          directorFormName='First'
          shareholderDirectorNum='3.'
        />
      );
    } else if (shareholderCompanyData.directorInfo.directorNum === 2) {
      return (
        <>
          <p>
            <b>Director 1</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director1}
            citizenship={shareholderCompanyData.directorInfo?.director1?.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector1}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry1}
            countries={countries}
            directorNumeration='2a'
            directorFormName='First'
            shareholderDirectorNum='3.'
          />
          <p>
            <b>Director 2</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director2}
            citizenship={shareholderCompanyData.directorInfo?.director2?.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector2}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry2}
            countries={countries}
            directorNumeration='2b'
            directorFormName='Second'
            shareholderDirectorNum='3.'
          />
        </>
      );
    } else if (shareholderCompanyData.directorInfo.directorNum === 3) {
      return (
        <>
          <p>
            <b>Director 1</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director1}
            citizenship={shareholderCompanyData.directorInfo.director1.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector1}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry1}
            countries={countries}
            directorNumeration='2a'
            directorFormName='First'
            shareholderDirectorNum='3.'
          />
          <p>
            <b>Director 2</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director2}
            citizenship={shareholderCompanyData.directorInfo.director2.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector2}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry2}
            countries={countries}
            directorNumeration='2b'
            directorFormName='Second'
            shareholderDirectorNum='3.'
          />
          <p>
            <b>Director 3</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director3}
            citizenship={shareholderCompanyData.directorInfo.director3.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector2}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry3}
            countries={countries}
            directorNumeration='2c'
            directorFormName='Third'
            shareholderDirectorNum='3.'
          />
        </>
      );
    } else if (shareholderCompanyData.directorInfo.directorNum === 4) {
      return (
        <>
          <p>
            <b>Director 1</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director1}
            citizenship={shareholderCompanyData.directorInfo.director1.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector1}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry1}
            countries={countries}
            directorNumeration='2a'
            directorFormName='First'
            shareholderDirectorNum='3.'
          />
          <p>
            <b>Director 2</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director2}
            citizenship={shareholderCompanyData.directorInfo.director2.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector2}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry2}
            countries={countries}
            directorNumeration='2b'
            directorFormName='Second'
            shareholderDirectorNum='3.'
          />
          <p>
            <b>Director 3</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director3}
            citizenship={shareholderCompanyData.directorInfo.director3.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector3}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry3}
            countries={countries}
            directorNumeration='2c'
            directorFormName='Third'
            shareholderDirectorNum='3.'
          />
          <p>
            <b>Director 4</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director4}
            citizenship={shareholderCompanyData.directorInfo.director4.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector4}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry4}
            countries={countries}
            directorNumeration='2d'
            directorFormName='Fourth'
            shareholderDirectorNum='3.'
          />
        </>
      );
    } else if (shareholderCompanyData.directorInfo.directorNum === 5) {
      return (
        <>
          <p>
            <b>Director 1</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director1}
            citizenship={shareholderCompanyData.directorInfo.director1.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector1}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry1}
            countries={countries}
            directorNumeration='2a'
            directorFormName='First'
            shareholderDirectorNum='3.'
          />
          <p>
            <b>Director 2</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director2}
            citizenship={shareholderCompanyData.directorInfo.director2.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector2}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry2}
            countries={countries}
            directorNumeration='2b'
            directorFormName='Second'
            shareholderDirectorNum='3.'
          />
          <p>
            <b>Director 3</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director3}
            citizenship={shareholderCompanyData.directorInfo.director3.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector3}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry3}
            countries={countries}
            directorNumeration='2c'
            directorFormName='Third'
            shareholderDirectorNum='3.'
          />
          <p>
            <b>Director 4</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director4}
            citizenship={shareholderCompanyData.directorInfo.director4.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector4}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry4}
            countries={countries}
            directorNumeration='2d'
            directorFormName='Fourth'
            shareholderDirectorNum='3.'
          />
          <p>
            <b>Director 5</b>
          </p>
          <DisplayDirector
            directorData={shareholderCompanyData.directorInfo.director5}
            citizenship={shareholderCompanyData.directorInfo.director5.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderDirector5}
            handleDirectorCountry={handleShareholderCompanyCountry.handleShareholderCompanyDirectorCountry5}
            countries={countries}
            directorNumeration='2e'
            directorFormName='Fifth'
            shareholderDirectorNum='3.'
          />
        </>
      );
    }
  };

  const handleShareholderDisplay = () => {
    if (shareholderCompanyData.shareholderInfo.shareholderNum === 1) {
      return (
        <DisplayShareholderCompanyShareholder
          shareholderData={shareholderCompanyData.shareholderInfo.shareholder1}
          citizenship={shareholderCompanyData.shareholderInfo.shareholder1.citizenship}
          handleChange={handleChange}
          handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder1}
          handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry1}
          countries={countries}
          shareholderNumeration={3}
          companyShareholderNum='3.'
        />
      );
    } else if (shareholderCompanyData.shareholderInfo.shareholderNum === 2) {
      return (
        <>
          <p>
            <b>Shareholder 1</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder1}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder1.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder1}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry1}
            countries={countries}
            shareholderNumeration='3a'
            companyShareholderNum='3.'
          />

          <p>
            <b>Shareholder 2</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder2}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder2.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder2}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry2}
            countries={countries}
            shareholderNumeration='3b'
            companyShareholderNum='3.'
          />
        </>
      );
    } else if (shareholderCompanyData.shareholderInfo.shareholderNum === 3) {
      return (
        <>
          <p>
            <b>Shareholder 1</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder1}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder1.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder1}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry1}
            countries={countries}
            shareholderNumeration='3a'
            companyShareholderNum='3.'
          />
          <p>
            <b>Shareholder 2</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder2}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder2.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder2}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry2}
            countries={countries}
            shareholderNumeration='3b'
            companyShareholderNum='3.'
          />
          <p>
            <b>Shareholder 3</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder3}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder3.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder3}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry3}
            countries={countries}
            shareholderNumeration='3c'
            companyShareholderNum='3.'
          />
        </>
      );
    } else if (shareholderCompanyData.shareholderInfo.shareholderNum === 4) {
      return (
        <>
          <p>
            <b>Shareholder 1</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder1}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder1.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder1}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry1}
            countries={countries}
            shareholderNumeration='3a'
            companyShareholderNum='3.'
          />
          <p>
            <b>Shareholder 2</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder2}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder2.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder2}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry2}
            countries={countries}
            shareholderNumeration='3b'
            companyShareholderNum='3.'
          />
          <p>
            <b>Shareholder 3</b>
          </p>
          <DisplayShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder3}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder3.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder3}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry3}
            countries={countries}
            shareholderNumeration='3c'
            companyShareholderNum='3.'
          />
          <p>
            <b>Shareholder 4</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder4}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder4.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder4}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry4}
            countries={countries}
            shareholderNumeration='3d'
            companyShareholderNum='3.'
          />
        </>
      );
    } else if (shareholderCompanyData.shareholderInfo.shareholderNum === 5) {
      return (
        <>
          <p>
            <b>Shareholder 1</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder1}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder1.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder1}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry1}
            countries={countries}
            shareholderNumeration='3a'
            companyShareholderNum='3.'
          />
          <p>
            <b>Shareholder 2</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder2}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder2.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder2}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry2}
            countries={countries}
            shareholderNumeration='3b'
            companyShareholderNum='3.'
          />
          <p>
            <b>Shareholder 3</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder3}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder3.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder3}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry3}
            countries={countries}
            shareholderNumeration='3c'
            companyShareholderNum='3.'
          />
          <p>
            <b>Shareholder 4</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder4}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder4.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder4}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry4}
            countries={countries}
            shareholderNumeration='3d'
            companyShareholderNum='3.'
          />
          <p>
            <b>Shareholder 5</b>
          </p>
          <DisplayShareholderCompanyShareholder
            shareholderData={shareholderCompanyData.shareholderInfo.shareholder5}
            citizenship={shareholderCompanyData.shareholderInfo.shareholder5.citizenship}
            handleChange={handleChange}
            handleRadioChange={handleRadioCompanyChange.handleRadioShareholderShareholder5}
            handleShareholderCountry={handleShareholderCompanyCountry.handleShareholderCompanyShareholderCountry5}
            countries={countries}
            shareholderNumeration='3e'
            companyShareholderNum='3.'
          />
        </>
      );
    }
  };

  useEffect(() => {
    if (shareholderCompanyData.shareholderInfo.shareholderNum === 1) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        shareholderInfo: {
          ...shareholderCompanyData.shareholderInfo,
          shareholder1: {
            ...shareholderCompanyData.shareholderInfo.shareholder1,
            ownershipPercentage: 100,
          },
        },
      });
    } else if (shareholderCompanyData.shareholderInfo.shareholderNum > 1) {
      setShareholderCompanyData({
        ...shareholderCompanyData,
        shareholderInfo: {
          ...shareholderCompanyData.shareholderInfo,
          shareholder1: {
            ...shareholderCompanyData.shareholderInfo.shareholder1,
            ownershipPercentage: '',
          },
        },
      });
    }
  }, [shareholderCompanyData.shareholderInfo.shareholderNum]);

  return (
    <>
      <p>
        <b>3.1 Company Data</b>
      </p>
      <Form.Item required label='3.1.1 Name of shareholding firm:'>
        <input
          type='text'
          name='firmName'
          value={shareholderCompanyData.companyInfo?.firmName}
          onChange={(e) => handleChange(shareholderCompanyData.companyInfo, e)}
          required
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
        />
      </Form.Item>
      {/* <Form.Item label='1.3 Address:'>
              <Select
                style={{
                  width: 140,
                }}
                onChange={handleFirmAddressChange}
              >
                <Option value='custom'>Custom</Option>
                <Option value='hlb'>HLB address</Option>
              </Select>
            </Form.Item> */}
      <Form.Item
        required
        // name='streetForm'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please enter street name!',
        //   },
        // ]}
        label='3.1.2 Street:'
      >
        <input
          required
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='street'
          value={shareholderCompanyData.companyInfo?.firmAddress.street}
          onChange={(e) => handleChange(shareholderCompanyData.companyInfo.firmAddress, e)}
        />
      </Form.Item>
      <Form.Item
        required
        // name='streetNumForm'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please enter street number!',
        //   },
        // ]}
        label='3.1.3 Street Number:'
      >
        <input
          required
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='streetNum'
          value={shareholderCompanyData.companyInfo?.firmAddress.streetNum}
          onChange={(e) => handleChange(shareholderCompanyData.companyInfo.firmAddress, e)}
        />
      </Form.Item>
      {/* <Form.Item label='1.6 Floor:'>
              <Input
                type='text'
                name='floor'
                value={firmData.firmAddress.floor}
                onChange={(e) => handleChange(firmData.firmAddress, e)}
              />
            </Form.Item> */}
      <Form.Item label='3.1.4 Apartment Number:'>
        <Input
          type='text'
          name='aptNum'
          value={shareholderCompanyData.companyInfo?.firmAddress.aptNum}
          onChange={(e) => handleChange(shareholderCompanyData.companyInfo.firmAddress, e)}
        />
      </Form.Item>
      <Form.Item
        required
        // name='cityForm'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please enter city!',
        //   },
        // ]}
        label='3.1.5 City:'
      >
        <input
          required
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: '100%',
            outline: 'none',
          }}
          type='text'
          name='city'
          value={shareholderCompanyData.companyInfo?.firmAddress.city}
          onChange={(e) => handleChange(shareholderCompanyData.companyInfo.firmAddress, e)}
        />
      </Form.Item>
      <Form.Item required label='3.1.6 Country:'>
        <Select
          showSearch
          // defaultValue='Serbia'
          style={{
            width: 140,
          }}
          value={shareholderCompanyData.companyInfo?.firmAddress?.country}
          name='country'
          onSelect={(values) => handleShareholderCompanyCountry.handleShareholderCompanyCountry1(values)}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
          {countries.map((country, id) => {
            return (
              <Option key={id} value={country}>
                {country}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item required label='3.1.7 Registration Number:'>
        <input
          required
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: 150,
            outline: 'none',
          }}
          type='number'
          name='registrationNum'
          value={shareholderCompanyData.companyInfo?.registrationNum}
          onChange={(e) => handleChange(shareholderCompanyData.companyInfo, e)}
        />
      </Form.Item>
      <Form.Item
        required
        // name='shareholdingValueForm'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please enter value of shareholding capital!',
        //   },
        // ]}
        label='3.1.8 Ownership Percentage:'
      >
        <input
          required
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '2px',
            padding: '4px 11px',
            width: 70,
            outline: 'none',
          }}
          type='number'
          name='ownershipPercentage'
          value={shareholderCompanyData.companyInfo?.ownershipPercentage}
          onChange={(e) => handleChange(shareholderCompanyData.companyInfo, e)}
        />
        {'  '}%
      </Form.Item>
      <p>
        <b>3.2 Directors</b>
      </p>
      <Form.Item label='Number of directors:'>
        <Select
          defaultValue='1'
          style={{
            width: 60,
          }}
          onChange={handleDirectorNum}
        >
          <Option value={1}>1</Option>
          <Option value={2}>2</Option>
          <Option value={3}>3</Option>
          <Option value={4}>4</Option>
          <Option value={5}>5</Option>
        </Select>
      </Form.Item>
      {handleDirectorDisplay()}
      <p>
        <b>3.3 Shareholders</b>
      </p>
      <Form.Item label='Number of company shareholders:'>
        <Select
          defaultValue='1'
          style={{
            width: 60,
          }}
          onChange={handleShareholderNum}
        >
          <Option value={1}>1</Option>
          <Option value={2}>2</Option>
          <Option value={3}>3</Option>
          <Option value={4}>4</Option>
          <Option value={5}>5</Option>
        </Select>
      </Form.Item>
      {handleShareholderDisplay()}
    </>
  );
};

export default DisplayShareholderCompany;
