import React, { useContext, useEffect, useState } from 'react';
import { notification, Button, Card } from 'antd';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import Table from '../../components/tables/DataTable';
import moment from 'moment';

const UnlockEmployees = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [companiesFiltered, fetchCompaniesFiltered] = useAxios('', [], currentuser.data.token, 'get');
  const company = localStorage.getItem('selectedCompany') ? localStorage.getItem('selectedCompany') : undefined;
  const [selectedRows, setSelectedRows] = useState([]);


  useEffect(() => {
    if (company) {
      const filter = { $or: [{ _id: company }, { mainCompany: company }] };
      fetchCompaniesFiltered(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      const companiesId = companiesFiltered && companiesFiltered.data && companiesFiltered.data.items &&
        companiesFiltered.data.items.map((item) => item._id);
      if (companiesId && companiesId.length !== 0) {
        const filterData = { company: { $in: companiesId ? companiesId : [] }, locked: true };
        fetchData(`${SERVER_URL}/data?filter=${JSON.stringify(filterData)}`, []);
      }
    }
  }, [companiesFiltered, company, fetchCompaniesFiltered, fetchData]);

  const TABLE_COLUMN_KEYS = [
    '_id',
    '__v',
    'createdBy',
    'address',
    'zip',
    'gender',
    'phone',
    'hotMeal',
    'regres',
    'travelExpenses',
    'createdAt',
    'updatedAt',
    'company',
    'email',
    'city'
  ];

  let columnKeys;
  let newColumnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys = ['numRow', 'firstName', 'lastName', 'JMBG', 'company'];
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'company') {
        alternativeNames.push('name of company');
      } else if (columnKey === 'numRow') {
        alternativeNames.push('number');
      } else if (columnKey === 'firstName') {
        alternativeNames.push('Name');
      } else if (columnKey === 'lastName') {
        alternativeNames.push('Surname');
      } else if (columnKey === 'JMBG') {
        alternativeNames.push('personal number');
      } else if (columnKey === 'email') {
        alternativeNames.push(columnKey);
      } else if (columnKey === 'city') {
        alternativeNames.push('City');
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('Created on');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('Updated on');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index],
    }));
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item, index) => {
      item.numRow = index + 1;
      if (item.company && item.company.name) {
        item.company = item.company.name;
      }
      item.createdAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      item.updatedAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      return item;
    });
  }

  const unlockEmployees = () => {
    try {
      selectedRows.forEach(async (row) => {
        await Axios.put(`${SERVER_URL}/data/${row}`, { locked: false }, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      });
      notification.success({
        message: 'Employees are unlocked.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.pathname = '/admin/data';
      }, 1200);
    }
    catch (error) {
      notification.error({
        message: 'Problem with unlock. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
      <div>
    <div className='dashboard'>
      <div style={{ width: '65%' }}>
        <Card title='Unlock employees' bordered={false}>
          {!data.isLoading &&
            data.data &&
            data.data.items &&
            data.data.items.length > 0 && (
              <Table
                page={window.location.pathname}
                data={tableData}
                columnKeys={newColumnKeys}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />)}
          {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2 style={{ width: '100%', textAlign: 'center' }}>NO DATA</h2>}
          <div className='text-right' style={{ marginTop: '15px' }}>
            <Button type='primary' onClick={unlockEmployees} disabled={selectedRows.length === 0}>Unlock</Button>
          </div>
        </Card>
      </div>
    </div >
  </div >);
};

export default UnlockEmployees;