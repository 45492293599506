import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

export default function LogoutIcon(props) {
  const { theme } = useThemeContext();
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M14.5833 2.08331H2.08331V22.9166H14.5833V2.08331Z' fill='#30bdb7' />
      <path
        d='M14.5833 7.29165V2.08331H2.08331V22.9166H14.5833V17.7083'
        // stroke={theme === 'dark' ? '#ffffff' : 'black'}
        stroke={theme === 'dark' ? '#ffffff' : '#ffffff'}
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M10.4167 12.5H22.9167'
        // stroke={theme === 'dark' ? '#ffffff' : 'black'}
        stroke={theme === 'dark' ? '#ffffff' : '#ffffff'}
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M18.75 8.33331L22.9167 12.5L18.75 16.6666'
        // stroke={theme === 'dark' ? '#ffffff' : 'black'}
        stroke={theme === 'dark' ? '#ffffff' : '#ffffff'}
        stroke-width='2'
        stroke-linecap='round'
      />
    </svg>
  );
}
