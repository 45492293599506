import React, {useContext, useEffect, useState} from 'react';
import {Card} from 'antd';
import HrForm from '../../components/HrForm';
import Axios from 'axios';
import {SERVER_URL} from '../../config';
import {UserContext} from '../../App';

export default function HrPlatformEmployee() {
  const user = useContext(UserContext);
  const [employee, setLoggedInEmployee] = useState([]);
  const [companyTypeOfHR, setCompanyTypeOfHR] = useState(undefined);
  const [selectedCompany, setSelectedCompany] = useState(undefined);

  const getCurrentEmployee = async() => {
    try {
      return await Axios.get(`${SERVER_URL}/data/${user?.data?.employee}`, {
        withCredentials: false,
        headers: {Authorization: `Bearer ${user?.data?.token}`},
      });
    } catch (error) {
      console.log('error in HR for fetch current employee', error)
    }
  }

  const getCompanyDataForHROfficer = async() => {
    try {
      return await Axios.get(`${SERVER_URL}/company/${user?.data?.sectorId}`, {
        withCredentials: false,
        headers: {Authorization: `Bearer ${user?.data?.token}`},
      });
    } catch (error) {
      console.log('error in HR for fetch company data for HR officer', error)
    }
  }


  useEffect(() => {
    if (user?.data?.employee !== null && (user?.data?.role?.includes('employee') || user?.data?.role?.includes('hrOfficer'))) {
      getCurrentEmployee().then(r =>  setLoggedInEmployee(r.data));
    }

    if (user?.data?.role?.includes('hrOfficer') && !user?.data?.role?.includes('employee') && !user?.data?.employeeCompany && user?.data?.sectorId) {
      getCompanyDataForHROfficer().then(r => {
        setCompanyTypeOfHR(r.data.typeOfHr)
      });
    }
  }, [user, user?.data]);

  useEffect(() => {
    if (user.data.employeeCompany) {
      setSelectedCompany(user.data.employeeCompany);
    } else if (!user.data.employeeCompany && employee?.company){
      setSelectedCompany(employee?.company);
    }
  }, [user, employee]);

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title='HR Platform' style={{ width: '90%' }}>
          <HrForm employeeId={employee._id} data={employee} companyTypeOfHR={companyTypeOfHR} selectedCompany={selectedCompany}/>
        </Card>
      </div>
    </div>
  );
}
