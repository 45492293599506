import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Card, Input, Radio, Checkbox, Divider } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';

const ViewCompany = (props) => {
  const [form] = Form.useForm();
  const { companyId } = props.match.params;
  const currentuser = useContext(UserContext);
  const [companies, fetchCompanies] = useAxios('', null, currentuser.data.token, 'get');

  const allCompaniesButtonText =
    currentuser?.data?.role?.includes('user') || currentuser?.data?.role?.includes('userOfficer')
      ? 'Company data'
      : 'All companies';

  useEffect(() => {
    if (companyId) {
      fetchCompanies(`${SERVER_URL}/companies/${companyId}`, []);
    }
  }, [companyId, fetchCompanies]);

  const calcMethods = {
    automatic: 'Per month (regular work)',
    manual: ['Per month (all days of work)', 'Per month (all days)'],
    fixed: 'Per month (fixed defined value)',
    perDay: ['Per day (regular work)', 'Per day (holiday leave)'],
    monthlyFee: 'Per month (excluding sick leave)',
  };

  if (companies.data) {
    form.setFieldsValue({
      documentReturnType: companies.data.documentReturnType === 'word-pdf' ? 'word' : companies.data.documentReturnType,
      documentSeparateRequestResponse: companies.data.documentSeparateRequestResponse === undefined ? 'regular' : companies.data.documentSeparateRequestResponse,
      name: companies.data.name,
      code: companies.data.code,
      pib: companies.data.pib,
      authorizedPerson: companies.data.authorizedPerson,
      typeOfHr: companies.data.typeOfHr,
      isHr: companies.data.isHr,
      zip: companies.data.zip,
      city: companies.data.city,
      address: companies.data.address,
      client: companies.data.client && companies.data.client.firstName + ' ' + companies.data.client.lastName,
      calculationMethodMeal: Array.isArray(calcMethods[companies.data.calculationMethodMeal])
        ? calcMethods[companies.data.calculationMethodMeal][0]
        : calcMethods[companies.data.calculationMethodMeal],
      calculationMethodHoliday: Array.isArray(calcMethods[companies.data.calculationMethodHoliday])
        ? companies.data.calculationMethodHoliday === 'perDay'
          ? calcMethods[companies.data.calculationMethodHoliday][1]
          : calcMethods[companies.data.calculationMethodHoliday][0]
        : calcMethods[companies.data.calculationMethodHoliday],
      travelExpenses: Array.isArray(calcMethods[companies.data.travelExpenses])
        ? calcMethods[companies.data.travelExpenses][1]
        : calcMethods[companies.data.travelExpenses],
      weekendLeaves: companies.data.weekendLeaves,
    });
  }

  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link to='/admin/companies'>
          <Button type='primary'>{allCompaniesButtonText}</Button>
        </Link>
      </div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title='VIEW COMPANY' bordered={false}>
            <Form className='form-horizontal companyForm' layout='horizontal' form={form}>
              <Form.Item
                label='HR Platform'
                valuePropName='checked'
                name='isHr'
                labelCol={{ span: 3 }}
                labelAlign='left'
              >
                <Checkbox disabled />
              </Form.Item>
              <Form.Item label='HR documents' name='typeOfHr' labelAlign='left'>
                <Radio.Group>
                  <Radio value='serbian' disabled>
                    Serbian
                  </Radio>
                  <Radio value='bilingual' disabled>
                    Bilingual
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label='HR document types' name='documentReturnType' labelAlign='left'>
                <Radio.Group>
                  <Radio value='word' disabled>
                    Word
                  </Radio>
                  <Radio value='pdf' disabled>
                    PDF
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label='Separated Request Docs' name='documentSeparateRequestResponse' labelAlign='left'>
                <Radio.Group>
                  <Radio value='regular' disabled>
                    Regular
                  </Radio>
                  <Radio value='separated' disabled>
                    Separated
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                  valuePropName='checked'
                  label='Leaves Over the Weekend'
                  name='weekendLeaves'
                  labelCol={{ span: 5 }}
                  labelAlign='left'
              >
                <Checkbox disabled />
              </Form.Item>

              <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />

              <Form.Item label='Registration number' name='code' labelCol={{ span: 6 }} labelAlign='left'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Name of company' name='name' labelCol={{ span: 6 }} labelAlign='left'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='TIN' name='pib' labelCol={{ span: 6 }} labelAlign='left'>
                <Input disabled />
              </Form.Item>
              <Form.Item label='Authorized person' name='authorizedPerson' labelCol={{ span: 6 }} labelAlign='left'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Postal number' name='zip' labelCol={{ span: 6 }} labelAlign='left'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='City' name='city' labelCol={{ span: 6 }} labelAlign='left'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Address' name='address' labelCol={{ span: 6 }} labelAlign='left'>
                <Input disabled />
              </Form.Item>

              {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) && (
                <Form.Item label='Client' name='client' labelCol={{ span: 6 }} labelAlign='left'>
                  <Input disabled />
                </Form.Item>
              )}

              <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />

              <Form.Item
                // className='calcMethod'
                labelCol={{ span: 24 }}
                label='Method for calculation of meal allowance'
                name='calculationMethodMeal'
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                //  className='calcMethod'
                labelCol={{ span: 24 }}
                label='Method for calculation of holliday allowance'
                name='calculationMethodHoliday'
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                //  className='calcMethod'
                labelCol={{ span: 24 }}
                label='Method for travel expenses'
                name='travelExpenses'
              >
                <Input disabled />
              </Form.Item>

              <div style={{ marginTop: '10px' }} className='text-center'>
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={() => (window.location.href = '/admin/companies')}
                  type='primary'
                  htmlType='button'
                >
                  Close
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ViewCompany;
