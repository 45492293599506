import React from 'react';

export default function NewClientIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.5 0C9.66851 0 7.36499 2.30361 7.36499 5.13501C7.36499 7.9665 9.66851 10.27 12.5 10.27C15.3315 10.27 17.635 7.9665 17.635 5.13501C17.635 2.30361 15.3315 0 12.5 0Z'
        fill='#FFCF70'
      />
      <path
        d='M12.5 13C7.2617 13 3 17.7622 3 23.6154C3 24.3802 3.55482 25 4.23913 25H20.7609C21.4452 25 22 24.3802 22 23.6154C22 17.7622 17.7383 13 12.5 13Z'
        fill='#D6E5F6'
      />
      <path d='M12 13V25H20.6957C21.416 25 22 24.3802 22 23.6154C22 17.7622 17.514 13 12 13Z' fill='#A4C6EC' />
      <path
        d='M12.5 12.725C11.8221 12.725 11.2725 13.24 11.2725 13.875V23.075C11.2725 23.7102 11.8221 24.225 12.5 24.225C13.1778 24.225 13.7274 23.7102 13.7274 23.075V13.875C13.7274 13.24 13.1778 12.725 12.5 12.725Z'
        fill='#1D3366'
      />
      <path
        d='M12.5 0V10.2701C15.3315 10.2701 17.635 7.96655 17.635 5.13506C17.635 2.30361 15.3315 0 12.5 0Z'
        fill='#F7B14D'
      />
      <path
        d='M9.76562 8.125C9.76562 10.0105 10.5146 11.8188 11.8479 13.1521C13.1812 14.4854 14.9895 15.2344 16.875 15.2344C18.7605 15.2344 20.5688 14.4854 21.9021 13.1521C23.2354 11.8188 23.9844 10.0105 23.9844 8.125C23.9844 6.23948 23.2354 4.43118 21.9021 3.09791C20.5688 1.76465 18.7605 1.01563 16.875 1.01562C14.9895 1.01563 13.1812 1.76465 11.8479 3.09791C10.5146 4.43118 9.76563 6.23948 9.76562 8.125Z'
        fill='#4CAF50'
      />
      <path d='M15.8594 4.73959H17.8906V11.5104H15.8594V4.73959Z' fill='white' />
      <path d='M13.4896 7.10938H20.2605V9.14062H13.4896V7.10938Z' fill='white' />
    </svg>
  );
}
