import React from 'react';

export default function CustomersIcon(props) {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.19054 14.9515C5.28956 14.9515 4.47213 14.5649 3.86901 13.94C3.86857 14.3145 3.85494 14.8551 3.75568 15.2494C3.82081 15.2892 3.87135 15.3546 3.88121 15.4359C4.00992 16.4833 5.00265 17.273 6.19054 17.273C7.37823 17.273 8.37091 16.4832 8.49987 15.4359C8.50988 15.3544 8.55934 15.2892 8.62545 15.2495C8.52614 14.8551 8.51251 14.3145 8.51207 13.94C7.90895 14.5649 7.09147 14.9515 6.19054 14.9515Z'
        fill='#FFB980'
      />
      <path
        d='M8.46168 13.9876C7.86364 14.5833 7.06744 14.9516 6.19054 14.9516C5.28956 14.9516 4.47213 14.5649 3.86901 13.9401C3.86857 14.3145 3.85494 14.8551 3.75568 15.2495C3.82081 15.2893 3.87135 15.3546 3.88121 15.4359C3.9381 15.8989 4.16422 16.3111 4.50099 16.6274C6.05402 16.4087 7.81671 14.8751 8.46168 13.9876Z'
        fill='#F8AB6B'
      />
      <path
        d='M10.8984 15.528C10.888 15.5251 10.8777 15.5228 10.8671 15.5213L8.80815 15.2124C8.79556 15.2105 8.7686 15.2095 8.75576 15.2095C8.62554 15.2095 8.51572 15.3066 8.49985 15.4359C8.3709 16.4832 7.37817 17.273 6.19053 17.273C5.00259 17.273 4.00991 16.4833 3.8812 15.4359C3.86509 15.3028 3.74517 15.2027 3.61875 15.2096C3.60361 15.2087 3.58877 15.21 3.5729 15.2124L1.51392 15.5213C1.50332 15.5229 1.49302 15.5251 1.48267 15.528C0.509326 15.8005 0 16.7583 0 17.5634V18.5627C0 18.9881 0.378857 19.3342 0.84458 19.3342H11.5364C12.0022 19.3342 12.381 18.9881 12.381 18.5627V17.5634C12.381 16.7583 11.8717 15.8005 10.8984 15.528Z'
        fill='#00E68C'
      />
      <path
        d='M8.51194 8.50307H4.12698C3.41589 8.50307 2.83728 9.08169 2.83728 9.79277V11.3404C2.83728 13.3316 4.34158 14.9515 6.19045 14.9515C8.03933 14.9515 9.54363 13.3316 9.54363 11.3404V9.53481C9.54368 8.96596 9.08093 8.50307 8.51194 8.50307Z'
        fill='#FDC88E'
      />
      <path
        d='M7.99609 5.66577H4.64287C4.15772 5.66577 3.7499 6.00229 3.64009 6.45405C2.75215 6.56689 2.06348 7.3271 2.06348 8.24512V9.53481C2.06348 10.1996 2.34565 10.8197 2.8373 11.2604V9.79272C2.8373 9.08164 3.41592 8.50303 4.127 8.50303H8.51196C9.08101 8.50303 9.5437 8.96587 9.5437 9.53477V11.2601C10.0355 10.8195 10.3175 10.1994 10.3175 9.53477V7.98716C10.3175 6.70723 9.27617 5.66577 7.99609 5.66577Z'
        fill='#5C546A'
      />
      <path
        d='M18.8095 14.9515C17.9085 14.9515 17.0911 14.5649 16.488 13.94C16.4875 14.3145 16.4739 14.8551 16.3746 15.2494C16.4398 15.2892 16.4903 15.3546 16.5002 15.4359C16.6289 16.4833 17.6216 17.273 18.8095 17.273C19.9972 17.273 20.9899 16.4832 21.1188 15.4359C21.1288 15.3544 21.1783 15.2892 21.2444 15.2495C21.1451 14.8551 21.1315 14.3145 21.131 13.94C20.5279 14.5649 19.7105 14.9515 18.8095 14.9515Z'
        fill='#FFB980'
      />
      <path
        d='M21.0806 13.9876C20.4826 14.5833 19.6864 14.9516 18.8095 14.9516C17.9085 14.9516 17.0911 14.5649 16.488 13.9401C16.4875 14.3145 16.4739 14.8551 16.3746 15.2495C16.4398 15.2893 16.4903 15.3546 16.5002 15.4359C16.5571 15.8989 16.7832 16.3111 17.1199 16.6274C18.673 16.4087 20.4357 14.8751 21.0806 13.9876Z'
        fill='#F8AB6B'
      />
      <path
        d='M23.5174 15.528C23.5071 15.5251 23.4967 15.5228 23.4861 15.5213L21.4271 15.2124C21.4145 15.2105 21.3876 15.2095 21.3747 15.2095C21.2445 15.2095 21.1347 15.3066 21.1188 15.4359C20.9899 16.4832 19.9971 17.273 18.8095 17.273C17.6216 17.273 16.6289 16.4833 16.5002 15.4359C16.4841 15.3028 16.3641 15.2027 16.2377 15.2096C16.2226 15.2087 16.2077 15.21 16.1919 15.2124L14.1329 15.5213C14.1223 15.5229 14.112 15.5251 14.1016 15.528C13.1283 15.8006 12.619 16.7583 12.619 17.5634V18.5627C12.619 18.9881 12.9979 19.3342 13.4636 19.3342H24.1554C24.6212 19.3342 25 18.9881 25 18.5627V17.5634C25 16.7583 24.4907 15.8005 23.5174 15.528Z'
        fill='#00ABFF'
      />
      <path
        d='M21.131 8.50307H16.746C16.0349 8.50307 15.4563 9.08169 15.4563 9.79277V11.3404C15.4563 13.3316 16.9606 14.9515 18.8095 14.9515C20.6583 14.9515 22.1626 13.3316 22.1626 11.3404V9.53481C22.1627 8.96596 21.7 8.50307 21.131 8.50307Z'
        fill='#FDC88E'
      />
      <path
        d='M20.6151 5.66577H17.2619C16.7767 5.66577 16.3689 6.00229 16.2591 6.45405C15.3712 6.56689 14.6825 7.3271 14.6825 8.24512V9.53481C14.6825 10.1996 14.9647 10.8197 15.4563 11.2604V9.79272C15.4563 9.08164 16.0349 8.50303 16.746 8.50303H21.131C21.7 8.50303 22.1627 8.96587 22.1627 9.53477V11.2601C22.6545 10.8195 22.9365 10.1994 22.9365 9.53477V7.98716C22.9365 6.70723 21.8952 5.66577 20.6151 5.66577Z'
        fill='#7E5C62'
      />
      <path
        d='M12.5 16.2126C11.1356 16.2126 9.89762 15.6271 8.98424 14.6808C8.98356 15.2479 8.96295 16.0665 8.81256 16.6638C8.91119 16.7241 8.98776 16.823 9.0027 16.9462C9.19762 18.5323 10.701 19.7283 12.5 19.7283C14.2986 19.7283 15.802 18.5322 15.9973 16.9462C16.0125 16.8229 16.0874 16.7241 16.1875 16.664C16.0371 16.0666 16.0165 15.248 16.0158 14.6808C15.1024 15.6271 13.8645 16.2126 12.5 16.2126Z'
        fill='#FDC88E'
      />
      <path
        d='M15.9395 14.7529C15.0338 15.6551 13.828 16.2126 12.5 16.2126C11.1356 16.2126 9.89762 15.6271 8.98424 14.6808C8.98356 15.2479 8.96295 16.0665 8.81256 16.6638C8.91119 16.7241 8.98776 16.823 9.0027 16.9462C9.08888 17.6473 9.43131 18.2716 9.94132 18.7506C12.2933 18.4194 14.9627 16.097 15.9395 14.7529Z'
        fill='#FFB980'
      />
      <path
        d='M19.6296 17.0856C19.614 17.0812 19.5983 17.0778 19.5823 17.0755L16.4642 16.6077C16.4451 16.6048 16.4043 16.6033 16.3849 16.6033C16.1876 16.6033 16.0213 16.7503 15.9973 16.9462C15.802 18.5321 14.2986 19.7283 12.5 19.7283C10.7009 19.7283 9.19756 18.5324 9.00264 16.9462C8.97822 16.7446 8.79663 16.593 8.60513 16.6034C8.58223 16.6021 8.55972 16.604 8.53569 16.6076L5.41758 17.0755C5.40156 17.0778 5.38594 17.0812 5.37026 17.0856C3.89634 17.4984 3.125 18.9487 3.125 20.1681V21.6814C3.125 22.3257 3.69873 22.8499 4.40405 22.8499H20.5959C21.3012 22.8499 21.875 22.3257 21.875 21.6814V20.1681C21.875 18.9487 21.1037 17.4984 19.6296 17.0856Z'
        fill='#FF4F19'
      />
      <path
        d='M16.0156 6.44702H9.375C8.2981 6.44702 7.42188 7.32324 7.42188 8.40015V10.7439C7.42188 13.7594 9.7 16.2126 12.5 16.2126C15.3 16.2126 17.5781 13.7594 17.5781 10.7439V8.00952C17.5781 7.148 16.8773 6.44702 16.0156 6.44702Z'
        fill='#FFE1B2'
      />
      <path
        d='M15.2344 2.15015H10.1562C9.42153 2.15015 8.80395 2.65977 8.6376 3.34395C7.29292 3.51484 6.25 4.66611 6.25 6.0564V8.00952C6.25 9.01621 6.67734 9.95537 7.42188 10.6228V8.40015C7.42188 7.32324 8.2981 6.44702 9.375 6.44702H16.0156C16.8773 6.44702 17.5781 7.14795 17.5781 8.00952V10.6224C18.3228 9.95508 18.75 9.01597 18.75 8.00952V5.66577C18.75 3.72734 17.173 2.15015 15.2344 2.15015Z'
        fill='#7E5449'
      />
    </svg>
  );
}
