import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification, Select } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import Sorting from '../../components/Sorting';
import { SERVER_URL } from '../../config';
import * as FileSaver from 'file-saver';
import { formatDateForTables } from '../../helpers/dates';

const { Option } = Select;

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'createdBy',
  'address',
  'zip',
  'gender',
  'phone',
  'hotMeal',
  'regres',
  'travelExpenses',
];

const Data = () => {
  const currentuser = useContext(UserContext);
  const [data, setData] = useState([]);
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [companiesFiltered, setCompaniesFiltered] = useState([]);
  const [branches, fetchBranches] = useAxios('', [], currentuser.data.token, 'get');
  const [sortOrder, setSortOrder] = useState(null);
  const [company, setCompany] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedSortingType, setSelectedSortingType] = useState('lastToFirstAdded');
  const [tableData, setTableData] = useState([]);
  const [randomKey, setRandomKey] = useState(Math.random());
  // const [allowedToAdmins, setAllowedToAdmins] = useState(false);

  /** Roles definition **/
  const allowedRoles =
    currentuser.data.role.includes('SuperAdmin') ||
    currentuser.data.role.includes('admin') ||
    currentuser.data.role.includes('user') ||
    currentuser.data.role.includes('hrOfficer') ||
    currentuser.data.role.includes('userOfficer');

  const allowedToAdmins =
    currentuser.data.role.includes('SuperAdmin') ||
    currentuser.data.role.includes('admin') ||
    (currentuser.data.role.includes('user') && !currentuser.data.role.includes('hrOfficer'));
  /** end of roles definition **/

  useEffect(() => {
    let initialCompany = localStorage.getItem('selectedCompany')
      ? localStorage.getItem('selectedCompany')
      : currentuser?.data?.company?._id;
    if (currentuser.data.sectorId) initialCompany = currentuser.data.sectorId;
    setCompany(initialCompany);
    localStorage.setItem('selectedCompany', initialCompany);
  }, []);

  const fetchData = async (filter, user) => {
    if (user && filter && Object.keys(filter).length > 0) {
      try {
        const res = await Axios.get(`${SERVER_URL}/data/?filter=${JSON.stringify(filter)}&user=${user}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        setData(res);
        setCompany(company);
        if (currentuser.data.role.includes('hrOfficer') && currentuser.data.sectorId) {
          setCompany(
            currentuser?.data?.employeeCompany?._id ? currentuser.data.employeeCompany._id : currentuser.data.sectorId,
          );
        }
      } catch (error) {
        console.log('error in fetching employees', error);
      }
    }
  };

  // this sets initial sorting type for roles other than admins
  useEffect(() => {
    if (data?.data?.items && !allowedToAdmins) {
      const companyItem = data.data.items.find((item) => item.company);
      if (companyItem) {
        setSelectedSortingType(companyItem.company.selectedSortingType);
        setRandomKey(Math.random());
      } else if (currentuser.data.company) {
        setSelectedSortingType(currentuser.data.company.selectedSortingType);
      }
    }
  }, [data]);

  // this triggers for all roles
  useEffect(() => {
    let isMounted = true;
    fetchDataAndCleanup();

    return () => {
      isMounted = false;
    };
  }, []);

  // this triggers only for SuperAdmin and admin
  useEffect(() => {
    let isMounted = true;
    if (allowedToAdmins) {
      fetchDataAndCleanup();
    }

    return () => {
      isMounted = false;
    };
  }, [branches, fetchBranches, company, allowedToAdmins]);

  const fetchFilteredCompanies = async (filter) => {
    if (filter && Object.keys(filter).length > 0 && filter !== {}) {
      try {
        const res = await Axios.get(`${SERVER_URL}/companies/?filter=${JSON.stringify(filter)}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        setCompaniesFiltered(res);
      } catch (error) {
        console.log('error in fetching filtered companies', error);
      }
    }
  };

  const fetchDataAndCleanup = async () => {
    if (allowedToAdmins) {
      if (company) {
        const filter = { $or: [{ _id: company }, { mainCompany: company }] };
        if (filter && Object.keys(filter).length > 0) {
          await fetchFilteredCompanies(filter);
        }
      } else fetchCompanies(`${SERVER_URL}/companies`);
    } else {
      if (
        currentuser.data.role.includes('userOfficer') ||
        (currentuser.data.role.includes('user') && !currentuser.data.role.includes('hrOfficer'))
      ) {
        const filter = { company: currentuser.data.company._id };

        if (currentuser.data.company._id && filter && Object.keys(filter).length > 0) {
          await fetchData(filter, currentuser.data.id);
        }
      }

      if (currentuser.data.role.includes('hrOfficer') && currentuser.data.sectorId) {
        const filter = { employeeSector: currentuser.data.sectorId };
        if (currentuser.data.sectorId && filter && Object.keys(filter).length > 0) {
          await fetchData(filter, currentuser.data.id);
        }
      }
    }
  };

  useEffect(() => {
    const companiesId = companiesFiltered?.data?.items?.map((item) => item._id);
    const filterData = { company: { $in: companiesId ? companiesId : [] } };
    if (companiesFiltered && companiesId && filterData && Object.keys(filterData).length > 0) {
      fetchData(filterData, currentuser.data.id);
    }
  }, [companiesFiltered]);

  const handleCompanyChange = async (value) => {
    localStorage.setItem('selectedCompany', value);
    setCompany(value);
  };

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Employee is deleted.',
        placement: 'bottomRight',
      });
      await fetchDataAndCleanup();
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys = ['numRow', 'firstName', 'lastName', 'JMBG', 'company', 'email', 'city', 'createdAt', 'updatedAt'];
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'company') {
        alternativeNames.push('name of company');
      } else if (columnKey === 'numRow') {
        alternativeNames.push('no.');
      } else if (columnKey === 'firstName') {
        alternativeNames.push('Name');
      } else if (columnKey === 'lastName') {
        alternativeNames.push('Surname');
      } else if (columnKey === 'JMBG') {
        alternativeNames.push('personal number');
      } else if (columnKey === 'email') {
        alternativeNames.push(columnKey);
      } else if (columnKey === 'city') {
        alternativeNames.push('City');
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('Created');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('Updated');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index],
    }));
  }

  useEffect(() => {
    let dataForTable;
    if (data?.data?.items?.length > 0) {
      dataForTable = data.data.items.map((item, index) => {
        item.numRow = index + 1;
        if (item?.company?.name) {
          item.company = item.company.name;
        }
        const formattedDate = formatDateForTables(item.createdAt);
        const formattedDate2 = formatDateForTables(item.updatedAt);
        return { ...item, createdAt: formattedDate, updatedAt: formattedDate2 };
      });

      const uniqueData = Array.from(new Map(dataForTable.map((item) => [item._id, item])).values());

      if (!currentuser.data.role.includes('hrOfficer')) {
        setTableData(uniqueData);
      }
    }

    if (currentuser.data.role.includes('hrOfficer') && currentuser.data.sectorId) {
      const filteredTableData = dataForTable?.filter((item, index) => {
        if (item.employeeSector === currentuser.data.sectorId) {
          return item;
        }
      });
      console.log('filteredTableData', filteredTableData);
      const uniqueData = filteredTableData?.length
        ? Array.from(new Map(filteredTableData.map((item) => [item._id, item])).values())
        : [];
      setTableData(uniqueData);
    }
  }, [data]);

  const lockEmployees = () => {
    try {
      selectedRows.forEach(async (row) => {
        await Axios.put(
          `${SERVER_URL}/data/${row}`,
          { locked: true },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      });
      notification.success({
        message: 'Employees are locked.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      notification.error({
        message: 'Problem with lock. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const handleExport = async () => {
    const initialCompany = company ? company : localStorage.getItem('selectedCompany');
    if (currentuser.data.role.includes('hrOfficer') && !currentuser.data.role.includes('user')) {
      try {
        const response = await Axios.post(
          `${SERVER_URL}/excel-sector`,
          { company: initialCompany },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        const chosenSector = await Axios.get(`${SERVER_URL}/sector/${initialCompany}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        FileSaver.saveAs(SERVER_URL + response.data.path, `Employees - ${chosenSector.data.sectorName}.xlsx`);
        notification.success({
          message: 'Successful export of employees for sector.',
          placement: 'bottomRight',
        });
      } catch (err) {
        notification.error({
          message: 'Problem with download. Please try later.',
          placement: 'bottomRight',
        });
      }
    } else {
      try {
        const response = await Axios.post(
          `${SERVER_URL}/excel`,
          { company: initialCompany },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        const chosenCompany = await Axios.get(`${SERVER_URL}/companies/${initialCompany}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        FileSaver.saveAs(SERVER_URL + response.data.path, `Employees - ${chosenCompany.data.name}.xlsx`);
        notification.success({
          message: 'Successful export of employees.',
          placement: 'bottomRight',
        });
      } catch (err) {
        notification.error({
          message: 'Problem with download. Please try later.',
          placement: 'bottomRight',
        });
      }
    }
  };

  let companiesList = [];
  if (allowedToAdmins) {
    if (companies?.data?.items) {
      companiesList = companies.data.items;
    }
  } else {
    if (companiesFiltered?.data?.items) {
      companiesList = companiesFiltered.data.items;
    }
  }

  const changeCompanySortOrder = async (sorting) => {
    const initialCompany = company ? company : localStorage.getItem('selectedCompany');
    try {
      const response = await Axios.put(
        `${SERVER_URL}/companies/${initialCompany}`,
        {
          selectedSortingType: sorting,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      return response.data.message;
    } catch (error) {
      console.log('error in changing company preferred sorting order of employees', error);
      notification.error({
        message: 'Problem with changing sorting type. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const handleSortingChange = async (value) => {
    setSelectedSortingType(value);
    if (currentuser.data.role.includes('hrOfficer')) {
      setSortOrder(value);
    } else {
      const response = await changeCompanySortOrder(value);
      if (response === 'Updated') await fetchDataAndCleanup();
    }
  };

  // this sets initial sorting type for admin roles
  useEffect(() => {
    const initialCompany = company ? company : localStorage.getItem('selectedCompany');
    if (companiesFiltered?.data?.items?.length) {
      companiesFiltered.data.items.map((item) => {
        if (item._id === initialCompany) {
          setSelectedSortingType(item.selectedSortingType);
        }
      });
    }
  }, [company, companiesFiltered]);

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        {allowedRoles && (
          <>
            <Link to='/admin/new-data'>
              <Button type='primary'>New employee</Button>
            </Link>
            {allowedToAdmins && (
              <Button
                onClick={lockEmployees}
                disabled={selectedRows.length === 0}
                type='primary'
                style={{ marginLeft: '10px' }}
              >
                Lock employees
              </Button>
            )}
          </>
        )}
        {allowedRoles && (
          <>
            {allowedToAdmins && (
              <Link to='/admin/unlock-employees'>
                <Button disabled={!localStorage.getItem('selectedCompany')} style={{ marginLeft: '10px' }}>
                  Unlock employees
                </Button>
              </Link>
            )}

            <Link to='/admin/terminate-employee'>
              <Button style={{ marginLeft: '10px' }} type='danger'>
                Terminate employment
              </Button>
            </Link>
            <Link to='/admin/extend-employment'>
              <Button className='extendButton' style={{ marginLeft: '10px' }}>
                Extend employment
              </Button>
            </Link>
            <Button style={{ marginLeft: '10px' }} onClick={handleExport}>
              Export
            </Button>
            {!currentuser.data.role.includes('employee') &&
              !currentuser.data.role.includes('hrOfficer') && (
                <Link to='/admin/upload-employees'>
                  <Button className='extendButton' style={{ marginLeft: '10px' }}>
                    Import
                  </Button>
                </Link>
              )}
          </>
        )}
      </div>
      {allowedToAdmins && (
        <div>
          <p style={{ marginTop: '20px' }}>Choose company:</p>
          <Select
            allowClear={true}
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(value) => handleCompanyChange(value)}
            style={{ width: '45%', marginBottom: '20px' }}
            value={company}
          >
            {companiesList &&
              companiesList
                .filter((item) => {
                  if (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) {
                    return true;
                  } else if (currentuser.data.role.includes('user') && !currentuser.data.role.includes('hrOfficer')) {
                    const mainCompanyId = currentuser.data.company._id;
                    return (
                      item._id === mainCompanyId || (item.type === 'branch' && item.mainCompany._id === mainCompanyId)
                    );
                  }
                  return false;
                })
                .map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.name}
                  </Option>
                ))}
          </Select>
        </div>
      )}
      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {allowedRoles && company && !data.isLoading && data.data && data.data.items && data.data.items.length > 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                maxWidth: '600px',
                display: 'flex',
                alignItems: 'center',
              }}
              key={randomKey}
            >
              <div style={{ marginRight: '20px' }}>Sorting:</div>
              <Sorting
                handleSortingChange={handleSortingChange}
                selectedSortingType={selectedSortingType}
                currentuser={currentuser}
              />
            </div>
            <Table
              data={tableData}
              deleteHandler={deleteDataHandler}
              columnKeys={newColumnKeys}
              editPath='/admin/edit-data/'
              viewPath='/admin/view-data/'
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              companyId={company}
              sortOrder={sortOrder}
            />
          </div>
        ) : (
          (!currentuser.data.role.includes('SuperAdmin') || !currentuser.data.role.includes('admin')) &&
          !data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  maxWidth: '600px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: '20px' }}>Sorting:</div>
                <Sorting
                  handleSortingChange={handleSortingChange}
                  selectedSortingType={selectedSortingType}
                  currentuser={currentuser}
                />
              </div>
              <Table
                data={tableData}
                deleteHandler={deleteDataHandler}
                columnKeys={newColumnKeys}
                editPath='/admin/edit-data/'
                viewPath='/admin/view-data/'
                companyId={company}
                sortOrder={sortOrder}
              />
            </div>
          )
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Data;
