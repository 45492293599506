import { Button, Drawer, Divider, Checkbox, notification } from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import Axios from 'axios';
import { SERVER_URL } from '../config/index.js';
import { UserContext } from '../App.js';

const CustomDocsDrawer = ({ onClose, customDocsDrawer, companyId, companyDocsSettings, resetState }) => {
  const currentuser = useContext(UserContext);
  const [holidayLeave, setHolidayLeave] = useState(true);
  const [paidLeave, setPaidLeave] = useState(true);
  const [nonPaidLeave, setNonPaidLeave] = useState(true);
  const [bonus, setBonus] = useState(false);
  const [maternityLeave, setMaternityLeave] = useState(false);

  useEffect(() => {
    if (companyDocsSettings) {
      setHolidayLeave(companyDocsSettings?.holidayLeave);
      setPaidLeave(companyDocsSettings?.paidLeave);
      setNonPaidLeave(companyDocsSettings?.nonPaidLeave);
      setBonus(companyDocsSettings?.bonus);
      setMaternityLeave(companyDocsSettings?.maternityLeave)
    }
  }, [companyDocsSettings]);

  const handleSubmit = async () => {
    const postData = {
      companyDocsSettings: {
        holidayLeave,
        paidLeave,
        nonPaidLeave,
        bonus,
        maternityLeave
      },
    };

    try {
      await Axios.put(`${SERVER_URL}/companies/${companyId}`, postData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Docs settings successfully updated',
        placement: 'bottomRight',
      });

      await onClose();
      await resetState(companyId);
    } catch (error) {
      console.log('error update company for docs settings', error);
    }
  };

  return (
    <Drawer title='HR documents settings' width={520} closable={false} onClose={onClose} open={customDocsDrawer}>
      <div style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
        <p style={{ marginBottom: '10px' }}>
          Here you can define which HR documents you want to create from timesheet.
        </p>
        <p style={{ marginBottom: '10px' }}>This setting will last until you change it.</p>
        <Divider style={{ margin: '30px 0', backgroundColor: '#2db1ab50' }} />
        <div style={{ marginBottom: '10px' }}>
          If you want to <strong>create documents for the following types of work</strong>, check them:
        </div>
        <Divider style={{ margin: '15px 0', backgroundColor: '#2db1ab50' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <span>Holiday leave</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={holidayLeave}
              onChange={() => {
                setHolidayLeave(!holidayLeave);
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>Paid leave</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={paidLeave}
              onChange={() => {
                setPaidLeave(!paidLeave);
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>Non-paid leave</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={nonPaidLeave}
              onChange={() => {
                setNonPaidLeave(!nonPaidLeave);
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>Maternity leave</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={maternityLeave}
              onChange={() => {
                setMaternityLeave(!maternityLeave);
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>Bonus</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={bonus}
              onChange={() => {
                setBonus(!bonus);
              }}
            />
          </div>
        </div>
        <Divider style={{ margin: '30px 0', backgroundColor: '#2db1ab50' }} />
        <div className='text-right timesheetStyles'>
          <Button
            type='primary'
            id='salaryButton'
            htmlType='submit'
            onClick={handleSubmit}
            className='salarySubmitButton timesheetStyles'
          >
            Submit
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default CustomDocsDrawer;
