import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import NavMenu from './NavMenu';
import Routes from '../routes/Routes';
import { Layout, Menu, Dropdown, Button, Tooltip } from 'antd';
import { DownOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { useThemeContext } from '../../context/ThemeContext';
import { ChangePasswordIcon, LogoutIcon, MyAccountIcon } from '../icons';

const { Header, Content } = Layout;

const AdminLayout = () => {
  const user = useContext(UserContext);
  const [isCollapsed, setCollapsed] = useState(false);
  const [collapsedNav, setCollapsedNav] = useState(false);
  const { theme } = useThemeContext();

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark-theme');
      document.documentElement.classList.remove('light-theme');
    } else {
      document.documentElement.classList.add('light-theme');
      document.documentElement.classList.remove('dark-theme');
    }
  }, [theme]);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    Axios.post(`${SERVER_URL}/remove-file-local`, { filename: user.data.id + '.zip' }, { withCredentials: false });
    sessionStorage.removeItem('user');
    localStorage.clear();
    window.location.href = '/login';
  };

  const userMenu = (
    <Menu>
      <Menu.Item>
        <Link to={`/admin/edit-user/${user.data ? user.data.id : ''}`}>
          {user?.data?.role[0].includes('employee') ? (
            <>
              <ChangePasswordIcon className='sub-menu__icon' />
              Change Password
            </>
          ) : (
            <>
              <MyAccountIcon className='sub-menu__icon' />
              My Account
            </>
          )}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <span onClick={handleSignOutSubmit}>
          <LogoutIcon className='logout__icon' />
          Log out
        </span>
      </Menu.Item>
    </Menu>
  );

  const displayName = `${user.data?.firstName} ${user.data?.lastName}`;
  const displayText = displayName.length > 30 ? displayName.slice(0, 30) + '...' : displayName;

  return (
    <Layout
      data-theme={theme === 'dark' ? 'dark' : 'light'}
      style={{
        minHeight: '100vh',
      }}
      className='layout__wrapper'
    >
      <NavMenu isCollapsed={isCollapsed} setCollapsed={setCollapsed} collapsedNav={collapsedNav} />
      <Layout>
        <Header className='admin_header'>
          <Button type='primary' className='btn-collapse' onClick={() => setCollapsedNav(!collapsedNav)}>
            {React.createElement(collapsedNav ? MenuUnfoldOutlined : MenuFoldOutlined)}
          </Button>
          <Link to='/admin' className='logo-mobile'>
            <div className='logo'>
              <img src='logoHLB.png' alt='HLB logo' />
            </div>
          </Link>
          <Dropdown overlay={userMenu} trigger={['click']} className='sub-menu__icon'>
            <span className='ant-dropdown-link'>
              <span className='user-header__wrapper'>
                {displayText}
                {displayName.length > 30 && (
                  <Tooltip title={displayName}>
                    <span>...</span>
                  </Tooltip>
                )}
              </span>
              <DownOutlined className={`ant-dropdown__icon ${theme === 'dark' ? '' : ''}`} />
            </span>
          </Dropdown>
        </Header>
        <Content className='content-space'>
          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
