import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

export default function NewCompanyIcon(props) {
  const { theme } = useThemeContext();
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_32_48)'>
        <path
          opacity='0.1'
          d='M12.4999 1.13H9.37492C7.41073 1.13 6.42864 1.13 5.81845 1.93546C5.20825 2.74091 5.20825 4.03727 5.20825 6.63V17.63V25.5362C5.20825 25.7261 5.32485 25.88 5.46867 25.88H19.5312C19.675 25.88 19.7916 25.7261 19.7916 25.5362V17.63V6.63C19.7916 4.03727 19.7916 2.74091 19.1814 1.93546C18.5712 1.13 17.5891 1.13 15.6249 1.13H12.4999Z'
          // fill={theme === 'dark' ? '#D1D1D1' : '#000000'}
          fill={theme === 'dark' ? '#D1D1D1' : 'white'}
        />
        <path
          d='M2.13 24.5H22.88'
          // fill={theme === 'dark' ? '#D1D1D1' : '#000000'}
          stroke={theme === 'dark' ? '#D1D1D1' : '#000000'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.7933 25.88V17.63V6.63C19.7933 4.03727 19.7933 2.74091 19.1831 1.93546C18.5729 1.13 17.5908 1.13 15.6266 1.13H12.5016H9.37663C7.41244 1.13 6.43035 1.13 5.82016 1.93546C5.20996 2.74091 5.20996 4.03727 5.20996 6.63V17.63V25.88'
          // fill={theme === 'dark' ? '#D1D1D1' : '#000000'}
          stroke={theme === 'dark' ? '#D1D1D1' : '#000000'}
          strokeLinejoin='round'
        />
        <path
          d='M9.375 8.33334H10.4167'
          // fill={theme === 'dark' ? '#D1D1D1' : '#000000'}
          stroke={theme === 'dark' ? '#D1D1D1' : '#000000'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.375 12.5H10.4167'
          // fill={theme === 'dark' ? '#D1D1D1' : '#000000'}
          stroke={theme === 'dark' ? '#D1D1D1' : '#000000'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.375 16.6667H10.4167'
          // fill={theme === 'dark' ? '#D1D1D1' : '#000000'}
          stroke={theme === 'dark' ? '#D1D1D1' : '#000000'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5834 8.33334H15.625'
          // fill={theme === 'dark' ? '#D1D1D1' : '#000000'}
          stroke={theme === 'dark' ? '#D1D1D1' : '#000000'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5834 12.5H15.625'
          // fill={theme === 'dark' ? '#D1D1D1' : '#000000'}
          stroke={theme === 'dark' ? '#D1D1D1' : '#000000'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5834 16.6667H15.625'
          // fill={theme === 'dark' ? '#D1D1D1' : '#000000'}
          stroke={theme === 'dark' ? '#D1D1D1' : '#000000'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.76562 8.125C9.76562 10.0105 10.5146 11.8188 11.8479 13.1521C13.1812 14.4854 14.9895 15.2344 16.875 15.2344C18.7605 15.2344 20.5688 14.4854 21.9021 13.1521C23.2354 11.8188 23.9844 10.0105 23.9844 8.125C23.9844 6.23948 23.2354 4.43118 21.9021 3.09791C20.5688 1.76465 18.7605 1.01563 16.875 1.01562C14.9895 1.01563 13.1812 1.76465 11.8479 3.09791C10.5146 4.43118 9.76563 6.23948 9.76562 8.125Z'
          fill='#4CAF50'
        />
        <path d='M15.8594 4.73959H17.8906V11.5104H15.8594V4.73959Z' fill='white' />
        <path d='M13.4896 7.10938H20.2605V9.14062H13.4896V7.10938Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_32_48'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
