import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Popconfirm, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined, SolutionOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import moment from 'moment';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';

const DataTable = ({
                     data,
                     deleteHandler,
                     columnKeys,
                     editPath,
                     viewPath,
                     setSelectedRows,
                     page,
                     companyId,
                     sortOrder,
                   }) => {
  const user = useContext(UserContext);
  const [currentCompany, fetchCurrentCompany] = useAxios('', [], user.data.token, 'get');
  const [sortedData, setSortedData] = useState([]);
  let searchInput;

  function dynamicSort(data, sortOrder) {
    switch (sortOrder) {
      case 'ascending':
        return data.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
      case 'descending':
        return data.sort((a, b) => (a.firstName < b.firstName ? 1 : -1));
      case 'firstToLastAdded':
        return data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      case 'lastToFirstAdded':
        return data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      default:
        return data;
    }
  }

  useEffect(() => {
    if (data && data.length && sortOrder && user.data.role.includes('hrOfficer')) {
      setSortedData(dynamicSort(data, sortOrder));
    }
  }, [data, sortOrder]);

  useEffect(() => {
    const filter = { _id: companyId };
    fetchCurrentCompany(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
  }, [companyId]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
          <div style={{ padding: 8 }}>
            <Input
                ref={(node) => {
                  searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
                type='primary'
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size='small'
                style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
          ? !dataIndex.includes('category')
              ? record[dataIndex[0]][dataIndex[1]]
              : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
          : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => {
    return {
      key: item.originalName,
      width:
          item.originalName !== 'numRow' &&
          item.originalName !== 'firstName' &&
          item.originalName !== 'lastName' &&
          item.originalName !== 'JMBG' &&
          item.originalName !== 'email' &&
          item.originalName !== 'city' &&
          item.originalName !== 'createdAt' &&
          item.originalName !== 'updatedAt'
              ? '100%'
              : item.originalName === 'numRow'
                  ? '5%'
                  : item.originalName === 'JMBG'
                      ? '17%'
                      : item.originalName === 'firstName' || item.originalName === 'lastName'
                          ? '17%'
                          : '30%',
      title: item.alternativeName.toUpperCase(),
      dataIndex: ['title', 'category'].includes(item.originalName)
          ? [item.originalName, user.language ? user.language.selected.code : '']
          : item.originalName,
      ...getColumnSearchProps(
          ['title', 'category'].includes(item.originalName)
              ? [item.originalName, user.language ? user.language.selected.code : '']
              : item.originalName,
      ),
      render: (text) => {
        const tooltip = (
            <Tooltip title={text}>
            <span style={{ display: 'inline-block', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {text}
            </span>
            </Tooltip>
        );

        const textElement = (
            <span style={{ display: 'inline-block', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {text}
          </span>
        );

        return text && text.length > 35 ? tooltip : textElement;
      },
    };
  });

  if (!page) {
    columns.push({
      title: 'Options',
      render: (text, record) => {
        return (
            <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
              {(!record.locked || user.data.role.includes('admin')) && (
                  <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                    <Link to={editPath + record._id}>
                      <EditOutlined title={'Edit employee'} style={{ textDecoration: 'none', color: 'black' }} />
                    </Link>
                  </div>
              )}
              {viewPath && (
                  <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                    <Link to={viewPath + record._id}>
                      <EyeOutlined title={'View employee'} style={{ textDecoration: 'none', color: 'black' }} />
                    </Link>
                  </div>
              )}
              {editPath &&
                  (user?.data?.role?.includes('admin') || user?.data?.role?.includes('SuperAdmin')) &&
                  !user?.data?.role?.includes('user') &&
                  currentCompany &&
                  currentCompany?.data &&
                  currentCompany?.data?.items &&
                  currentCompany?.data?.items[0]?.isHr && (
                      <Link to={editPath + record._id + '?hr'}>
                        <SolutionOutlined title={'Create HR document'} style={{ color: 'black' }} />
                      </Link>
                  )}
              {editPath &&
                  (user?.data?.role?.includes('hrOfficer') ||
                      user?.data?.role?.includes('user') ||
                      user?.data?.role?.includes('userOfficer')) && (
                      <Link to={editPath + record._id + '?hr'}>
                        <SolutionOutlined title={'Create HR document'} style={{ paddingLeft: '4px', color: 'black' }} />
                      </Link>
                  )}

              {(user.data.role.includes('admin') || user.data.role.includes('SuperAdmin')) && (
                  <div style={{ margin: '2px', padding: '4px' }}>
                    <Popconfirm
                        disabled={record._id === user.data.id}
                        placement='left'
                        title={'This will delete employee and ALL RECORDS FOR THIS EMPLOYEE. Do you want to delete employee?'}
                        onConfirm={() => {
                          deleteHandler(record._id);
                        }}
                        okText='Ok'
                        cancelText='Cancel'
                    >
                      <DeleteOutlined title={'Delete employee'} />
                    </Popconfirm>
                  </div>
              )}
            </div>
        );
      },
    });
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  function onChange(page) {
    page && localStorage.setItem('pageNumber2', page);
    localStorage.getItem('pageNumber2') &&
    parseInt(localStorage.getItem('pageNumber2')) === 1 &&
    localStorage.removeItem('pageNumber2');
  }

  return (
    <div>
      {user.data.role.includes('admin') ? (
        <Table
          size='middle'
          bordered
          dataSource={sortedData.length ? sortedData : data}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
            onChange: onChange,
            defaultCurrent: localStorage.getItem('pageNumber2') ? parseInt(localStorage.getItem('pageNumber2')) : 1,
          }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          rowClassName={(record) =>
            record.endDate && moment(record.endDate).isBefore(moment(), 'day') ? 'suspended' : ''
          }
        />
      ) : (
        <Table
          size='middle'
          bordered
          dataSource={sortedData.length ? sortedData : data}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
            onChange: onChange,
            defaultCurrent: localStorage.getItem('pageNumber2') ? parseInt(localStorage.getItem('pageNumber2')) : 1,
          }}
          rowClassName={(record) =>
            record.endDate && moment(record.endDate).isBefore(moment(), 'day') ? 'suspended' : ''
          }
        />
      )}
    </div>
  );
};

export default DataTable;
