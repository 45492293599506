import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
  Upload,
} from 'antd';
import useAxios from '../../hooks/useAxios';
import { DownloadOutlined, EditOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import Axios from 'axios';
import moment from 'moment';
import FileSaver from 'file-saver';
import HrEmployeeTable from '../tables/HREmployeeTable';
import HrForm from '../HrForm';
import CareerForm from './CareerForm';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

const formInit = {
  firstName: undefined,
  lastName: undefined,
  JMBG: undefined,
  email: undefined,
  position: undefined,
  positionEng: undefined,
  phone: undefined,
  gender: undefined,
  city: undefined,
  zip: undefined,
  address: undefined,
  mealCurrency: 'rsd',
  holidayCurrency: 'rsd',
  travelExpCurrency: 'rsd',
  employmentType: 'unlimited',
  workTimeType: 'fullTime',
  workTimeCustomType: 0,
  otherIncomeCurrency: 'rsd',
  otherExpensesCurrency: 'rsd',
  suspensionCurrency: 'rsd',
  bonusCurrency: 'rsd',
  otherIncomeValue: 0,
  otherExpensesValue: 0,
  suspensionValue: 0,
  bonusValue: 0,
  hotMeal: 0,
  regres: 0,
  travelExpenses: 0,
  workFromHome: 0,
  employeeTrackerType: 'new',
  numberOfHolidayDays: 0,
  numberOfHolidayDaysByEndOfContract: 0,
  numberOfHolidayDaysContract: '',
  numberOfHolidayDaysCurrent: '',
  numberOfHolidayDaysPast: '0',
  numberOfHolidayDaysUsed: '0',
  employeeSector: undefined,
};

const DataForm = ({ isNew, data, onSubmit, SERVER_URL, location, datesForDisabling, token }) => {
  const [form] = Form.useForm();
  const myRef = useRef(null);
  const currentuser = useContext(UserContext);
  const [company, setCompanies] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [holidayPastDisabled, setHolidayPastDisabled] = useState(true);
  const [selectedType, setSelectedType] = useState();
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [isError, setIsError] = useState(false);
  const [employmentType, setEmploymentType] = useState((data && data.employmentType) || 'unlimited');
  const [companyAndBranches, setCompanyAndBranches] = useState();
  const [customTime, setCustomTime] = useState(data?.workTimeType);
  const [currentSectors, fetchCurrentSectors] = useAxios('', [], currentuser.data.token, 'get');
  const [executed, setExecuted] = useState(true);
  const [employeeTrackerType, setEmployeeTrackerType] = useState(data ? data.employeeTrackerType : 'new');
  const [passwordChangeVisible, setPasswordChangeVisible] = useState(false);
  const [chosenStartDate, setChosenStartDate] = useState(undefined);
  const [defaultSectorName, setDefaultSectorName] = useState(undefined);
  const [isTotalDaysForContractFieldVisible, setIsTotalDaysForContractFieldVisible] = useState(false);
  const [disableCurrentDaysUsed, setDisableCurrentDaysUsed] = useState(false);
  const [disablePastDays, setDisablePastDays] = useState(false);
  const [numberOfHolidayDaysCurrent, setNumberOfHolidayDaysCurrent] = useState(
    data && data.numberOfHolidayDaysCurrent ? data.numberOfHolidayDaysCurrent : 0,
  );
  const [numberOfHolidayDaysByEndOfContract, setNumberOfHolidayDaysByEndOfContract] = useState(
    data && data.numberOfDaysByEndOfContract ? +data.numberOfDaysByEndOfContract : 0,
  );
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const calcMethods = {
    automatic: 'Per month (regular work)',
    manual: ['Per month (all days of work)', 'Per month (all days)'],
    fixed: 'Per month (fixed defined value)',
    perDay: ['Per day (regular work)', 'Per day (holiday leave)'],
    monthlyFee: 'Per month (excluding sick leave)',
  };

  // this set variable if employee's start date is before half of the year
  // this is for conditional rendering holiday days elements
  const handleStartDateSelect = () => {
    setChosenStartDate(moment(form.getFieldValue('startDate')));
    form.setFieldsValue({
      numberOfHolidayDaysCurrent: '',
      numberOfHolidayDaysContract: '',
      numberOfHolidayDays: 0,
      numberOfHolidayDaysByEndOfContract: 0,
      numberOfHolidayDaysPast: '0',
      numberOfHolidayDaysUsed: '0',
    });
  };

  const executeScroll = () => {
    if (myRef && myRef.current && myRef.current.scrollIntoView) {
      const element = myRef.current;
      const elementRect = element.getBoundingClientRect();
      const offset = window.pageYOffset || document.documentElement.scrollTop;

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        top: elementRect.top + offset - 100,
      });
    }
  };


  // this resets some of the form items
  // to have all form fields related to holiday tracker reset upon date changes
  const handleEndDateSelect = () => {
    form.setFieldsValue({
      numberOfHolidayDaysCurrent: '',
      numberOfHolidayDaysContract: '',
      numberOfHolidayDays: 0,
      numberOfHolidayDaysByEndOfContract: 0,
      numberOfHolidayDaysPast: '0',
      numberOfHolidayDaysUsed: '0',
    });
    // this is for conditional display of numberOfHolidayDaysByEndOfContract field
    // if employee endDate is not within current year, numberOfHolidayDaysByEndOfContract is not being calculating
    const currentYear = new Date().getFullYear();
    if (new Date(form.getFieldValue('endDate')).getFullYear() === currentYear) {
      setIsTotalDaysForContractFieldVisible(true);
    } else {
      setIsTotalDaysForContractFieldVisible(false);
    }
  };

  // calculation of holiday days for new employee
  const employeeType = (e) => {
    setEmployeeTrackerType(e.target.value);
  };

  // this resets value of holiday tracker fields upon switch between New Employee and Existing Employee
  // or employment types: limited/unlimited employment
  // or upon changing the selected company
  useEffect(() => {
    if(data) {
      setSelectedCompany(data.company);
    }
  }, [data]);

  useEffect(() => {
    if(isNew) {
      form.setFieldsValue({
        numberOfHolidayDaysCurrent: '',
        numberOfHolidayDaysContract: '',
        numberOfHolidayDays: 0,
        numberOfHolidayDaysByEndOfContract: 0,
        numberOfHolidayDaysPast: '0',
        numberOfHolidayDaysUsed: '0',
      });
    }
  }, [employeeTrackerType, employmentType, selectedCompany, form, isNew]);

  const getAllCompanies = async () => {
    try {
      const responseCompanies = await Axios.get(`${SERVER_URL}/companies`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      
      setCompanies(responseCompanies.data);
      setExecuted(false);
    } catch (error) {
      console.log('error message', error.message);
    }
  };

  // set default company for hrOfficer role
  useEffect(() => {
    if (company && company.items && (currentuser?.data?.role.includes('hrOfficer') || currentuser?.data?.role.includes('userOfficer'))) {
      const foundCompany = company.items.filter((item) => item._id === currentuser.data.company?._id);
      setSelectedCompany(foundCompany[0]);
    }
  }, [company, currentuser]);

  useEffect(() => {
    const filterSectors = { company: selectedCompany?._id };
    fetchCurrentSectors(`${SERVER_URL}/sectors?filter=${JSON.stringify(filterSectors)}`, []);
  }, [SERVER_URL, selectedCompany]);


  // set needed data for hrOfficer role
  useEffect(() => {
    if (currentSectors && currentSectors.data && currentuser.data.role.includes('hrOfficer')) {
      const foundSector = currentSectors.data.filter((item) => item._id === currentuser.data.sectorId);
      if (foundSector && foundSector.length) {
        form.setFieldsValue({
          employeeSector: foundSector[0],
        });
        setDefaultSectorName(foundSector[0].sectorName);
      }
    }
  }, [currentSectors]);

  useEffect(() => {
    if (executed) {
      getAllCompanies();
    }
  }, [executed, getAllCompanies]);

  useEffect(() => {
    setCustomTime(customTime);
  }, [customTime]);

  const [formInitialValues, setFormInitialValues] = useState();

  useEffect(() => {
    let initialValues = { ...formInit, ...data, company: data?.company?._id ? data?.company?._id : '' };
    setFormInitialValues(initialValues);
    if (!isNew && holidayPastDisabled) {
      form.setFieldsValue({ ...initialValues });
    }
  }, [isNew]);

  // prevent entering both holiday days from past and holiday days used from current year at the same time
  useEffect(() => {
    const numberOfHolidayDaysPast = Number(form.getFieldValue('numberOfHolidayDaysPast'));
    const numberOfHolidayDaysUsed = Number(form.getFieldValue('numberOfHolidayDaysUsed'));

    if (numberOfHolidayDaysPast > 0) {
      form.setFieldValue('numberOfHolidayDaysUsed', 0);
      setDisableCurrentDaysUsed(true);
      setDisablePastDays(false);
    } else if (numberOfHolidayDaysPast === 0 && numberOfHolidayDaysUsed === 0) {
      setDisableCurrentDaysUsed(false);
      setDisablePastDays(false);
    }

    if (numberOfHolidayDaysUsed > 0) {
      form.setFieldValue('numberOfHolidayDaysPast', 0);
      setDisablePastDays(true);
      setDisableCurrentDaysUsed(false);
    } else if (numberOfHolidayDaysPast === 0 && numberOfHolidayDaysUsed === 0) {
      setDisableCurrentDaysUsed(false);
      setDisablePastDays(false);
    }
  }, [
    form.getFieldValue('numberOfHolidayDaysUsed'),
    form.getFieldValue('numberOfHolidayDaysPast'),
    formInitialValues
  ]);

  const handleInputChange = (e) => {
    setCustomTime(e.target.value);
  };

  const onFinish = async (values, isNew) => {
    setSubmitDisabled(true);
    let attachments = [];
    values.workTimeCustomType = +values.workTimeCustomType;
    values.numberOfHolidayDaysCurrent = +numberOfHolidayDaysCurrent;
    values.numberOfHolidayDaysByEndOfContract = +numberOfHolidayDaysByEndOfContract;

    if (!isNew) {
      values._id = data._id;
    }
    if (data) {
      attachments = data.attachments;
    }
    for (let i = 0; i < selectedTypes.length; i++) {
      attachments.push({
        typeOfFile: selectedTypes[i],
        location: files[i].location,
        originalName: files[i].originalName,
      });
    }
    values.attachments = attachments;

    values.otherExpensesCurrency = selectedCompany.otherExpensesCurrency
      ? selectedCompany.otherExpensesCurrency
      : 'rsd';
    values.otherIncomeCurrency = selectedCompany.otherIncomeCurrency ? selectedCompany.otherIncomeCurrency : 'rsd';
    values.suspensionCurrency = selectedCompany.suspensionCurrency ? selectedCompany.suspensionCurrency : 'rsd';
    values.bonusCurrency = selectedCompany.bonusCurrency ? selectedCompany.bonusCurrency : 'rsd';

    if (employmentType && employmentType !== 'limited') {
      values.numberOfHolidayDaysByEndOfContract = null;
    }

    if (isNew) {
      values.mealCurrency = selectedCompany.mealCurrency ? selectedCompany.mealCurrency : 'rsd';
      values.holidayCurrency = selectedCompany.holidayCurrency ? selectedCompany.holidayCurrency : 'rsd';
      values.travelExpCurrency = selectedCompany.travelExpCurrency ? selectedCompany.travelExpCurrency : 'rsd';
      values.otherExpensesCurrency = selectedCompany.otherExpensesCurrency
        ? selectedCompany.otherExpensesCurrency
        : 'rsd';
      values.otherIncomeCurrency = selectedCompany.otherIncomeCurrency ? selectedCompany.otherIncomeCurrency : 'rsd';
      values.suspensionCurrency = selectedCompany.suspensionCurrency ? selectedCompany.suspensionCurrency : 'rsd';
      values.bonusCurrency = selectedCompany.bonusCurrency ? selectedCompany.bonusCurrency : 'rsd';

      if (
        currentuser.data.role.includes('SuperAdmin') ||
        currentuser.data.role.includes('admin') ||
        companyAndBranches.length > 1
      ) {
        form.setFieldsValue({ company: values.company });
      } else {
        values.company = selectedCompany._id;
        if (currentuser.data.role.includes('hrOfficer')) {
          values.company = currentuser.data.company._id;
        }
      }
      values.numberOfHolidayDays = +values.numberOfHolidayDays;
      setFileList([]);
      setFiles([]);
      setSelectedTypes([]);
      setSelectedType();
    }
    const res = await onSubmit(values, isNew);

    if (res !== false) {
      form.resetFields();
      executeScroll();
      setSubmitDisabled(false);
    }
  };

  if (data && data.company && data.company._id) {
    data.startDate = data.startDate && moment(data.startDate);
    data.endDate = data.endDate && moment(data.endDate);
  }

  if (data && data.company && data.company._id) {
    data.dateOfWorkPermissionForeigner =
      data.dateOfWorkPermissionForeigner && moment(data.dateOfWorkPermissionForeigner);
  }

  if (
    (!currentuser.data.role.includes('SuperAdmin') || !currentuser.data.role.includes('admin')) &&
    company &&
    company?.items &&
    !companyAndBranches
  ) {
    let companyClient;
    let companyBranches;
    const userRole = currentuser.data.role.map((item) => item);
    if (userRole.includes('userOfficer')) {
      companyClient = company.items.filter((item) => item.userOfficer == currentuser.data.id);

      // Check if the _id matches in companyClient or mainCompany within companyClient
      companyBranches = company.items.filter((companyItem) => {
        return (
          companyClient.some((client) => client._id === companyItem._id) ||
          companyClient.some((client) => client.mainCompany && client.mainCompany._id === companyItem._id)
        );
      });
    } else {
      companyClient = company.items.filter((item) => item.client?._id === currentuser.data.id);
      companyBranches =
        companyClient.length !== 0
          ? company.items.filter(
              (item) => item._id === companyClient[0]._id || item?.mainCompany?._id === companyClient[0]._id,
            )
          : [];
    }

    if (companyBranches.length === 1) {
      setSelectedCompany(companyClient[0]);
      setCompanyAndBranches(companyBranches);
    } else {
      company.items = companyBranches;
      !companyAndBranches && setCompanyAndBranches(companyBranches);
    }

    setCompanyAndBranches(company.items);
  }

  const resetSectorOnCompanyChange = () => {
    form.setFieldValue('employeeSector', undefined);
  }

  const selectCompany = async (companyId) => {
    const foundCompany = company.items.find((item) => item._id === companyId);
    resetSectorOnCompanyChange();
    setSelectedCompany(foundCompany);
  };

  const canUserTransferEmployee = (userRole, company = undefined) => {
    if (userRole.includes('admin') || userRole.includes('SuperAdmin')) {
      return true;
    }

    if ((userRole === 'user' || userRole === 'userOfficer') && company.mainCompany === undefined) {
      return true;
    }

    return false;
  }

  let calcMethodNames = {};

  if (selectedCompany) {
    calcMethodNames = {
      calculationMethodMeal: Array.isArray(calcMethods[selectedCompany.calculationMethodMeal])
        ? calcMethods[selectedCompany.calculationMethodMeal][0]
        : calcMethods[selectedCompany.calculationMethodMeal],
      calculationMethodHoliday: Array.isArray(calcMethods[selectedCompany.calculationMethodHoliday])
        ? selectedCompany.calculationMethodHoliday === 'perDay'
          ? calcMethods[selectedCompany.calculationMethodHoliday][1]
          : calcMethods[selectedCompany.calculationMethodHoliday][0]
        : calcMethods[selectedCompany.calculationMethodHoliday],
      travelExpenses: Array.isArray(calcMethods[selectedCompany.travelExpenses])
        ? calcMethods[selectedCompany.travelExpenses][1]
        : calcMethods[selectedCompany.travelExpenses],
      calculationMethodWorkFromHome: Array.isArray(calcMethods[selectedCompany.calculationMethodWorkFromHome])
        ? calcMethods[selectedCompany.calculationMethodWorkFromHome][1]
        : calcMethods[selectedCompany.calculationMethodWorkFromHome],
    };
  }

  if (data && data.attachments && fileList.length === 0) {
    data.attachments.forEach((attachment, index) => {
      let thumbUrl;
      let nameArray = attachment.originalName.split('.');
      switch (nameArray[nameArray.length - 1]) {
        case 'pdf':
          thumbUrl = '/pdf.png';
          break;
        case 'docx':
          thumbUrl = '/docx.png';
          break;
        case 'png':
          thumbUrl = '/png.png';
          break;
        case 'jpg':
          thumbUrl = '/jpg.png';
          break;
        case 'xlsx':
          thumbUrl = '/xlsx.png';
          break;

        default:
          thumbUrl = '/other.png';
          break;
      }
      setFileList((prevState) => [
        ...prevState,
        {
          uid: (index + 1).toString(),
          name: attachment.originalName,
          status: 'done',
          thumbUrl,
          response: {
            key: attachment.location,
          },
        },
      ]);
    });
  }

  const showUploadList = {
    showDownloadIcon: true,
    downloadIcon: <DownloadOutlined />,
    showRemoveIcon:
      !!(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')),
  };

  const handleStartDateEmpty = () => {
    const currentDate = form.getFieldValue('startDate');

    if (!currentDate) {
      notification.info({
        message: 'Please enter start date first!',
        placement: 'bottomRight',
      });
    }
  };

  // setting date in the middle of the year until when employee can use his old holiday days from last year - 1.7.
  const date = new Date();
  date.setMonth(6); //6
  date.setDate(1);
  const today = moment(date).toLocaleString();
  const trackerDate = moment(selectedCompany?.trackerStartDate).toLocaleString();
  const currentYear = new Date().getFullYear();

  const calculateContractDaysChange = (e) => {
    form.setFieldValue(`${e.target.id}`, Number(form.getFieldValue(e.target.id)));
    const date = new Date();
    const endDate = moment(form.getFieldValue('endDate'));
    let startDate = moment(form.getFieldValue('startDate'));
    const startDateOfYear = new Date(date.getFullYear(), 0, 1);
    const endOfYearDate = new Date();
    endOfYearDate.setMonth(11); //December
    endOfYearDate.setDate(31);
    date.setMonth(6); // July is zero-based, so 6 represents July, half of the year
    date.setDate(1);
    const todayDate = new Date();
    const currentMonth = todayDate.getMonth(); // 0 for January, 11 for December
    const isStartDateWithinCurrentYear = new Date(form.getFieldValue('startDate')).getFullYear() === currentYear;
    const isEndDateWithinCurrentYear = new Date(form.getFieldValue('endDate')).getFullYear() === currentYear;
    startDate = !isStartDateWithinCurrentYear ? new Date(startDateOfYear) : startDate;
    const condition = (employeeTrackerType === 'existing' && !isStartDateWithinCurrentYear) || !isNew;
    const monthsLeft = 12 - (condition ? 0 : currentMonth);
    let daysDifferance = endDate.diff(startDateOfYear, 'day', true);
    daysDifferance = daysDifferance > 365 ? 365 : daysDifferance;
    let basicCalculationForYear;

    const dateOfYearEnd = moment(endOfYearDate);
    const variable = isEndDateWithinCurrentYear ? endDate : dateOfYearEnd;
    const employeesDaysDifferance = variable.diff(startDate, 'day', true);

    if (isStartDateWithinCurrentYear) {
      daysDifferance = dateOfYearEnd.diff(startDate, 'day', true);
      basicCalculationForYear =
          Number(!endDate || !isEndDateWithinCurrentYear ? employeesDaysDifferance : daysDifferance) *
          (Number(form.getFieldValue('numberOfHolidayDaysContract')) / 365);
    } else {
      basicCalculationForYear = Number(monthsLeft) * (Number(form.getFieldValue('numberOfHolidayDaysContract')) / 12);
    }

    let basicCalculationForContract =
        Number(endDate ? employeesDaysDifferance : daysDifferance) *
        (Number(form.getFieldValue('numberOfHolidayDaysContract')) / 365);

    let totalDaysLeftThisYear;
    let totalDaysLeftForContract;
    let currentYearDays;

    if (isNew) {
      if (employmentType === 'limited') {
        if (employeeTrackerType === 'existing') {
          // check if endDate is within current year
          if (new Date(form.getFieldValue('endDate')).getFullYear() === currentYear) {
            basicCalculationForContract =
                Number(employeesDaysDifferance) * (Number(form.getFieldValue('numberOfHolidayDaysContract')) / 365);
            totalDaysLeftForContract = Number(
                Number(basicCalculationForContract) +
                Number(form.getFieldValue('numberOfHolidayDaysPast')) -
                Number(form.getFieldValue('numberOfHolidayDaysUsed')),
            );
            totalDaysLeftThisYear = Number(
                Number(basicCalculationForYear.toFixed(0)) +
                Number(form.getFieldValue('numberOfHolidayDaysPast')) -
                Number(form.getFieldValue('numberOfHolidayDaysUsed')),
            );
            currentYearDays = totalDaysLeftForContract;
          } else {
            // case when endDate is not within current year
            totalDaysLeftForContract = '';
            if (form.getFieldValue('numberOfHolidayDaysCurrent') === 0 || numberOfHolidayDaysCurrent === 0) {
              totalDaysLeftThisYear = 0;
            } else {
              totalDaysLeftThisYear = Number(
                  Number(basicCalculationForYear.toFixed(0)) +
                  Number(form.getFieldValue('numberOfHolidayDaysPast')) -
                  Number(form.getFieldValue('numberOfHolidayDaysUsed')),
              );
              currentYearDays = totalDaysLeftThisYear;
            }
          }
        }

        if (employeeTrackerType === 'new') {
          if (new Date(form.getFieldValue('endDate')).getFullYear() === currentYear) {
            totalDaysLeftForContract = Number(basicCalculationForContract.toFixed(0));
            currentYearDays = totalDaysLeftForContract;
          } else {
            totalDaysLeftThisYear = Number(
                Number(basicCalculationForYear.toFixed(0)) +
                Number(form.getFieldValue('numberOfHolidayDaysPast')) -
                Number(form.getFieldValue('numberOfHolidayDaysUsed')),
            );
            currentYearDays = totalDaysLeftThisYear;
            totalDaysLeftForContract = 'null';
            currentYearDays = totalDaysLeftThisYear;
          }
          totalDaysLeftThisYear = Number(basicCalculationForYear.toFixed(0));
        }
      }

      if (employmentType === 'unlimited') {
        if (employeeTrackerType === 'existing') {
          totalDaysLeftThisYear = Number(
              Number(basicCalculationForYear.toFixed(0)) +
              Number(form.getFieldValue('numberOfHolidayDaysPast')) -
              Number(form.getFieldValue('numberOfHolidayDaysUsed')),
          );
          currentYearDays = totalDaysLeftThisYear;
        } else {
          totalDaysLeftThisYear = Number(basicCalculationForYear.toFixed(0));
          currentYearDays = totalDaysLeftThisYear;
        }
      }

      const value = totalDaysLeftForContract ? Number(totalDaysLeftForContract).toFixed(0) : '';

      form.setFieldsValue({ numberOfHolidayDays: Number(totalDaysLeftThisYear).toFixed(0) });
      form.setFieldsValue({
        numberOfHolidayDaysByEndOfContract:
            Number(value) > Number(totalDaysLeftThisYear) ? Number(totalDaysLeftThisYear) : Number(value),
      });
      setNumberOfHolidayDaysByEndOfContract(
          Number(value) > Number(totalDaysLeftThisYear) ? Number(totalDaysLeftThisYear) : Number(value),
      );
      setNumberOfHolidayDaysCurrent(Number(currentYearDays).toFixed(0));
    }

    if (!isNew) {
      if (form.getFieldValue('endDate') !== undefined) {
        if (new Date(form.getFieldValue('endDate')).getFullYear() === currentYear) {
          totalDaysLeftForContract = Number(
              Number(basicCalculationForContract) +
              Number(form.getFieldValue('numberOfHolidayDaysPast')) -
              Number(form.getFieldValue('numberOfHolidayDaysUsed')),
          );
          totalDaysLeftThisYear = Number(
              Number(basicCalculationForYear.toFixed(0)) +
              Number(form.getFieldValue('numberOfHolidayDaysPast')) -
              Number(form.getFieldValue('numberOfHolidayDaysUsed')),
          );

          const value = totalDaysLeftForContract ? Number(totalDaysLeftForContract).toFixed(0) : '';
          currentYearDays = totalDaysLeftForContract;
          setNumberOfHolidayDaysByEndOfContract(
              Number(value) > Number(totalDaysLeftForContract)
                  ? Number(totalDaysLeftForContract).toFixed(0)
                  : Number(value),
          );
        }
        if (new Date(form.getFieldValue('endDate')).getFullYear() !== currentYear) {
          // case when endDate is not within current year
          totalDaysLeftForContract = '';
          totalDaysLeftThisYear = Number(
              Number(basicCalculationForYear.toFixed(0)) +
              Number(form.getFieldValue('numberOfHolidayDaysPast')) -
              Number(form.getFieldValue('numberOfHolidayDaysUsed')),
          );
          currentYearDays = totalDaysLeftThisYear;
        }
      }

      if (form.getFieldValue('endDate') === undefined) {
        totalDaysLeftThisYear = Number(
            Number(basicCalculationForYear.toFixed(0)) +
            Number(form.getFieldValue('numberOfHolidayDaysPast')) -
            Number(form.getFieldValue('numberOfHolidayDaysUsed')),
        );
        currentYearDays = totalDaysLeftThisYear;
      }

      form.setFieldsValue({ numberOfHolidayDays: Number(totalDaysLeftThisYear).toFixed(0) });
      form.setFieldsValue({
        numberOfHolidayDaysByEndOfContract: totalDaysLeftForContract ? Number(totalDaysLeftForContract).toFixed(0) : '',
      });
      setNumberOfHolidayDaysCurrent(Number(currentYearDays).toFixed(0));
    }
  };

  const toggleDisable = () => {
    setHolidayPastDisabled(!holidayPastDisabled);
  };

  return (
    <div className='dashboard'>
      <div style={{ width: '80%' }}>
        {company && formInitialValues && company.items ? (
          <Card title={`${isNew ? 'NEW' : 'EDIT'} EMPLOYEE`} bordered={false} ref={myRef}>
            {isNew ? (
              <Form
                className='form-horizontal employeeForm'
                initialValues={formInitialValues}
                onFinish={(values) => onFinish(values, !data)}
                layout='horizontal'
                form={form}
                labelAlign='left'
              >
                {(currentuser.data.role.includes('SuperAdmin') ||
                  currentuser.data.role.includes('admin') ||
                  (companyAndBranches && companyAndBranches.length > 1)) && (
                  <Form.Item
                    className='employeeField'
                    label='Company'
                    labelCol={{ span: 6 }}
                    name='company'
                    rules={[
                      {
                        required: true,
                        message: 'Please choose company!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={(value) => selectCompany(value)}
                    >
                      {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) &&
                        company &&
                        company.items &&
                        company.items.map((item, index) => (
                          <Option key={index} value={item._id}>
                            {item.name}
                          </Option>
                        ))}
                      {(!currentuser.data.role.includes('SuperAdmin') || !currentuser.data.role.includes('admin')) &&
                        companyAndBranches &&
                        companyAndBranches.length !== 0 &&
                        companyAndBranches.map((item, index) => (
                          <Option key={index} value={item._id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  className='employeeField'
                  label='Name'
                  labelCol={{ span: 6 }}
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter name!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className='employeeField'
                  label='Surname'
                  labelCol={{ span: 6 }}
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter surname!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='Personal number'
                  labelCol={{ span: 6 }}
                  name='JMBG'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter personal ID!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item className='employeeField' label='Email' labelCol={{ span: 6 }} name='email'>
                  <Input />
                </Form.Item>
                {!selectedCompany?.isHr ||
                  (selectedCompany?.typeOfHr === 'serbian' && (
                    <Form.Item className='employeeField' label='Position' labelCol={{ span: 6 }} name='position'>
                      <Input />
                    </Form.Item>
                  ))}
                {selectedCompany?.isHr && selectedCompany?.typeOfHr === 'bilingual' && (
                  <div>
                    <Form.Item label='Position - Serbian' labelCol={{ span: 6 }} name='position'>
                      <Input />
                    </Form.Item>
                    <Form.Item label='Position - English' labelCol={{ span: 6 }} name='positionEng'>
                      <Input />
                    </Form.Item>
                  </div>
                )}
                <Form.Item className='employeeField' label='Address' name='address' labelCol={{ span: 6 }}>
                  <Input />
                </Form.Item>
                <Form.Item className='employeeField' label='City' labelCol={{ span: 6 }} name='city'>
                  <Input />
                </Form.Item>
                <Form.Item label='Bank account number' labelCol={{ span: 6 }} name='bankAccount'>
                  <Input />
                </Form.Item>
                <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                <Form.Item name='employmentType'>
                  <Radio.Group onChange={(event) => setEmploymentType(event.target.value)}>
                    <Space direction='vertical'>
                      <Radio value='unlimited'>Unlimited employment</Radio>
                      <Radio value='limited'>Limited employment</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label='Start date of work'
                  name='startDate'
                  rules={[
                    {
                      required: true,
                      message: 'Please select start date!',
                    },
                  ]}
                >
                  <DatePicker format={'DD-MM-YYYY'} onChange={handleStartDateSelect} />
                </Form.Item>
                {(employmentType === 'limited' || (data && data.endDate && employmentType === 'limited')) && (
                  <Form.Item
                    label='End date of work'
                    name='endDate'
                    rules={[
                      {
                        required: true,
                        message: 'Please select start date!',
                      },
                    ]}
                  >
                    <DatePicker style={{ marginLeft: '7px' }} format={'DD-MM-YYYY'} onChange={handleEndDateSelect} />
                  </Form.Item>
                )}
                <Form.Item name='workTimeType'>
                  <Radio.Group onChange={(event) => handleInputChange(event)}>
                    <Space direction='vertical'>
                      <Radio value='fullTime'>Full time</Radio>
                      <Radio value='halfTime'>50% of work time</Radio>
                      <Radio value='customTime'>Custom time</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {customTime === 'customTime' && (
                  <Form.Item
                    label='Input work percentage'
                    name='workTimeCustomType'
                    rules={[
                      {
                        required: true,
                        message: 'Please input work percentage!',
                      },
                    ]}
                  >
                    <Input type='number' style={{ width: '16%' }} min='1' max='100' />
                  </Form.Item>
                )}
                {selectedCompany?.isSector && !currentSectors.isLoading && currentSectors?.data?.length && (
                  <>
                    <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                    {currentuser.data.role.includes('hrOfficer') ? (
                      <>
                        <Form.Item label='Sector of the employee' labelCol={{ span: 7 }} labelAlign='left'>
                          <Input value={defaultSectorName} disabled style={{ width: '50%' }} />
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        <Form.Item
                          label='Select the sector for the employee'
                          name='employeeSector'
                          labelCol={{ span: 7 }}
                          labelAlign='left'
                          rules={[
                            {
                              required: true,
                              message: 'Please select the sector for the employee!',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '30%' }}
                          >
                            {currentSectors.data &&
                              currentSectors.data.map((item, index) => (
                                <Option key={index} value={item._id}>
                                  {item.sectorName}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <div style={{ display: 'flex' }}>
                          <Form.Item
                            valuePropName='checked'
                            label='HR Officer role'
                            name='isHrOfficer'
                            labelCol={{ span: 2.5 }}
                            labelAlign='left'
                          >
                            <Checkbox />
                          </Form.Item>
                          <Tooltip
                            title='If company have sectors functionality enabled, here you can set employee as responsible person for chosen sector. NOTE: You cannot set this role for an employee if there is already someone in this sector with that role.'
                            color='#b1b1b1'
                            placement='right'
                            autoAdjustOverflow={true}
                          >
                            <QuestionCircleOutlined
                              style={{ marginLeft: '4px', color: '#b1b1b1', marginBottom: '25px' }}
                            />
                          </Tooltip>
                        </div>
                      </>
                    )}
                  </>
                )}
                <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                {selectedCompany?.holidayDaysTracker && isNew && (
                  <Form.Item label='Type of employee' name='employeeTrackerType' labelCol={{ span: 6 }}>
                    <Radio.Group onChange={employeeType}>
                      <Radio defaultChecked value='new'>
                        New employee
                      </Radio>
                      <Radio value='existing'>Existing employee</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
                {selectedCompany?.holidayDaysTracker && (
                  <>
                    {employeeTrackerType === 'existing' && (
                      <Form.Item
                        label='Holiday days left from past year'
                        labelCol={{ span: 7 }}
                        name='numberOfHolidayDaysPast'
                      >
                        <Input
                          defaultValue={''}
                          style={{ width: '20%' }}
                          onChange={(e) => {
                            const value = e.target.value.trim();
                            if (value === '') {
                              e.target.value = null;
                            }
                            calculateContractDaysChange(e);
                          }}
                          disabled={
                            !(
                              moment(trackerDate).isSameOrBefore(moment(today)) &&
                              moment(chosenStartDate).isSameOrBefore(moment(today)) &&
                              moment(date).isSameOrAfter(moment())
                            ) || disablePastDays
                          }
                        />
                      </Form.Item>
                    )}

                    {employeeTrackerType === 'existing' && (
                      <Form.Item
                        label={
                          form.getFieldValue('endDate')
                            ? 'Holiday days used from current contract'
                            : 'Holiday days used from current year'
                        }
                        labelCol={{ span: 7 }}
                        name='numberOfHolidayDaysUsed'
                      >
                        <Input
                          defaultValue={'0'}
                          disabled={disableCurrentDaysUsed}
                          style={{ width: '20%' }}
                          onChange={(e) => {
                            const value = e.target.value.trim();
                            if (value === '') {
                              e.target.value = null;
                            }

                            calculateContractDaysChange(e);
                          }}
                        />
                      </Form.Item>
                    )}

                    <Form.Item
                      label='Holiday days defined in contract'
                      labelCol={{ span: 7 }}
                      name='numberOfHolidayDaysContract'
                      labelAlign=''
                    >
                      <Input
                        defaultValue={''}
                        style={{ width: '20%' }}
                        onChange={(e) => {
                          const value = e.target.value.trim();
                          if (value === '') {
                            e.target.value = null;
                          }

                          calculateContractDaysChange(e);
                        }}
                        onClick={handleStartDateEmpty}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Total holiday days left up to year end'
                      labelCol={{ span: 7 }}
                      name='numberOfHolidayDays'
                    >
                      <Input type='number' style={{ width: '20%' }} disabled />
                    </Form.Item>

                    {employmentType !== 'unlimited' && isTotalDaysForContractFieldVisible && (
                      <Form.Item
                        label='Total days left by limited contract'
                        labelCol={{ span: 7 }}
                        name='numberOfHolidayDaysByEndOfContract'
                      >
                        <Input type='number' style={{ width: '20%' }} disabled />
                      </Form.Item>
                    )}
                    <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                  </>
                )}
                <Row type='flex' gutter={10}>
                  <Col xs={12} md={7}>
                    <Form.Item
                      label='Meal allowance'
                      labelCol={{ span: 17 }}
                      name='hotMeal'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter meal allowance!',
                        },
                      ]}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={2}>
                    <Input
                      value={
                        selectedCompany && selectedCompany.mealCurrency
                          ? selectedCompany.mealCurrency.toUpperCase()
                          : 'RSD'
                      }
                      style={{ pointerEvents: 'none', textAlign: 'center' }}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                      <TextArea
                        autoSize={{ maxRows: 2 }}
                        value={
                          selectedCompany
                            ? !selectedCompany.calculationMethodMeal.includes('perDay')
                              ? 'per month'
                              : 'per day'
                            : undefined
                        }
                        style={{ pointerEvents: 'none' }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={11}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginLeft: '30px', marginTop: '5px', marginRight: '5px', width: '50%' }}>
                        calculation method
                      </span>
                      <TextArea
                        autoSize={{ maxRows: 2 }}
                        value={selectedCompany && calcMethodNames.calculationMethodMeal}
                        style={{ pointerEvents: 'none' }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row type='flex' gutter={10}>
                  <Col xs={12} md={7}>
                    <Form.Item
                      label='Holiday allowance'
                      labelCol={{ span: 17 }}
                      name='regres'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter holiday allowance!',
                        },
                      ]}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={2}>
                    <Input
                      value={
                        selectedCompany && selectedCompany.holidayCurrency
                          ? selectedCompany.holidayCurrency.toUpperCase()
                          : 'RSD'
                      }
                      style={{ pointerEvents: 'none', textAlign: 'center' }}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                      <TextArea
                        autoSize={{ maxRows: 2 }}
                        value={
                          selectedCompany
                            ? !selectedCompany.calculationMethodHoliday.includes('perDay')
                              ? 'per month'
                              : 'per day'
                            : undefined
                        }
                        style={{ pointerEvents: 'none' }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={11}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginLeft: '30px', marginTop: '5px', marginRight: '5px', width: '50%' }}>
                        calculation method
                      </span>
                      <TextArea
                        autoSize={{ maxRows: 2 }}
                        value={selectedCompany && calcMethodNames.calculationMethodHoliday}
                        style={{ pointerEvents: 'none' }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row type='flex' gutter={10}>
                  <Col xs={12} md={7}>
                    <Form.Item
                      label='Travel expenses'
                      labelCol={{ span: 17 }}
                      name='travelExpenses'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter travel expenses!',
                        },
                      ]}
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={2}>
                    <Input
                      value={
                        selectedCompany && selectedCompany.travelExpCurrency
                          ? selectedCompany.travelExpCurrency.toUpperCase()
                          : 'RSD'
                      }
                      style={{ pointerEvents: 'none', textAlign: 'center' }}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                      <TextArea
                        autoSize={{ maxRows: 2 }}
                        value={
                          selectedCompany
                            ? !selectedCompany.travelExpenses.includes('perDay')
                              ? 'per month'
                              : 'per day'
                            : undefined
                        }
                        style={{ pointerEvents: 'none' }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={11}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginLeft: '30px', marginTop: '5px', marginRight: '5px', width: '50%' }}>
                        calculation method
                      </span>
                      <TextArea
                        autoSize={{ maxRows: 2 }}
                        value={selectedCompany && calcMethodNames.travelExpenses}
                        style={{ pointerEvents: 'none' }}
                      />
                    </div>
                  </Col>
                </Row>
                {selectedCompany?.workFromHomeType === '1' && (
                  <Row type='flex' gutter={10}>
                    <Col xs={12} md={7}>
                      <Form.Item
                        label='Work from home 1'
                        labelCol={{ span: 17 }}
                        name='workFromHome'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter work from home value!',
                          },
                        ]}
                      >
                        <Input type='number' />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={2}>
                      <Input
                        value={
                          selectedCompany && selectedCompany.workFromHomeCurrency
                            ? selectedCompany.workFromHomeCurrency.toUpperCase()
                            : 'RSD'
                        }
                        style={{ pointerEvents: 'none', textAlign: 'center' }}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                        <TextArea
                          autoSize={{ maxRows: 2 }}
                          value={
                            selectedCompany
                              ? !selectedCompany.calculationMethodWorkFromHome.includes('perDay')
                                ? 'per month'
                                : 'per day'
                              : undefined
                          }
                          style={{ pointerEvents: 'none' }}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={11}>
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginLeft: '30px', marginTop: '5px', marginRight: '5px', width: '50%' }}>
                          calculation method
                        </span>
                        <TextArea
                          autoSize={{ maxRows: 2 }}
                          value={selectedCompany && calcMethodNames.calculationMethodWorkFromHome}
                          style={{ pointerEvents: 'none' }}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {selectedCompany?.workFromHomeType === '2' && (
                  <Row type='flex' gutter={10}>
                    <Col xs={12} md={7}>
                      <Form.Item
                        label='Work from home 2'
                        labelCol={{ span: 17 }}
                        name='workFromHome'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter work from home value!',
                          },
                        ]}
                      >
                        <Input type='number' />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={2}>
                      <Input
                        value={
                          selectedCompany && selectedCompany.workFromHomeCurrency
                            ? selectedCompany.workFromHomeCurrency.toUpperCase()
                            : 'RSD'
                        }
                        style={{ pointerEvents: 'none', textAlign: 'center' }}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                        <TextArea
                          autoSize={{ maxRows: 2 }}
                          value={
                            selectedCompany
                              ? !selectedCompany.calculationMethodWorkFromHome.includes('perDay')
                                ? 'per month'
                                : 'per day'
                              : undefined
                          }
                          style={{ pointerEvents: 'none' }}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={11}>
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginLeft: '30px', marginTop: '5px', marginRight: '5px', width: '50%' }}>
                          calculation method
                        </span>
                        <TextArea
                          autoSize={{ maxRows: 2 }}
                          value={selectedCompany && calcMethodNames.calculationMethodWorkFromHome}
                          style={{ pointerEvents: 'none' }}
                        />
                      </div>
                    </Col>
                  </Row>
                )}{' '}
                {selectedCompany?.otherExpenses === true && selectedCompany?.otherExpensesCurrency && (
                  <>
                    <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                    <Row type='flex' gutter={10}>
                      <Col xs={12} md={7}>
                        <Form.Item
                          label='Other expenses'
                          labelCol={{ span: 17 }}
                          name='otherExpensesValue'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter other expenses!',
                            },
                          ]}
                        >
                          <Input type='number' />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={2}>
                        <Input
                          value={
                            selectedCompany && selectedCompany.otherExpensesCurrency
                              ? selectedCompany.otherExpensesCurrency.toUpperCase()
                              : 'RSD'
                          }
                          style={{ pointerEvents: 'none', textAlign: 'center' }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {selectedCompany?.suspension === true && selectedCompany?.suspensionCurrency && (
                  <Row type='flex' gutter={10}>
                    <Col xs={12} md={7}>
                      <Form.Item
                        label='Suspension'
                        labelCol={{ span: 17 }}
                        name='suspensionValue'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter suspension value!',
                          },
                        ]}
                      >
                        <Input type='number' />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={2}>
                      <Input
                        value={
                          selectedCompany && selectedCompany.suspensionCurrency
                            ? selectedCompany.suspensionCurrency.toUpperCase()
                            : 'RSD'
                        }
                        style={{ pointerEvents: 'none', textAlign: 'center' }}
                      />
                    </Col>
                  </Row>
                )}
                {selectedCompany?.otherIncome === true && selectedCompany?.otherIncomeCurrency && (
                  <Row type='flex' gutter={10}>
                    <Col xs={12} md={7}>
                      <Form.Item
                        label='Other income'
                        labelCol={{ span: 17 }}
                        name='otherIncomeValue'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter suspension value!',
                          },
                        ]}
                      >
                        <Input type='number' />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={2}>
                      <Input
                        value={
                          selectedCompany && selectedCompany.otherIncomeCurrency
                            ? selectedCompany.otherIncomeCurrency.toUpperCase()
                            : 'RSD'
                        }
                        style={{ pointerEvents: 'none', textAlign: 'center' }}
                      />
                    </Col>
                  </Row>
                )}
                {selectedCompany?.bonus === true && selectedCompany?.bonusCurrency && (
                  <Row type='flex' gutter={10}>
                    <Col xs={12} md={7}>
                      <Form.Item
                        label='Bonus'
                        labelCol={{ span: 17 }}
                        name='bonusValue'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter bonus value!',
                          },
                        ]}
                      >
                        <Input type='number' />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={2}>
                      <Input
                        value={
                          selectedCompany && selectedCompany.bonusCurrency
                            ? selectedCompany.bonusCurrency.toUpperCase()
                            : 'RSD'
                        }
                        style={{ pointerEvents: 'none', textAlign: 'center' }}
                      />
                    </Col>
                  </Row>
                )}
                <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                {selectedCompany?.foreignWorkPermitTracker && (
                  <div style={{ display: 'flex' }}>
                    <Form.Item label='Work permit expiry date (for foreigners)' name='dateOfWorkPermissionForeigner'>
                      <DatePicker format={'DD-MM-YYYY'} />
                    </Form.Item>
                    <Tooltip
                      title='Here, you can set the expiration date for a foreign employee work permit. If you specify this date, the application will send reminder emails three and one month(s) before this date.'
                      color='#b1b1b1'
                      placement='right'
                      autoAdjustOverflow={true}
                    >
                      <QuestionCircleOutlined style={{ marginLeft: '3px', color: '#b1b1b1', marginBottom: '20px' }} />
                    </Tooltip>
                  </div>
                )}
                <div>
                  <div style={{ display: 'flex' }}>
                    <Form.Item
                      name='fileOption'
                      label='Attach file'
                      className='attach employeeAttach'
                      style={{ marginTop: '20px' }}
                    >
                      <Select
                        onChange={(value) => setSelectedType(value)}
                        style={{ width: '200px', marginLeft: '20px' }}
                        placeholder='Select type of file'
                        onSelect={() => {
                          document.getElementById('uploadButton').click();
                        }}
                      >
                        <Option value='labour agreement'>Labour agreement</Option>
                        <Option value='personal ID'>Personal ID</Option>
                        <Option value='diploma'>Last available diploma</Option>
                        <Option value='annex'>Annex</Option>
                        <Option value='other'>Other</Option>
                      </Select>
                    </Form.Item>
                    <Tooltip
                      title='Please make sure that name, surname and personal ID are filled before upload. NOTE: You cannot upload file larger than 5 MB.'
                      color='#b1b1b1'
                      placement='right'
                      autoAdjustOverflow={true}
                    >
                      <QuestionCircleOutlined style={{ marginLeft: '3px', color: '#b1b1b1' }} />
                    </Tooltip>
                  </div>
                  <Upload
                    fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
                    listType='picture'
                    showUploadList={showUploadList}
                    action={`${SERVER_URL}/upload-s3?employee=${JSON.stringify({
                      employeeID: form.getFieldValue('JMBG'),
                      employeeName: form.getFieldValue('firstName') + '_' + form.getFieldValue('lastName'),
                    })}`}
                    name='file'
                    headers={{
                      Authorization: `Bearer ${currentuser.data.token}`,
                      uri: 'static/pdf/',
                      timestamp: Date.now().toString(),
                    }}
                    onChange={(info) => {
                      if (!info.file.status) {
                        setIsError(true);
                      }
                      if (info.file.status === 'done') {
                        setIsError(false);
                        setSelectedTypes([...selectedTypes, selectedType]);
                        setFiles([...files, { location: info.file.response.key, originalName: info.file.name }]);
                        for (const file of info.fileList) {
                          let nameArray = file.name.split('.');
                          switch (nameArray[nameArray.length - 1]) {
                            case 'pdf':
                              file.thumbUrl = '/pdf.png';
                              break;
                            case 'docx':
                              file.thumbUrl = '/docx.png';
                              break;
                            case 'png':
                              file.thumbUrl = '/png.png';
                              break;
                            case 'jpg':
                              file.thumbUrl = '/jpg.png';
                              break;
                            case 'xlsx':
                              file.thumbUrl = '/xlsx.png';
                              break;

                            default:
                              file.thumbUrl = '/other.png';
                              break;
                          }
                        }
                        notification.success({
                          message: 'Upload successfull',
                          placement: 'bottomRight',
                        });
                      } else if (info.file.status === 'error') {
                        notification.error({
                          message: 'Upload failed',
                          placement: 'bottomRight',
                        });
                      }
                      setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
                    }}
                    beforeUpload={(file) => {
                      setIsError(false);
                      if (!selectedType) {
                        notification.error({
                          message: 'Select from above dropdown!',
                          placement: 'bottomRight',
                        });
                        return false;
                      }
                      if (
                        !form.getFieldValue('firstName') &&
                        !form.getFieldValue('lastName') &&
                        !form.getFieldValue('JMBG')
                      ) {
                        notification.error({
                          message: 'Name, surname and personal are required.',
                          placement: 'bottomRight',
                        });
                        return false;
                      }
                      if (file.size / 1000000 > 5) {
                        notification.error({
                          message: 'File is larger than 5 MB!',
                          placement: 'bottomRight',
                        });
                        return false;
                      }
                      return true;
                    }}
                    onRemove={async (file) => {
                      notification.info({
                        message: 'Removing file in progress.',
                        placement: 'bottomRight',
                      });
                      const urlParts = file?.name;
                      const response = await Axios.post(`${SERVER_URL}/remove-file/${urlParts}`, {
                        withCredentials: false,
                      });
                    }}
                    onDownload={async (file) => {
                      notification.info({
                        message: 'Downloading in progress.',
                        placement: 'bottomRight',
                      });
                      const urlParts = file?.name;
                      const response = await Axios.get(`${SERVER_URL}/download-file/${urlParts}`, {
                        withCredentials: false,
                      });
                      FileSaver.saveAs(response.config.url, file?.name);
                    }}
                  >
                    <Button style={{ display: 'none' }} id='uploadButton' icon={<UploadOutlined />}>
                      Upload
                    </Button>
                  </Upload>
                </div>
                <Form.Item label='Other remark' name='otherRemark' style={{ marginTop: '15px' }}>
                  <TextArea rows={4} />
                </Form.Item>
                <div className='text-right'>
                  <Button type='primary' htmlType='submit' disabled={submitDisabled}>
                    Submit
                  </Button>
                </div>
              </Form>
            ) : (
              <Tabs defaultActiveKey={location === '?hr' ? '2' : '1'}>
                <TabPane tab='General data' key='1'>
                  <Form
                    className='form-horizontal employeeForm'
                    initialValues={holidayPastDisabled ? formInitialValues : form.getFieldsValue()}
                    onFinish={(values) => onFinish(values, !data)}
                    layout='horizontal'
                    form={form}
                    labelAlign='left'
                  >
                    {((currentuser.data && canUserTransferEmployee(currentuser.data.role)) || (companyAndBranches 
                        && companyAndBranches.length >= 1))
                    && (
                      <Form.Item
                        className='employeeField'
                        label='Company'
                        name='company'
                        labelAlign='left'
                        labelCol={{ span: 6 }}
                        rules={[
                          {
                            required: true,
                            message: 'Please choose company!',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => selectCompany(value)}
                          disabled={companyAndBranches && companyAndBranches.length <= 1 ? true : false}
                        >
                          {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) &&
                            company &&
                            company.items &&
                            company.items.map((item, index) => (
                              <Option key={index} value={item._id}>
                                {item.name}
                              </Option>
                            ))}
                          {(currentuser.data.role.includes('user') 
                              || currentuser.data.role.includes('userOfficer')
                            ) 
                            && companyAndBranches 
                            && companyAndBranches.length !== 0 
                            && companyAndBranches.map((item, index) => (
                                <Option key={index} value={item._id}>
                                  {item.name}
                                </Option>
                              ))}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item
                      className='employeeField'
                      label='Name'
                      labelCol={{ span: 6 }}
                      name='firstName'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter name!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      className='employeeField'
                      label='Surname'
                      labelCol={{ span: 6 }}
                      name='lastName'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter surname!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label='Personal number'
                      labelCol={{ span: 6 }}
                      name='JMBG'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter personal ID!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item className='employeeField' labelCol={{ span: 6 }} label='Email' name='email'>
                      <Input />
                    </Form.Item>
                    {(!selectedCompany?.isHr || selectedCompany?.typeOfHr === 'serbian') && (
                      <Form.Item className='employeeField' labelCol={{ span: 6 }} label='Position' name='position'>
                        <Input />
                      </Form.Item>
                    )}
                    {selectedCompany?.isHr && selectedCompany?.typeOfHr === 'bilingual' && (
                      <div>
                        <Form.Item
                          className='employeeField'
                          label='Position - Serbian'
                          labelCol={{ span: 6 }}
                          name='position'
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          className='employeeField'
                          label='Position - English'
                          labelCol={{ span: 6 }}
                          name='positionEng'
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    )}
                    <Form.Item className='employeeField' label='Address' labelCol={{ span: 6 }} name='address'>
                      <Input />
                    </Form.Item>
                    <Form.Item className='employeeField' label='City' labelCol={{ span: 6 }} name='city'>
                      <Input />
                    </Form.Item>

                    <Form.Item label='Bank account number' labelCol={{ span: 6 }} name='bankAccount'>
                      <Input />
                    </Form.Item>

                    {selectedCompany?.isHr &&
                      (currentuser.data.role.includes('admin') ||
                        currentuser.data.role.includes('SuperAdmin') ||
                        currentuser.data.role.includes('user') ||
                        currentuser.data.role.includes('userOfficer') ||
                        currentuser.data.role.includes('hrOfficer')) &&
                      !isNew && (
                        <>
                          {!passwordChangeVisible && (
                            <div
                              onClick={() => setPasswordChangeVisible(true)}
                              style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                            >
                              Change password for employee
                            </div>
                          )}
                          {passwordChangeVisible && (
                            <div>
                              <Form.Item label='New password' name='newPassword' labelCol={{ span: 6 }}>
                                <Input.Password placeholder='New password' />
                              </Form.Item>
                              <Form.Item label='Confirm password' name='confirmPassword' labelCol={{ span: 6 }}>
                                <Input.Password placeholder='Confirm password' />
                              </Form.Item>
                              <div style={{ color: '#696c74' }}>
                                *** You cannot change email and password at the same time!
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />

                    <Form.Item name='employmentType'>
                      <Radio.Group
                        onChange={(event) => setEmploymentType(event.target.value)}
                        disabled={
                          !currentuser.data.role.includes('SuperAdmin') && !currentuser.data.role.includes('admin')
                        }
                      >
                        <Space direction='vertical'>
                          <Radio value='unlimited'>Unlimited employment</Radio>
                          <Radio value='limited'>Limited employment</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>

                    <div style={{ display: 'flex' }}>
                      <Form.Item
                        label='Start date of work'
                        name='startDate'
                        rules={[
                          {
                            required: true,
                            message: 'Please select start date!',
                          },
                        ]}
                      >
                        <DatePicker
                          format={'DD-MM-YYYY'}
                          disabled={
                            !currentuser.data.role.includes('SuperAdmin') && !currentuser.data.role.includes('admin')
                          }
                        />
                      </Form.Item>
                      {(!currentuser.data.role.includes('SuperAdmin') || !currentuser.data.role.includes('admin')) && (
                        <Tooltip
                          title='In order to extend or terminate employment, please check the buttons TERMINATE and EXTEND which are presented on top of screen in sections EMPLOYEES.'
                          color='#b1b1b1'
                          placement='right'
                          autoAdjustOverflow={true}
                        >
                          <QuestionCircleOutlined
                            style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '20px' }}
                          />
                        </Tooltip>
                      )}
                    </div>
                    {(employmentType === 'limited' || (data && data.endDate && employmentType === 'limited')) && (
                      <Form.Item
                        label='End date of work'
                        name='endDate'
                        rules={[
                          {
                            required: true,
                            message: 'Please select end date!',
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ marginLeft: '7px' }}
                          format={'DD-MM-YYYY'}
                          disabled={
                            !currentuser.data.role.includes('SuperAdmin') && !currentuser.data.role.includes('admin')
                          }
                        />
                      </Form.Item>
                    )}

                    <Form.Item name='workTimeType'>
                      <Radio.Group
                        onChange={(event) => handleInputChange(event)}
                        disabled={
                          !currentuser.data.role.includes('SuperAdmin') && !currentuser.data.role.includes('admin')
                        }
                      >
                        <Space direction='vertical'>
                          <Radio value='fullTime'>Full time</Radio>
                          <Radio value='halfTime'>50% of work time</Radio>
                          <Radio value='customTime'>Custom time</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                    {customTime === 'customTime' && (
                      <Form.Item
                        label='Input work percentage'
                        name='workTimeCustomType'
                        rules={[
                          {
                            required: true,
                            message: 'Please input work percentage!',
                          },
                        ]}
                      >
                        <Input
                          type='number'
                          style={{ width: '16%' }}
                          min='1'
                          max='100'
                          disabled={
                            !currentuser.data.role.includes('SuperAdmin') && !currentuser.data.role.includes('admin')
                          }
                        />
                      </Form.Item>
                    )}
                    {selectedCompany?.isSector && !currentSectors.isLoading && currentSectors?.data?.length && (
                      <>
                        <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                        <Form.Item
                          label='Select the sector for the employee'
                          name='employeeSector'
                          rules={[
                            {
                              required: true,
                              message: 'Please select the sector for the employee!',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '30%', marginLeft: '10px' }}
                          >
                            {currentSectors.data &&
                              currentSectors.data.map((item, index) => (
                                <Option key={index} value={item._id}>
                                  {item.sectorName}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <div style={{ display: 'flex' }}>
                          <Form.Item
                            valuePropName='checked'
                            label='HR Officer role'
                            name='isHrOfficer'
                            labelCol={{ span: 2.5 }}
                            labelAlign='left'
                          >
                            <Checkbox />
                          </Form.Item>
                          <Tooltip
                            title='If company have sectors functionality enabled, here you can set employee as responsible person for chosen sector. NOTE: You cannot set this role for an employee if there is already someone in this sector with that role.'
                            color='#b1b1b1'
                            placement='right'
                            autoAdjustOverflow={true}
                          >
                            <QuestionCircleOutlined
                              style={{ marginLeft: '4px', color: '#b1b1b1', marginBottom: '25px' }}
                            />
                          </Tooltip>
                        </div>
                      </>
                    )}

                    <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />

                    {selectedCompany?.holidayDaysTracker && isNew && (
                      <Form.Item
                        label='Type of employee'
                        name='employeeTrackerType'
                        labelCol={{ span: 6 }}
                        labelAlign='left'
                      >
                        <Radio.Group>
                          <Radio defaultChecked value='new'>
                            New employee
                          </Radio>
                          <Radio value='existing'>Existing employee</Radio>
                        </Radio.Group>
                      </Form.Item>
                    )}
                    {selectedCompany?.holidayDaysTracker && (
                      <div>
                        <Form.Item label='Holiday days left from past year'>
                          <Space>
                            <Form.Item
                              style={{ marginBottom: '5px', maxWidth: '300px', marginLeft: '75px' }}
                              name='numberOfHolidayDaysPast'
                              defaultValue={form.getFieldValue('numberOfHolidayDaysPast')}
                            >
                              <Input
                                type='number'
                                style={{ width: '160px' }}
                                onChange={(e) => calculateContractDaysChange(e)}
                                disabled={
                                  holidayPastDisabled ||
                                  !(
                                    moment(trackerDate).isSameOrBefore(moment(today)) &&
                                    moment(chosenStartDate).isSameOrBefore(moment(today)) &&
                                    moment(date).isSameOrAfter(moment())
                                  ) ||
                                  disablePastDays
                                }
                              />
                            </Form.Item>
                            <Tooltip title={'Edit holiday fields'}>
                              <EditOutlined style={{ marginLeft: '8px' }} onClick={toggleDisable}>
                                Edit number of days of holiday
                              </EditOutlined>
                            </Tooltip>
                          </Space>
                        </Form.Item>

                        <Form.Item
                          label={
                            form.getFieldValue('endDate')
                              ? 'Holiday days used from current contract'
                              : 'Holiday days used from current year'
                          }
                        >
                          <Form.Item
                            style={{ marginBottom: '5px', maxWidth: '300px', marginLeft: '12px' }}
                            name='numberOfHolidayDaysUsed'
                          >
                            <Input
                              type='number'
                              style={{ width: '160px', marginLeft: form.getFieldValue('endDate') ? '0px' : '30px' }}
                              onChange={(e) => calculateContractDaysChange(e)}
                              disabled={holidayPastDisabled || disableCurrentDaysUsed}
                            />
                          </Form.Item>
                        </Form.Item>

                        <Form.Item label='Holiday days defined in contract'>
                          <Space>
                            <Form.Item
                              style={{ marginBottom: '5px', maxWidth: '300px', marginLeft: '70px' }}
                              name='numberOfHolidayDaysContract'
                            >
                              <Input
                                type='number'
                                style={{ width: '160px' }}
                                onChange={(e) => calculateContractDaysChange(e)}
                                disabled={holidayPastDisabled}
                              />
                            </Form.Item>
                          </Space>
                        </Form.Item>

                        <Form.Item label='Total holiday days left up to year end'>
                          <Space>
                            <Form.Item
                              style={{ marginBottom: '5px', maxWidth: '300px', marginLeft: '40px' }}
                              name='numberOfHolidayDays'
                            >
                              <Input type='number' style={{ width: '160px' }} disabled />
                            </Form.Item>
                          </Space>
                        </Form.Item>

                        {employmentType !== 'unlimited' &&
                          new Date(form.getFieldValue('endDate')).getFullYear() === currentYear && (
                            <>
                              <Form.Item label='Total days left by limited contract'>
                                <Space>
                                  <Form.Item
                                    style={{ marginBottom: '5px', maxWidth: '300px', marginLeft: '65px' }}
                                    name='numberOfHolidayDaysByEndOfContract'
                                  >
                                    <Input type='number' style={{ width: '160px' }} disabled />
                                  </Form.Item>
                                </Space>
                              </Form.Item>
                            </>
                          )}
                        <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                      </div>
                    )}

                    <Row type='flex' gutter={10}>
                      <Col xs={12} md={7}>
                        <Form.Item
                          label='Meal allowance'
                          labelCol={{ span: 17 }}
                          name='hotMeal'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter meal allowance!',
                            },
                          ]}
                        >
                          <Input type='number' />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={2}>
                        <Input
                          value={
                            selectedCompany && selectedCompany.mealCurrency
                              ? selectedCompany.mealCurrency.toUpperCase()
                              : 'RSD'
                          }
                          style={{ pointerEvents: 'none', textAlign: 'center' }}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                          <TextArea
                            autoSize={{ maxRows: 2 }}
                            value={
                              selectedCompany
                                ? !selectedCompany.calculationMethodMeal.includes('perDay')
                                  ? 'per month'
                                  : 'per day'
                                : undefined
                            }
                            style={{ pointerEvents: 'none' }}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={11}>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginRight: '5px', marginLeft: '30px', width: '35%' }}>
                            calculation method
                          </span>
                          <TextArea
                            autoSize={{ maxRows: 2 }}
                            value={selectedCompany && calcMethodNames.calculationMethodMeal}
                            style={{ pointerEvents: 'none' }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row type='flex' gutter={10}>
                      <Col xs={12} md={7}>
                        <Form.Item
                          label='Holiday allowance'
                          labelCol={{ span: 17 }}
                          name='regres'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter holiday allowance!',
                            },
                          ]}
                        >
                          <Input type='number' />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={2}>
                        <Input
                          value={
                            selectedCompany && selectedCompany.holidayCurrency
                              ? selectedCompany.holidayCurrency.toUpperCase()
                              : 'RSD'
                          }
                          style={{ pointerEvents: 'none', textAlign: 'center' }}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                          <TextArea
                            autoSize={{ maxRows: 2 }}
                            value={
                              selectedCompany
                                ? !selectedCompany.calculationMethodHoliday.includes('perDay')
                                  ? 'per month'
                                  : 'per day'
                                : undefined
                            }
                            style={{ pointerEvents: 'none' }}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={11}>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginRight: '5px', marginLeft: '30px', width: '35%' }}>
                            calculation method
                          </span>
                          <TextArea
                            autoSize={{ maxRows: 2 }}
                            value={selectedCompany && calcMethodNames.calculationMethodHoliday}
                            style={{ pointerEvents: 'none' }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row type='flex' gutter={10}>
                      <Col xs={12} md={7}>
                        <Form.Item
                          label='Travel expenses'
                          labelCol={{ span: 17 }}
                          name='travelExpenses'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter travel expenses!',
                            },
                          ]}
                        >
                          <Input type='number' />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={2}>
                        <Input
                          value={
                            selectedCompany && selectedCompany.travelExpCurrency
                              ? selectedCompany.travelExpCurrency.toUpperCase()
                              : 'RSD'
                          }
                          style={{ pointerEvents: 'none', textAlign: 'center' }}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                          <TextArea
                            autoSize={{ maxRows: 2 }}
                            value={
                              selectedCompany
                                ? !selectedCompany.travelExpenses.includes('perDay')
                                  ? 'per month'
                                  : 'per day'
                                : undefined
                            }
                            style={{ pointerEvents: 'none' }}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={11}>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginRight: '5px', marginLeft: '30px', width: '35%' }}>
                            calculation method
                          </span>
                          <TextArea
                            autoSize={{ maxRows: 2 }}
                            value={selectedCompany && calcMethodNames.travelExpenses}
                            style={{ pointerEvents: 'none' }}
                          />
                        </div>
                      </Col>
                    </Row>
                    {selectedCompany?.workFromHomeType === '1' && (
                      <Row type='flex' gutter={10}>
                        <Col xs={12} md={7}>
                          <Form.Item
                            label='Work from home 1'
                            labelCol={{ span: 17 }}
                            name='workFromHome'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter work from home value!',
                              },
                            ]}
                          >
                            <Input type='number' />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={2}>
                          <Input
                            value={
                              selectedCompany && selectedCompany.workFromHomeCurrency
                                ? selectedCompany.workFromHomeCurrency.toUpperCase()
                                : 'RSD'
                            }
                            style={{ pointerEvents: 'none', textAlign: 'center' }}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <div style={{ display: 'flex' }}>
                            <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                            <TextArea
                              autoSize={{ maxRows: 2 }}
                              value={
                                selectedCompany
                                  ? !selectedCompany.calculationMethodWorkFromHome.includes('perDay')
                                    ? 'per month'
                                    : 'per day'
                                  : undefined
                              }
                              style={{ pointerEvents: 'none' }}
                            />
                          </div>
                        </Col>
                        <Col xs={12} md={11}>
                          <div style={{ display: 'flex' }}>
                            <span style={{ marginLeft: '30px', marginTop: '5px', marginRight: '5px', width: '35%' }}>
                              calculation method
                            </span>
                            <TextArea
                              autoSize={{ maxRows: 2 }}
                              value={selectedCompany && calcMethodNames.calculationMethodWorkFromHome}
                              style={{ pointerEvents: 'none' }}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    {selectedCompany?.workFromHomeType === '2' && (
                      <Row type='flex' gutter={10}>
                        <Col xs={12} md={7}>
                          <Form.Item
                            label='Work from home 2'
                            labelCol={{ span: 17 }}
                            name='workFromHome'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter work from home value!',
                              },
                            ]}
                          >
                            <Input type='number' />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={2}>
                          <Input
                            value={
                              selectedCompany && selectedCompany.workFromHomeCurrency
                                ? selectedCompany.workFromHomeCurrency.toUpperCase()
                                : 'RSD'
                            }
                            style={{ pointerEvents: 'none', textAlign: 'center' }}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <div style={{ display: 'flex' }}>
                            <span style={{ marginTop: '5px', marginRight: '5px' }}>unit</span>
                            <TextArea
                              autoSize={{ maxRows: 2 }}
                              value={
                                selectedCompany
                                  ? !selectedCompany.calculationMethodWorkFromHome.includes('perDay')
                                    ? 'per month'
                                    : 'per day'
                                  : undefined
                              }
                              style={{ pointerEvents: 'none' }}
                            />
                          </div>
                        </Col>
                        <Col xs={12} md={11}>
                          <div style={{ display: 'flex' }}>
                            <span style={{ marginLeft: '30px', marginTop: '5px', marginRight: '5px', width: '35%' }}>
                              calculation method
                            </span>
                            <TextArea
                              autoSize={{ maxRows: 2 }}
                              value={selectedCompany && calcMethodNames.calculationMethodWorkFromHome}
                              style={{ pointerEvents: 'none' }}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                    {selectedCompany?.otherExpenses === true && selectedCompany?.otherExpensesCurrency && (
                      <Row type='flex' gutter={10}>
                        <Col xs={12} md={7}>
                          <Form.Item
                            label='Other expenses'
                            labelCol={{ span: 17 }}
                            name='otherExpensesValue'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter other expenses!',
                              },
                            ]}
                          >
                            <Input type='number' />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={2}>
                          <Input
                            value={
                              selectedCompany && selectedCompany.otherExpensesCurrency
                                ? selectedCompany.otherExpensesCurrency.toUpperCase()
                                : 'RSD'
                            }
                            style={{ pointerEvents: 'none', textAlign: 'center' }}
                          />
                        </Col>
                      </Row>
                    )}
                    {selectedCompany?.suspension === true && selectedCompany?.suspensionCurrency && (
                      <Row type='flex' gutter={10}>
                        <Col xs={12} md={7}>
                          <Form.Item
                            label='Suspension'
                            labelCol={{ span: 17 }}
                            name='suspensionValue'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter suspension value!',
                              },
                            ]}
                          >
                            <Input type='number' />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={2}>
                          <Input
                            value={
                              selectedCompany && selectedCompany.suspensionCurrency
                                ? selectedCompany.suspensionCurrency.toUpperCase()
                                : 'RSD'
                            }
                            style={{ pointerEvents: 'none', textAlign: 'center' }}
                          />
                        </Col>
                      </Row>
                    )}
                    {selectedCompany?.otherIncome === true && selectedCompany?.otherIncomeCurrency && (
                      <Row type='flex' gutter={10}>
                        <Col xs={12} md={7}>
                          <Form.Item
                            label='Other income'
                            labelCol={{ span: 17 }}
                            name='otherIncomeValue'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter suspension value!',
                              },
                            ]}
                          >
                            <Input type='number' />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={2}>
                          <Input
                            value={
                              selectedCompany && selectedCompany.otherIncomeCurrency
                                ? selectedCompany.otherIncomeCurrency.toUpperCase()
                                : 'RSD'
                            }
                            style={{ pointerEvents: 'none', textAlign: 'center' }}
                          />
                        </Col>
                      </Row>
                    )}
                    {selectedCompany?.bonus === true && selectedCompany?.bonusCurrency && (
                      <Row type='flex' gutter={10}>
                        <Col xs={12} md={7}>
                          <Form.Item
                            label='Bonus'
                            labelCol={{ span: 17 }}
                            name='bonusValue'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter bonus value!',
                              },
                            ]}
                          >
                            <Input type='number' />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={2}>
                          <Input
                            value={
                              selectedCompany && selectedCompany.bonusCurrency
                                ? selectedCompany.bonusCurrency.toUpperCase()
                                : 'RSD'
                            }
                            style={{ pointerEvents: 'none', textAlign: 'center' }}
                          />
                        </Col>
                      </Row>
                    )}
                    <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                    <div>
                      {selectedCompany?.foreignWorkPermitTracker && (
                        <div style={{ display: 'flex' }}>
                          <Form.Item
                            label='Work permit expiry date (for foreigners)'
                            name='dateOfWorkPermissionForeigner'
                          >
                            <DatePicker format={'DD-MM-YYYY'} />
                          </Form.Item>
                          <Tooltip
                            title='Here, you can set the expiration date for a foreign employee work permit. If you specify this date, the application will send reminder emails three and one month(s) before this date.'
                            color='#b1b1b1'
                            placement='right'
                            autoAdjustOverflow={true}
                          >
                            <QuestionCircleOutlined
                              style={{ marginLeft: '3px', color: '#b1b1b1', marginBottom: '20px' }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      <div style={{ display: 'flex' }}>
                        <Form.Item
                          name='fileOption'
                          label='Attach file'
                          labelAlign='left'
                          className='attach employeeAttach'
                          style={{ marginTop: '20px' }}
                        >
                          <Select
                            onChange={(value) => setSelectedType(value)}
                            style={{ width: '200px', marginLeft: '20px' }}
                            placeholder='Select type of file'
                            onSelect={() => {
                              document.getElementById('uploadButton').click();
                            }}
                          >
                            <Option value='labour agreement'>Labour agreement</Option>
                            <Option value='personal ID'>Personal ID</Option>
                            <Option value='diploma'>Last available diploma</Option>
                            <Option value='annex'>Annex</Option>
                            <Option value='other'>Other</Option>
                          </Select>
                        </Form.Item>
                        <Tooltip
                          title='Please make sure that name, surname and personal ID are filled before upload. NOTE: You cannot upload file larger than 5 MB.'
                          color='#b1b1b1'
                          placement='right'
                          autoAdjustOverflow={true}
                        >
                          <QuestionCircleOutlined style={{ marginLeft: '3px', color: '#b1b1b1' }} />
                        </Tooltip>
                      </div>
                      <Upload
                        fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
                        listType='picture'
                        showUploadList={showUploadList}
                        action={`${SERVER_URL}/upload-s3?employee=${JSON.stringify({
                          employeeID: form.getFieldValue('JMBG'),
                          employeeName: form.getFieldValue('firstName') + '_' + form.getFieldValue('lastName'),
                        })}`}
                        name='file'
                        headers={{
                          Authorization: `Bearer ${currentuser.data.token}`,
                          uri: 'static/pdf/',
                          timestamp: Date.now().toString(),
                        }}
                        onChange={(info) => {
                          if (!info.file.status) {
                            setIsError(true);
                          }
                          if (info.file.status === 'done') {
                            setIsError(false);
                            setSelectedTypes([...selectedTypes, selectedType]);
                            setFiles([...files, { location: info.file.response.key, originalName: info.file.name }]);
                            for (const file of info.fileList) {
                              let nameArray = file.name.split('.');
                              switch (nameArray[nameArray.length - 1]) {
                                case 'pdf':
                                  file.thumbUrl = '/pdf.png';
                                  break;
                                case 'docx':
                                  file.thumbUrl = '/docx.png';
                                  break;
                                case 'png':
                                  file.thumbUrl = '/png.png';
                                  break;
                                case 'jpg':
                                  file.thumbUrl = '/jpg.png';
                                  break;
                                case 'xlsx':
                                  file.thumbUrl = '/xlsx.png';
                                  break;

                                default:
                                  file.thumbUrl = '/other.png';
                                  break;
                              }
                            }
                            notification.success({
                              message: 'Upload successfull',
                              placement: 'bottomRight',
                            });
                          } else if (info.file.status === 'error') {
                            notification.error({
                              message: 'Upload failed',
                              placement: 'bottomRight',
                            });
                          }
                          setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
                        }}
                        beforeUpload={(file) => {
                          setIsError(false);
                          if (!selectedType) {
                            notification.error({
                              message: `Select from above dropdown!`,
                              placement: 'bottomRight',
                            });
                            return false;
                          }
                          if (
                            !form.getFieldValue('firstName') &&
                            !form.getFieldValue('lastName') &&
                            !form.getFieldValue('JMBG')
                          ) {
                            notification.error({
                              message: 'Name, surname and personal are required.',
                              placement: 'bottomRight',
                            });
                            return false;
                          }
                          if (file.size / 1000000 > 5) {
                            notification.error({
                              message: 'File is larger than 5 MB!',
                              placement: 'bottomRight',
                            });
                            return false;
                          }
                          return true;
                        }}
                        onRemove={async (file) => {
                          notification.info({
                            message: 'Removing file in progress.',
                            placement: 'bottomRight',
                          });
                          let newFileList = [...fileList];
                          let newFiles = [...files];
                          let foundFile;
                          for (let i = 0; i < fileList.length; i++) {
                            if (fileList[i].name === file.name) {
                              foundFile = fileList[i];
                              newFileList.splice(i, 1);
                              if (data && data.attachments && data.attachments.length !== 0) {
                                data.attachments.splice(i, 1);
                                await Axios.put(
                                  `${SERVER_URL}/data/${data._id}`,
                                  { attachments: data.attachments },
                                  {
                                    withCredentials: false,
                                    headers: { Authorization: `Bearer ${currentuser.data.token}` },
                                  },
                                );
                              }
                            }
                          }
                          for (let i = 0; i < files.length; i++) {
                            if (files[i].originalName === file.name) {
                              newFiles.splice(i, 1);
                            }
                          }
                          let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
                          if (linkParts && linkParts.length !== 0) {
                            await Axios.post(`${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`, {
                              withCredentials: false,
                            });
                          }
                          setFileList(newFileList);
                          setFiles(newFiles);
                        }}
                        onDownload={async (file) => {
                          notification.info({
                            message: 'Downloading in progress.',
                            placement: 'bottomRight',
                          });
                          const urlParts = file.response.key.split('/');
                          const response = await Axios.get(`${SERVER_URL}/file/${urlParts[0]}/${urlParts[1]}`, {
                            withCredentials: false,
                          });
                          FileSaver.saveAs(response.config.url, file.name);
                        }}
                      >
                        <Button style={{ display: 'none' }} id='uploadButton' icon={<UploadOutlined />}>
                          Upload
                        </Button>
                      </Upload>
                    </div>
                    <Form.Item label='Other remark' name='otherRemark' style={{ marginTop: '15px' }}>
                      <TextArea rows={4} />
                    </Form.Item>
                    <div className='text-right'>
                      <Button type='primary' htmlType='submit' disabled={submitDisabled}>
                        Submit
                      </Button>
                    </div>
                  </Form>
                </TabPane>
                {selectedCompany?.isHr && (
                  <TabPane tab='HR documents' key='2'>
                    <HrEmployeeTable employee={data} employeeId={data._id} data={data} typeOfHr={selectedCompany?.typeOfHr} />
                  </TabPane>
                )}
                {selectedCompany?.isHr && (
                  <TabPane tab='Create new request' key='3'>
                    <HrForm
                      employeeId={data._id}
                      data={data}
                      companyTypeOfHR={selectedCompany.typeOfHr}
                      selectedCompany={selectedCompany}
                      datesForDisabling={datesForDisabling}
                    />
                  </TabPane>
                )}
                {selectedCompany?.isHr && (
                    <TabPane tab='Career' key='4'>
                      <CareerForm
                        employee={data}
                        userToken={token}
                        datesForDisabling={datesForDisabling}
                      />
                    </TabPane>
                )}
              </Tabs>
            )}
          </Card>
        ) : (
          <p style={{ fontSize: '1.3em', textAlign: 'center' }}>Must be in a company before adding employees!</p>
        )}
      </div>
    </div>
  );
};

export default DataForm;
