import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Popconfirm, Table } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, HistoryOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import Axios from 'axios';
import { SERVER_URL } from '../../config';

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward"
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    type,
    item: {
      index
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{
        cursor: "move",
        ...style
      }}
      {...restProps}
    />
  );
};

const GuidelineTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath, revisionPath }) => {
  const user = useContext(UserContext);
  let searchInput;
  const [tableData, setTableData] = useState(data);


  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size='small' style={{ width: 90, marginRight: 8 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: item,
    dataIndex: item === 'createdBy' ? 'user' : ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(item === 'createdBy' ? 'user' : ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item),
  }));

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={editPath + record._id}>
            <EditOutlined title={`Edit ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
        </div>

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined title={`View ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`This will delete ${title.toLowerCase()}`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='Ok'
            cancelText='Cancel'
          >
            <DeleteOutlined title={`Delete ${title.toLowerCase()}`} />
          </Popconfirm>
        </div>

        {revisionPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={revisionPath + record._id}>
              <HistoryOutlined title={`Revision ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )}
      </div>
    ),
  });

  const components = {
    body: {
      row: DraggableBodyRow
    }
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = tableData[dragIndex];
      setTableData(
        update(tableData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      );
    },
    [tableData]
  );

  const sortCategories = async () => {
    await Axios.put(`${SERVER_URL}/guideline-category-sort`, { data: tableData },
      { withCredentials: false, headers: { Authorization: `Bearer ${user.data.token}` } });
  };

  useEffect(() => {
    sortCategories();
  }, [tableData]);

  return (
    <div>
      {user.data.role.includes('admin') || user.data.role.includes('SuperAdmin') ?
        <DndProvider backend={HTML5Backend}>
          <Table
            size='middle'
            bordered
            dataSource={tableData}
            columns={columns}
            rowKey='_id'
            pagination={{
              defaultPageSize: 20,
              position: 'bottom',
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
              hideOnSinglePage: true,
            }}
            components={components}
            onRow={(_, index) => {
              return {
                index,
                moveRow,
              };
            }}
          />
        </DndProvider>
        :
        <Table
          size='middle'
          bordered
          dataSource={data}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />}

    </div>
  );
};

export default GuidelineTable;
