import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

export default function HrLogoIcon({ props }) {
  const { theme } = useThemeContext();
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_12_37)'>
        <path
          d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z'
          fill={theme === 'dark' ? '#26BEBE' : 'white'}
        />
        <path
          d='M10.7571 17.8281C14.3181 17.1233 17.1234 14.318 17.8281 10.757L13.2427 6.17157L12.414 6.41401L10.0001 4.00001L4.67759 9.02092L4.00006 10L6.48635 12.4863L6.17163 13.2427L10.7571 17.8281Z'
          fill={theme === 'dark' ? '#00A49A' : 'white'}
        />
        <path
          d='M14.0001 9.99998V7.99998H12.8741C12.7797 7.63302 12.6337 7.28694 12.4457 6.96864L13.2428 6.17154L11.8285 4.75731L11.0314 5.55441C10.7132 5.36636 10.3671 5.22042 10.0001 5.12599V4.00001H8.00009V5.12599C7.63313 5.22042 7.28706 5.36643 6.96875 5.55441L6.17165 4.75731L4.75742 6.1715L5.55452 6.9686C5.36647 7.28684 5.22054 7.63295 5.12611 7.99995H4.00012V9.99995H5.12611C5.22054 10.3669 5.36654 10.713 5.55452 11.0313L4.75742 11.8284L6.17162 13.2426L6.96872 12.4455C7.28695 12.6336 7.63306 12.7795 8.00006 12.8739V14H10.0001V12.874C10.367 12.7796 10.7131 12.6336 11.0314 12.4456L11.8285 13.2427L13.2427 11.8285L12.4456 11.0314C12.6337 10.7132 12.7796 10.3671 12.874 10.0001H14.0001V9.99998H14.0001ZM9.00008 11C7.89554 11 7.00008 10.1045 7.00008 9C7.00008 7.89546 7.89554 7 9.00008 7C10.1046 7 11.0001 7.89546 11.0001 9C11.0001 10.1045 10.1046 11 9.00008 11Z'
          fill='#324A5E'
        />
        <path
          d='M14.0001 9.99998V7.99998H12.8741C12.7797 7.63302 12.6337 7.28694 12.4457 6.96864L13.2428 6.17154L11.8285 4.75731L11.0314 5.55441C10.7132 5.36636 10.3671 5.22042 10.0001 5.12599V4.00001H9.00012V7C10.1047 7 11.0001 7.89546 11.0001 9C11.0001 10.1045 10.1047 11 9.00012 11V14H10.0001V12.874C10.3671 12.7796 10.7131 12.6336 11.0314 12.4456L11.8285 13.2427L13.2427 11.8285L12.4456 11.0314C12.6337 10.7132 12.7796 10.3671 12.8741 10.0001H14.0001V9.99998H14.0001Z'
          fill='#2B3B4E'
        />
        <path
          d='M9.00009 12.2121C7.22888 12.2121 5.78796 10.7712 5.78796 9C5.78796 7.22879 7.22888 5.78788 9.00009 5.78788C10.7713 5.78788 12.2122 7.22879 12.2122 9C12.2122 10.7712 10.7712 12.2121 9.00009 12.2121ZM9.00009 6.21211C7.46288 6.21211 6.21219 7.46279 6.21219 9C6.21219 10.5372 7.46288 11.7879 9.00009 11.7879C10.5373 11.7879 11.788 10.5372 11.788 9C11.788 7.46279 10.5373 6.21211 9.00009 6.21211Z'
          fill='#B5F1F4'
        />
        <path
          d='M12.2122 9C12.2122 7.22886 10.7713 5.78788 9.00006 5.78788V6.21211C10.5373 6.21211 11.788 7.46279 11.788 9C11.788 10.5372 10.5373 11.7879 9.00006 11.7879V12.2121C10.7712 12.2121 12.2122 10.7712 12.2122 9Z'
          fill='#84DBFF'
        />
        <path
          d='M9.00001 11.8264C10.561 11.8264 11.8264 10.561 11.8264 9C11.8264 7.43901 10.561 6.17358 9.00001 6.17358C7.43902 6.17358 6.17358 7.43901 6.17358 9C6.17358 10.561 7.43902 11.8264 9.00001 11.8264Z'
          fill='white'
        />
        <path
          d='M11.8265 9C11.8265 7.43903 10.561 6.17358 9.00006 6.17358V11.8265C10.561 11.8264 11.8265 10.561 11.8265 9Z'
          fill='#E9EFF4'
        />
        <path
          d='M10.568 10.4241H7.43213V9.89262C7.43213 9.51486 7.6739 9.17947 8.03225 9.06001L8.60807 8.86806H9.39202L9.96784 9.06001C10.3262 9.17947 10.568 9.51486 10.568 9.89262V10.4241Z'
          fill='white'
        />
        <path
          d='M10.5679 9.89262C10.5679 9.51486 10.3261 9.17947 9.96779 9.06001L9.39197 8.86806H8.9798V10.4241H10.5678V9.89262H10.5679Z'
          fill='#B8C9D9'
        />
        <path d='M9.39201 8.86806L9.00009 9.26002L8.60809 8.86806V8.47607H9.39201V8.86806Z' fill='#FED8B2' />
        <path d='M8.9798 8.47607V9.23984L9.00005 9.26002L9.39197 8.86806V8.47607H8.9798Z' fill='#EFC095' />
        <path
          d='M8.21606 7.69212C8.21606 7.25913 8.56703 6.90817 9.00001 6.90817C9.433 6.90817 9.78396 7.25913 9.78396 7.69212C9.78396 8.1251 9.433 8.86806 9.00001 8.86806C8.56703 8.86806 8.21606 8.1251 8.21606 7.69212Z'
          fill='#F4E3C3'
        />
        <path
          d='M9.0001 6.90817C8.99331 6.90817 8.9867 6.90901 8.97992 6.90919V8.86693C8.98663 8.86729 8.99331 8.86802 9.0001 8.86802C9.43308 8.86802 9.78405 8.12507 9.78405 7.69208C9.78401 7.25917 9.43301 6.90817 9.0001 6.90817Z'
          fill='#FED8B2'
        />
        <path
          d='M9.78396 7.69212C9.78396 7.25913 9.433 6.90817 9.00001 6.90817C8.56703 6.90817 8.21606 7.25913 8.21606 7.69212C8.21606 7.86491 8.27207 8.08696 8.36678 8.29157C8.3446 8.17379 8.35054 8.05338 8.36217 7.96254C8.85981 7.92352 9.34001 7.69071 9.34001 7.69071C9.34001 7.69071 9.4008 7.8024 9.62875 7.90179C9.64489 7.99453 9.66088 8.14532 9.63311 8.29199C9.72785 8.08727 9.78396 7.86502 9.78396 7.69212Z'
          fill='#444444'
        />
        <path
          d='M9.78399 7.69212C9.78399 7.25913 9.43302 6.90817 9.00004 6.90817C8.99325 6.90817 8.98664 6.90901 8.97986 6.90919V7.8313C9.19392 7.76131 9.33996 7.69071 9.33996 7.69071C9.33996 7.69071 9.40075 7.8024 9.6287 7.90179C9.64484 7.99453 9.66083 8.14532 9.63306 8.29199C9.72788 8.08727 9.78399 7.86498 9.78399 7.69212Z'
          fill='#272525'
        />
        <path d='M8.83521 10.4104L8.94507 9.39048H9.05497L9.16483 10.4104H8.83521Z' fill='#F9B54C' />
        <path d='M9.05496 9.39048H8.9798V10.4104H9.16482L9.05496 9.39048Z' fill='#F4A200' />
        <path
          d='M8.42874 8.92783L8.03225 9.06001C7.6739 9.17947 7.43213 9.51487 7.43213 9.89262V10.4241H8.97981V10.358L8.42874 8.92783Z'
          fill='#324A5E'
        />
        <path
          d='M10.5679 9.89262C10.5679 9.51487 10.3261 9.17947 9.96779 9.06001L9.5713 8.92783L8.99998 10.4104L8.9798 10.358V10.4241H10.5678V9.89262H10.5679Z'
          fill='#2B3B4E'
        />
        <path
          d='M9.57132 8.92783L9.67992 9.39048L9.47387 9.49352L9.67992 9.73048L9 10.4104L9.57132 8.92783Z'
          fill='#1F2D3A'
        />
        <path
          d='M8.42873 8.92783L8.32013 9.39048L8.52615 9.49352L8.32013 9.73048L9.00002 10.4104L8.42873 8.92783Z'
          fill='#2B3B4E'
        />
        <path d='M9.21636 9.26177L9.07211 9.6017H8.9279L8.78369 9.26177H9.21636Z' fill='#F4A200' />
        <path d='M9.21633 9.26177H8.9798V9.6017H9.07208L9.21633 9.26177Z' fill='#CC8200' />
        <path d='M9.33999 9.39048L9.57131 8.92782L9.39198 8.86806L9.00006 9.26001L9.33999 9.39048Z' fill='#CFDBE6' />
        <path d='M8.66007 9.39048L8.42871 8.92782L8.60808 8.86806L9 9.26001L8.66007 9.39048Z' fill='#E9EFF4' />
      </g>
      <defs>
        <clipPath id='clip0_12_37'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
